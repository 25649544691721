import { useTranslation } from 'react-i18next';
import CustomDialog from '../../../dialogs/CustomDialog';

interface IIntersectionDetectedDialogProps {
  show: boolean;
  setShow: (show: boolean) => void;
  selection: Selection | null;
  typeOfIntersectedElement: 'Mark' | 'Link';
}

function IntersectionDetectedDialog({
  show,
  setShow,
  selection,
  typeOfIntersectedElement,
}: IIntersectionDetectedDialogProps): JSX.Element {
  const { t: translation } = useTranslation();

  return (
    <CustomDialog
      titleId='IntersectionDetectedDialog'
      dialogTitle={translation(
        `selectionOverlapsWith${typeOfIntersectedElement}`,
      )}
      show={show}
      closeFunction={() => setShow(false)}
      actionFunction={() => {
        setShow(false);
        if (selection) {
          selection.collapseToStart();
        }
      }}
      closeTitle={translation('cancel')}
      actionTitle={translation('removeSelection')}
      actionButtonVariant='outline-danger'>
      {translation(`cannotOverlapWithExisting${typeOfIntersectedElement}`)}
    </CustomDialog>
  );
}

export default IntersectionDetectedDialog;
