import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  useGetApiVersionsArticleByIdQuery,
  usePostApiVersionsMutation,
  usePutApiVersionsConvertByIdMutation,
  usePutApiVersionsByIdMutation,
  useGetApiCategoryTreeQuery,
  useGetApiSettingsUserQuery,
} from '../../redux/store/api/api';
import {
  selectActiveVersion,
  selectCurrentVersions,
  setMaxNumberOfVersionsDialogOpened,
} from '../../redux/store/content/slice';
import { addMessage } from '../../redux/store/layout/slice';
import { ArticleType } from '../../shared/enums';
import { formatToInputDateString } from '../../shared/utils';
import CustomCard from '../cards/CustomCard';
import Loader from '../loader/Loader';
import VersionForm from '../version/VersionForm';
import VersionSelect from '../version/VersionSelect';
import ValidityDateIsNotChangedDialog from '../version/dialogs/ValidityDateIsNotChangedDialog';
import {
  EDIT_MODE_KEY,
  settingTypeMaxVersionCount,
} from '../../shared/constants';
import DraftToVersionDialog from './dialogs/DraftToVersionDialog';
import useGetCategoryByArticleId from '../../hooks/useGetCategoryByArticleId';
import { getArticleUrl, getEditDraftUrl } from '../../shared/urlBuilder';
import MaxNumberOfVersionsDialog from '../version/dialogs/MaxNumberOfVersionsDialog';

function EditDraftForm(): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const versions = useAppSelector(selectCurrentVersions);
  const activeVersion = useAppSelector(selectActiveVersion);
  const [draftSaveDialogOpened, setDraftSaveDialogOpened] = useState(false);
  const [
    validityDateNotChangedDialogOpened,
    setValidityDateNotChangedDialogOpened,
  ] = useState(false);
  const [versionContent, setVersionContent] = useState(
    activeVersion.htmlContent || '',
  );
  const [versionTitle, setVersionTitle] = useState(activeVersion.title || '');
  const [validityDate, setValidityDate] = useState(
    activeVersion.validFrom
      ? formatToInputDateString(activeVersion.validFrom)
      : '',
  );
  const [publicationDateStart, setPublicationDateStart] = useState(
    activeVersion.publishedFrom
      ? formatToInputDateString(activeVersion.publishedFrom)
      : '',
  );
  const [publicationDateEnd, setPublicationDateEnd] = useState(
    activeVersion.publishedUntil
      ? formatToInputDateString(activeVersion.publishedUntil)
      : '',
  );
  const { refetch: refetchCategoryTree } = useGetApiCategoryTreeQuery();
  const defaultSaveAsValue = '0';
  const [saveAsValue, setSaveAsValue] = useState(defaultSaveAsValue);
  const [isValidTitle, setIsValidTitle] = useState<boolean>(true);
  const [isValidValidityDate, setIsValidValidityDate] = useState(true);
  const [
    addDraft,
    {
      isError: addDraftIsError,
      isLoading: addDraftIsLoading,
      error: addDraftError,
    },
  ] = usePostApiVersionsMutation();
  const [
    convertToVersion,
    {
      isError: convertToVersionIsError,
      isLoading: convertToVersionIsLoading,
      error: convertToVersionError,
    },
  ] = usePutApiVersionsConvertByIdMutation();
  const [
    updateDraft,
    {
      isError: updateDraftIsError,
      isLoading: updateDraftIsLoading,
      error: updateDraftError,
    },
  ] = usePutApiVersionsByIdMutation();
  const [editModeIsActive] = useSessionStorage<boolean>(EDIT_MODE_KEY, false);
  const { refetch } = useGetApiVersionsArticleByIdQuery(
    activeVersion.articleId
      ? { id: activeVersion.articleId, editMode: editModeIsActive }
      : skipToken,
  );
  const valuesNotChanged =
    activeVersion.htmlContent === versionContent &&
    activeVersion.title === versionTitle.trim() &&
    formatToInputDateString(activeVersion.validFrom || '') ===
      formatToInputDateString(validityDate) &&
    formatToInputDateString(activeVersion.publishedFrom || '') ===
      formatToInputDateString(publicationDateStart) &&
    formatToInputDateString(activeVersion.publishedUntil || '') ===
      formatToInputDateString(publicationDateEnd);

  const { data: settings } = useGetApiSettingsUserQuery();
  const versionMaxSetting =
    Number(
      settings?.resultObject?.find(
        (s) => s.settingType?.key === settingTypeMaxVersionCount,
      )?.value,
    ) || undefined;

  useEffect(() => {
    setVersionContent(activeVersion.htmlContent || '');
    setVersionTitle(activeVersion.title || '');
    setValidityDate(
      activeVersion.validFrom
        ? formatToInputDateString(activeVersion.validFrom)
        : '',
    );
    setPublicationDateStart(
      activeVersion.publishedFrom
        ? formatToInputDateString(activeVersion.publishedFrom)
        : '',
    );
    setPublicationDateEnd(
      activeVersion.publishedUntil
        ? formatToInputDateString(activeVersion.publishedUntil)
        : '',
    );
    setIsValidTitle(true);
    setIsValidValidityDate(true);
  }, [activeVersion]);

  useEffect(() => {
    if (addDraftIsError) {
      dispatch(
        addMessage({
          id: 'AddDraftError',
          variant: 'danger',
          messageKeyBody:
            addDraftError && 'data' in addDraftError
              ? addDraftError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (convertToVersionIsError) {
      dispatch(
        addMessage({
          id: 'ConvertToVersionError',
          variant: 'danger',
          messageKeyBody:
            convertToVersionError && 'data' in convertToVersionError
              ? convertToVersionError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (updateDraftIsError) {
      dispatch(
        addMessage({
          id: 'UpdateVersionError',
          variant: 'danger',
          messageKeyBody:
            updateDraftError && 'data' in updateDraftError
              ? updateDraftError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [addDraftIsError, convertToVersionIsError, updateDraftIsError]);

  const validateInputs = () => {
    let valid = true;
    if (versionTitle.trim() === '') {
      setIsValidTitle(false);
      valid = false;
    }
    if (validityDate.trim() === '') {
      setIsValidValidityDate(false);
      valid = false;
    }
    return valid;
  };

  const handleAddDraft = () => {
    addDraft({
      version: {
        type: ArticleType.Html,
        articleId: activeVersion.articleId || '',
        disabled: activeVersion.disabled,
        draft: true,
        title: versionTitle,
        validFrom: validityDate,
        publishedFrom:
          publicationDateStart === '' ? undefined : publicationDateStart,
        publishedUntil:
          publicationDateEnd === '' ? undefined : publicationDateEnd,
        htmlContent: versionContent,
      },
      oldVersionId: activeVersion.id || '',
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'AddDraftSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        navigate(
          getEditDraftUrl(
            activeVersion.articleId || '',
            result?.resultObject?.id || '',
          ),
          {
            replace: true,
          },
        );
      });
  };

  const handleEditDraft = () => {
    if (!validateInputs()) {
      return;
    }

    updateDraft({
      id: activeVersion.id || '',
      namedVersion: {
        type: ArticleType.Html,
        articleId: activeVersion.articleId || '',
        disabled: activeVersion.disabled,
        draft: true,
        title: versionTitle,
        validFrom: validityDate,
        publishedFrom:
          publicationDateStart === '' ? undefined : publicationDateStart,
        publishedUntil:
          publicationDateEnd === '' ? undefined : publicationDateEnd,
        htmlContent: versionContent,
        name: activeVersion.name,
        id: activeVersion.id || '',
      },
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'UpdateVersionSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        navigate(
          getEditDraftUrl(
            activeVersion.articleId || '',
            activeVersion.id || '',
          ),
          {
            replace: true,
          },
        );
      });
  };

  const handleConvertToVersion = (targetVersionId: string) => {
    convertToVersion({
      id: activeVersion.id || '',
      namedVersion: {
        type: ArticleType.Html,
        articleId: activeVersion.articleId || '',
        disabled: activeVersion.disabled,
        draft: false,
        title: versionTitle,
        validFrom: validityDate,
        publishedFrom:
          publicationDateStart === '' ? undefined : publicationDateStart,
        publishedUntil:
          publicationDateEnd === '' ? undefined : publicationDateEnd,
        htmlContent: versionContent,
        name: activeVersion.name,
        id: activeVersion.id,
      },
      targetVersionId,
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'ConvertToVersionSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        refetchCategoryTree();
        navigate(getArticleUrl(activeVersion.articleId || '', saveAsValue), {
          replace: true,
        });
      });
  };

  const handleOnClickSaveVersion = () => {
    if (!validateInputs()) {
      return;
    }

    if (
      saveAsValue === defaultSaveAsValue &&
      new Date(
        formatToInputDateString(activeVersion.validFrom || ''),
      ).getTime() >= new Date(validityDate).getTime()
    ) {
      setValidityDateNotChangedDialogOpened(true);
      return;
    }

    if (versionMaxSetting && versions.length >= versionMaxSetting) {
      dispatch(setMaxNumberOfVersionsDialogOpened(true));
      return;
    }

    setDraftSaveDialogOpened(true);
  };

  const category = useGetCategoryByArticleId(activeVersion.articleId);
  const isLoading =
    addDraftIsLoading || convertToVersionIsLoading || updateDraftIsLoading;

  return (
    <div className='article-content'>
      <CustomCard
        helpId='help_3_4'
        focusableHeadline
        headlineAsH1
        iconClass={category?.categoryTypeIconClass || ''}
        title={translation('editDraft')}
        customCardStyle={{ flex: 1 }}>
        <div aria-busy={isLoading}>
          {isLoading && <Loader />}
          {!isLoading && (
            <>
              <VersionForm
                versionContent={versionContent}
                setVersionContent={setVersionContent}
                versionTitle={versionTitle}
                setVersionTitle={setVersionTitle}
                validityDate={validityDate}
                setValidityDate={setValidityDate}
                publicationStart={publicationDateStart}
                setPublicationStart={setPublicationDateStart}
                publicationEnd={publicationDateEnd}
                setPublicationEnd={setPublicationDateEnd}
                isValidTitle={isValidTitle}
                setIsValidTitle={setIsValidTitle}
                isValidValidityDate={isValidValidityDate}
                setIsValidValidityDate={setIsValidValidityDate}
              />
              <VersionSelect
                defaultSaveAsValue={defaultSaveAsValue}
                saveAsValue={saveAsValue}
                setSaveAsValue={setSaveAsValue}
              />
              <div className='text-end'>
                <Button
                  className='me-1 mt-1'
                  variant='outline-dark'
                  onClick={() => handleAddDraft()}>
                  {translation('saveAsNewDraft')}
                </Button>
                <Button
                  className='me-1 mt-1'
                  variant='outline-dark'
                  disabled={valuesNotChanged}
                  onClick={() => handleEditDraft()}>
                  {translation('saveDraft')}
                </Button>
                <Button
                  className='me-1 mt-1'
                  variant='outline-success'
                  onClick={handleOnClickSaveVersion}>
                  {translation('saveVersion')}
                </Button>
              </div>
            </>
          )}
        </div>
      </CustomCard>
      <ValidityDateIsNotChangedDialog
        dialogShow={validityDateNotChangedDialogOpened}
        setDialogShow={setValidityDateNotChangedDialogOpened}
      />
      <DraftToVersionDialog
        dialogShow={draftSaveDialogOpened}
        setDialogShow={setDraftSaveDialogOpened}
        handleConvertToVersion={handleConvertToVersion}
        targetVersionId={saveAsValue === defaultSaveAsValue ? '' : saveAsValue}
      />
      <MaxNumberOfVersionsDialog />
    </div>
  );
}

export default EditDraftForm;
