import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiCategoryTreeQuery,
  useGetApiCategoryTypesQuery,
  usePutApiCategoriesByIdMutation,
} from '../../../redux/store/api/api';
import {
  selectContextCategory,
  selectEditCategoryDialogOpened,
  setContextCategory,
  setEditCategoryDialogOpened,
} from '../../../redux/store/content/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import { RightKey } from '../../../shared/enums';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';
import CategoryForm from '../CategoryForm';
import { formatToInputDateString } from '../../../shared/utils';

interface IEditCategoryDialogProps {
  setLastUpdatedItem: (updatedId: string) => void;
}

function EditCategoryDialog({
  setLastUpdatedItem,
}: IEditCategoryDialogProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { t: translation } = useTranslation();
  const dialogShow = useAppSelector(selectEditCategoryDialogOpened);
  const category = useAppSelector(selectContextCategory);
  const [categoryNameIsValid, setCategoryNameIsValid] = useState(true);
  const [categoryTypeIsValid, setCategoryTypeIsValid] = useState(true);
  const [categoryAcronymIsValid, setCategoryAcronymIsValid] = useState(true);
  const [categoryName, setCategoryName] = useState(category.name || '');
  const [categoryAcronym, setCategoryAcronym] = useState(
    category.abbreviation || '',
  );
  const [categoryTypeId, setCategoryTypeId] = useState(
    category.categoryTypeId || '',
  );
  const [lastModified, setLastModified] = useState(
    category.lastModified ? formatToInputDateString(category.lastModified) : '',
  );
  const [inheritCategoryType, setInheritCategoryType] = useState(false);
  const {
    data: categoryTypes,
    isFetching,
    isError: getCategoryTypesIsError,
    error: getCategoryTypesError,
  } = useGetApiCategoryTypesQuery(!dialogShow ? skipToken : undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [
    editCategory,
    { isError: editCategoryIsError, isLoading, error: editCategoryError },
  ] = usePutApiCategoriesByIdMutation();
  const { refetch } = useGetApiCategoryTreeQuery();

  useEffect(() => {
    if (getCategoryTypesIsError) {
      dispatch(
        addMessage({
          id: 'GetCategoryTypesError',
          variant: 'danger',
          messageKeyBody:
            getCategoryTypesError && 'data' in getCategoryTypesError
              ? getCategoryTypesError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (editCategoryIsError) {
      dispatch(
        addMessage({
          id: 'UpdateGategoryError',
          variant: 'danger',
          messageKeyBody:
            editCategoryError && 'data' in editCategoryError
              ? editCategoryError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [getCategoryTypesIsError, editCategoryIsError]);

  useEffect(() => {
    setCategoryName(category.name || '');
    setCategoryAcronym(category.abbreviation || '');
    setCategoryTypeId(category.categoryTypeId || '');
    setInheritCategoryType(false);
    setLastModified(
      category.lastModified
        ? formatToInputDateString(category.lastModified)
        : '',
    );
    setCategoryAcronymIsValid(true);
    setCategoryNameIsValid(true);
    setCategoryTypeIsValid(true);
  }, [category]);

  const handleEditCategory = () => {
    if (
      !categoryAcronymIsValid ||
      categoryName.trim().length === 0 ||
      categoryTypeId.trim().length === 0
    ) {
      if (categoryName.trim().length === 0) {
        setCategoryNameIsValid(false);
      }
      if (categoryTypeId.trim().length === 0) {
        setCategoryTypeIsValid(false);
      }
      return;
    }

    editCategory({
      id: category.id || '',
      category: {
        id: category.id,
        name: categoryName.trim(),
        parentId: category.parentId,
        order: category.order,
        categoryIds: category.categoryIds,
        articleIds: category.articleIds,
        abbreviation: categoryAcronym.trim(),
        disabled: category.disabled,
        deleted: category.deleted,
        categoryTypeId,
        lastModified,
      },
      setCategoryTypeRecursive: inheritCategoryType,
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'UpdateCategorySuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        dispatch(setEditCategoryDialogOpened(false));
        dispatch(setContextCategory({}));
        refetch();
        setLastUpdatedItem(category.id ?? '');
      });
  };

  const inputsAreNotChanged =
    category.name === categoryName.trim() &&
    category.categoryTypeId === categoryTypeId &&
    category.abbreviation === categoryAcronym.trim() &&
    category.lastModified === lastModified &&
    !inheritCategoryType;

  return (
    <CustomDialog
      titleId='EditCategoryDialog'
      show={dialogShow}
      closeFunction={() => {
        dispatch(setEditCategoryDialogOpened(false));
        dispatch(setContextCategory({}));
      }}
      closeTitle={translation('cancel')}
      actionFunction={handleEditCategory}
      actionTitle={translation('save')}
      actionButtonDisabled={inputsAreNotChanged || isLoading}
      dialogTitle={translation('editCategory')}>
      {(isFetching || isLoading) && <Loader />}
      {categoryTypes && !isFetching && !isLoading && (
        <>
          <p>{translation('fieldsAreRequiredLegend')}</p>
          <CategoryForm
            categoryName={categoryName}
            categoryNameIsValid={categoryNameIsValid}
            setCategoryNameIsValid={setCategoryNameIsValid}
            categoryAcronym={categoryAcronym}
            setCategoryAcronymIsValid={setCategoryAcronymIsValid}
            categoryTypeId={categoryTypeId}
            categoryTypeIsValid={categoryTypeIsValid}
            setCategoryTypeIsValid={setCategoryTypeIsValid}
            setCategoryName={setCategoryName}
            setCategoryAcronym={setCategoryAcronym}
            setCategoryTypeId={setCategoryTypeId}
            categoryTypeCanSet={
              category.permittedActions?.includes(
                RightKey.RightCategoryManagementChangeCategoryType,
              ) || false
            }
          />
          {category.permittedActions?.includes(
            RightKey.RightCategoryManagementChangeCategoryType,
          ) && (
            <Form.Check
              className='mt-3 mb-3'
              type='checkbox'
              id='inheritCategoryType'
              label={translation('inheritCategoryType')}
              checked={inheritCategoryType}
              onChange={() => setInheritCategoryType(!inheritCategoryType)}
            />
          )}
        </>
      )}
    </CustomDialog>
  );
}

export default EditCategoryDialog;
