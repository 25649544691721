import { useEffect, useMemo, useState } from 'react';
import TreeView from 'react-accessible-treeview';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useInterval, useSessionStorage } from 'usehooks-ts';
import _ from 'lodash';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  useGetApiCategoryTreeCheckForChangeQuery,
  useGetApiCategoryTreeQuery,
  useGetApiSettingsUserQuery,
} from '../../redux/store/api/api';
import { addMessage } from '../../redux/store/layout/slice';
import Loader from '../loader/Loader';
import './Content.scss';
import ContentTreeItem from './ContentTreeItem';
import { ContentTreeItems, IArticleTreeItem, ICategoryTreeItem } from './types';
import AddCategoryDialog from './dialogs/AddCategoryDialog';
import EditCategoryDialog from './dialogs/EditCategoryDialog';
import {
  selectControlledSelectedIds,
  selectMoveElementsStarted,
  setControlledSelectedIds,
  setTreeContentInvalid,
} from '../../redux/store/content/slice';
import { getTreeItems } from './functions';
import ContentImportDialog from './dialogs/ContentImportDialog';
import ElementsToRecycleBinDialog from './dialogs/ElementsToRecycleBinDialog';
import AddStructureElementDialog from './dialogs/AddStructureElementDialog';
import RenameStructureElementDialog from './dialogs/RenameStructureElementDialog';
import { RightKey } from '../../shared/enums';
import {
  articleIdUrlParam,
  EXPANDED_CATEGORY_IDS,
  settingTypeContentTreeValidCheckInterval,
} from '../../shared/constants';
import MoveContentItemAlert from './MoveContentItemAlert';
import ChangeValidDateDialog from './dialogs/ChangeValidDateDialog';
import MoveElementsDialog from './dialogs/MoveElementsDialog';
import DisableElementsDialog from './dialogs/DisableElementsDialog';
import ActivateElementsDialog from './dialogs/ActivateElementsDialog';
import ChangePublishDatesDialog from './dialogs/ChangePublishDatesDialog';

function ContentTree(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [lastUpdatedItem, setLastUpdatedItem] = useState<string>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const controlledSelectedIds = useAppSelector(selectControlledSelectedIds);
  const moveElementsStarted = useAppSelector(selectMoveElementsStarted);
  const [
    expandedCategoriesInSessionStorage,
    setExpandedCategoriesInSessionStorage,
  ] = useSessionStorage<string[]>(EXPANDED_CATEGORY_IDS, []);
  const [searchParams] = useSearchParams();
  const activeArticleId = searchParams.get(articleIdUrlParam);
  const {
    data: contentTreeData,
    isFetching: categoriesIsFetching,
    isError,
    error,
  } = useGetApiCategoryTreeQuery();
  const treeCheckIntervalTime = 300000; // 5 min
  const milliSecPerMinute = 60000;
  const { data: settings, isFetching: settingsIsFetching } =
    useGetApiSettingsUserQuery();
  const defaultValidCheckTime = 15;
  const validCheckSetting = Number(
    settings?.resultObject?.find(
      (s) => s.settingType?.key === settingTypeContentTreeValidCheckInterval,
    ) || defaultValidCheckTime,
  );

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetContentTreeError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  const treeData = useMemo<ContentTreeItems>(() => {
    if (contentTreeData?.resultObject) {
      const data = getTreeItems(contentTreeData.resultObject);

      const filteredExpandedIds = expandedCategoriesInSessionStorage.filter(
        (c) => data.find((d) => d.id === c),
      );

      if (!_.isEqual(filteredExpandedIds, expandedCategoriesInSessionStorage)) {
        setExpandedCategoriesInSessionStorage(filteredExpandedIds);
      }
      setSelectedIds([]);
      dispatch(setControlledSelectedIds(undefined));

      return data;
    }
    return [];
  }, [contentTreeData]);

  useEffect(() => {
    // Open tree for active article
    if (activeArticleId && !categoriesIsFetching && contentTreeData) {
      if (
        contentTreeData &&
        !contentTreeData.resultObject?.articles?.find(
          (a) => a.id === activeArticleId,
        )
      ) {
        navigate('/');
        return;
      }

      const parentOfAtricle = contentTreeData.resultObject?.categories?.find(
        (c) => c.articleIds?.includes(activeArticleId),
      );

      const treePath: string[] | undefined = parentOfAtricle
        ? [...(parentOfAtricle.treePath || []), parentOfAtricle.id || '']
        : undefined;

      if (treePath && !_.isEqual(treePath, [''])) {
        setExpandedCategoriesInSessionStorage(
          _.union(expandedCategoriesInSessionStorage, treePath),
        );
      }
    }

    // scroll to tree element
    setTimeout(() => {
      const section: HTMLElement | null = document.getElementById(
        activeArticleId || '',
      );

      if (section) {
        if (section.getBoundingClientRect().bottom > window.innerHeight) {
          section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }

        if (section.getBoundingClientRect().top < 0) {
          section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }

        section.focus();
      }
    });
  }, [activeArticleId, categoriesIsFetching]);

  const { refetch: checkTreeForChanges, data: isTreeChanged } =
    useGetApiCategoryTreeCheckForChangeQuery(
      contentTreeData?.resultObject?.utcTime && !settingsIsFetching
        ? {
            utcTimestamp: contentTreeData.resultObject.utcTime,
          }
        : skipToken,
    );

  useInterval(
    () => {
      if (
        contentTreeData?.resultObject?.utcTime &&
        Date.parse(contentTreeData?.resultObject?.utcTime) +
          validCheckSetting * milliSecPerMinute <
          Date.parse(new Date().toUTCString())
      ) {
        checkTreeForChanges();
      }
    },
    settingsIsFetching ? null : treeCheckIntervalTime,
  );

  useEffect(() => {
    dispatch(setTreeContentInvalid(isTreeChanged?.resultObject || false));
  }, [isTreeChanged]);

  return (
    <>
      <div aria-busy={categoriesIsFetching}>
        {categoriesIsFetching && <Loader />}
        {!categoriesIsFetching && contentTreeData && treeData.length > 1 && (
          <>
            {moveElementsStarted && <MoveContentItemAlert />}
            <TreeView
              onBlur={() => {
                setLastUpdatedItem(undefined);
              }}
              focusedId={lastUpdatedItem}
              multiSelect
              selectedIds={controlledSelectedIds}
              togglableSelect
              propagateCollapse
              expandedIds={expandedCategoriesInSessionStorage}
              data={treeData}
              onExpand={(e) => {
                setExpandedCategoriesInSessionStorage(
                  Array.from(e.treeState.expandedIds as Set<string>),
                );
              }}
              onSelect={(e) => {
                setSelectedIds(Array.from(e.treeState.selectedIds) as string[]);
              }}
              nodeRenderer={({
                element,
                getNodeProps,
                level,
                isBranch,
                isExpanded,
                handleExpand,
                handleSelect,
                isSelected,
              }) => {
                const convertedElement = element as
                  | IArticleTreeItem
                  | ICategoryTreeItem;
                const permittedActions: RightKey[] =
                  ('recentVersionId' in convertedElement
                    ? (
                        treeData.find(
                          (i) => i.id === convertedElement.parent,
                        ) as ICategoryTreeItem
                      ).permittedActions
                    : convertedElement.permittedActions) || [];
                return ContentTreeItem({
                  level,
                  isBranch,
                  isExpanded,
                  getNodeProps,
                  handleExpand,
                  permittedActions,
                  handleSelect,
                  isSelected,
                  selectedIds,
                  element: convertedElement,
                });
              }}
            />
          </>
        )}
      </div>
      <AddCategoryDialog setLastUpdatedItem={setLastUpdatedItem} />
      <EditCategoryDialog setLastUpdatedItem={setLastUpdatedItem} />
      <AddStructureElementDialog setLastUpdatedItem={setLastUpdatedItem} />
      <RenameStructureElementDialog setLastUpdatedItem={setLastUpdatedItem} />
      <ContentImportDialog setLastUpdatedItem={setLastUpdatedItem} />
      <ElementsToRecycleBinDialog selectedIds={selectedIds} />
      <MoveElementsDialog setLastUpdatedItem={setLastUpdatedItem} />
      <DisableElementsDialog
        selectedIds={selectedIds}
        setLastUpdatedItem={setLastUpdatedItem}
      />
      <ActivateElementsDialog
        selectedIds={selectedIds}
        setLastUpdatedItem={setLastUpdatedItem}
      />
      <ChangeValidDateDialog setLastUpdatedItem={setLastUpdatedItem} />
      <ChangePublishDatesDialog setLastUpdatedItem={setLastUpdatedItem} />
    </>
  );
}

export default ContentTree;
