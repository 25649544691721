import { useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useIsFirstRender, useSessionStorage } from 'usehooks-ts';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  Mark,
  Note,
  useGetApiNotesByVersionIdQuery,
  useGetApiVersionsInvalidMarksQuery,
  usePutApiMarksByIdMutation,
} from '../../../redux/store/api/api';
import {
  selectActiveNote,
  selectActiveVersion,
  selectNoteData,
  setActiveNote,
} from '../../../redux/store/content/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import convertFromRange from '../../version/functions/convertFromRange';
import makeSuggestionEl from '../../version/functions/makeSuggestionEl';
import '../Marks.scss';
import { NoteVisibilityType } from '../../../shared/enums';
import { addMessage } from '../../../redux/store/layout/slice';
import DeleteNoteDialog from '../../notes/dialogs/DeleteNoteDialog';
import DeleteMarkFromNoteDialog from './DeleteMarkFromNoteDialog';
import { ExtendedNote, VisibilityGroupString } from '../../notes/types';
import makeContent from '../../version/functions/makeContent';
import testForIntersection from '../../version/functions/testForIntersection';
import {
  MARK_VISIBILITY_GROUP_KEY,
  SUGGESTED_EL_ID_EXTENSION,
  SUGGESTION_CONTENT_CONTAINER_ID,
} from '../../../shared/constants';
import makeRangeFromSuggestion from '../../version/functions/makeRangeFromSuggestion';
import { getNotesByVisibilityGroup } from '../../notes/functions';
import { getRange } from '../../version/functions/validateRange';

interface IMarkNeedsActionDialogProps {
  show: boolean;
  setShow: (show: boolean) => void;
  getUserCanDeleteNote: (note: Note) => boolean;
  getUserCanEditNote: (note: Note) => boolean;
  getUserCouldEditNote: (note: Note) => boolean;
  getMarkIsError: (note: ExtendedNote) => boolean;
}

function MarkNeedsActionDialog({
  show,
  setShow,
  getUserCanDeleteNote,
  getUserCanEditNote,
  getUserCouldEditNote,
  getMarkIsError,
}: IMarkNeedsActionDialogProps): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const version = useAppSelector(selectActiveVersion);
  const note = useAppSelector(selectActiveNote);
  const [, setActiveMarkVisibilityGroup] =
    useSessionStorage<VisibilityGroupString | null>(
      MARK_VISIBILITY_GROUP_KEY,
      null,
    );
  const noteData = useAppSelector(selectNoteData);
  const [suggestion, setSuggestion] = useState<string | null>('');
  const [selectingNewMark, setSelectingNewMark] = useState(false);
  const [deleteNoteDialogOpened, setDeleteNoteDialogOpened] = useState(false);
  const [deleteMarkDialogOpened, setDeleteMarkDialogOpened] = useState(false);
  const [suggestionContainer, setSuggestionContainer] = useState(
    document.getElementById(SUGGESTION_CONTENT_CONTAINER_ID),
  );
  const [showInvalidSelectionWarning, setShowInvalidSelectionWarning] =
    useState(false);
  const isFirstRender = useIsFirstRender();

  const [updateMark, { isError: updateMarkIsError, error }] =
    usePutApiMarksByIdMutation();
  const { refetch: refetchNotes } = useGetApiNotesByVersionIdQuery(
    version.id
      ? {
          versionId: version.id,
        }
      : skipToken,
  );
  const { refetch: refetchInvalidMarks } = useGetApiVersionsInvalidMarksQuery();

  const resetStates = () => {
    setShow(false);
    setSelectingNewMark(false);
    setShowInvalidSelectionWarning(false);
  };

  const makeSuggestionContent = (suggestionNote?: Note) => {
    let marksToShow: Note[] = [];

    marksToShow =
      getNotesByVisibilityGroup(
        NoteVisibilityType[note.visibility || 0] as VisibilityGroupString,
        noteData,
      ).filter((n) => n.mark?.id && !n.mark.markInvalid) || [];

    if (suggestionNote) {
      marksToShow.push(suggestionNote);
    }

    return makeContent({
      content: version.htmlContent || '',
      elements: marksToShow,
      tagName: 'mark',
      isSuggestionContent: true,
      suggestionElId: suggestionNote?.mark?.id || '',
    }).content;
  };

  const onSave = () => {
    let range: Range | null | undefined = makeRangeFromSuggestion(
      suggestionContainer,
      'suggestion-mark',
    );
    const selection = window.getSelection();

    if (!range) {
      if (selection && !selection.isCollapsed) {
        range = getRange(selection, SUGGESTION_CONTENT_CONTAINER_ID);
        if (!range) {
          setShowInvalidSelectionWarning(true);
          return;
        }
      } else {
        setShowInvalidSelectionWarning(true);
        return;
      }
    }

    if (testForIntersection(range, SUGGESTION_CONTENT_CONTAINER_ID, 'mark')) {
      selection?.collapseToStart();
      setShowInvalidSelectionWarning(true);
      return;
    }

    const markId = note?.mark?.id || '';
    const changedMark: Mark = {
      id: markId,
      ...convertFromRange(range, SUGGESTION_CONTENT_CONTAINER_ID, document),
      noteId: note?.id || '',
      markInvalid: false,
      markAutoUpdated: false,
    };
    updateMark({ id: markId, mark: changedMark })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'UpdateMarkSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        setShow(false);
        resetStates();
        refetchNotes();
        refetchInvalidMarks();
        if (note.visibility !== undefined) {
          setActiveMarkVisibilityGroup(
            NoteVisibilityType[note.visibility] as VisibilityGroupString,
          );
        }
      });
  };

  const onSetNewMark = () => {
    if (!suggestionContainer) {
      return;
    }
    setSelectingNewMark(true);
    setSuggestion(makeSuggestionContent() || '');
    const selection = window.getSelection();
    selection?.collapseToStart();
  };

  // Scroll to suggested mark in content
  useEffect(() => {
    if (suggestionContainer) {
      const suggestionMark = document.querySelector(
        `[id$="${SUGGESTED_EL_ID_EXTENSION}"]`,
      );
      suggestionMark?.scrollIntoView(true);
    }
  }, [suggestionContainer]);

  useEffect(() => {
    if (updateMarkIsError) {
      dispatch(
        addMessage({
          id: 'UpdateMarkError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [updateMarkIsError]);

  useEffect(() => {
    if (show) {
      setSuggestionContainer(
        document.getElementById(SUGGESTION_CONTENT_CONTAINER_ID),
      );
    }
  }, [show]);

  useEffect(() => {
    if (isFirstRender || !note || !show) {
      return;
    }

    const suggestionMark = makeSuggestionEl(
      makeSuggestionContent(),
      note.mark?.patternString || '',
      note.mark?.id || '',
    );

    if (suggestionMark) {
      setSuggestion(
        makeSuggestionContent({
          ...note,
          mark: { ...note?.mark, ...suggestionMark },
        }),
      );
    } else {
      setSuggestion('');
    }
  }, [note, show]);

  return (
    <>
      <CustomDialog
        dialogId='MarkNeedsActionDialog'
        titleId='MarkNeedsActionDialogTitle'
        dialogTitle={
          getUserCanEditNote(note)
            ? translation('checkMark')
            : translation('markNotFound')
        }
        show={show}
        closeFunction={() => {
          dispatch(setActiveNote({}));
          resetStates();
        }}
        closeTitle={translation('cancel')}>
        {showInvalidSelectionWarning && (
          <Alert
            aria-label={translation('closeAlert')}
            variant='warning'
            onClose={() => setShowInvalidSelectionWarning(false)}
            dismissible>
            <p className='m-0'>{translation('makeNewSelection')}</p>
          </Alert>
        )}

        {!getUserCanEditNote(note) && !getUserCouldEditNote(note) && (
          <p>{translation('markNotValidCreatorWasInformed')}</p>
        )}

        {!getUserCanEditNote(note) && getUserCouldEditNote(note) && (
          <p>{translation('markNotValidEditModeNeedsToBeActiveToEditMark')}</p>
        )}

        {getMarkIsError(note) && (
          <p>{translation('markIsErrorContactAdmin')}</p>
        )}

        <h5>{translation('titleOfTheNote')}:</h5>
        <p>{note.name}</p>
        <h5>{translation('contentOfTheNote')}:</h5>
        {note.text ? (
          <p>{note.text}</p>
        ) : (
          <p>{translation('noteHasNoContent')}</p>
        )}
        {getUserCanEditNote(note) && !getMarkIsError(note) && (
          <>
            <h5>
              {selectingNewMark
                ? translation('selectNewArea')
                : translation('suggestion')}
              :
            </h5>
            <div
              className='max-350 version-content'
              id={SUGGESTION_CONTENT_CONTAINER_ID}>
              {parse(suggestion || translation('noSuggestionFound'))}
            </div>
            <div className='mt-3'>
              <h5>{translation('originallyMarkedText')}:</h5>
              <p>{note.mark?.patternString}</p>
            </div>
          </>
        )}
        {getUserCanEditNote(note) && (
          <Button
            className={`m-1 ${
              suggestion || selectingNewMark ? 'visible' : 'invisible'
            } optionButton`}
            variant='outline-dark'
            onClick={onSave}>
            {translation('save')}
          </Button>
        )}
        {getUserCanEditNote(note) && (
          <Button
            className={`m-1 ${
              selectingNewMark ? 'invisible' : 'visible'
            } optionButton`}
            variant='outline-dark'
            onClick={onSetNewMark}>
            {translation('setNewMark')}
          </Button>
        )}
        {getUserCanDeleteNote(note) && (
          <Button
            className='m-1 optionButton'
            variant='outline-danger'
            onClick={() => {
              resetStates();
              setDeleteMarkDialogOpened(true);
            }}>
            {translation('deleteMark')}
          </Button>
        )}
        {getUserCanDeleteNote(note) && (
          <Button
            className='m-1 optionButton'
            variant='outline-danger'
            onClick={() => {
              resetStates();
              setDeleteNoteDialogOpened(true);
            }}>
            {translation('deleteWholeNote')}
          </Button>
        )}
      </CustomDialog>
      <DeleteMarkFromNoteDialog
        show={deleteMarkDialogOpened}
        setShow={setDeleteMarkDialogOpened}
      />
      <DeleteNoteDialog
        show={deleteNoteDialogOpened}
        setShow={setDeleteNoteDialogOpened}
      />
    </>
  );
}

export default MarkNeedsActionDialog;
