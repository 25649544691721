import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiUserGroupsQuery,
  usePutApiUsersByIdMutation,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import {
  clearSelectedUsers,
  selectContextUser,
  selectEditUserDialogOpened,
  setEditUserDialogOpened,
} from '../../../redux/store/user-management/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';
import UserForm from '../UserForm';

interface IEditUserDialogProps {
  setLastUpdatedItem: (updatedId: string) => void;
}

function EditUserDialog({
  setLastUpdatedItem,
}: IEditUserDialogProps): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectContextUser);
  const dialogShow = useAppSelector(selectEditUserDialogOpened);
  const [email, setEmail] = useState<string>(user.email || '');
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [firstName, setFirstName] = useState<string>(user.firstname || '');
  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [lastName, setLastName] = useState<string>(user.lastname || '');
  const [isValidLastName, setIsValidLastName] = useState(true);
  const {
    refetch,
    isError: getUserGroupsIsError,
    error: getUserGroupsError,
  } = useGetApiUserGroupsQuery({
    includeUsers: true,
    includePermittedActions: true,
  });
  const [
    updateUser,
    { isError: putUserIsError, isLoading, error: putUserError },
  ] = usePutApiUsersByIdMutation();

  useEffect(() => {
    if (dialogShow) {
      setEmail(user.email || '');
      setFirstName(user.firstname || '');
      setLastName(user.lastname || '');
      setIsValidEmail(true);
      setIsValidFirstName(true);
      setIsValidLastName(true);
    }
  }, [dialogShow]);

  useEffect(() => {
    if (getUserGroupsIsError) {
      dispatch(
        addMessage({
          id: 'GetUserGroupsError',
          variant: 'danger',
          messageKeyBody:
            getUserGroupsError && 'data' in getUserGroupsError
              ? getUserGroupsError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (putUserIsError) {
      dispatch(
        addMessage({
          id: 'UpdateUserError',
          variant: 'danger',
          messageKeyBody:
            putUserError && 'data' in putUserError
              ? putUserError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [getUserGroupsIsError, putUserIsError]);

  const validateInputs = () => {
    let valid = true;

    if (!isValidEmail || email.trim().length === 0) {
      setIsValidEmail(false);
      valid = false;
    }

    if (firstName.trim().length === 0) {
      setIsValidFirstName(false);
      valid = false;
    }

    if (lastName.trim().length === 0) {
      setIsValidLastName(false);
      valid = false;
    }

    return valid;
  };

  const handleAddUser = (): void => {
    if (!validateInputs()) {
      return;
    }

    updateUser({
      id: user.id || '',
      user: {
        id: user.id,
        userGroupId: user.userGroupId,
        firstname: firstName.trim(),
        lastname: lastName.trim(),
        email: email.trim(),
        emailConfirmed: user.emailConfirmed,
        emailSentDate: user.emailSentDate,
        disabled: user.disabled,
        loggedIn: user.loggedIn,
        timedOut: user.timedOut,
        isSuperAdmin: user.isSuperAdmin,
      },
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'UpdateUserSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        dispatch(setEditUserDialogOpened(false));
        dispatch(clearSelectedUsers());
        setLastUpdatedItem(user.id ?? '');
      });
  };

  const inputsNotChanged =
    firstName.trim() === user.firstname &&
    lastName.trim() === user.lastname &&
    email.trim() === user.email;

  return (
    <CustomDialog
      titleId='EditUserDialog'
      actionButtonDisabled={inputsNotChanged || isLoading}
      dialogTitle={translation('editUser')}
      show={dialogShow}
      closeFunction={() => {
        dispatch(setEditUserDialogOpened(false));
      }}
      actionFunction={handleAddUser}
      closeTitle={translation('cancel')}
      actionTitle={translation('save')}>
      {isLoading && <Loader />}
      {!isLoading && (
        <UserForm
          email={email}
          setEmail={setEmail}
          isValidEmail={isValidEmail}
          setIsValidEmail={setIsValidEmail}
          firstName={firstName}
          setFirstName={setFirstName}
          isValidFirstName={isValidFirstName}
          setIsValidFirstName={setIsValidFirstName}
          lastName={lastName}
          setLastName={setLastName}
          isValidLastName={isValidLastName}
          setIsValidLastName={setIsValidLastName}
        />
      )}
    </CustomDialog>
  );
}

export default EditUserDialog;
