/*
 * Custom customer code to compare versions
 * Is based on adapted code from diff_match_patch version 1.0.3 (https://www.npmjs.com/package/diff-match-patch/v/1.0.3)
 * Adapted lib code is in public/lib and is called in index.html
 */

//
// GLOBALE VARIABLEN
//

// maskierung für die ins und del tags
var ins_open = String.fromCharCode(9820);
var ins_close = String.fromCharCode(9821);
var del_open = String.fromCharCode(9822);
var del_close = String.fromCharCode(9823);
// markierungen für inlinetags
var inlt_br = String.fromCharCode(9824);
// spezialbehandlung einzelner buchstaben
var specialChars = [',', '.', '?', '!'];
var specialCharsOffset = 9826;

// spezialbehandlung einzelner elemente
var specialElementsRegEx = [
  ['§ ?[0-9]+[a-zA-Z]{0,2}( Abs(atz|.) [0-9]+)?( Satz [0-9]+)?'], // § 12 - § 15a - § 15A - § 11aa - § 15AA - § 11aa - § 14b Abs. 2 - § 14b Absatz 2 - § 22 Abs. 1 Satz 2
  // ['[0-9]+[a-z]?\.[0-9]+']; // v.36 hatte nur das
  //['[0-9]+[a-z]?\.[0-9]+'],  // RZ + Zahl + optionaler Buchstabe + Punkt + Leerzeichen + Zahl  RZ 52a. 12    , '[0-9]+ €'  <- alte version
  ['R(z|Z).? [0-9]+[a-z]?.[0-9]+'], // Rz. XX.XX  - RZ 52a. 12
  ['[0-9,.]+(,[0-9]+)? ?(€|%)'], // euro und prozent  - 1.000 € - 1.000,00 € - 12,00 € - 1,5 % - 100 %
  ['<sup>[0-9a-zA-Z]+</sup>'], // hochgestellte zeichen
  ['[0-9]+([0-9.]+)?(,[0-9]+)'], // komma zahlen - nicht mit sonstigen zahlen zusammen fassen damit punkt am satzende nicht einbezogen wird!
  ['[0-9]{1,2}. ?[0-9]{1,2}. ?[0-9]{2,4}'], // datum -  1.1.2000 - 18.8.2024 - 07. 11. 2006 (kann auch so vorkommen) - nach komma zahlen sonst fehler
  ['[0-9]+[0-9.]+[0-9]+'], // sonstige zahlen
  //['(https?://)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}(/[a-zA-Z0-9.-/]+)?']  <- probleme von v36->v37, zb in text 33
  ['(https?://)?www.[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}(/[a-zA-Z0-9.-/]+)?'], // URLs
];

// letzter zustand
var ins_start = false;
var del_start = false;
// unicode offset für die maskierung der tags
var charOffset = 9830; // Alte Idee: Supplementary Private Use Area-A ab code point U+F0000 = 983040 - benutzung von 98340 funktioniert aber nicht richtig; deswegen test mit 9830

//
//  HILFSFUNKTIONEN
//

Array.prototype.unique = function () {
  var a = this.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }
  return a;
};

function escapeRegExp(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function sanitizeEntities(str) {
  // zb text 11  -- erstmal nur die bekanntesten deutschen; evtl später noch andere adden

  if (str.indexOf('&') == -1) return str;

  str = str.replace(/&Auml;/g, 'Ä');
  str = str.replace(/&auml;/g, 'ä');
  str = str.replace(/&Ouml;/g, 'Ö');
  str = str.replace(/&ouml;/g, 'ö');
  str = str.replace(/&Uuml;/g, 'Ü');
  str = str.replace(/&uuml;/g, 'ü');
  str = str.replace(/&szlig;/g, 'ß');
  str = str.replace(/&euro;/g, '€');
  str = str.replace(/&sect;/g, '§');
  str = str.replace(/&nbsp;/g, ' ');

  return str;
}

function sanitizeSups(str) {
  str = str.replace(/&sup1;|¹/g, '<sup>1</sup>'); // U+00B9
  str = str.replace(/&sup2;|²/g, '<sup>2</sup>'); // U+00B2
  str = str.replace(/&sup3;|³/g, '<sup>3</sup>'); // U+00B3

  for (var i = 0; i <= 9; i++) {
    // U+2070 (&#8304; / &#x2070;) [0] und  U+2074 bis U+2079 -- &#x2074; bis &#x2079;  --  &#8308; bis &#8313; [4-9]
    if (i > 0 && i < 4) continue;
    var c = String.fromCharCode(8304 + i); // zeichen als solches
    var regex = new RegExp('&#x207' + i + ';|&#' + (8304 + i) + ';|' + c, 'g');
    str = str.replace(regex, '<sup>' + i + '</sup>');
  }

  return str.replace(/<\/sup><sup>/g, '');
}

function sanitizePunctuation(str) {
  str = str.replace(/[–‑‐]/g, '-'); // bindestricharten:  U+2013 / U+2010 / U+2011 -> U+002D
  str = str.replace(/:<\/em>/g, '</em>:'); //  ":</em>" vs "</em>:" angleichen  - noch andere elemente?!

  return str;
}

function sanitizeListElements(str) {
  // <ul><li>Text1 <ins></li><li>Text2</ins></li></ul>
  str = str.replace(RegExp(/ ?<(del|ins)><\/li><li>/g), '</li><li><$1>');
  // <ul><li><del>Text1</li><li></del> Text2</li></ul>
  str = str.replace(RegExp(/<\/li><li><\/(del|ins)> ?/g), '</$1></li><li>');

  str = str.replace(RegExp(/<dl><dt><\/(del|ins)> ?/g), '</$1><dl><dt>');

  return str;
}

function sanitizeTableRows(str) {
  // <tr><td>Text1</td><td>Text2 <ins></td></tr><tr><td>Text3</td><td>Text4</ins></td></tr>
  str = str.replace(
    RegExp(/ ?<(del|ins)><\/td><\/tr><tr><td>/g),
    '</td></tr><tr><td><$1>',
  );

  // </tr><tr><td></ins>  ->  </ins></tr><tr><td>
  str = str.replace(
    RegExp(/<\/td><\/tr><tr><td><\/(del|ins)> ?/g),
    '</$1></td></tr><tr><td>',
  );

  // <tr><td><ins>Text3</td><td>Text4</ins></td></tr>
  var trs = str.match(/<tr><td><(del|ins)>.+?<\/\1><\/td><\/tr>/g);

  if (trs && trs.length > 0) {
    for (var i = 0; i < trs.length; i++) {
      // wichtig: um nur neu eingefügte oder gelöschte zeilen zu finden dürfen ins bzw del nur exakt 2 mal vorkommen (am beginn und am anfang)
      // sonst kann es sein dass nur innerhalb der zelle gelöscht oder geändert wurde
      var c_ins = (trs[i].match(/<\/?ins>/g) || []).length;
      var c_del = (trs[i].match(/<\/?del>/g) || []).length;

      if (c_ins == 2 && c_del == 0) {
        var tr_new = trs[i].replace(/<\/td><td>/g, '</ins></td><td><ins>');
        tr_new = tr_new.replace(
          /<\/td><\/tr><tr><td>/g,
          '</ins></td></tr><tr><td><ins>',
        ); // über mehrere zeilen
      } else if (c_del == 2 && c_ins == 0) {
        var tr_new = trs[i].replace(/<\/td><td>/g, '</del></td><td><del>');
        tr_new = tr_new.replace(
          /<\/td><\/tr><tr><td>/g,
          '</del></td></tr><tr><td><del>',
        ); // über mehrere zeilen
      } else {
        continue;
      }

      str = str.replace(trs[i], tr_new);
    }
  }

  str = str.replace(/ ?<(ins|del)><\/td><td>/g, '</td><td><$1>'); //  <ins></td><td> -> </td><td><ins>  (auch außerhalb von table rows)

  return str;
}

function removeColGroups(str) {
  // typ <colgroup style="width: 5%;"></colgroup>
  str = str.replace(/<colgroup[^>]+><\/colgroup>/g, '');

  // typ <colgroup style="width: 5%; "/>
  str = str.replace(/<colgroup[^>]+\/>/g, '');

  return str;
}

function calcLev(s1, s2) {
  if (s1 == s2) return 0;
  if (s1.length == 0) return s2.length;
  if (s2.length == 0) return s1.length;

  var matrix = [];

  for (var i = 0; i <= s2.length; i++) {
    matrix[i] = [i];
  }
  for (var j = 0; j <= s1.length; j++) {
    matrix[0][j] = j;
  }

  for (var i = 1; i <= s2.length; i++) {
    for (var j = 1; j <= s1.length; j++) {
      if (s2.charAt(i - 1) == s1.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1 /* repl */,
          Math.min(
            matrix[i][j - 1] + 1 /* ins */,
            matrix[i - 1][j] + 1,
          ) /* del */,
        );
      }
    }
  }

  return matrix[s2.length][s1.length];
}

function changeNodesRecursive(x) {
  for (var i = 0; i < x.length; i++) {
    var y = x[i].childNodes;
    if (y.length > 1) {
      changeNodesRecursive(y);
    } else if (y.length > 0) {
      var s = x[i].innerHTML;
      var change = false;

      if (ins_start) {
        s = ins_open + s;
        change = true;
        ins_start = false;
      }
      if (del_start) {
        s = del_open + s;
        change = true;
        del_start = false;
      }

      var p_ins_open = s.indexOf(ins_open);
      var p_ins_close = s.indexOf(ins_close);
      var p_del_open = s.indexOf(del_open);
      var p_del_close = s.indexOf(del_close);

      if (p_ins_close > -1 && (p_ins_open == -1 || p_ins_open > p_ins_close)) {
        s = ins_open + s;
        change = true;
      }
      if (p_del_close > -1 && (p_del_open == -1 || p_del_open > p_del_close)) {
        s = del_open + s;
        change = true;
      }

      var p_ins_open = s.lastIndexOf(ins_open);
      var p_ins_close = s.lastIndexOf(ins_close);
      var p_del_open = s.lastIndexOf(del_open);
      var p_del_close = s.lastIndexOf(del_close);

      if (p_ins_open > -1 && (p_ins_close == -1 || p_ins_open > p_ins_close)) {
        s = s + ins_close;
        change = true;
        ins_start = true;
      }
      if (p_del_open > -1 && (p_del_close == -1 || p_del_open > p_del_close)) {
        s = s + del_close;
        change = true;
        del_start = true;
      }

      if (change == true) {
        x[i].innerHTML = s;
      }
    }
  }
}

//
//  RAW FUNKTION
//

function rawDiffMatch(text1, text2, cleanupSemantics) {
  var dmp = new diff_match_patch();

  // alter code um an wörtern zu trennen:
  // var a = dmp.diff_linesToWords_(text1, text2);
  // var lineText1 = a.chars1;
  // var lineText2 = a.chars2;
  // var lineArray = a.lineArray;
  // var diffs = dmp.diff_main(lineText1, lineText2, false);
  // dmp.diff_charsToLines_(diffs, lineArray);

  var diffs = dmp.diff_main(text1, text2, false);

  if (cleanupSemantics) {
    dmp.diff_cleanupSemantic(diffs); // manchmal bessere ergebnisse ohne diese funktion
  }

  return dmp.diff_prettyHtml(diffs);
}

//
//  MAIN COMPARE FUNC
//

function compareTexts(text1, text2) {
  // reset
  ins_start = false;
  del_start = false;

  // nbsp entfernen
  text1 = text1.replace(/ /g, ' ');
  text2 = text2.replace(/ /g, ' ');

  // lbs entfernen
  text1 = text1
    .replace(/[\n\r\t]/g, ' ')
    .replace(/[ ]+/g, ' ')
    .replace(/> </g, '><');
  text2 = text2
    .replace(/[\n\r\t]/g, ' ')
    .replace(/[ ]+/g, ' ')
    .replace(/> </g, '><');

  // ids entfernen
  text1 = text1.replace(/ id="[a-zA-Z0-9_-]+"/g, '');
  text2 = text2.replace(/ id="[a-zA-Z0-9_-]+"/g, '');

  // spans mit classes "content-ref" und "external-ref" entfernen
  text1 = text1.replace(
    /<span [^>]*class="(content|external)-ref"[^>]*>([^<]+)<\/span>/g,
    '$2',
  );
  text2 = text2.replace(
    /<span [^>]*class="(content|external)-ref"[^>]*>([^<]+)<\/span>/g,
    '$2',
  );

  // spans mit attributen "refnorm" / "refpara" / "refpara" entfernen
  text1 = text1.replace(
    /<span [^>]*ref(norm|para|abs)[^>]*>([^<]+)<\/span>/g,
    '$2',
  );
  text2 = text2.replace(
    /<span [^>]*ref(norm|para|abs)[^>]*>([^<]+)<\/span>/g,
    '$2',
  );

  // classes entfernen
  text1 = text1.replace(/ class="[a-zA-Z0-9 _-]+"/g, '');
  text2 = text2.replace(/ class="[a-zA-Z0-9 _-]+"/g, '');

  // links entfernen
  text1 = text1.replace(/<a[^>]+>([^<]+)<\/a>/g, '$1');
  text2 = text2.replace(/<a[^>]+>([^<]+)<\/a>/g, '$1');

  // divs entfernen
  text1 = text1.replace(/<[\/]?div[^>]?>/g, '');
  text2 = text2.replace(/<[\/]?div[^>]?>/g, '');

  // colgroups entfernen
  text1 = removeColGroups(text1);
  text2 = removeColGroups(text2);

  // <td/> -> <td></td> angleichen
  text1 = text1.replace(/<td\/>/g, '<td></td>');
  text2 = text2.replace(/<td\/>/g, '<td></td>');

  // <sup>2</sup> / &sup2; / ... vereinheitlichen
  text1 = sanitizeSups(text1);
  text2 = sanitizeSups(text2);

  // ähnliche satzzeichen angleichen
  text1 = sanitizePunctuation(text1);
  text2 = sanitizePunctuation(text2);

  // html entities angleichen
  text1 = sanitizeEntities(text1);
  text2 = sanitizeEntities(text2);

  // klammern separieren -> noch mehr solche zeichen? -> eigene func
  // wichtig!! nur mitten im satz, nicht im falle von <p>(3a)...
  text1 = text1.replace(/ \(/g, ' ( ');
  text2 = text2.replace(/ \(/g, ' ( ');
  text1 = text1.replace(/\) /g, ' ) ');
  text2 = text2.replace(/\) /g, ' ) ');

  // tags separieren
  text1 = text1.replace(/>/g, '> ');
  text1 = text1.replace(/</g, ' <');
  text2 = text2.replace(/>/g, '> ');
  text2 = text2.replace(/</g, ' <');

  // zusammenhängende tags verschmelzen
  text1 = ' ' + text1.replace(/> +</g, '><') + ' ';
  text2 = ' ' + text2.replace(/> +</g, '><') + ' ';
  //text1 = text1.replace(/<\/td><td/g, '</td> <td');
  //text2 = text2.replace(/<\/td><td/g, '</td> <td');

  // inline tags ausnehmen
  text1 = text1.replace(/<br ?\/?>/g, inlt_br);
  text2 = text2.replace(/<br ?\/?>/g, inlt_br);

  // verbleibende tags aus beiden texten auslesen
  var tags = [];
  var tags1 = text1.match(/<[^>]+>/g);
  var tags2 = text2.match(/<[^>]+>/g);
  if (Array.isArray(tags1) && tags1.length > 0)
    var tags = tags.concat(tags1).unique();
  if (Array.isArray(tags2) && tags2.length > 0)
    var tags = tags.concat(tags2).unique();

  // original tags aus beiden texten durch eindeutige zeichen ersetzen
  var charOffsetTags = charOffset;
  for (var i = 0; i < tags.length; i++) {
    var c = String.fromCharCode(charOffsetTags + i);
    var regex = new RegExp(escapeRegExp(tags[i]), 'g');
    text1 = text1.replace(regex, c);
    text2 = text2.replace(regex, c);
  }

  // special elements ersetzen
  var specialElements = [];
  for (var i = 0; i < specialElementsRegEx.length; i++) {
    var regex = new RegExp(specialElementsRegEx[i], 'g');
    var specialElements1 = text1.match(regex);
    var specialElements2 = text2.match(regex);
    if (Array.isArray(specialElements1) && specialElements1.length > 0)
      var specialElements = specialElements.concat(specialElements1).unique();
    if (Array.isArray(specialElements2) && specialElements2.length > 0)
      var specialElements = specialElements.concat(specialElements2).unique();
  }

  var charOffsetSpecialElements = charOffsetTags + tags.length + 1;
  for (var i = 0; i < specialElements.length; i++) {
    var c = String.fromCharCode(charOffsetSpecialElements + i);
    var regex = new RegExp(escapeRegExp(specialElements[i]), 'g');
    text1 = text1.replace(regex, c);
    text2 = text2.replace(regex, c);
  }

  // special chars ersetzen  -> hier geht url verloren
  for (var i = 0; i < specialChars.length; i++) {
    var c = String.fromCharCode(specialCharsOffset + i);
    var regex = new RegExp(
      '([a-zA-Z0-9])' + escapeRegExp(specialChars[i]),
      'g',
    );
    text1 = text1.replace(regex, '$1 ' + c + ' ');
    text2 = text2.replace(regex, '$1 ' + c + ' ');
  }

  // wörter und andere einheiten aus den beiden texten durch eindeutige zeichen ersetzen
  var charOffsetElements =
    charOffsetSpecialElements + specialElements.length + 1;
  var elements1 = text1.match(/[^ ]+/g);
  var elements2 = text2.match(/[^ ]+/g);

  var elements = [];
  if (Array.isArray(elements1) && elements1.length > 0)
    var elements = elements.concat(elements1).unique();
  if (Array.isArray(elements2) && elements2.length > 0)
    var elements = elements.concat(elements2).unique();

  for (var i = 0; i < elements.length; i++) {
    var regex = new RegExp(' ' + escapeRegExp(elements[i]) + ' ', 'g');
    var c = ' ' + String.fromCharCode(charOffsetElements + i) + ' ';
    text1 = text1.replace(regex, c);
    text2 = text2.replace(regex, c);
  }

  text1 = text1.replace(/ /g, '');
  text2 = text2.replace(/ /g, '');

  // diff erzeugen
  var s = rawDiffMatch(text1, text2, false);

  // original elemente wiederherstellen
  for (var i = 0; i < elements.length; i++) {
    var c = String.fromCharCode(charOffsetElements + i);
    s = s.replace(RegExp(c, 'g'), ' ' + elements[i] + ' ');
  }

  // special elements wiederherstellen
  for (var i = 0; i < specialElements.length; i++) {
    var c = String.fromCharCode(charOffsetSpecialElements + i);
    s = s.replace(RegExp(c, 'g'), specialElements[i]);
  }

  // original tags wiederherstellen
  for (var i = 0; i < tags.length; i++) {
    var c = String.fromCharCode(charOffsetTags + i);
    s = s.replace(RegExp(c, 'g'), tags[i]);
  }

  // ins und del tags kodieren damit sie nicht im dom berücksichtigt werden
  s = s.replace(/<ins>/g, ins_open);
  s = s.replace(/<\/ins>/g, ins_close);
  s = s.replace(/<del>/g, del_open);
  s = s.replace(/<\/del>/g, del_close);

  // überflüssige spaces entfernen
  s = s.replace(/ *</g, '<');
  s = s.replace(/> */g, '>');
  s = s.replace(/  +/g, ' ');

  // special chars wiederherstellen
  for (var i = 0; i < specialChars.length; i++) {
    var c = String.fromCharCode(specialCharsOffset + i);
    s = s.replace(RegExp(' ' + c, 'g'), specialChars[i]);
  }

  // opening ins/del am ende eines p-nodes -> in den nächsten verschieben
  s = s.replace(RegExp(ins_open + '(</p><p[^>]*?>)', 'g'), '$1' + ins_open);
  s = s.replace(RegExp(del_open + '(</p><p[^>]*?>)', 'g'), '$1' + del_open);

  // position vertauschen: <del><p>  -> <p><del> etc
  s = s.replace(RegExp(del_open + '(<[^>/]+>)', 'g'), '$1' + del_open);
  s = s.replace(RegExp(ins_open + '(<[^>/]+>)', 'g'), '$1' + ins_open);
  s = s.replace(RegExp('(</[^>]+>)' + del_close, 'g'), del_close + '$1');
  s = s.replace(RegExp('(</[^>]+>)' + ins_close, 'g'), ins_close + '$1');

  // als dom einlesen
  var dom = new DOMParser().parseFromString(s, 'text/html');

  // durch dom gehen und jedes einzelne element korrigieren
  var x = dom.documentElement.childNodes;
  changeNodesRecursive(x);

  // ins und del tags wieder herstellen + leere ins/del tags löschen
  s = dom.body.innerHTML;
  s = s.replace(RegExp(ins_open + ins_close, 'g'), '');
  s = s.replace(RegExp(del_open + del_close, 'g'), '');
  s = s.replace(RegExp(ins_open, 'g'), '<ins>');
  s = s.replace(RegExp(ins_close, 'g'), '</ins>');
  s = s.replace(RegExp(del_open, 'g'), '<del>');
  s = s.replace(RegExp(del_close, 'g'), '</del>');

  // ins / del für zusätzliche oder gelöschte listen elemente / tabellen spalten korrigieren
  s = sanitizeListElements(s);
  s = sanitizeTableRows(s);

  // schönheitskorrekturen für special chars
  for (var i = 0; i < specialChars.length; i++) {
    var escapedChar = escapeRegExp(specialChars[i]);
    s = s.replace(RegExp(' (</?ins>' + escapedChar + '[ <])', 'g'), '$1');
    s = s.replace(RegExp(' (</?del>' + escapedChar + '[ <])', 'g'), '$1');
    s = s.replace(RegExp('([a-zA-Z0-9]' + escapedChar + ') <', 'g'), '$1<');
  }

  // schönheitskorrekturen für ins/del
  s = s.replace(/<(del|ins)><(\/?[a-z]+)><\/\1>/g, '<$2>');
  s = s.replace(RegExp('<ins></ins>', 'g'), '');
  s = s.replace(RegExp('<del></del>', 'g'), '');
  s = s.replace(RegExp(' </del><ins> ', 'g'), '</del> <ins>');
  s = s.replace(RegExp('<ins> ', 'g'), ' <ins>');
  s = s.replace(RegExp('<del> ', 'g'), ' <del>');
  s = s.replace(RegExp(' </del> ', 'g'), '</del> ');
  s = s.replace(RegExp(' </ins> ', 'g'), '</ins> ');
  s = s.replace(RegExp('><ins> ', 'g'), '><ins>');
  s = s.replace(RegExp('><del> ', 'g'), '><del>');
  s = s.replace(RegExp(' </del><', 'g'), '</del><');
  s = s.replace(RegExp(' </ins><', 'g'), '</ins><');
  s = s.replace(
    RegExp('<(p|ul|td|h1|h2|h3|li|td)> <(del|ins)>', 'g'),
    '<$1><$2>',
  );
  //s = s.replace(RegExp('<(del|ins)></([a-z]+)>', "g"), '<$2><$1>'); -> probleme in : txt37 "gegenüber einem nachrangigen geschiedenen Ehegatten"
  s = s.replace(RegExp(' +', 'g'), ' ');

  // schönheitskorrekturen für inline tags
  s = s.replace(RegExp('([a-zA-Z0-9äöüÄÖÜ])<span ', 'g'), '$1 <span ');
  var inlTags = ['strong', 'em', 'b', 'i', 'u', 'span'];
  for (var i = 0; i < inlTags.length; i++) {
    var t = inlTags[i];
    s = s.replace(
      RegExp('([a-zA-Z0-9äöüÄÖÜ])<' + t + '>', 'g'),
      '$1 <' + t + '>',
    );
    s = s.replace(
      RegExp('</' + t + '>([a-zA-Z0-9äöüÄÖÜ])', 'g'),
      '</' + t + '> $1',
    );
    s = s.replace(RegExp('><' + t + '> ', 'g'), '> <' + t + '>');
    s = s.replace(RegExp(' </' + t + '>', 'g'), '</' + t + '> ');
  }
  s = s.replace(RegExp(' +', 'g'), ' ');

  // schönheitskorrekturen für sonstige tags
  s = s.replace(RegExp('</sup>([^<])', 'g'), '</sup> $1');
  s = s.replace(RegExp('([a-z]) </', 'g'), '$1</');

  // inline tags wiederherstellen
  s = s.replace(RegExp(inlt_br, 'g'), '<br />');

  // leerzeichen nach tags entfernen
  s = s.replace(RegExp('<(p|ul|td|h1|h2|h3|li|td)> ', 'g'), '<$1>');

  // klammern zurücksetzen
  s = s.replace(/\( /g, '(');
  s = s.replace(/ \)/g, ')');

  // ausgabe
  return s.trim();
}

//
//  HILFSFUNKTIONEN FÜR DEN PART-VERGLEICH
//

function splitToParts(text) {
  // var parts = text.split(/(<\/?table>?)/g);
  var parts = text.split(/(<table.*?<\/table>)/g);
  return parts.filter(String);
}

function addInnerTagRecursive(nodes, tag) {
  for (var i = 0; i < nodes.length; i++) {
    if (nodes[i].childNodes.length == 0) continue;
    // range.selectNode(document.querySelector("p"));

    if (nodes[i].firstChild.nodeType == 3) {
      // 3 = text node
      const range = document.createRange();
      const newParent = document.createElement(tag);

      range.selectNode(nodes[i].firstChild);
      range.surroundContents(newParent);
    } else {
      if (nodes[i].childNodes.length > 0) {
        addInnerTagRecursive(nodes[i].childNodes, tag);
      }
    }
  }
}

function addInnerTag(text, tag) {
  // ganze tabelle mit dem ins/del tag umrahmen:
  // if (text.indexOf('<table') == 0) return '<'+tag+'>'+text+'</'+tag+'>';

  var dom = new DOMParser().parseFromString(text, 'text/html');
  var childNodes = dom.body.childNodes;

  addInnerTagRecursive(childNodes, tag);

  return dom.body.innerHTML;
}

function getBestTextMatch(txt, start_index, parts) {
  var k = Math.min(start_index + 5, parts.length);
  var best_match_index = start_index; // das käme als nächstes
  var best_match_score = 1; // schlechtest möglichstes ergebnis eines vergleichs
  var vgl_limit = 200; // werte um die 100 haben zu keinen guten resultaten geführt, da dadurch manchmal nur die ids abgedeckt werden
  txt = txt.slice(0, vgl_limit);

  for (var i = start_index; i < k; i++) {
    var p = parts[i].slice(0, vgl_limit);
    if (parts[i].indexOf('<table') == 0) continue; // nicht mit tabellen vergleichen
    var l = calcLev(txt, p) / Math.max(p.length, txt.length);

    if (l == 0) {
      return i; // -> direkter fund -> fertig
    } else if (l < best_match_score) {
      best_match_index = i;
      best_match_score = l;
    }
  }

  if (best_match_score > 0.5) return start_index; // schlechter match unter 0.5 -> besser den nächsten text nehmen als einen der kaum passt

  return best_match_index;
}

function getNumOfTableColumns(tab, check_all) {
  var trs = tab.match(/<tr><td>.+?<\/td><\/tr>/g); // machts sinn alle durchzusehen?!
  if (!trs || trs.length < 1) return 0;
  var tds = trs[0].match(/<td/g);
  if (!tds || tds.length < 1) return 0;

  var first_row = tds.length;

  if (check_all) {
    for (var i = 0; i < trs.length; i++) {
      var tds = trs[i].match(/<td/g);
      if (!tds || tds.length < 1) return 0;
      if (tds.length != first_row) return 0;
    }
  }

  return first_row;
}

function tableMatch(tab1, tab2) {
  var c1 = getNumOfTableColumns(tab1, true);
  var c2 = getNumOfTableColumns(tab2, true);
  return c1 == c2;
}

//
//  MAIN FUNC PART VERGLEICH
//

export function compareTextsPartly(
  text1,
  text2,
  text_match_check,
  table_match_check,
) {
  var table_count_text1 = (text1.match(/<table/g) || []).length;
  var table_count_text2 = (text2.match(/<table/g) || []).length;

  // keine oder maximale eine tabelle pro text -> direkt einfache compare func aufrufen
  if (table_count_text1 < 2 && table_count_text2 < 2) {
    return compareTexts(text1, text2);
  }

  var parts_text1 = splitToParts(text1);
  var parts_text2 = splitToParts(text2);
  var res = '';
  var x = 0;
  var y = 0;
  var t1 = false;
  var t2 = false;

  do {
    var ready = false;

    do {
      var new_pass = false;

      if (x >= parts_text1.length) {
        ready = true;
      } else {
        t1 = parts_text1[x].indexOf('<table') == 0;
      }
      if (y >= parts_text2.length) {
        ready = true;
      } else {
        t2 = parts_text2[y].indexOf('<table') == 0;
      }

      // gibt es einen text der besser passt als der nächste?!
      if (!ready && text_match_check && !t1 && !t2) {
        // nur texte prüfen

        var best_matching_y = getBestTextMatch(parts_text1[x], y, parts_text2);
        var best_matching_x = getBestTextMatch(parts_text2[y], x, parts_text1);

        if (x < best_matching_x && y == best_matching_y) {
          for (var i = x; i < best_matching_x; i++)
            res += addInnerTag(parts_text1[i], 'del');
          x = best_matching_x;
          new_pass = true;
        }

        if (y < best_matching_y && x == best_matching_x) {
          for (var i = y; i < best_matching_y; i++)
            res += addInnerTag(parts_text2[i], 'ins');
          y = best_matching_y;
          new_pass = true;
        }
      }

      // sind die beiden zu vergleichenden tabellen kompatibel?!
      if (!ready && table_match_check && t1 && t2) {
        if (!tableMatch(parts_text1[x], parts_text2[y])) {
          res += addInnerTag(parts_text1[x], 'del');
          x += 1;
          res += addInnerTag(parts_text2[y], 'ins');
          y += 1;
          new_pass = true;
        }
      }
    } while (new_pass);

    if (!ready) {
      if (t1 == t2) {
        // gleicher typ -> wir können vergleichen

        res += compareTexts(parts_text1[x], parts_text2[y]);
        x += 1;
        y += 1;
      } else {
        // ungleich -> wir warten auf die nächste tabelle

        if (t1) {
          // text 1 hat tabelle, text 2 einen text -> text wurde neu eingefügt

          res += addInnerTag(parts_text2[y], 'ins');
          y += 1;
        } else {
          // test 2 hat tabelle, text 1 einen text -> alter text wurde gelöscht

          res += addInnerTag(parts_text1[x], 'del');
          x += 1;
        }
      }
    }

    // ist einer der texte fertig?!
    var b1 = x >= parts_text1.length; //  -> text 1 ist durch
    var b2 = y >= parts_text2.length; //  -> text 2 ist durch

    if (b1 || b2) {
      // verbleibende elemente als eingefügt oder gelöscht anhängen
      if (!b1 && b2) {
        for (var i = x; i < parts_text1.length; i++)
          res += addInnerTag(parts_text1[i], 'del');
      } // 2 ist durch, 1 hat noch -> aus 1 wurde gelöscht
      if (b1 && !b2) {
        for (var i = y; i < parts_text2.length; i++)
          res += addInnerTag(parts_text2[i], 'ins');
      } // 1 ist durch, 2 hat noch -> in 2 wurde eingefügt
      break;
    }
  } while (1 == 1);

  return res;
}
