import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectSearchKeyword,
  setSearchKeyword,
} from '../../redux/store/content/slice';

function SearchField(): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const searchKeyword = useAppSelector(selectSearchKeyword);

  return (
    <Form.Group className='mb-4' controlId='SearchKeyword'>
      <Form.Label>{translation('searchKeyword')}</Form.Label>
      <Form.Control
        required
        onChange={(e) => {
          dispatch(setSearchKeyword(e.target.value));
        }}
        value={searchKeyword}
        type='text'
      />
    </Form.Group>
  );
}

export default SearchField;
