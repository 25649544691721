import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiNotesByVersionIdQuery,
  usePostApiMarksMutation,
} from '../../../redux/store/api/api';
import {
  selectActiveNote,
  selectActiveVersion,
} from '../../../redux/store/content/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import {
  MARK_VISIBILITY_GROUP_KEY,
  VERSION_CONTENT_CONTAINER_ID,
} from '../../../shared/constants';
import { NoteVisibilityType } from '../../../shared/enums';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';
import { VisibilityGroupString } from '../../notes/types';
import convertFromRange from '../../version/functions/convertFromRange';
import testForIntersection from '../../version/functions/testForIntersection';
import { validateRange } from '../../version/functions/validateRange';
import IntersectionDetectedDialog from '../../version/dialogs/version-content/IntersectionDetectedDialog';

interface IAddMarkToNoteDialogProps {
  show: boolean;
  setShow: (show: boolean) => void;
}

function AddMarkToNoteDialog({
  show,
  setShow,
}: IAddMarkToNoteDialogProps): JSX.Element {
  const { t: translation } = useTranslation();
  const note = useAppSelector(selectActiveNote);
  const noteHasContent = note.text !== '';
  const version = useAppSelector(selectActiveVersion);
  const [, setActiveMarkVisibilityGroup] =
    useSessionStorage<VisibilityGroupString | null>(
      MARK_VISIBILITY_GROUP_KEY,
      null,
    );
  const [addMark, { isError, isLoading, error }] = usePostApiMarksMutation();
  // since a mark is part of a note the notes are refetched here instead of the marks
  const { refetch } = useGetApiNotesByVersionIdQuery(
    version.id
      ? {
          versionId: version.id,
        }
      : skipToken,
  );
  const dispatch = useAppDispatch();
  const selection = window.getSelection();
  const [
    intersectionDetectedDialogOpened,
    setIntersectionDetectedDialogOpened,
  ] = useState<boolean>(false);

  const handleAddMark = () => {
    const selectionIsValid = selection !== null && !selection.isCollapsed;

    if (!selectionIsValid) {
      return;
    }

    const range = validateRange(
      selection.getRangeAt(0),
      VERSION_CONTENT_CONTAINER_ID,
    );

    if (!range) {
      return;
    }

    if (testForIntersection(range, VERSION_CONTENT_CONTAINER_ID, 'mark')) {
      setShow(false);
      setIntersectionDetectedDialogOpened(true);
    } else {
      setActiveMarkVisibilityGroup(null);

      const newMark = convertFromRange(
        range,
        VERSION_CONTENT_CONTAINER_ID,
        document,
      );
      if (newMark) {
        addMark({ mark: { ...newMark, noteId: note.id } })
          .unwrap()
          .then((result) => {
            if (result.messageKey && result.messageKey !== '') {
              dispatch(
                addMessage({
                  id: 'AddMarkToNoteSuccess',
                  variant: 'success',
                  messageKeyBody: result.messageKey,
                }),
              );
            }
            setShow(false);
            refetch();
            selection.collapseToStart();
            setActiveMarkVisibilityGroup(
              NoteVisibilityType[note.visibility || 0] as VisibilityGroupString,
            );
          });
      }
    }
  };

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'AddMarkToNoteError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  return (
    <>
      <CustomDialog
        titleId='AddMarkToNoteDialog'
        dialogTitle={translation('addMarkToNote')}
        show={show}
        closeFunction={() => setShow(false)}
        actionFunction={handleAddMark}
        closeTitle={translation('cancel')}
        actionTitle={translation('add')}
        actionButtonVariant='outline-danger'
        actionButtonDisabled={isLoading}>
        {isLoading && <Loader />}
        {!isLoading && (
          <>
            <p>{translation('addMarkToThisNote')}</p>
            <p>{`${translation('titleOfTheNote')}: "${note.name}"`}</p>
            {noteHasContent && (
              <p>{`${translation('contentOfTheNote')}: "${note.text}"`}</p>
            )}
          </>
        )}
      </CustomDialog>
      <IntersectionDetectedDialog
        show={intersectionDetectedDialogOpened}
        setShow={setIntersectionDetectedDialogOpened}
        selection={selection}
        typeOfIntersectedElement='Mark'
      />
    </>
  );
}

export default AddMarkToNoteDialog;
