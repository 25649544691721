import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const requestOptions: RequestInit = {};

if (process.env.NODE_ENV === 'development') {
  requestOptions.cache = 'no-cache';
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['navigator'],
    },
    load: 'languageOnly',
    supportedLngs: ['de'],
    fallbackLng: 'de',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
    defaultNS: 'wisentro',
    ns: ['wisentro'],
    backend: {
      queryStringParams: {
        v: process.env.REACT_APP_VERSION || '3.0.0',
      },
      requestOptions,
    },
  });
