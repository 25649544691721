import {
  ForwardedRef,
  KeyboardEventHandler,
  PropsWithChildren,
  forwardRef,
} from 'react';

interface IDropdownMenuProps {
  // eslint-disable-next-line react/require-default-props
  onKeyDown?: KeyboardEventHandler;
  className: string;
}

function DropdownMenu(
  { onKeyDown, className, children }: PropsWithChildren<IDropdownMenuProps>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={`shadow ${className}`}
      onKeyDown={onKeyDown}
      ref={ref}
      data-bs-popper='static'>
      <ul className='list-unstyled mb-0'>{children}</ul>
    </div>
  );
}

export default forwardRef(DropdownMenu);
