import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, Tab } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { useUpdateEffect, useWindowSize } from 'usehooks-ts';
import { ChevronDoubleLeft, ChevronDoubleRight } from 'react-bootstrap-icons';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  PostApiSearchArticlesApiResponse,
  usePostApiSearchArticlesMutation,
  PostApiSearchCategoriesApiResponse,
  usePostApiSearchCategoriesMutation,
} from '../../redux/store/api/api';
import { addMessage } from '../../redux/store/layout/slice';
import CustomCard from '../cards/CustomCard';
import Loader from '../loader/Loader';
import './Search.scss';
import {
  includeVersionsUrlParam,
  searchKeywordUrlParam,
  searchOptionUrlParam,
  selectedCategoriesUrlParam,
  searchResultSortUrlParam,
  searchResultTypeUrlParam,
  mdBreakpoint,
} from '../../shared/constants';
import SearchArticleResult from './SearchArticleResult';
import { SearchResultSort } from '../../shared/enums';
import SearchCategoryResult from './SearchCategoryResult';
import SearchForm from './SearchForm';
import {
  selectSearchIncludeVersions,
  selectSearchKeyword,
  selectSearchOption,
  selectSelectedCategoryIdsForSearch,
  setControlledSelectedCategoryIdsForSearch,
  setSearchIncludeVersions,
  setSearchKeyword,
  setSearchOption,
  setSelectedCategoryIdsForSearch,
} from '../../redux/store/content/slice';
import { SearchOption } from '../../redux/store/content/types';
import { SearchResultType } from './types';

function SearchCards(): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const { width: windowWidth } = useWindowSize();

  const [searchParams, setSearchParams] = useSearchParams();
  const searchKeywordParam: string =
    searchParams.get(searchKeywordUrlParam) || '';
  const searchResultTypeParam: string =
    searchParams.get(searchResultTypeUrlParam) || 'articles';
  const searchOptionParam: SearchOption =
    SearchOption[
      searchParams.get(searchOptionUrlParam) as keyof typeof SearchOption
    ] || SearchOption.AllWords;
  const includeVersionsParam: boolean =
    searchParams.get(includeVersionsUrlParam) === 'true' || false;
  const searchResultSortParam: SearchResultSort = +(
    searchParams.get(searchResultSortUrlParam) || SearchResultSort.ByRelevance
  ) as SearchResultSort;
  const selectedCategoriesParamString: string | null = searchParams.get(
    selectedCategoriesUrlParam,
  );
  const selectedCategoriesParam: string[] | undefined =
    selectedCategoriesParamString && selectedCategoriesParamString !== ''
      ? selectedCategoriesParamString.split(',')
      : undefined;

  const searchKeyword = useAppSelector(selectSearchKeyword);
  const selectedCategoryIds = useAppSelector(
    selectSelectedCategoryIdsForSearch,
  );
  const searchOption = useAppSelector(selectSearchOption);
  const includeVersions = useAppSelector(selectSearchIncludeVersions);
  const [searchResultType, setSearchResultType] = useState<SearchResultType>(
    searchResultTypeParam as SearchResultType,
  );
  const [
    searchCategory,
    {
      isError: searchCategoryIsError,
      error: searchCategoryError,
      isLoading: searchCategoryIsLoading,
    },
  ] = usePostApiSearchCategoriesMutation();
  const [searchCategoryResponse, setSearchCategoryResponse] =
    useState<PostApiSearchCategoriesApiResponse | null>(null);
  const [
    searchArticle,
    {
      isError: searchArticlesIsError,
      error: searchArticlesError,
      isLoading: searchArticleIsLoading,
    },
  ] = usePostApiSearchArticlesMutation();
  const [searchArticleResponse, setSearchArticleResponse] =
    useState<PostApiSearchArticlesApiResponse | null>(null);
  const [sortOption, setSortOption] = useState<SearchResultSort>(
    searchResultSortParam,
  );

  const itemsPerPage = 25;
  const [startOffset, setStartOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const startSearch = (isInitialSearch = false) => {
    if (!isInitialSearch) {
      searchParams.set(searchKeywordUrlParam, searchKeyword.trim());
      searchParams.set(searchOptionUrlParam, SearchOption[searchOption]);
      searchParams.set(includeVersionsUrlParam, includeVersions.toString());
      searchParams.set(searchResultTypeUrlParam, searchResultType);
      searchParams.set(searchResultSortUrlParam, sortOption.toString());

      if (selectedCategoryIds.length > 0) {
        searchParams.set(
          selectedCategoriesUrlParam,
          selectedCategoryIds.toString(),
        );
      } else {
        searchParams.delete(selectedCategoriesUrlParam);
      }

      setSearchParams(searchParams);
    }

    if (
      (isInitialSearch && searchResultTypeParam === 'articles') ||
      searchResultType === 'articles'
    ) {
      searchArticle({
        searchAttributes: {
          searchString: isInitialSearch ? searchKeywordParam : searchKeyword,
          searchExact:
            (isInitialSearch ? searchOptionParam : searchOption) ===
            SearchOption.ExactExpression,
          includeAttachments: true,
          searchResultSort: isInitialSearch
            ? searchResultSortParam
            : sortOption,
          selectedCategoryIds: isInitialSearch
            ? selectedCategoriesParam
            : selectedCategoryIds,
          includeVersions: isInitialSearch
            ? includeVersionsParam
            : includeVersions,
          startOffset,
          itemsPerPage,
        },
      })
        .unwrap()
        .then((response) => setSearchArticleResponse(response));
    } else {
      searchCategory({
        searchAttributes: {
          searchString: searchKeywordParam,
          selectedCategoryIds,
          includeVersions,
        },
      })
        .unwrap()
        .then((response) => setSearchCategoryResponse(response));
    }
  };

  const handleSortChange = useCallback((option: SearchResultSort) => {
    setSortOption(option);
    setStartOffset(0);
  }, []);

  useEffect(() => {
    dispatch(setSearchKeyword(searchKeywordParam));
    dispatch(setSelectedCategoryIdsForSearch(selectedCategoriesParam || []));
    dispatch(
      setControlledSelectedCategoryIdsForSearch(
        selectedCategoriesParam || undefined,
      ),
    );
    dispatch(setSearchOption(searchOptionParam));
    dispatch(setSearchIncludeVersions(includeVersionsParam));
    setSearchResultType(searchResultTypeParam as SearchResultType);
    startSearch(true);
  }, []);

  useEffect(() => {
    if (searchArticlesIsError) {
      dispatch(
        addMessage({
          id: 'SearchArticlesError',
          variant: 'danger',
          messageKeyBody:
            searchArticlesError && 'data' in searchArticlesError
              ? searchArticlesError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (searchCategoryIsError) {
      dispatch(
        addMessage({
          id: 'SearchCategoriesError',
          variant: 'danger',
          messageKeyBody:
            searchCategoryError && 'data' in searchCategoryError
              ? searchCategoryError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [searchArticlesIsError, searchCategoryIsError]);

  useUpdateEffect(() => {
    if (!searchArticleIsLoading && !searchCategoryIsLoading) {
      startSearch();
    }
  }, [sortOption, startOffset, searchResultType]);

  useUpdateEffect(() => {
    if (
      (searchKeywordParam !== searchKeyword ||
        selectedCategoriesParamString !== selectedCategoryIds.toString() ||
        selectedCategoriesParamString ||
        SearchOption[searchOptionParam] !== SearchOption[searchOption] ||
        includeVersionsParam !== includeVersions) &&
      !searchArticleIsLoading &&
      !searchCategoryIsLoading
    ) {
      startSearch();
    }
  }, [
    searchKeywordParam,
    selectedCategoriesParamString,
    searchOptionParam,
    includeVersionsParam,
  ]);

  useEffect(() => {
    if (
      searchArticleResponse?.resultObject?.resultCount &&
      searchArticleResponse.resultObject.resultCount > 0
    ) {
      setPageCount(
        Math.ceil(
          (searchArticleResponse.resultObject?.resultCount || 0) / itemsPerPage,
        ),
      );
    } else {
      setPageCount(0);
    }
  }, [searchArticleResponse]);

  // Invoke when user click to request another page.
  const handlePageClick = (selectedItem: { selected: number }) => {
    const newStartOffset =
      (selectedItem.selected * itemsPerPage) %
      (searchArticleResponse?.resultObject?.resultCount || 0);
    setStartOffset(newStartOffset);
  };

  return (
    <>
      <div className='search-filter-card-container'>
        <CustomCard>
          <SearchForm
            startOffset={startOffset}
            setStartOffset={setStartOffset}
            startSearch={startSearch}
          />
        </CustomCard>
      </div>
      {(searchArticleIsLoading ||
        searchCategoryIsLoading ||
        searchCategoryResponse?.resultObject ||
        searchArticleResponse?.resultObject) && (
        <Tabs
          className='search-tabs mt-5'
          activeKey={searchResultType}
          onSelect={(k) => {
            setSearchResultType(k as SearchResultType);
          }}>
          <Tab
            className='search-result-container'
            eventKey='articles'
            title={translation('articles')}
            tabAttrs={{
              className: `mb-2 shadow-sm btn-outline-dark ${
                searchResultType === 'articles' ? 'active' : ''
              }`,
            }}>
            <CustomCard
              helpId={windowWidth > mdBreakpoint ? undefined : 'help_4_7'}
              iconClass='icon-search'
              title={
                !searchArticleIsLoading
                  ? `${
                      searchArticleResponse?.resultObject?.resultCount || '0'
                    } ${translation('searchResults')}`
                  : undefined
              }
              ariaLiveForTitle={
                searchArticleIsLoading ? undefined : 'assertive'
              }>
              <div aria-busy={searchArticleIsLoading}>
                {searchArticleIsLoading && <Loader />}
                {!searchArticleIsLoading &&
                  searchArticleResponse?.resultObject &&
                  (searchArticleResponse.resultObject?.resultCount || 0) >
                    0 && (
                    <SearchArticleResult
                      searchResult={searchArticleResponse.resultObject}
                      onSortChange={handleSortChange}
                      sortOption={sortOption}
                    />
                  )}
              </div>
            </CustomCard>
            {!searchArticleIsLoading &&
              (searchArticleResponse?.resultObject?.resultCount || 0) > 0 && (
                <nav>
                  <ReactPaginate
                    nextLabel={<ChevronDoubleRight aria-hidden />}
                    nextAriaLabel={translation('nextPage')}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={10}
                    marginPagesDisplayed={3}
                    pageCount={pageCount}
                    previousLabel={<ChevronDoubleLeft aria-hidden />}
                    previousAriaLabel={translation('previousPage')}
                    pageClassName='page-item'
                    pageLinkClassName='page-link'
                    previousClassName='page-item'
                    previousLinkClassName='page-link'
                    nextClassName='page-item'
                    nextLinkClassName='page-link'
                    breakLabel='...'
                    breakClassName='page-item'
                    breakLinkClassName='page-link'
                    containerClassName='pagination mt-3 justify-content-center'
                    activeClassName='active'
                    initialPage={startOffset / itemsPerPage}
                    ariaLabelBuilder={(pageIndex) =>
                      `${translation('page')} ${pageIndex}`
                    }
                  />
                </nav>
              )}
          </Tab>
          <Tab
            className='search-result-container'
            eventKey='categories'
            title={translation('categories')}
            tabAttrs={{
              className: `mb-2 shadow-sm btn-outline-dark ${
                searchResultType === 'categories' ? 'active' : ''
              }`,
            }}>
            <CustomCard
              helpId='help_4_7'
              title={
                !searchCategoryIsLoading
                  ? `${searchCategoryResponse?.resultObject
                      ?.resultCount} ${translation('searchResults')}`
                  : undefined
              }
              ariaLiveForTitle={
                searchCategoryIsLoading ? undefined : 'assertive'
              }>
              <div aria-busy={searchCategoryIsLoading}>
                {searchCategoryIsLoading && <Loader />}
                {!searchCategoryIsLoading &&
                  searchCategoryResponse?.resultObject && (
                    <SearchCategoryResult
                      searchCategoryResult={
                        searchCategoryResponse.resultObject?.items ?? []
                      }
                      foundTerms={
                        searchCategoryResponse.resultObject?.foundTerms ?? []
                      }
                    />
                  )}
              </div>
            </CustomCard>
          </Tab>
        </Tabs>
      )}
    </>
  );
}

export default SearchCards;
