import { useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useLocalStorage } from 'usehooks-ts';
import { useSearchParams } from 'react-router-dom';
import Loader from '../loader/Loader';
import { useAppSelector } from '../../redux/hooks';
import {
  selectActiveVersion,
  selectVersionContentIsLoading,
} from '../../redux/store/content/slice';
import {
  useGetApiArticlesReferencesByIdQuery,
  useGetApiNotesByVersionIdQuery,
  useGetApiVersionsArticleByIdQuery,
} from '../../redux/store/api/api';
import { articleIdUrlParam, EDIT_MODE_KEY } from '../../shared/constants';
import useGetCategoryByArticleId from '../../hooks/useGetCategoryByArticleId';
import { RightKey } from '../../shared/enums';
import LinkContent from './content/LinkContent';

function VersionContent() {
  const version = useAppSelector(selectActiveVersion);
  const [searchParams] = useSearchParams();
  const articleId: string = searchParams.get(articleIdUrlParam) || '';
  const category = useGetCategoryByArticleId(articleId);
  const userCanEditArticle =
    category?.permittedActions?.includes(
      RightKey.RightArticleManagementEditArticle,
    ) || false;
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);
  const contentIsLoading = useAppSelector(selectVersionContentIsLoading);

  const { isFetching: fetchingReferences } =
    useGetApiArticlesReferencesByIdQuery(
      articleId
        ? {
            id: articleId,
          }
        : skipToken,
      { refetchOnMountOrArgChange: true },
    );
  const { refetch: refetchNotes } = useGetApiNotesByVersionIdQuery(
    version.id
      ? {
          versionId: version.id,
        }
      : skipToken,
  );
  const { isFetching: fetchingVersions } = useGetApiVersionsArticleByIdQuery(
    articleId
      ? { id: articleId, editMode: editModeIsActive && userCanEditArticle }
      : skipToken,
  );
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    setIsFetching(fetchingReferences || fetchingVersions);
  }, [fetchingReferences, fetchingVersions]);

  useEffect(() => {
    // Set focus on article title
    const articleTitleElement = document
      .getElementsByClassName('article-content')[0]
      .getElementsByClassName('card-title')[0]
      .getElementsByTagName('h1')[0];

    articleTitleElement.focus();
  }, [version]);

  return (
    <>
      {(isFetching || contentIsLoading) && <Loader />}

      {!isFetching && <LinkContent refetchNotes={refetchNotes} />}
    </>
  );
}

export default VersionContent;
