import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import useGetCategoryByArticleId from '../../hooks/useGetCategoryByArticleId';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  Note,
  useGetApiSettingsPublicBySettingTypeKeyQuery,
} from '../../redux/store/api/api';
import {
  selectActiveVersion,
  selectLinksNeedingAction,
  selectNoteData,
  selectShowMakeLinkSelectionInfo,
  selectShowMakeMarkSelectionInfo,
  selectVersionContentIsLoading,
  setShowMakeLinkSelectionInfo,
  setShowMakeMarkSelectionInfo,
} from '../../redux/store/content/slice';
import {
  EDIT_MODE_KEY,
  SEARCH_RESULT_SPAN,
  settingTypeApplicationName,
  textAreaIdUrlParam,
} from '../../shared/constants';
import { RightKey, NoteVisibilityType } from '../../shared/enums';
import { formatToInputDateString } from '../../shared/utils';

function VersionAlerts() {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const version = useAppSelector(selectActiveVersion);
  const noteData = useAppSelector(selectNoteData);
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);
  const versionContentIsLoading = useAppSelector(selectVersionContentIsLoading);
  const [searchParams] = useSearchParams();
  const areaId: string | null = searchParams.get(textAreaIdUrlParam);
  const [showIdNotFoundWarning, setShowIdNotFoundWarning] = useState(false);
  const [showOldVersionInfo, setShowOldVersionInfo] = useState(false);
  const [showFutureVersionInfo, setShowFutureVersionInfo] = useState(false);
  const linksNeedingAction = useAppSelector(selectLinksNeedingAction);
  const showMakeLinkSelectionInfo = useAppSelector(
    selectShowMakeLinkSelectionInfo,
  );
  const showMakeMarkSelectionInfo = useAppSelector(
    selectShowMakeMarkSelectionInfo,
  );
  const [showMarkNeedsActionWarning, setShowMarkNeedsActionWarning] =
    useState(false);
  const [showLinkNeedsActionWarning, setShowLinkNeedsActionWarning] =
    useState(false);

  const { data: applicationNameSetting } =
    useGetApiSettingsPublicBySettingTypeKeyQuery({
      settingTypeKey: settingTypeApplicationName,
    });

  const category = useGetCategoryByArticleId(version.articleId);

  // permissions
  const userCanEditUserGroupNote =
    category?.permittedActions?.includes(
      RightKey.RightNotesManagementEditGroupNote,
    ) || false;
  const userCanEditGlobalNote =
    category?.permittedActions?.includes(
      RightKey.RightNotesManagementEditOrganizationNote,
    ) || false;

  useEffect(() => {
    document.title = `${
      applicationNameSetting?.resultObject?.value
        ? `${applicationNameSetting.resultObject.value} - `
        : ''
    }${version.title}`;
  }, [version, applicationNameSetting]);

  useEffect(() => {
    setShowFutureVersionInfo(false);
    setShowOldVersionInfo(false);

    if (
      !version.isRecentVersion &&
      !(
        new Date().getTime() >
          new Date(
            formatToInputDateString(version.publishedFrom || ''),
          ).getTime() ||
        new Date().getTime() >
          new Date(formatToInputDateString(version.validFrom || '')).getTime()
      )
    ) {
      setShowFutureVersionInfo(true);
      return;
    }

    if (
      !version.isRecentVersion &&
      !(
        new Date().getTime() <
          new Date(
            formatToInputDateString(version.publishedFrom || ''),
          ).getTime() ||
        new Date().getTime() <
          new Date(formatToInputDateString(version.validFrom || '')).getTime()
      )
    ) {
      setShowOldVersionInfo(true);
    }
  }, [version]);

  useEffect(() => {
    if (versionContentIsLoading) {
      return;
    }

    // Remove old highlighting of a text passage, if it exists.
    Array.from(
      document.body.getElementsByClassName('border border-2 border-primary'),
    )
      .filter((e) => !e.classList.contains(SEARCH_RESULT_SPAN))
      .forEach((e) => {
        e.classList.remove('border', 'border-2', 'border-primary');
      });
    setShowIdNotFoundWarning(false);

    // Scroll to referenced targetContentAreaId, add border and focus on this element or inform user that it not exists
    if (areaId) {
      const section: HTMLElement | null = document.getElementById(areaId);

      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        section.classList.add('border', 'border-2', 'border-primary');
        section.focus();
      } else {
        setShowIdNotFoundWarning(true);
      }
    }
  }, [versionContentIsLoading, areaId]);

  useEffect(() => {
    const allNotes: Note[] = [
      ...(noteData?.userNotes || []),
      ...(noteData?.userGroupNotes || []),
      ...(noteData?.generalNotes || []),
    ];
    const notesWithMarksNotFound = allNotes.filter((n) => n.mark?.markInvalid);

    const show =
      notesWithMarksNotFound.length > 0 &&
      (!!notesWithMarksNotFound.find(
        (n) => n.visibility === NoteVisibilityType.User,
      ) ||
        (!!notesWithMarksNotFound.find(
          (n) => n.visibility === NoteVisibilityType.UserGroup,
        ) &&
          userCanEditUserGroupNote) ||
        (!!notesWithMarksNotFound.find(
          (n) => n.visibility === NoteVisibilityType.General,
        ) &&
          userCanEditGlobalNote));

    setShowMarkNeedsActionWarning(show);
  }, [noteData]);

  useEffect(() => {
    setShowLinkNeedsActionWarning(linksNeedingAction.length > 0);
  }, [linksNeedingAction]);

  useEffect(() => {
    if (showMakeLinkSelectionInfo && showMakeMarkSelectionInfo) {
      dispatch(setShowMakeMarkSelectionInfo(false));
    }
  }, [showMakeLinkSelectionInfo]);

  useEffect(() => {
    if (showMakeMarkSelectionInfo && showMakeLinkSelectionInfo) {
      dispatch(setShowMakeLinkSelectionInfo(false));
    }
  }, [showMakeMarkSelectionInfo]);

  return (
    <>
      {showOldVersionInfo && (
        <Alert
          aria-label={translation('closeAlert')}
          variant='warning'
          className='mt-3'
          onClose={() => setShowOldVersionInfo(false)}
          dismissible>
          <p className='m-0'>
            <i className='icon-alte_version me-2' aria-hidden />
            {translation('oldVersionInfo')}
          </p>
        </Alert>
      )}
      {showFutureVersionInfo && (
        <Alert
          aria-label={translation('closeAlert')}
          variant='warning'
          className='mt-3'
          onClose={() => setShowFutureVersionInfo(false)}
          dismissible>
          <p className='m-0'>
            <i className='icon-version_future me-2' aria-hidden />
            {translation('futureVersionInfo')}
          </p>
        </Alert>
      )}
      {showMakeLinkSelectionInfo && (
        <Alert
          aria-label={translation('closeAlert')}
          variant='info'
          className='mt-3'
          onClose={() => dispatch(setShowMakeLinkSelectionInfo(false))}
          dismissible>
          <p className='m-0'>{translation('makeLinkSelection')}</p>
        </Alert>
      )}
      {showMakeMarkSelectionInfo && (
        <Alert
          aria-label={translation('closeAlert')}
          variant='info'
          className='mt-3'
          onClose={() => dispatch(setShowMakeMarkSelectionInfo(false))}
          dismissible>
          <p className='m-0'>{translation('makeMarkSelection')}</p>
        </Alert>
      )}
      {showMarkNeedsActionWarning && (
        <Alert
          aria-label={translation('closeAlert')}
          variant='warning'
          className='mt-3'
          onClose={() => setShowMarkNeedsActionWarning(false)}
          dismissible>
          <p className='m-0'>{translation('marksNotFound')}</p>
        </Alert>
      )}
      {showLinkNeedsActionWarning &&
        editModeIsActive &&
        version.isRecentVersion && (
          <Alert
            aria-label={translation('closeAlert')}
            variant='warning'
            className='mt-3'
            onClose={() => setShowLinkNeedsActionWarning(false)}
            dismissible>
            <p className='m-0'>{translation('linksNotFound')}</p>
          </Alert>
        )}
      {showIdNotFoundWarning && (
        <Alert
          aria-label={translation('closeAlert')}
          variant='warning'
          className='mt-3'
          onClose={() => setShowIdNotFoundWarning(false)}
          dismissible>
          <p className='m-0'>
            {translation('textPassageNotFoundInThisVersion')}
          </p>
        </Alert>
      )}
    </>
  );
}

export default VersionAlerts;
