import { SyntheticEvent } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Article, Category } from '../../redux/store/api/api';
import { ArticleType } from '../../shared/enums';
import {
  convertArticleTreeItemToArticle,
  convertCategoryTreeItemToCategory,
  getArticleIcon,
  getArticleTranslationKeyOfIconDescription,
} from '../../shared/utils';
import { paddingOffsetForTreeElements } from '../../shared/constants';
import { IArticleTreeItem, ICategoryTreeItem } from '../content-tree/types';
import ContextMenu from '../dropdown-menus/ContextMenu';
import { ContextAction } from '../dropdown-menus/types';
import DescriptiveIcon from '../descriptive-icons/DescriptiveIcon';
import useGetCategoryByArticleId from '../../hooks/useGetCategoryByArticleId';
import { useAppDispatch } from '../../redux/hooks';
import {
  setContextArticle,
  setContextCategory,
} from '../../redux/store/content/slice';

interface IRecycleBinTreeItem {
  element: IArticleTreeItem | ICategoryTreeItem;
  level: number;
  isBranch: boolean;
  isExpanded: boolean | undefined;
  getNodeProps: Function;
  setDeleteArticleDialogShow: (show: boolean) => void;
  setRestoreDialogShow: (show: boolean) => void;
  selectedItems: (Article | Category)[];
  handleSelect: Function;
  handleExpand: Function;
  isSelected: boolean;
}

function RecycleBinTreeItem({
  element,
  level,
  isBranch,
  isExpanded,
  getNodeProps,
  setDeleteArticleDialogShow,
  setRestoreDialogShow,
  selectedItems,
  handleSelect,
  handleExpand,
  isSelected,
}: IRecycleBinTreeItem) {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const isArticleElement: boolean = 'recentVersionId' in element;
  const itemClass: string = isArticleElement ? 'child-item' : 'parent-item';

  const getContextActionsForSelectedItems = (): ContextAction[] => {
    const contextActions: ContextAction[] = [];

    contextActions.push({
      helpId: 'help_2_2_12',
      iconClass: 'icon-wiederherstellen',
      iconColorClass: 'text-body',
      name: translation('restoreSelectedElements'),
      onClick: () => {
        setRestoreDialogShow(true);
      },
    });

    contextActions.push({
      helpId: 'help_2_2_13',
      iconClass: 'icon-trash',
      iconColorClass: 'text-danger',
      name: translation('deleteSelectedElementsPermanently'),
      onClick: () => {
        setDeleteArticleDialogShow(true);
      },
    });

    return contextActions;
  };

  const getContextActions = (): ContextAction[] => {
    let restoreTranslationKey = 'restoreCategory';
    const contextActions: ContextAction[] = [];

    if (selectedItems.length > 0) {
      contextActions.push(...getContextActionsForSelectedItems());

      return contextActions;
    }

    if (isArticleElement) {
      restoreTranslationKey =
        (element as IArticleTreeItem).type === ArticleType.StructureElement
          ? 'restoreStuctureElement'
          : 'restoreArticle';
    }

    contextActions.push({
      iconClass: 'icon-wiederherstellen',
      iconColorClass: 'text-body',
      name: translation(restoreTranslationKey),
      onClick: () => {
        if (isArticleElement) {
          const article: Article = convertArticleTreeItemToArticle(
            element as IArticleTreeItem,
          );
          dispatch(setContextArticle(article));
          setRestoreDialogShow(true);
        } else {
          const curCategory: Category = convertCategoryTreeItemToCategory(
            element as ICategoryTreeItem,
          );
          dispatch(setContextCategory(curCategory));
          setRestoreDialogShow(true);
        }
      },
    });

    contextActions.push({
      iconClass: 'icon-trash',
      iconColorClass: 'text-danger',
      name: translation('deletePermanently'),
      onClick: () => {
        if (isArticleElement) {
          const article: Article = convertArticleTreeItemToArticle(
            element as IArticleTreeItem,
          );
          dispatch(setContextArticle(article));
          setDeleteArticleDialogShow(true);
        } else {
          const curCategory: Category = convertCategoryTreeItemToCategory(
            element as ICategoryTreeItem,
          );
          dispatch(setContextCategory(curCategory));
          setDeleteArticleDialogShow(true);
        }
      },
    });

    return contextActions;
  };

  const checkClickOnContextMenu = (
    event: SyntheticEvent<HTMLElement | Element>,
  ): boolean =>
    event.target instanceof HTMLButtonElement ||
    (event.target as HTMLElement).parentElement instanceof HTMLButtonElement ||
    (event.target as HTMLElement).parentElement?.parentElement instanceof
      HTMLButtonElement ||
    event.target instanceof HTMLAnchorElement;

  const category = useGetCategoryByArticleId(
    isArticleElement ? element.id : null,
  );

  return (
    <div className={`li-inner ${itemClass}`}>
      <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getNodeProps({
          onClick: (e: SyntheticEvent) => {
            if (checkClickOnContextMenu(e)) {
              return;
            }
            handleExpand(e);
          },
        })}
        style={{ paddingLeft: paddingOffsetForTreeElements * (level - 1) }}>
        {isArticleElement && (
          <div>
            {level === 1 && (
              <span className='category-path'>{category?.permittedPath}</span>
            )}
            <div className='d-flex pe-5'>
              <Form.Check
                id={`${(element as IArticleTreeItem).name}_${element.id}`}>
                <Form.Check.Input
                  onChange={(e) => {
                    handleSelect(e);
                  }}
                  className='mt-2'
                  checked={isSelected}
                  type='checkbox'
                />
                <Form.Check.Label>
                  <div className='d-flex'>
                    <DescriptiveIcon
                      iconClass={`mt-1 ${getArticleIcon(
                        (element as IArticleTreeItem).type || ArticleType.Html,
                      )}`}
                      description={translation(
                        getArticleTranslationKeyOfIconDescription(
                          (element as IArticleTreeItem).type ||
                            ArticleType.Html,
                        ),
                      )}
                    />
                    <span>{(element as IArticleTreeItem).name}</span>
                  </div>
                </Form.Check.Label>
              </Form.Check>
              <ContextMenu contextActions={getContextActions()} />
            </div>
          </div>
        )}
        {!isArticleElement && (
          <div className='pe-5'>
            {level === 1 && (
              <span className='category-path'>
                {(element as ICategoryTreeItem).permittedPath}
              </span>
            )}
            <div className='d-flex'>
              {isBranch && isExpanded && (
                <i className='icon-carretup mt-1' aria-hidden />
              )}
              {isBranch && !isExpanded && (
                <i className='icon-carretdown mt-1' aria-hidden />
              )}
              <Form.Check
                className='ms-2'
                id={`${(element as ICategoryTreeItem).name}_${element.id}`}>
                <Form.Check.Input
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    e.stopPropagation();
                    handleSelect(e);
                  }}
                  className='mt-2'
                  checked={isSelected}
                  type='checkbox'
                />
                <Form.Check.Label>
                  <div className='visually-hidden'>
                    <i
                      className={`${
                        (element as ICategoryTreeItem).categoryTypeIconClass
                      } me-1 mt-1`}
                      aria-hidden
                      style={{
                        color:
                          (element as ICategoryTreeItem)
                            .categoryTypeColorHexCode || '',
                      }}
                    />
                    <span>{(element as ICategoryTreeItem).name}</span>
                  </div>
                </Form.Check.Label>
              </Form.Check>
              <span className='d-flex'>
                <i
                  className={`${
                    (element as ICategoryTreeItem).categoryTypeIconClass
                  } me-1 mt-1`}
                  aria-hidden
                  style={{
                    color:
                      (element as ICategoryTreeItem).categoryTypeColorHexCode ||
                      '',
                  }}
                />
                {(element as ICategoryTreeItem).name}
              </span>
              <ContextMenu contextActions={getContextActions()} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RecycleBinTreeItem;
