import { configureStore } from '@reduxjs/toolkit';
import { api } from './store/api/api';
import userManagementReducer from './store/user-management/slice';
import layoutReducer from './store/layout/slice';
import contentTreeReducer from './store/content/slice';

export const store = configureStore({
  reducer: {
    layout: layoutReducer,
    userManagement: userManagementReducer,
    content: contentTreeReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
