import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useIsFirstRender } from 'usehooks-ts';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Button, Form } from 'react-bootstrap';
import _ from 'lodash';
import AccordionItem from '../accordion/AccordionItem';
import CustomAccordion from '../accordion/CustomAccordion';
import {
  Role,
  useGetApiRolesByIdQuery,
  useGetApiRolesRightsCategoriesByPermissionContextQuery,
  usePutApiRolesRightsMutation,
} from '../../redux/store/api/api';
import { addMessage } from '../../redux/store/layout/slice';
import { useAppDispatch } from '../../redux/hooks';
import Loader from '../loader/Loader';
import CustomCheckbox from '../forms/CustomCheckbox';
import CustomCard from '../cards/CustomCard';
import { RightKey } from '../../shared/enums';
import { camelizeString } from '../../shared/utils';

interface IRoleSettingsProps {
  activeRole: Role;
  rights: RightKey[];
}

function RoleSettings({ activeRole, rights }: IRoleSettingsProps): JSX.Element {
  const { t: translation } = useTranslation();
  const defaultActiveKey = '0';
  const [selectedRights, setSelectedRights] = useState<string[]>([]);
  const [activeKey, setActiveKey] = useState<string>(defaultActiveKey);
  const {
    data: roleData,
    isFetching: getRoleIsFetching,
    refetch: refetchRole,
    isError: getRolesIsError,
    error: getRolesError,
  } = useGetApiRolesByIdQuery(
    activeRole.id ? { id: activeRole.id } : skipToken,
  );
  const {
    data: categoriesData,
    isFetching: getRightCategoriesIsFetching,
    refetch: refetchCategoriesByPermissionContext,
    isError: getCategoriesIsError,
    error: getCategoriesError,
  } = useGetApiRolesRightsCategoriesByPermissionContextQuery({
    permissionContext: activeRole.permissionContext || 1,
  });
  const [
    setRightsOfRole,
    {
      isError: toggleOneRightIsError,
      isLoading: setRightsToRoleIsFetching,
      error: toggleOneRightError,
    },
  ] = usePutApiRolesRightsMutation();
  const dispatch = useAppDispatch();
  const isFirstRender = useIsFirstRender();
  const isFetching =
    getRoleIsFetching ||
    getRightCategoriesIsFetching ||
    setRightsToRoleIsFetching;

  // permissions
  const userCanAddRightsToRole = rights.includes(
    RightKey.RightRoleManagementAddRightToRole,
  );
  const userCanRemoveRightFromRole = rights.includes(
    RightKey.RightRoleManagementRemoveRightFromRole,
  );

  useEffect(() => {
    if (!isFirstRender) {
      refetchRole();
      refetchCategoriesByPermissionContext();
      setActiveKey(defaultActiveKey);
    }
  }, [activeRole]);

  useEffect(() => {
    if (getRolesIsError) {
      dispatch(
        addMessage({
          id: 'GetRolesError',
          variant: 'danger',
          messageKeyBody:
            getRolesError && 'data' in getRolesError
              ? getRolesError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (getCategoriesIsError) {
      dispatch(
        addMessage({
          id: 'GetCategoriesError',
          variant: 'danger',
          messageKeyBody:
            getCategoriesError && 'data' in getCategoriesError
              ? getCategoriesError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (toggleOneRightIsError) {
      dispatch(
        addMessage({
          id: 'ToggleRightError',
          variant: 'danger',
          messageKeyBody:
            toggleOneRightError && 'data' in toggleOneRightError
              ? toggleOneRightError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [getRolesIsError, getCategoriesIsError, toggleOneRightIsError]);

  useEffect(() => {
    if (roleData?.resultObject) {
      setSelectedRights(
        roleData.resultObject?.rights?.map((r) => r.id || '') || [],
      );
    }
  }, [roleData]);

  const handleSaveRights = (): void => {
    setRightsOfRole({
      roleId: activeRole.id || '',
      body: selectedRights,
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'ToggleRightSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetchRole();
      });
  };

  return (
    <CustomCard title={translation('roleSettings')}>
      <div aria-busy={isFetching}>
        {isFetching && <Loader />}
        {!isFetching && (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSaveRights();
            }}>
            <CustomAccordion activeKey={activeKey}>
              {categoriesData?.resultObject?.map((category, index) => {
                const sortedRights = _.orderBy(category.rights || [], [
                  'order',
                ]);
                return (
                  <AccordionItem
                    key={category.id}
                    title={category.key ? translation(category.key) : ''}
                    setActiveKey={setActiveKey}
                    activeKey={activeKey}
                    eventKey={index.toString()}>
                    <div className='mb-4'>
                      <CustomCheckbox
                        controlId={category.id || ''}
                        label={translation('activateAllRightsInCategory')}
                        checked={
                          selectedRights.filter((id) =>
                            sortedRights.map((r) => r.id || '').includes(id),
                          ).length === sortedRights.length
                        }
                        onChange={(checked) => {
                          if (checked) {
                            setSelectedRights(
                              _.union(
                                selectedRights,
                                sortedRights.map((r) => r.id || '') || [],
                              ),
                            );
                          } else {
                            setSelectedRights(
                              selectedRights.filter(
                                (id) =>
                                  !sortedRights
                                    .map((r) => r.id || '')
                                    .includes(id),
                              ),
                            );
                          }
                        }}
                      />
                    </div>
                    {sortedRights.map((right) => (
                      <CustomCheckbox
                        key={right.id}
                        controlId={right.id || ''}
                        label={
                          typeof right.key === 'number'
                            ? translation(camelizeString(RightKey[right.key]))
                            : ''
                        }
                        checked={!!selectedRights.find((r) => r === right.id)}
                        onChange={(checked: boolean) => {
                          if (userCanAddRightsToRole && checked) {
                            setSelectedRights(
                              _.union(selectedRights, [right.id || '']),
                            );
                          }
                          if (userCanRemoveRightFromRole && !checked) {
                            setSelectedRights(
                              selectedRights.filter((id) => id !== right.id),
                            );
                          }
                        }}
                      />
                    ))}
                  </AccordionItem>
                );
              })}
            </CustomAccordion>
            {(userCanAddRightsToRole || userCanRemoveRightFromRole) && (
              <Button
                type='submit'
                variant='outline-success'
                className='float-end mt-3'>
                {translation('save')}
              </Button>
            )}
          </Form>
        )}
      </div>
    </CustomCard>
  );
}

export default RoleSettings;
