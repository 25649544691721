import { useEffect } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useDeleteApiAttachmentsByIdMutation,
  useGetApiAttachmentsByVersionIdQuery,
} from '../../../redux/store/api/api';
import {
  selectActiveVersion,
  selectContextAttachment,
  selectDeleteAttachmentDialogOpened,
  setDeleteAttachmentDialogOpened,
} from '../../../redux/store/content/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';

function DeleteAttachmentDialog(): JSX.Element {
  const { t: translation } = useTranslation();
  const attachment = useAppSelector(selectContextAttachment);
  const version = useAppSelector(selectActiveVersion);
  const dialogShow = useAppSelector(selectDeleteAttachmentDialogOpened);
  const dispatch = useAppDispatch();
  const [
    deleteAttachment,
    { isError: deleteAttachmentError, isLoading, error },
  ] = useDeleteApiAttachmentsByIdMutation();
  const { refetch } = useGetApiAttachmentsByVersionIdQuery(
    version.id
      ? {
          versionId: version.id,
        }
      : skipToken,
  );

  useEffect(() => {
    if (deleteAttachmentError) {
      dispatch(
        addMessage({
          id: 'UpdateAttachmentError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [deleteAttachmentError]);

  const handleAttachmentVersion = (): void => {
    deleteAttachment({ id: attachment.id || '' })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'DeleteAttachmentSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        dispatch(setDeleteAttachmentDialogOpened(false));
      });
  };

  return (
    <CustomDialog
      titleId='DeleteAttachmentDialog'
      dialogTitle={translation('deleteAttachment')}
      show={dialogShow}
      closeFunction={() => {
        dispatch(setDeleteAttachmentDialogOpened(false));
      }}
      actionFunction={handleAttachmentVersion}
      closeTitle={translation('cancel')}
      actionTitle={translation('delete')}
      actionButtonVariant='outline-danger'
      actionButtonDisabled={isLoading}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <p>{translation('deleteAttachmentPermanently')}</p>
          <p className='fw-bold'>{attachment.name}</p>
        </>
      )}
    </CustomDialog>
  );
}

export default DeleteAttachmentDialog;
