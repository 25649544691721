import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ErrorBoundary } from 'react-error-boundary';
import RoleList from '../components/role-management/RoleList';
import RoleSettings from '../components/role-management/RoleSettings';
import { useAppDispatch } from '../redux/hooks';
import { Role, useGetApiPermissionsUserQuery } from '../redux/store/api/api';
import { addMessage } from '../redux/store/layout/slice';
import { permissionCacheDelayInSeconds } from '../shared/constants';
import { RightKey } from '../shared/enums';
import ViewFallback from '../components/error-fallback/ViewFallback';

function RoleManagement(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [activeRole, setActiveRole] = useState<Role>({});

  const {
    data: permissionData,
    isError,
    error,
  } = useGetApiPermissionsUserQuery(undefined, {
    refetchOnMountOrArgChange: permissionCacheDelayInSeconds,
  });

  useEffect(() => {
    if (
      permissionData?.resultObject &&
      !permissionData.resultObject.includes(RightKey.RightAccessRoleManagement)
    ) {
      navigate('/');
    }
  }, [permissionData]);

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetPermissionsError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
      navigate('/');
    }
  }, [isError]);

  useEffect(() => {
    if (permissionData?.resultObject) {
      document.getElementsByTagName('h1')[0].focus();
    }
  }, [permissionData]);

  return (
    <div className='content-inner'>
      <ErrorBoundary fallback={<ViewFallback />}>
        {permissionData?.resultObject && (
          <>
            <RoleList
              activeRole={activeRole}
              setActiveRole={setActiveRole}
              rights={permissionData.resultObject}
            />
            <RoleSettings
              activeRole={activeRole}
              rights={permissionData.resultObject}
            />
          </>
        )}
      </ErrorBoundary>
    </div>
  );
}

export default RoleManagement;
