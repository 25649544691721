import { useEffect } from 'react';
import { useSessionStorage } from 'usehooks-ts';
import parse from 'html-react-parser';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectActiveNote,
  selectNoteData,
  setActiveNote,
  setShowNotePopoverOpened,
} from '../../../redux/store/content/slice';
import {
  MARK_VISIBILITY_GROUP_KEY,
  markIdUrlParam,
} from '../../../shared/constants';
import { VisibilityGroupString } from '../../notes/types';
import {
  getNotesByVisibilityGroup,
  scrollMarkIntoView,
} from '../../notes/functions';

interface IMarkContentProps {
  content: string;
}

function MarkContent({ content }: IMarkContentProps): JSX.Element {
  const dispatch = useAppDispatch();
  const noteData = useAppSelector(selectNoteData);
  const activeNote = useAppSelector(selectActiveNote);
  const [searchParams, setSearchParams] = useSearchParams();
  const markIdFromBookmark: string | null = searchParams.get(markIdUrlParam);
  const [activeMarkVisibilityGroup] =
    useSessionStorage<VisibilityGroupString | null>(
      MARK_VISIBILITY_GROUP_KEY,
      null,
    );

  const findAndShowNote = (e: Event) => {
    const idExtentionRegex = /(-(\d)+\/(\d)+)$/g;
    const markEl =
      (e.target as HTMLElement).tagName === 'MARK'
        ? (e.target as HTMLElement)
        : (e.target as HTMLElement).children[0];
    const markId = markEl.id.replace(idExtentionRegex, '');
    const note =
      getNotesByVisibilityGroup(activeMarkVisibilityGroup, noteData).find(
        (n) => n?.mark?.id === markId,
      ) || {};

    dispatch(setActiveNote(note));
    dispatch(setShowNotePopoverOpened(true));
  };

  useEffect(() => {
    // Make currently displayed marks clickable
    const marks = Array.from(document.getElementsByTagName('MARK'));
    marks.forEach((mark) => {
      mark.parentElement?.addEventListener('click', (e) => findAndShowNote(e));
      mark.parentElement?.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          findAndShowNote(e);
        }
      });
    });

    // Scroll to mark of recently added note if there is one
    if (activeNote.mark?.id) {
      const markEl = document.querySelector(`[id^="${activeNote.mark.id}"]`);
      if (markEl) {
        markEl.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        if ((activeNote.text?.length ?? 0) === 0) {
          dispatch(setActiveNote({}));
        }
      }
    }
  }, [content, noteData]);

  useEffect(() => {
    if (markIdFromBookmark) {
      const noteOfMarkIdInUrl = [
        ...(noteData.userNotes || []),
        ...(noteData.userGroupNotes || []),
        ...(noteData.generalNotes || []),
      ].find((n) => n.mark?.id === markIdFromBookmark);

      if (noteOfMarkIdInUrl) {
        dispatch(setActiveNote(noteOfMarkIdInUrl));
        dispatch(setShowNotePopoverOpened(true));
        scrollMarkIntoView(noteOfMarkIdInUrl);
        searchParams.delete(markIdUrlParam);
        setSearchParams(searchParams);
      }
    }
  }, [markIdFromBookmark, content]);

  return <>{parse(content)}</>;
}

export default MarkContent;
