import { useLocalStorage } from 'usehooks-ts';
import Attachments from '../attachments/Attachments';
import Drafts from '../drafts/Drafts';
import References from '../references/reference-view/References';
import Notes from '../notes/Notes';
import Versions from '../version/Versions';
import './Article.scss';
import { EDIT_MODE_KEY } from '../../shared/constants';
import { useAppSelector } from '../../redux/hooks';
import {
  selectActiveVersion,
  selectComparerIsActive,
} from '../../redux/store/content/slice';
import { ArticleType, RightKey } from '../../shared/enums';
import useGetCategoryByArticleId from '../../hooks/useGetCategoryByArticleId';
import { selectSidebarOpened } from '../../redux/store/layout/slice';
import ComparerLegend from '../compare-versions/ComparerLegend';

function ArticleAttachmentsContainer(): JSX.Element {
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);
  const comparerIsActive = useAppSelector(selectComparerIsActive);
  const sidebarOpened = useAppSelector(selectSidebarOpened);
  const activeVersion = useAppSelector(selectActiveVersion);
  const category = useGetCategoryByArticleId(activeVersion.articleId);

  return (
    <div
      className={`article-attachment-container ${
        sidebarOpened ? '' : 'wide-container'
      }`}>
      {comparerIsActive && <ComparerLegend />}
      {!comparerIsActive && (
        <>
          <Versions />
          {editModeIsActive &&
            category?.permittedActions?.includes(
              RightKey.RightArticleManagementEditArticle,
            ) &&
            activeVersion.type !== ArticleType.Pdf && <Drafts />}
          {activeVersion.isRecentVersion && <References />}
          <Attachments />
          <Notes />
        </>
      )}
    </div>
  );
}

export default ArticleAttachmentsContainer;
