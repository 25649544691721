import { useEffect, useState } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocalStorage, useWindowSize } from 'usehooks-ts';
import { Link } from 'react-router-dom';
import BookmarksTree from '../components/bookmarks/BookmarksTree';
import LastChanges from '../components/last-changes/LastChanges';
import ContentTree from '../components/content-tree/ContentTree';
import RecycleBin from '../components/recycle-bin/RecycleBin';
import {
  EDIT_MODE_KEY,
  mdBreakpoint,
  permissionCacheDelayInSeconds,
  settingTypeLogo,
  settingTypeLogoSmall,
} from '../shared/constants';
import {
  useGetApiArticlesRecentChangesQuery,
  useGetApiPermissionsUserQuery,
  useGetApiSettingsUserQuery,
  useGetApiVersionsInvalidMarksQuery,
} from '../redux/store/api/api';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
  addMessage,
  selectSidebarOpened,
  setSidebarOpened,
} from '../redux/store/layout/slice';
import { RightKey } from '../shared/enums';
import './Sidebar.scss';
import logo from '../assets/images/Logo-colored.png';
import logoSmall from '../assets/images/Logo-colored_Mobile.png';
import { useFilesByIdMutation } from '../redux/store/api/fileApi';
import DeleteBookmarkDialog from '../components/bookmarks/dialog/DeleteBookmarkDialog';
import OnlineHelpButton from '../components/buttons/OnlineHelpButton';
import InvalidMarks from '../components/marks/InvalidMarks';

type SidebarTabEventKey =
  | 'content'
  | 'lastChanges'
  | 'favorites'
  | 'recycleBin'
  | 'invalidMarks';

function SidebarContent(): JSX.Element {
  const dispatch = useAppDispatch();
  const { t: translation } = useTranslation();
  const { width: windowWidth } = useWindowSize();
  const [key, setKey] = useState<SidebarTabEventKey>('content');
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);
  const sidebarOpened = useAppSelector(selectSidebarOpened);
  const {
    data: permissionData,
    isError,
    error,
  } = useGetApiPermissionsUserQuery(undefined, {
    refetchOnMountOrArgChange: permissionCacheDelayInSeconds,
  });

  const { data: settings } = useGetApiSettingsUserQuery();
  const logoSetting = settings?.resultObject?.find(
    (s) => s.settingType?.key === settingTypeLogo,
  );
  const logoSmallSetting = settings?.resultObject?.find(
    (s) => s.settingType?.key === settingTypeLogoSmall,
  );
  const [displayedLogoLarge, setDisplayedLogoLarge] = useState<
    undefined | string
  >(undefined);
  const [displayedLogoSmall, setDisplayedLogoSmall] = useState<
    undefined | string
  >(undefined);
  const [getLogo] = useFilesByIdMutation();

  const { refetch: refetchRecentChanges } =
    useGetApiArticlesRecentChangesQuery();

  const { refetch: refetchInvalidMarks } = useGetApiVersionsInvalidMarksQuery();

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetPermissionsError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  useEffect(() => {
    if (!editModeIsActive && key === 'recycleBin') {
      setKey('content');
    }
  }, [editModeIsActive]);

  useEffect(() => {
    if (key === 'lastChanges') {
      refetchRecentChanges();
    }

    if (key === 'invalidMarks') {
      refetchInvalidMarks();
    }
  }, [key]);

  useEffect(() => {
    if (!settings) {
      return;
    }

    if (logoSetting?.value) {
      getLogo({ id: logoSetting.value })
        .unwrap()
        .then((logoObjectUrl) => {
          setDisplayedLogoLarge(logoObjectUrl);
        })
        .catch(() => {
          dispatch(
            addMessage({
              id: 'GetLogoLargeError',
              messageKeyBody: 'logoCanNotBeLoad',
              variant: 'danger',
            }),
          );
        });
    } else {
      setDisplayedLogoLarge(logo);
    }

    if (logoSmallSetting?.value) {
      getLogo({ id: logoSmallSetting.value })
        .unwrap()
        .then((logoObjectUrl) => {
          setDisplayedLogoSmall(logoObjectUrl);
        })
        .catch(() => {
          dispatch(
            addMessage({
              id: 'GetLogoSmallError',
              messageKeyBody: 'logoCanNotBeLoad',
              variant: 'danger',
            }),
          );
        });
    } else {
      setDisplayedLogoSmall(logoSmall);
    }
  }, [settings]);

  return (
    <>
      <div
        className={`h-100${
          sidebarOpened ? ' d-flex flex-column overflow-hidden' : ' d-none'
        }`}>
        <div className='p-4 text-center sidebar-header'>
          <Link to='/' aria-label={translation('logo')}>
            <img src={displayedLogoLarge} alt='' width='200px' />
          </Link>
          <Button
            variant='light'
            className='bg-white sidebar-inner-toggle btn-hover-round'
            title={translation('closeSidebar')}
            aria-label={translation('closeSidebar')}
            onClick={() => {
              dispatch(setSidebarOpened(false));
            }}>
            <i className='icon-sidebar_close' aria-hidden />
          </Button>
        </div>
        <div className='sidebar-body position-relative flex-grow-1'>
          <OnlineHelpButton helpId='help_2' />
          <Tabs
            className='bg-white justify-content-center'
            activeKey={key}
            onSelect={(k) => {
              if (k) {
                setKey(k as SidebarTabEventKey);
              }
            }}
            id='tablist-sidebar'>
            <Tab
              eventKey='content'
              tabAttrs={{
                title: translation('content'),
                'aria-label': translation('content'),
              }}
              title={<i aria-hidden className='icon-tree' />}>
              <ContentTree />
            </Tab>
            <Tab
              eventKey='favorites'
              tabAttrs={{
                title: translation('favorites'),
                'aria-label': translation('favorites'),
              }}
              title={<i aria-hidden className='icon-merkliste' />}>
              {key === 'favorites' && <BookmarksTree />}
            </Tab>
            <Tab
              eventKey='lastChanges'
              tabAttrs={{
                title: translation('lastChanges'),
                'aria-label': translation('lastChanges'),
              }}
              title={<i aria-hidden className='icon-aenderungen' />}>
              <LastChanges />
            </Tab>
            <Tab
              eventKey='invalidMarks'
              tabAttrs={{
                title: translation('invalidMarks'),
                'aria-label': translation('invalidMarks'),
              }}
              title={<i aria-hidden className='icon-check_marks' />}>
              <InvalidMarks />
            </Tab>
            {editModeIsActive &&
              permissionData?.resultObject &&
              permissionData.resultObject.includes(
                RightKey.RightAccessCategoryManagement,
              ) && (
                <Tab
                  eventKey='recycleBin'
                  tabAttrs={{
                    title: translation('recycleBin'),
                    'aria-label': translation('recycleBin'),
                  }}
                  title={<i aria-hidden className='icon-trash' />}>
                  <RecycleBin />
                </Tab>
              )}
          </Tabs>
        </div>
      </div>
      <div
        className={
          sidebarOpened || windowWidth < mdBreakpoint ? 'd-none' : undefined
        }>
        <div className='p-2 text-center bg-white'>
          <Link to='/' aria-label={translation('logo')}>
            <img src={displayedLogoSmall} alt='' width='30px' />
          </Link>
        </div>
        <div className='border-bottom border-light sidebar-close-button pt-2 pb-2'>
          <Button
            aria-expanded={sidebarOpened}
            aria-controls='Sidebar'
            variant='light'
            className='bg-white'
            title={translation('openSidebar')}
            aria-label={translation('openSidebar')}
            onClick={() => {
              dispatch(setSidebarOpened(true));
            }}>
            <i className='icon-sidebar_open fs-4' aria-hidden />
          </Button>
        </div>
        <div className='border-bottom border-light sidebar-close-button pt-2 pb-2'>
          <Button
            variant='light'
            className='bg-white'
            title={translation('content')}
            aria-label={translation('content')}
            onClick={() => {
              setKey('content');
              dispatch(setSidebarOpened(true));
            }}>
            <i className='icon-tree fs-4' aria-hidden />
          </Button>
        </div>
        <div className='border-bottom border-light sidebar-close-button pt-2 pb-2'>
          <Button
            variant='light'
            className='bg-white'
            title={translation('favorites')}
            aria-label={translation('favorites')}
            onClick={() => {
              setKey('favorites');
              dispatch(setSidebarOpened(true));
            }}>
            <i className='icon-merkliste fs-4' aria-hidden />
          </Button>
        </div>
        <div className='border-bottom border-light sidebar-close-button pt-2 pb-2'>
          <Button
            variant='light'
            className='bg-white'
            title={translation('lastChanges')}
            aria-label={translation('lastChanges')}
            onClick={() => {
              setKey('lastChanges');
              dispatch(setSidebarOpened(true));
            }}>
            <i className='icon-aenderungen fs-4' aria-hidden />
          </Button>
        </div>
        <div className='border-bottom border-light sidebar-close-button pt-2 pb-2'>
          <Button
            variant='light'
            className='bg-white'
            title={translation('invalidMarks')}
            aria-label={translation('invalidMarks')}
            onClick={() => {
              setKey('invalidMarks');
              dispatch(setSidebarOpened(true));
            }}>
            <i className='icon-check_marks fs-4' aria-hidden />
          </Button>
        </div>
        {editModeIsActive &&
          permissionData?.resultObject &&
          permissionData.resultObject.includes(
            RightKey.RightAccessCategoryManagement,
          ) && (
            <div className='border-bottom border-light sidebar-close-button pt-2 pb-2'>
              <Button
                variant='light'
                className='bg-white'
                title={translation('recycleBin')}
                aria-label={translation('recycleBin')}
                onClick={() => {
                  setKey('recycleBin');
                  dispatch(setSidebarOpened(true));
                }}>
                <i className='icon-trash fs-4' aria-hidden />
              </Button>
            </div>
          )}
      </div>
      <DeleteBookmarkDialog />
    </>
  );
}

export default SidebarContent;
