// session storage keys
export const EDIT_MODE_KEY = 'editMode';
export const MARK_VISIBILITY_GROUP_KEY = 'activeMarkVisibilityGroup';
export const EXPANDED_CATEGORY_IDS = 'expandedCategories';
export const CONTENT_EVALUATION_CYCLE_COUNT = 'contentEvaluationCyleCount';

// html element ids
export const VERSION_CONTENT_CONTAINER_ID = 'VersionContentContainer';
export const CALCULATION_CONTENT_CONTAINER_ID = 'CalculationContent';
export const SUGGESTION_CONTENT_CONTAINER_ID = 'SuggestionContainer';
export const SUGGESTED_EL_ID_EXTENSION = '-Suggestion';
export const REFERENCE_LIST_ITEM_ID_EXTENSION = '-RefListItem';
export const RANGE_START_ID = 'RangeStart';
export const RANGE_END_ID = 'RangeEnd';

// html element class names
export const SEARCH_RESULT_SPAN = 'search-result-span';

// breakpoints
export const xsBreakpoint = 0;
export const smBreakpoint = 576;
export const mdBreakpoint = 768;
export const lgBreakpoint = 992;
export const xlBreakpoint = 1200;
export const xxlBreakpoint = 1400;

// url parameter
export const versionIdUrlParam = 'versionId';
export const markIdUrlParam = 'markId';
export const articleIdUrlParam = 'articleId';
export const categoryIdUrlParam = 'categoryId';
export const articleIdsWithFoundTermsUrlParam = 'articleIdsWithFoundTerms';
export const textAreaIdUrlParam = 'areaId';
export const pdfArticleUrlParam = 'pdfArticle';
export const searchKeywordsUrlParam = 'searchKeywords';
export const searchResultSortUrlParam = 's';
export const searchKeywordUrlParam = 'k';
export const searchOptionUrlParam = 'o';
export const includeVersionsUrlParam = 'v';
export const selectedCategoriesUrlParam = 'c';
export const searchResultTypeUrlParam = 't';

// settings
export const settingTypeClientSessionTimeoutInterval =
  'settingTypeClientSessionTimeoutInterval';
export const settingTypeSessionEditModeTimeoutInterval =
  'settingTypeSessionEditModeTimeoutInterval';
export const settingTypeTinyConfigFile = 'settingTypeTinyConfigFile';
export const settingTypeAllowedFileTypes = 'settingTypeAllowedFileTypes';
export const settingTypeInformationText = 'settingTypeInformationText';
export const settingTypeImprintText = 'settingTypeImprintText';
export const settingTypePrivacyText = 'settingTypePrivacyText';
export const settingTypeSupportText = 'settingTypeSupportText';
export const settingTypeContentTreeValidCheckInterval =
  'settingTypeContentTreeValidCheckInterval';
export const settingTypeCaching = 'settingTypeCaching';
export const settingTypeLogo = 'settingTypeLogo';
export const settingTypeLogoSmall = 'settingTypeLogoSmall';
export const settingTypeMaxCategoryNameLength =
  'settingTypeMaxCategoryNameLength';
export const settingTypeMaxContentNameLength =
  'settingTypeMaxContentNameLength';
export const settingTypeMaxAbbreviationLength =
  'settingTypeMaxAbbreviationLength';
export const settingTypeMaxNoteContentLength =
  'settingTypeMaxNoteContentLength';
export const settingTypeMaxVersionCount = 'settingTypeMaxVersionCount';
export const settingTypeMaxFileSize = 'settingTypeMaxFileSize';
export const settingTypeMaxFileAmount = 'settingTypeMaxFileAmount';
export const settingTypeApplicationName = 'settingTypeApplicationName';
export const settingTypeManualContent = 'settingTypeManualContent';
export const settingTypeVideoContent = 'settingTypeVideoContent';
export const settingTypeFaqContent = 'settingTypeFaqContent';

// shared constants
export const permissionCacheDelayInSeconds = 1800;
export const countOfTimeDigitsInDate = 10;
export const paddingOffsetForTreeElements = 24;

export const fileExtensions = [
  '.xla', // application/excel
  '.xlt', // application/excel
  '.xls', // application/excel
  '.ppt', // 'application/mspowerpoint'
  '.pps', // 'application/mspowerpoint'
  '.pot', // 'application/mspowerpoint'
  '.doc', // application/msword
  '.dot', // application/msword
  '.pdf', // application/pdf
  '.rtf', // application/rtf
  '.odp', // application/vnd.oasis.opendocument.presentation
  '.ods', // application/vnd.oasis.opendocument.spreadsheet
  '.odt', // application/vnd.oasis.opendocument.text
  '.pptm', // application/vnd.openxmlformats-officedocument.presentationml.presentation
  '.ppsm', // application/vnd.openxmlformats-officedocument.presentationml.presentation
  '.potm', // application/vnd.openxmlformats-officedocument.presentationml.presentation
  '.pptx', // application/vnd.openxmlformats-officedocument.presentationml.presentation
  '.ppsx', // application/vnd.openxmlformats-officedocument.presentationml.presentation
  '.potx', // application/vnd.openxmlformats-officedocument.presentationml.presentation
  '.xlsm', // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
  '.xltm', // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
  '.xlsx', // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
  '.xltx', // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
  '.docx', // application/vnd.openxmlformats-officedocument.wordprocessingml.document
  '.dotx', // application/vnd.openxmlformats-officedocument.wordprocessingml.document
  '.docm', // application/vnd.openxmlformats-officedocument.wordprocessingml.document
  '.dotm', // application/vnd.openxmlformats-officedocument.wordprocessingml.document
  '.xml', // application/xml
  '.gif', // image/gif
  '.jpg', // image/jpeg
  '.png', // image/png
  '.csv', // text/csv
  '.txt', // text/plain
];

// regex for line breaks
export const lineBreakRegex = /(\r\n|\n|\r)/gm;

// accordion keys
export const externalReferencesAccordionKey = 'ExternalReferenceList';

export const HttpStatusCode = {
  UnsupportedMediaType: 415,
};

export const focusableElementsString =
  'a[href], area[href], input:not([disabled]), select:not([disabled]),' +
  ' textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';
