import { useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useIsFirstRender } from 'usehooks-ts';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  ArticleReference,
  SourceContentArea,
  useGetApiArticlesReferencesByIdQuery,
  usePutApiSourceContentAreasByIdMutation,
} from '../../../redux/store/api/api';
import {
  selectActiveArticleReference,
  selectActiveVersion,
  selectLinksNeedingAction,
} from '../../../redux/store/content/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import convertFromRange from '../../version/functions/convertFromRange';
import makeSuggestionEl from '../../version/functions/makeSuggestionEl';
import { addMessage } from '../../../redux/store/layout/slice';
import makeContent from '../../version/functions/makeContent';
import testForIntersection from '../../version/functions/testForIntersection';
import {
  articleIdUrlParam,
  SUGGESTED_EL_ID_EXTENSION,
  SUGGESTION_CONTENT_CONTAINER_ID,
} from '../../../shared/constants';
import DeleteReferenceDialog from './DeleteReferenceDialog';
import DeleteSourceContentAreaDialog from './DeleteSourceContentAreaDialog';
import makeRangeFromSuggestion from '../../version/functions/makeRangeFromSuggestion';
import '../References.scss';

interface ILinkNeedsActionDialogProps {
  show: boolean;
  setShow: (show: boolean) => void;
}

function LinkNeedsActionDialog({
  show,
  setShow,
}: ILinkNeedsActionDialogProps): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const version = useAppSelector(selectActiveVersion);
  const reference = useAppSelector(selectActiveArticleReference);
  const linksNeedingAction = useAppSelector(selectLinksNeedingAction);
  const [suggestion, setSuggestion] = useState<string | null>('');
  const [noSuggestionFound, setNoSuggestionFound] = useState(
    suggestion === null,
  );
  const [selectingNewArea, setSelectingNewArea] = useState(false);
  const [deleteReferenceDialogOpened, setDeleteReferenceDialogOpened] =
    useState(false);
  const [
    deleteSourceContentAreaDialogOpened,
    setDeleteSourceContentAreaDialogOpened,
  ] = useState(false);

  const [suggestionContainer, setSuggestionContainer] = useState(
    document.getElementById(SUGGESTION_CONTENT_CONTAINER_ID),
  );
  const [showInvalidSelectionWarning, setShowInvalidSelectionWarning] =
    useState(false);
  const isFirstRender = useIsFirstRender();
  const [searchParams] = useSearchParams();
  const articleId: string | null = searchParams.get(articleIdUrlParam);

  const [
    updateSourceContentArea,
    { isError: updateSourceContentAreaIsError, error },
  ] = usePutApiSourceContentAreasByIdMutation();
  const { data: referenceData, refetch } = useGetApiArticlesReferencesByIdQuery(
    articleId
      ? {
          id: articleId,
        }
      : skipToken,
  );

  const closeModalResetButtons = () => {
    setShow(false);
    setSelectingNewArea(false);
    setShowInvalidSelectionWarning(false);
  };

  const makeSuggestionContent = (suggestionLink?: ArticleReference) => {
    const linksToShow = referenceData?.resultObject?.articleReferenceLists
      ?.flatMap((rL) => rL.articleReferences)
      .filter(
        (r) =>
          r &&
          r.sourceContentArea?.id &&
          !linksNeedingAction.includes(r.sourceContentArea.id),
      ) as ArticleReference[];

    if (suggestionLink) {
      linksToShow.push(suggestionLink);
    }

    return makeContent({
      content: version.htmlContent || '',
      elements: linksToShow,
      tagName: 'a',
      isSuggestionContent: true,
      suggestionElId: suggestionLink?.sourceContentArea?.id || '',
    }).content;
  };

  const onSave = () => {
    let range = makeRangeFromSuggestion(suggestionContainer, 'suggestion-link');
    const selection = window.getSelection();

    if (!range) {
      if (selection && !selection.isCollapsed) {
        range = selection.getRangeAt(0);
      } else {
        return;
      }
    }

    if (testForIntersection(range, SUGGESTION_CONTENT_CONTAINER_ID, 'a')) {
      selection?.collapseToStart();
      setShowInvalidSelectionWarning(true);
      return;
    }

    const sourceContentAreaId = reference?.sourceContentArea?.id || '';
    const changedSorceContentArea: SourceContentArea = {
      id: sourceContentAreaId,
      ...convertFromRange(range, SUGGESTION_CONTENT_CONTAINER_ID, document),
      articleReferenceId: reference?.id || '',
    };

    updateSourceContentArea({
      id: sourceContentAreaId,
      sourceContentArea: changedSorceContentArea,
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'UpdateSourceContentAreaSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        setShow(false);
        refetch();
      });
  };

  const onSetNewLink = () => {
    if (!suggestionContainer) {
      return;
    }
    setSelectingNewArea(true);
    setSuggestion(makeSuggestionContent() || '');
    const selection = window.getSelection();
    selection?.collapseToStart();
    setNoSuggestionFound(false);
  };

  // Scroll to suggested link in content
  useEffect(() => {
    if (suggestionContainer) {
      const suggestionLink = document.querySelector(
        `[id$="${SUGGESTED_EL_ID_EXTENSION}"]`,
      );
      suggestionLink?.scrollIntoView(true);
    }
  }, [suggestionContainer]);

  useEffect(() => {
    if (updateSourceContentAreaIsError) {
      dispatch(
        addMessage({
          id: 'UpdateSourceVontentAreaError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [updateSourceContentAreaIsError]);

  useEffect(() => {
    if (show) {
      setSuggestionContainer(
        document.getElementById(SUGGESTION_CONTENT_CONTAINER_ID),
      );
    }
  }, [show]);

  useEffect(() => {
    if (isFirstRender || !reference || !show) {
      return;
    }

    const suggestionLink = makeSuggestionEl(
      makeSuggestionContent(),
      reference.sourceContentArea?.patternString || '',
      reference.sourceContentArea?.id || '',
    );

    if (suggestionLink) {
      setSuggestion(
        makeSuggestionContent({
          ...reference,
          sourceContentArea: {
            ...reference?.sourceContentArea,
            ...suggestionLink,
          },
        }),
      );
    }
  }, [reference, version.htmlContent, show]);

  return (
    <>
      <CustomDialog
        titleId='LinkNeedsActionDialog'
        dialogTitle={translation('checkLink')}
        show={show}
        closeFunction={closeModalResetButtons}
        closeTitle={translation('cancel')}>
        {showInvalidSelectionWarning && (
          <Alert
            aria-label={translation('closeAlert')}
            variant='warning'
            onClose={() => setShowInvalidSelectionWarning(false)}
            dismissible>
            <p className='m-0'>{translation('makeNewSelection')}</p>
          </Alert>
        )}

        <h5>{translation('nameOfTheReference')}:</h5>
        <p>{reference.name}</p>
        <h5>
          {selectingNewArea
            ? translation('selectNewArea')
            : translation('suggestion')}
          :
        </h5>
        <div className='max-350' id={SUGGESTION_CONTENT_CONTAINER_ID}>
          {parse(suggestion || translation('noSuggestionFound'))}
        </div>
        <div className='mt-3'>
          <h5>{translation('originallyLinkedText')}:</h5>
          <p>{reference.sourceContentArea?.patternString}</p>
        </div>

        <Button
          className='m-1 option-button'
          variant='outline-dark'
          disabled={noSuggestionFound}
          onClick={onSave}>
          {translation('save')}
        </Button>
        <Button
          className={`m-1 ${
            selectingNewArea ? 'invisible' : 'visible'
          } option-button`}
          variant='outline-dark'
          onClick={onSetNewLink}>
          {translation('setNewLink')}
        </Button>
        <Button
          className='m-1 option-button'
          variant='outline-danger'
          onClick={() => {
            closeModalResetButtons();
            setDeleteSourceContentAreaDialogOpened(true);
          }}>
          {translation('deleteLink')}
        </Button>
        <Button
          className='m-1 option-button'
          variant='outline-danger'
          onClick={() => {
            closeModalResetButtons();
            setDeleteReferenceDialogOpened(true);
          }}>
          {translation('deleteWholeReference')}
        </Button>
      </CustomDialog>

      <DeleteSourceContentAreaDialog
        show={deleteSourceContentAreaDialogOpened}
        setShow={setDeleteSourceContentAreaDialogOpened}
      />
      <DeleteReferenceDialog
        reference={reference}
        dialogShow={deleteReferenceDialogOpened}
        setDialogShow={setDeleteReferenceDialogOpened}
      />
    </>
  );
}

export default LinkNeedsActionDialog;
