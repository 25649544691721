import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  Attachment,
  useGetApiAttachmentsByVersionIdQuery,
  usePutApiAttachmentsMoveByIdMutation,
} from '../../../redux/store/api/api';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectActiveVersion } from '../../../redux/store/content/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import Loader from '../../loader/Loader';
import CustomDialog from '../../dialogs/CustomDialog';

interface IMoveAttachmentDialogProps {
  attachment: Attachment;
  setAttachmentToMove: (attachment: Attachment | null) => void;
  show: boolean;
  setShow: (show: boolean) => void;
}

function MoveAttachmentDialog({
  attachment,
  setAttachmentToMove,
  show,
  setShow,
}: IMoveAttachmentDialogProps) {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const version = useAppSelector(selectActiveVersion);

  const [moveAttachment, { isError, isLoading, error }] =
    usePutApiAttachmentsMoveByIdMutation();

  const { refetch } = useGetApiAttachmentsByVersionIdQuery(
    version.id
      ? {
          versionId: version.id,
        }
      : skipToken,
  );

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'MoveAttachmentError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  const handleMove = (): void => {
    moveAttachment({
      id: attachment.id || '',
      targetPosition: attachment.order || 0,
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'MoveAttachmentSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        setAttachmentToMove(null);
        refetch();
        setShow(false);
      });
  };

  return (
    <CustomDialog
      titleId='MoveAttachmentDialog'
      dialogTitle={translation('moveAttachment')}
      show={show}
      closeFunction={() => {
        setAttachmentToMove(null);
        setShow(false);
      }}
      actionFunction={handleMove}
      closeTitle={translation('cancel')}
      actionTitle={translation('move')}
      actionButtonDisabled={isLoading}>
      {isLoading && <Loader />}
      {!isLoading && <p>{translation('moveAttachmentToThisPosition')}</p>}
    </CustomDialog>
  );
}

export default MoveAttachmentDialog;
