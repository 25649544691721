import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../../redux/hooks';
import {
  ArticleReference,
  ExternalReference,
  useDeleteApiArticleReferencesByIdMutation,
  useDeleteApiExternalReferencesByIdMutation,
  useGetApiArticlesReferencesByIdQuery,
  useGetApiCategoriesArticleReferencesByIdQuery,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import {
  articleIdUrlParam,
  categoryIdUrlParam,
  textAreaIdUrlParam,
} from '../../../shared/constants';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';

interface IDeleteReferenceDialogProps {
  reference: ArticleReference | ExternalReference;
  dialogShow: boolean;
  setDialogShow: (show: boolean) => void;
}

function DeleteReferenceDialog({
  reference,
  dialogShow,
  setDialogShow,
}: IDeleteReferenceDialogProps) {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const articleId: string | null = searchParams.get(articleIdUrlParam);
  const categoryId: string | null = searchParams.get(categoryIdUrlParam);
  const areaId: string | null = searchParams.get(textAreaIdUrlParam);
  const [
    deleteArticleReference,
    {
      isError: deleteArticleReferenceIsError,
      error: deleteArticleReferenceError,
      isLoading: deleteArticleReferenceIsLoading,
    },
  ] = useDeleteApiArticleReferencesByIdMutation();
  const [
    deleteExternalReference,
    {
      isError: deleteExternalReferenceIsError,
      error: deleteExternalReferenceError,
      isLoading: deleteExternalReferenceIsLoading,
    },
  ] = useDeleteApiExternalReferencesByIdMutation();
  const isLoading =
    deleteArticleReferenceIsLoading || deleteExternalReferenceIsLoading;
  const { refetch: refetchGetArticleReferences } =
    useGetApiArticlesReferencesByIdQuery(
      articleId
        ? {
            id: articleId,
          }
        : skipToken,
    );
  const { refetch: refetchGetCategoryReferences } =
    useGetApiCategoriesArticleReferencesByIdQuery(
      categoryId ? { id: categoryId } : skipToken,
    );

  useEffect(() => {
    if (deleteExternalReferenceIsError) {
      dispatch(
        addMessage({
          id: 'DeleteExternalReferenceError',
          variant: 'danger',
          messageKeyBody:
            deleteExternalReferenceError &&
            'data' in deleteExternalReferenceError
              ? deleteExternalReferenceError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (deleteArticleReferenceIsError) {
      dispatch(
        addMessage({
          id: 'DeleteArticleReferenceError',
          variant: 'danger',
          messageKeyBody:
            deleteArticleReferenceError && 'data' in deleteArticleReferenceError
              ? deleteArticleReferenceError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [deleteArticleReferenceIsError, deleteExternalReferenceIsError]);

  const handleDeleteReference = (): void => {
    const aId = reference.hasContentLink ? articleId ?? undefined : undefined;
    if ((reference as ArticleReference).sourceArticleId) {
      deleteArticleReference({ id: reference.id || '', articleId: aId })
        .unwrap()
        .then((result) => {
          if (result.messageKey && result.messageKey !== '') {
            dispatch(
              addMessage({
                id: 'DeleteArticleReferenceSuccess',
                variant: 'success',
                messageKeyBody: result.messageKey,
              }),
            );
          }
          if (areaId === (reference as ArticleReference).targetContentAreaId) {
            searchParams.delete('areaId');
            setSearchParams(searchParams);
          }
          if (articleId) {
            refetchGetArticleReferences();
          }
          if (categoryId) {
            refetchGetCategoryReferences();
          }
          setDialogShow(false);
        });
    } else {
      deleteExternalReference({
        id: reference.id || '',
        articleId: aId,
      })
        .unwrap()
        .then((result) => {
          if (result.messageKey && result.messageKey !== '') {
            dispatch(
              addMessage({
                id: 'DeleteExternalReferenceSuccess',
                variant: 'success',
                messageKeyBody: result.messageKey,
              }),
            );
          }
          refetchGetArticleReferences();
          setDialogShow(false);
        });
    }
  };

  return (
    <CustomDialog
      titleId='DeleteReferenceDialog'
      dialogTitle={translation('deleteReference')}
      show={dialogShow}
      closeFunction={() => {
        setDialogShow(false);
      }}
      actionFunction={handleDeleteReference}
      closeTitle={translation('cancel')}
      actionTitle={translation('delete')}
      actionButtonVariant='outline-danger'
      actionButtonDisabled={isLoading}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <p>{translation('deleteReferencePermanently')}</p>
          {(reference as ArticleReference).sourceContentArea !== null && (
            <p>{translation('linkInContentWillBeDeleted')}</p>
          )}
          <p className='fw-bold'>
            {(reference as ArticleReference).sourceArticleId &&
            articleId === (reference as ArticleReference).sourceArticleId
              ? (reference as ArticleReference).name
              : (reference as ArticleReference).backwardName}
            {(reference as ExternalReference).hyperlink && (
              <span>{(reference as ExternalReference).name}</span>
            )}
          </p>
        </>
      )}
    </CustomDialog>
  );
}

export default DeleteReferenceDialog;
