import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import {
  useGetApiBookmarkFoldersQuery,
  usePutApiBookmarkFoldersByIdMutation,
} from '../../../redux/store/api/api';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { addMessage } from '../../../redux/store/layout/slice';
import { selectContextBookmarkFolder } from '../../../redux/store/content/slice';

interface IRenameBookmarkFolderDialogProps {
  dialogShow: boolean;
  setDialogShow: (show: boolean) => void;
  setLastUpdatedItem: (updatedId: string) => void;
}

function RenameBookmarkFolderDialog({
  dialogShow,
  setDialogShow,
  setLastUpdatedItem,
}: IRenameBookmarkFolderDialogProps) {
  const dispatch = useAppDispatch();
  const { t: translation } = useTranslation();
  const contextBookmarkFolder = useAppSelector(selectContextBookmarkFolder);
  const [updateBookmarkFolder, { isLoading, isError, error }] =
    usePutApiBookmarkFoldersByIdMutation();
  const [folderName, setFolderName] = useState('');
  const [bookmarkFolderIsValid, setBookmarkFolderIsValid] = useState(true);
  const { refetch } = useGetApiBookmarkFoldersQuery();

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'RenameBookmarkFolderError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  useEffect(() => {
    setFolderName(contextBookmarkFolder.name || '');
    setBookmarkFolderIsValid(true);
  }, [contextBookmarkFolder]);

  const handleRenameBookmarkFolder = () => {
    if (folderName.trim().length === 0) {
      setBookmarkFolderIsValid(false);
      return;
    }

    updateBookmarkFolder({
      id: contextBookmarkFolder.id || '',
      bookmarkFolder: { ...contextBookmarkFolder, name: folderName.trim() },
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'RenameBookmarkFolderSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        setDialogShow(false);
        refetch();
        setLastUpdatedItem(contextBookmarkFolder.id ?? '');
      });
  };

  return (
    <CustomDialog
      titleId='RenameBookmarkFolderDialog'
      show={dialogShow}
      closeFunction={() => {
        setFolderName('');
        setBookmarkFolderIsValid(true);
        setDialogShow(false);
      }}
      closeTitle={translation('cancel')}
      actionFunction={handleRenameBookmarkFolder}
      actionTitle={translation('rename')}
      actionButtonDisabled={
        folderName.trim() === contextBookmarkFolder.name || isLoading
      }
      dialogTitle={translation('renameBookmarkFolder')}>
      <>
        {isLoading && <Loader />}
        {!isLoading && (
          <>
            <p>{translation('fieldsAreRequiredLegend')}</p>
            <Form.Group className='mb-3' controlId='BookmarkFolderName'>
              <Form.Label>{translation('name')}*</Form.Label>
              <Form.Control
                aria-describedby={
                  !bookmarkFolderIsValid
                    ? 'BookmarkFolderNameInputError'
                    : undefined
                }
                isInvalid={!bookmarkFolderIsValid}
                value={folderName}
                type='text'
                onChange={(e) => {
                  setFolderName(e.target.value);
                  if (e.target.value.trim().length > 0) {
                    setBookmarkFolderIsValid(true);
                  }
                }}
              />
              <Form.Control.Feedback
                id='BookmarkFolderNameInputError'
                type='invalid'>
                {translation('fieldNotEmpty')}
              </Form.Control.Feedback>
            </Form.Group>
          </>
        )}
      </>
    </CustomDialog>
  );
}

export default RenameBookmarkFolderDialog;
