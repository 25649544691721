/* eslint-disable react/jsx-no-useless-fragment */
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  Role,
  useDeleteApiRolesByIdMutation,
  useGetApiRolesQuery,
  useGetApiRolesIsInUseByIdQuery,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import { useAppDispatch } from '../../../redux/hooks';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';

interface IDeleteRoleDialogProps {
  show: boolean;
  onClose: () => void;
  role: Role;
}

function DeleteRoleDialog({
  show,
  onClose,
  role,
}: IDeleteRoleDialogProps): JSX.Element {
  const { t: translation } = useTranslation();
  const [
    deleteRole,
    { isError: deleteRoleIsError, error: deleteRoleError, isLoading },
  ] = useDeleteApiRolesByIdMutation();
  const {
    refetch,
    isError: getRolesIsError,
    error: getRolesError,
  } = useGetApiRolesQuery();
  const {
    data: roleInUse,
    isFetching,
    isError: getRoleInUseIsError,
    error: getRoleInUseError,
  } = useGetApiRolesIsInUseByIdQuery(
    role.id && show
      ? {
          id: role.id,
        }
      : skipToken,
    { refetchOnMountOrArgChange: true },
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (getRolesIsError) {
      dispatch(
        addMessage({
          id: 'GetRolesError',
          variant: 'danger',
          messageKeyBody:
            getRolesError && 'data' in getRolesError
              ? getRolesError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (getRoleInUseIsError) {
      dispatch(
        addMessage({
          id: 'RoleInUseError',
          variant: 'danger',
          messageKeyBody:
            getRoleInUseError && 'data' in getRoleInUseError
              ? getRoleInUseError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (deleteRoleIsError) {
      dispatch(
        addMessage({
          id: 'DeleteRoleError',
          variant: 'danger',
          messageKeyBody:
            deleteRoleError && 'data' in deleteRoleError
              ? deleteRoleError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [getRolesIsError, getRoleInUseIsError, deleteRoleIsError]);

  const handleDeleteRole = (): void => {
    deleteRole({ id: role.id || '' })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'DeleteRoleSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        onClose();
      });
  };

  return (
    <CustomDialog
      titleId='DeleteRoleDialog'
      actionButtonDisabled={roleInUse?.resultObject || isLoading}
      dialogTitle={translation('deleteRole')}
      show={show}
      closeFunction={onClose}
      actionFunction={handleDeleteRole}
      closeTitle={translation('cancel')}
      actionTitle={translation('delete')}
      actionButtonVariant='outline-danger'>
      {(isFetching || isLoading) && <Loader />}
      {!isFetching && !isLoading && (
        <>
          {roleInUse ? (
            <>
              <p>{translation('roleAssignedWarning')}</p>
            </>
          ) : (
            <>
              <p>{translation('deleteRolePermanently')}</p>
            </>
          )}
          <p className='fw-bold'>{role.name}</p>
        </>
      )}
    </CustomDialog>
  );
}

export default DeleteRoleDialog;
