import { SyntheticEvent, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useDeleteApiPermissionsByIdMutation,
  useGetApiPermissionsQuery,
  usePostApiPermissionsMutation,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import { selectContextUserGroup } from '../../../redux/store/user-management/slice';
import { paddingOffsetForTreeElements } from '../../../shared/constants';
import { RightKey } from '../../../shared/enums';
import { IUserGroupTreeItem } from '../types';

interface IUserGroupPermissionsTreeItemProps {
  level: number;
  isBranch: boolean;
  isExpanded: boolean | undefined;
  element: IUserGroupTreeItem;
  getNodeProps: Function;
  isSelected: boolean;
  handleExpand: Function;
  roleId: string;
  permissionId: string | null;
  lastClickedElementId: string | null;
  setLastClickedElementId: (elementId: string) => void;
}

function UserGroupPermissionsTreeItem({
  level,
  isBranch,
  isExpanded,
  element,
  getNodeProps,
  isSelected,
  handleExpand,
  permissionId,
  roleId,
  lastClickedElementId,
  setLastClickedElementId,
}: IUserGroupPermissionsTreeItemProps) {
  const dispatch = useAppDispatch();
  const userGroup = useAppSelector(selectContextUserGroup);
  const [
    addPermission,
    { isError: addPermissionIsError, error: addPermissionError },
  ] = usePostApiPermissionsMutation();
  const [
    deletePermission,
    { isError: deletePermissionIsError, error: deletePermissionError },
  ] = useDeleteApiPermissionsByIdMutation();
  const { refetch: refetchGetPermissions } = useGetApiPermissionsQuery({
    roleId,
    userGroupId: userGroup.id || '',
  });

  // permissions
  const userCanAddPermission = userGroup.permittedActions?.includes(
    RightKey.RightPermissionManagementCreate,
  );
  const userCanDeletePermission = userGroup.permittedActions?.includes(
    RightKey.RightPermissionManagementDelete,
  );

  useEffect(() => {
    if (addPermissionIsError) {
      dispatch(
        addMessage({
          id: 'CreatePermissionError',
          variant: 'danger',
          messageKeyBody:
            addPermissionError && 'data' in addPermissionError
              ? addPermissionError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (deletePermissionIsError) {
      dispatch(
        addMessage({
          id: 'DeletePermissionError',
          variant: 'danger',
          messageKeyBody:
            deletePermissionError && 'data' in deletePermissionError
              ? deletePermissionError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [addPermissionIsError, deletePermissionIsError]);

  useEffect(() => {
    if (lastClickedElementId) {
      document.getElementById(lastClickedElementId)?.focus();
    }
  }, [lastClickedElementId]);

  const handleAddPermission = () => {
    addPermission({
      permission: {
        roleId,
        userGroupId: userGroup.id || '',
        targetId: element.id,
        recursiveInheritanceEnabled: false,
      },
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'CreatePermissionSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetchGetPermissions();
      });
  };

  const handleDeletePermission = () => {
    deletePermission({
      id: permissionId || '',
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'DeletePersmissionSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetchGetPermissions();
        setLastClickedElementId(element.id || '');
      });
  };

  return (
    <div className='li-inner'>
      <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getNodeProps({
          onClick: (e: SyntheticEvent) => {
            if (
              (e.target as HTMLElement).classList.contains('form-check-input')
            ) {
              return;
            }

            handleExpand(e);
          },
        })}
        style={{ paddingLeft: paddingOffsetForTreeElements * (level - 1) }}>
        <div className='d-flex'>
          {isBranch && isExpanded && (
            <i className='me-2 icon-carretup' aria-hidden />
          )}
          {isBranch && !isExpanded && (
            <i className='me-2 icon-carretdown' aria-hidden />
          )}
          <Form.Check
            id={element.id}
            type='checkbox'
            label={element.name}
            checked={isSelected || element.isRecursivePermitted}
            disabled={element.isRecursivePermitted && !permissionId}
            readOnly={!userCanAddPermission && !userCanDeletePermission}
            onKeyDown={(e) => {
              if (e.code === 'Space') {
                const newCheckboxState = !isSelected;
                if (newCheckboxState && userCanAddPermission) {
                  handleAddPermission();
                }
                if (!newCheckboxState && userCanDeletePermission) {
                  handleDeletePermission();
                }
              }
            }}
            onChange={(e) => {
              e.stopPropagation();
              const newCheckboxState = !isSelected;
              if (newCheckboxState && userCanAddPermission) {
                handleAddPermission();
              }
              if (!newCheckboxState && userCanDeletePermission) {
                handleDeletePermission();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default UserGroupPermissionsTreeItem;
