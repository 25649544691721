import { Button, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Filter } from 'react-bootstrap-icons';
import { getSearchUrl } from '../../shared/urlBuilder';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectSearchIncludeVersions,
  selectSearchKeyword,
  selectSearchOption,
  selectSelectedCategoryIdsForSearch,
  setControlledSelectedCategoryIdsForSearch,
  setSearchIncludeVersions,
  setSearchKeyword,
  setSearchOption,
  setSelectedCategoryIdsForSearch,
} from '../../redux/store/content/slice';
import { SearchOption } from '../../redux/store/content/types';

interface ISearchFieldInNavbarProps {
  searchFilterOpened: boolean;
  setSearchFilterOpened: (opened: boolean) => void;
}

function SearchFieldInNavbar({
  searchFilterOpened,
  setSearchFilterOpened,
}: ISearchFieldInNavbarProps): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchKeyword = useAppSelector(selectSearchKeyword);
  const selectedCategoryIds = useAppSelector(
    selectSelectedCategoryIdsForSearch,
  );
  const searchOption = useAppSelector(selectSearchOption);
  const includeVersions = useAppSelector(selectSearchIncludeVersions);

  const submitSearch = () => {
    if (searchKeyword.trim() !== '') {
      setSearchFilterOpened(false);
      navigate(
        getSearchUrl(
          searchKeyword,
          searchOption,
          includeVersions,
          selectedCategoryIds,
        ),
        { replace: true },
      );
    }
  };

  const toggleSearchFilter = () => {
    setSearchFilterOpened(!searchFilterOpened);
  };

  useEffect(() => {
    if (!location.pathname.includes('/search')) {
      dispatch(setSearchKeyword(''));
      dispatch(setSelectedCategoryIdsForSearch([]));
      dispatch(setControlledSelectedCategoryIdsForSearch([]));
      dispatch(setSearchOption(SearchOption.AllWords));
      dispatch(setSearchIncludeVersions(false));
    }
  }, [location.pathname]);

  return (
    <InputGroup
      aria-expanded={searchFilterOpened}
      aria-controls='SearchFilterContainer'
      className='ms-2 me-2 search-field-header'>
      <Form.Label className='visually-hidden' htmlFor='SearchKeywordInHeader'>
        {translation('searchKeyword')}
      </Form.Label>
      <Form.Control
        id='SearchKeywordInHeader'
        className='border-0'
        required
        placeholder={`${translation('search')}...`}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            submitSearch();
          }
        }}
        onChange={(e) => {
          dispatch(setSearchKeyword(e.target.value));
        }}
        value={searchKeyword}
        type='text'
      />
      {!location.pathname.includes('/search') && (
        <Button
          variant='light'
          className='search-filter-btn'
          onKeyDown={(e) => {
            if (searchFilterOpened) {
              if (e.key === 'Tab') {
                e.preventDefault();
                document
                  .getElementById('SearchFilterContainer')
                  ?.getElementsByTagName('button')?.[0]
                  .focus();
              }

              if (e.key === 'Escape') {
                setSearchFilterOpened(false);
              }
            }
          }}
          aria-expanded={searchFilterOpened}
          onClick={toggleSearchFilter}
          aria-label={translation('openSearchFilter')}
          title={translation('openSearchFilter')}>
          <Filter className='fs-3' aria-hidden />
        </Button>
      )}
      <Button
        className='bg-white border-0 search-btn'
        onClick={() => {
          submitSearch();
        }}
        aria-label={translation('startSearch')}>
        <i className='icon-search header-active-background' aria-hidden />
      </Button>
    </InputGroup>
  );
}

export default SearchFieldInNavbar;
