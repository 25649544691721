import preprocessRange from './preprocessRange';

const makeRangeFromSuggestion = (
  suggestionContainer: HTMLElement | null,
  suggestionElementId: string,
): Range | undefined => {
  if (!suggestionContainer) {
    return undefined;
  }
  const marks = Array.from(
    suggestionContainer.getElementsByClassName(suggestionElementId),
  );
  if (marks.length <= 0) {
    return undefined;
  }
  const rangeStart = marks[0];
  const rangeEnd = marks[marks.length - 1];
  const range = document.createRange();
  if (!rangeStart || !rangeEnd) {
    return undefined;
  }
  range.setStartBefore(rangeStart);
  range.setEndAfter(rangeEnd);

  marks.forEach((mark) => {
    const markChildNodes = Array.from(mark.childNodes);
    mark.replaceWith(...markChildNodes);
    if (mark === rangeStart) {
      range.setStartBefore(markChildNodes[0]);
    }
    if (mark === rangeEnd) {
      range.setEndAfter(markChildNodes[markChildNodes.length - 1]);
    }
  });
  suggestionContainer.normalize();
  return preprocessRange(range, document);
};

export default makeRangeFromSuggestion;
