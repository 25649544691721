import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiUserGroupsMoveCheckMoveByIdQuery,
  useGetApiUserGroupsQuery,
  usePutApiUserGroupsMoveByIdMutation,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import {
  selectMovingUserGroup,
  selectContextUserGroup,
  selectMoveUserGroupDialogOpened,
  setMoveUserGroupStarted,
  setMoveUserGroupDialogOpened,
  clearSelectedUsers,
  addExpandedUserGroup,
} from '../../../redux/store/user-management/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';

interface IMoveUserGroupDialogProps {
  setLastUpdatedItem: (updatedId: string) => void;
}

function MoveUserGroupDialog({
  setLastUpdatedItem,
}: IMoveUserGroupDialogProps) {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const dialogShow = useAppSelector(selectMoveUserGroupDialogOpened);
  const movingUserGroup = useAppSelector(selectMovingUserGroup);
  const userGroup = useAppSelector(selectContextUserGroup);
  const {
    data: moveUserGroupIsPossible,
    isFetching,
    isError: getMoveUserGroupPossibleIsError,
    error: getMoveUserGroupPossibleError,
  } = useGetApiUserGroupsMoveCheckMoveByIdQuery(
    dialogShow && movingUserGroup.id && userGroup.id
      ? {
          id: movingUserGroup.id,
          targetUserGroupId: userGroup.id,
        }
      : skipToken,
    { refetchOnMountOrArgChange: true },
  );
  const [
    moveUserGroup,
    {
      isError: putMoveUserGroupIsError,
      error: putMoveUserGroupError,
      isLoading,
    },
  ] = usePutApiUserGroupsMoveByIdMutation();
  const {
    refetch,
    isError: getUserGroupsIsError,
    error: getUserGroupsError,
  } = useGetApiUserGroupsQuery({
    includeUsers: true,
    includePermittedActions: true,
  });

  useEffect(() => {
    if (getMoveUserGroupPossibleIsError) {
      dispatch(
        addMessage({
          id: 'MoveUSerGroupPossibleError',
          variant: 'danger',
          messageKeyBody:
            getMoveUserGroupPossibleError &&
            'data' in getMoveUserGroupPossibleError
              ? getMoveUserGroupPossibleError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (putMoveUserGroupIsError) {
      dispatch(
        addMessage({
          id: 'MoveUserGroupError',
          variant: 'danger',
          messageKeyBody:
            putMoveUserGroupError && 'data' in putMoveUserGroupError
              ? putMoveUserGroupError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (getUserGroupsIsError) {
      dispatch(
        addMessage({
          id: 'GetUserGroupsError',
          variant: 'danger',
          messageKeyBody:
            getUserGroupsError && 'data' in getUserGroupsError
              ? getUserGroupsError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [
    getMoveUserGroupPossibleIsError,
    putMoveUserGroupIsError,
    getUserGroupsIsError,
  ]);

  const handleMoveUserGroup = (): void => {
    moveUserGroup({
      id: movingUserGroup.id || '',
      targetUserGroupId: userGroup.id || '',
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'MoveUserGroupSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        dispatch(addExpandedUserGroup(userGroup.id || ''));
        dispatch(setMoveUserGroupStarted(false));
        dispatch(setMoveUserGroupDialogOpened(false));
        dispatch(clearSelectedUsers());
        setLastUpdatedItem(movingUserGroup.id ?? '');
      });
  };

  return (
    <CustomDialog
      titleId='MoveUserGroupDialog'
      actionButtonDisabled={!moveUserGroupIsPossible || isLoading}
      dialogTitle={translation('moveUserGroup')}
      show={dialogShow}
      closeFunction={() => {
        dispatch(setMoveUserGroupDialogOpened(false));
      }}
      actionFunction={handleMoveUserGroup}
      closeTitle={translation('cancel')}
      actionTitle={translation('move')}>
      {(isFetching || isLoading) && <Loader />}
      {!isFetching && !isLoading && (
        <>
          {moveUserGroupIsPossible && (
            <p>
              {translation('moveUserGroupDialog', {
                userGroup: movingUserGroup.name || '',
                targetUserGroup: userGroup.name || '',
              })}
            </p>
          )}
          {!moveUserGroupIsPossible && (
            <p>{translation('moveUserGroupImpossible')}</p>
          )}
        </>
      )}
    </CustomDialog>
  );
}

export default MoveUserGroupDialog;
