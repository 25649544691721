import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiCategoryTreeQuery,
  usePutApiCategoryTreeMoveMutation,
} from '../../../redux/store/api/api';
import {
  selectContextArticle,
  selectContextCategory,
  selectMoveElementsDialogOpened,
  selectMovingElements,
  setContextArticle,
  setContextCategory,
  setMoveElementsDialogOpened,
  setMoveElementsStarted,
} from '../../../redux/store/content/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import { EXPANDED_CATEGORY_IDS } from '../../../shared/constants';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';
import {
  useGetArticleIdsForElementIds,
  useGetCategoryIdsForElementIds,
} from '../../../hooks/useGetArticlesOrCategoriesForElementIds';

interface IMoveElementsDialogProps {
  setLastUpdatedItem: (updatedId: string) => void;
}

function MoveElementsDialog({
  setLastUpdatedItem,
}: IMoveElementsDialogProps): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const [expandedCategoryGuids, setExpandedCategoryGuids] = useSessionStorage<
    string[]
  >(EXPANDED_CATEGORY_IDS, []);
  const dialogShow = useAppSelector(selectMoveElementsDialogOpened);
  const movingElements = useAppSelector(selectMovingElements);
  const contextCategory = useAppSelector(selectContextCategory);
  const contextArticle = useAppSelector(selectContextArticle);
  const [moveElements, { isError, isLoading, error }] =
    usePutApiCategoryTreeMoveMutation();
  const { refetch } = useGetApiCategoryTreeQuery();
  const articles = useGetArticleIdsForElementIds(
    dialogShow ? movingElements.elementIds : [],
  );
  const categories = useGetCategoryIdsForElementIds(
    dialogShow ? movingElements.elementIds : [],
  );

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'MoveElementsError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  const handleMoveElements = (): void => {
    const targetCategoryId = movingElements.targetCategory.id || '';

    moveElements({
      categoryTreeBatchOperationItems: {
        articleIds: contextArticle.id ? [contextArticle.id] : articles,
        categoryIds: contextCategory.id ? [contextCategory.id] : categories,
      },
      position: movingElements.newPosition,
      targetCategoryId,
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'MoveElementsSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        dispatch(setContextCategory({}));
        dispatch(setContextArticle({}));
        dispatch(setMoveElementsStarted(false));
        dispatch(setMoveElementsDialogOpened(false));
        setExpandedCategoryGuids(
          _.union(expandedCategoryGuids, targetCategoryId),
        );
        setTimeout(() => {
          refetch();
        });
        setLastUpdatedItem(
          contextArticle.id ||
            contextCategory.id ||
            movingElements.elementIds[0],
        );
      });
  };

  return (
    <CustomDialog
      titleId='MoveElementsDialog'
      dialogTitle={translation('moveElements')}
      show={dialogShow}
      closeFunction={() => {
        dispatch(setContextCategory({}));
        dispatch(setContextArticle({}));
        dispatch(setMoveElementsDialogOpened(false));
      }}
      actionFunction={handleMoveElements}
      closeTitle={translation('cancel')}
      actionTitle={translation('move')}
      actionButtonDisabled={isLoading}>
      <div aria-busy={isLoading}>
        {isLoading && <Loader />}
        {!isLoading && (
          <>
            {typeof movingElements.newPosition === 'number' && (
              <p>{translation('moveElementsAtSelectedPosition')}</p>
            )}
            {typeof movingElements.newPosition !== 'number' && (
              <p>
                {translation('moveElementsInCategory', {
                  targetCategory: movingElements.targetCategory.name,
                })}
              </p>
            )}
          </>
        )}
      </div>
    </CustomDialog>
  );
}

export default MoveElementsDialog;
