import { useEffect, useMemo } from 'react';
import parse from 'html-react-parser';
import { useLocalStorage, useSessionStorage } from 'usehooks-ts';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectActiveVersion,
  selectNoteData,
  selectVersionContentIsLoading,
  setActiveReferenceListKey,
  setLinksNeedingAction,
  setVersionContentIsLoading,
} from '../../../redux/store/content/slice';
import makeContent, {
  iconBtnIdExtension,
  linkButtonClass,
} from '../functions/makeContent';
import { VisibilityGroupString } from '../../notes/types';
import {
  EDIT_MODE_KEY,
  MARK_VISIBILITY_GROUP_KEY,
  REFERENCE_LIST_ITEM_ID_EXTENSION,
  VERSION_CONTENT_CONTAINER_ID,
  articleIdUrlParam,
  externalReferencesAccordionKey,
  markIdUrlParam,
  searchKeywordUrlParam,
} from '../../../shared/constants';
import { preventApplicationReloadAndOpenInternalRoute } from '../../../shared/utils';
import SearchContent from './SearchContent';
import { RefetchNoteQueryType } from '../types';
import {
  ArticleReference,
  useGetApiArticlesReferencesByIdQuery,
  useGetApiNotesByVersionIdQuery,
} from '../../../redux/store/api/api';
import VersionAlerts from '../VersionAlerts';
import { contentReferenceClass } from '../functions/nodes';
import MarkContentPreprocessor from './MarkContentPreprocessor';
import {
  addButtonsToImportedLinks,
  removeButtonsFromImportedLinks,
} from '../functions/addButtonsToImportedLinks';
import { NoteVisibilityType } from '../../../shared/enums';

interface ILinkContentProps {
  refetchNotes: RefetchNoteQueryType;
}

function LinkContent({ refetchNotes }: ILinkContentProps) {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const version = useAppSelector(selectActiveVersion);
  const contentIsLoading = useAppSelector(selectVersionContentIsLoading);
  const noteData = useAppSelector(selectNoteData);
  const [activeMarkVisibilityGroup, setActiveMarkVisibilityGroup] =
    useSessionStorage<VisibilityGroupString | null>(
      MARK_VISIBILITY_GROUP_KEY,
      null,
    );
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);
  const [searchParams] = useSearchParams();
  const articleId: string | null = searchParams.get(articleIdUrlParam);
  const markIdFromBookmark: string | null = searchParams.get(markIdUrlParam);

  const { data: referenceData } = useGetApiArticlesReferencesByIdQuery(
    articleId
      ? {
          id: articleId,
        }
      : skipToken,
  );
  const articleReferencesWithCategoryId =
    referenceData?.resultObject?.articleReferenceLists?.flatMap((rL) => {
      const refs = rL.articleReferences?.map((r) => r);
      const categoryTypeId = rL.categoryType?.id || '';

      return refs?.map((r) => ({ reference: r, categoryTypeId }));
    });
  const externalReferences = referenceData?.resultObject?.externalReferences;
  const references = articleReferencesWithCategoryId?.map(
    (r) => r?.reference,
  ) as ArticleReference[];

  const contentAndNotFoundLinks = useMemo(
    () =>
      makeContent({
        content: version.htmlContent || '',
        elements: references?.filter((r) => r && r.sourceContentArea?.id) ?? [],
        tagName: 'a',
        iconClassName: editModeIsActive ? 'icon-verlinkungen' : undefined,
        description: editModeIsActive
          ? translation('showReferenceAssociatedWithLink')
          : undefined,
      }),
    [version, references, editModeIsActive],
  );

  const content = version.isRecentVersion
    ? contentAndNotFoundLinks.content
    : version.htmlContent || '';

  const { isFetching: isfetchingMarks } = useGetApiNotesByVersionIdQuery(
    version.id
      ? {
          versionId: version.id,
        }
      : skipToken,
  );

  useEffect(() => {
    dispatch(setVersionContentIsLoading(false));
  }, []);

  useEffect(() => {
    dispatch(
      setLinksNeedingAction([...contentAndNotFoundLinks.elementsNotFound]),
    );
  }, [contentAndNotFoundLinks.elementsNotFound]);

  useEffect(() => {
    const openCorrespondingAccordion = async (refId: string) => {
      const articleRef = articleReferencesWithCategoryId?.find(
        (r) => r?.reference.id === refId,
      );
      if (articleRef) {
        dispatch(setActiveReferenceListKey(articleRef.categoryTypeId));
        return;
      }

      const externalRef = externalReferences?.find((r) => r?.id === refId);
      if (externalRef) {
        dispatch(setActiveReferenceListKey(externalReferencesAccordionKey));
      }
    };

    const focusRefInBox = (e: Event) => {
      const targetRefId =
        (
          (e.target as HTMLElement).id ||
          (e.target as HTMLElement).parentElement?.id ||
          ''
        ).replace(iconBtnIdExtension, '') + REFERENCE_LIST_ITEM_ID_EXTENSION;
      const refEl = document.getElementById(targetRefId);

      if (refEl) {
        openCorrespondingAccordion(
          targetRefId.replace(REFERENCE_LIST_ITEM_ID_EXTENSION, ''),
        ).then(() => refEl.getElementsByTagName('a')[0].focus());
      }
    };

    if (editModeIsActive) {
      addButtonsToImportedLinks({
        iconClassName: 'icon-verlinkungen',
        description: translation('showReferenceAssociatedWithLink'),
      });
    }

    if (!editModeIsActive || !version.isRecentVersion) {
      removeButtonsFromImportedLinks();
    }

    const linkIconBtns = Array.from(
      document
        .getElementById(VERSION_CONTENT_CONTAINER_ID)
        ?.querySelectorAll(`.${linkButtonClass}`) || [],
    );

    if (linkIconBtns.length > 0 && editModeIsActive) {
      linkIconBtns.forEach((btn) => {
        btn.addEventListener('click', focusRefInBox);
      });
    }

    return () => {
      if (linkIconBtns.length > 0 && editModeIsActive) {
        linkIconBtns.forEach((btn) => {
          btn.removeEventListener('click', focusRefInBox);
        });
      }
    };
  }, [content, editModeIsActive, activeMarkVisibilityGroup]);

  useEffect(() => {
    if (!version.isRecentVersion) {
      return;
    }

    // Prevent full application reload when opening the link of an anchor tag
    const contentRoot = document.getElementById(VERSION_CONTENT_CONTAINER_ID);
    const anchorTags: NodeListOf<HTMLAnchorElement> | undefined =
      contentRoot?.querySelectorAll(`a[class='${contentReferenceClass}']`);

    if (!anchorTags) {
      return;
    }

    anchorTags.forEach((a) => {
      ['click', 'onkeydown'].forEach((evt) =>
        a.addEventListener(
          evt,
          (e) => {
            preventApplicationReloadAndOpenInternalRoute(e, a);
          },
          false,
        ),
      );
    });

    // eslint-disable-next-line consistent-return
    return () => {
      anchorTags.forEach((a) => {
        ['click', 'onkeydown'].forEach((evt) =>
          a.removeEventListener(
            evt,
            (e) => {
              preventApplicationReloadAndOpenInternalRoute(e, a);
            },
            false,
          ),
        );
      });
    };
  }, [content]);

  useEffect(() => {
    if (markIdFromBookmark) {
      const noteOfMarkIdInUrl = [
        ...(noteData.userNotes || []),
        ...(noteData.userGroupNotes || []),
        ...(noteData.generalNotes || []),
      ].find((n) => n.mark?.id === markIdFromBookmark);

      if (noteOfMarkIdInUrl) {
        setActiveMarkVisibilityGroup(
          NoteVisibilityType[
            noteOfMarkIdInUrl.visibility || 0
          ] as VisibilityGroupString,
        );
      }
    }
  }, [markIdFromBookmark, noteData]);

  return (
    <>
      {!contentIsLoading && <VersionAlerts />}

      <div
        id={VERSION_CONTENT_CONTAINER_ID}
        className={`mt-2 pt-1 version-content${
          contentIsLoading ? ' d-none' : ''
        }`}>
        {searchParams.get(searchKeywordUrlParam) !== null && (
          <SearchContent content={content} />
        )}
        <MarkContentPreprocessor
          content={content}
          refetchNotes={refetchNotes}
        />
        {(activeMarkVisibilityGroup === null || isfetchingMarks) &&
          searchParams.get(searchKeywordUrlParam) === null &&
          parse(content)}
      </div>
    </>
  );
}

export default LinkContent;
