import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsFirstRender } from 'usehooks-ts';
import { Setting, SettingUpdate } from '../../redux/store/api/api';
import SettingInput from '../inputs/SettingInput';
import { DataType } from '../../shared/enums';
import {
  fileExtensions,
  lineBreakRegex,
  settingTypeAllowedFileTypes,
} from '../../shared/constants';
import { useFilesByIdMutation } from '../../redux/store/api/fileApi';
import { useAppDispatch } from '../../redux/hooks';
import { addMessage } from '../../redux/store/layout/slice';

interface IGlobalSettingsInputProps {
  setting: Setting;
  userCanEditSetting: boolean;
  newSettingValues: SettingUpdate[];
  setNewSettingValues: (values: SettingUpdate[]) => void;
}

function GlobalSettingsInput({
  setting,
  userCanEditSetting,
  newSettingValues,
  setNewSettingValues,
}: IGlobalSettingsInputProps) {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const settingId = setting.id || '';
  const [value, setValue] = useState(setting.value || '');
  const [listValues, setListValues] = useState<string[]>([]);
  const [file, setFile] = useState<File | string>();
  const isFirstRender = useIsFirstRender();

  const [getFileUrl] = useFilesByIdMutation();

  useEffect(() => {
    if (setting.settingType?.dataType === DataType.Image && setting.value) {
      getFileUrl({ id: setting.value })
        .unwrap()
        .then((url) => {
          setFile(url);
        })
        .catch(() => {
          dispatch(
            addMessage({
              id: 'GetFileUrlError',
              messageKeyBody: 'unknownError',
              variant: 'danger',
            }),
          );
        });

      return;
    }

    if (setting.settingType?.dataType === DataType.List) {
      setListValues(setting.value?.split(',') || []);
      return;
    }

    setValue(setting.value || '');
  }, [setting]);

  useEffect(() => {
    if (isFirstRender) {
      return;
    }

    if (setting.settingType?.dataType === DataType.Image) {
      if (typeof file === 'string' || !file) {
        return;
      }

      setNewSettingValues([
        ...newSettingValues.filter((s) => s.id !== settingId),
        { id: settingId, file },
      ]);

      return;
    }

    if (setting.settingType?.dataType === DataType.List) {
      const joinedListValues = listValues.join(',');

      if (setting.value === joinedListValues) {
        setNewSettingValues([
          ...newSettingValues.filter((s) => s.id !== settingId),
        ]);

        return;
      }

      setNewSettingValues([
        ...newSettingValues.filter((s) => s.id !== settingId),
        { id: settingId, value: joinedListValues },
      ]);

      return;
    }

    if (
      setting.value?.replace(lineBreakRegex, '') ===
      value.replace(lineBreakRegex, '')
    ) {
      setNewSettingValues([
        ...newSettingValues.filter((s) => s.id !== settingId),
      ]);

      return;
    }

    setNewSettingValues([
      ...newSettingValues.filter((s) => s.id !== settingId),
      { id: settingId, value },
    ]);
  }, [value, listValues, file]);

  return (
    <div className='mb-3'>
      <SettingInput
        controlId={settingId}
        label={translation(setting.settingType?.key || '')}
        setValue={(val) => {
          setValue(val);
        }}
        value={value}
        setListValues={
          setting.settingType?.dataType === DataType.List
            ? setListValues
            : undefined
        }
        listValues={
          setting.settingType?.dataType === DataType.List
            ? listValues
            : undefined
        }
        setFile={
          setting.settingType?.dataType === DataType.Image ? setFile : undefined
        }
        file={
          setting.settingType?.dataType === DataType.Image ? file : undefined
        }
        possibleValues={
          setting.settingType?.key === settingTypeAllowedFileTypes
            ? fileExtensions
            : undefined
        }
        dataType={setting.settingType?.dataType || DataType.String}
        userCanEditSetting={userCanEditSetting}
      />
    </div>
  );
}

export default GlobalSettingsInput;
