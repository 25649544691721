import { useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface IExternalReferenceFormProps {
  displayName: string;
  setDisplayName: (name: string) => void;
  isValidName: boolean;
  setIsValidName: (isValid: boolean) => void;
  targetUrl: string;
  setTargetUrl: (url: string) => void;
  isValidUrl: boolean;
  setIsValidUrl: (isValid: boolean) => void;
}

function ExternalReferenceForm({
  displayName,
  setDisplayName,
  isValidName,
  setIsValidName,
  targetUrl,
  setTargetUrl,
  isValidUrl,
  setIsValidUrl,
}: IExternalReferenceFormProps): JSX.Element {
  const { t: translation } = useTranslation();
  const urlInputRef = useRef<HTMLInputElement>(null);

  return (
    <div className='mt-3'>
      <p className='fw-bold'>{translation('pleaseEnterTarget')}</p>
      <Form.Group className='mt-2' controlId='ExternalReferenceDisplayName'>
        <Form.Label>
          {translation('displayNameForExternalReference')}*
        </Form.Label>
        <Form.Control
          aria-describedby={
            isValidName ? undefined : 'StructureElementNameInputError'
          }
          isInvalid={!isValidName}
          type='text'
          value={displayName}
          onChange={(e) => {
            setDisplayName(e.target.value);
            if (e.target.value.trim().length > 0) {
              setIsValidName(true);
            }
          }}
        />
        <Form.Control.Feedback
          id='StructureElementNameInputError'
          type='invalid'>
          {translation('fieldNotEmpty')}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className='mt-3' controlId='TargetUrl'>
        <Form.Label>{translation('targetUrl')}*</Form.Label>
        <Form.Control
          ref={urlInputRef}
          aria-describedby={!isValidUrl ? 'UrlInputError' : undefined}
          pattern="^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$"
          type='text'
          isInvalid={!isValidUrl}
          value={targetUrl}
          onBlur={() => {
            const isValidValue: boolean =
              urlInputRef.current?.validity.valid || false;

            if (!isValidValue) {
              setIsValidUrl(false);
              return;
            }
            setIsValidUrl(true);
          }}
          onChange={(e) => {
            setTargetUrl(e.target.value);
            if (e.target.value.trim().length > 0) {
              setIsValidUrl(true);
            }
          }}
        />
        <Form.Control.Feedback id='UrlInputError' type='invalid'>
          {translation(
            targetUrl.trim().length === 0
              ? 'fieldNotEmpty'
              : 'urlInvalidMessage',
          )}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
}

export default ExternalReferenceForm;
