import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {
  useDeleteApiBookmarksByIdMutation,
  useGetApiBookmarkFoldersQuery,
} from '../../../redux/store/api/api';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { addMessage } from '../../../redux/store/layout/slice';
import {
  selectContextBookmark,
  selectDeleteBookmarkDialogOpened,
  setDeleteBookmarkDialogOpened,
} from '../../../redux/store/content/slice';

function DeleteBookmarkDialog(): JSX.Element {
  const dispatch = useAppDispatch();
  const { t: translation } = useTranslation();
  const dialogShow = useAppSelector(selectDeleteBookmarkDialogOpened);
  const contextBookmark = useAppSelector(selectContextBookmark);
  const [deleteBookmark, { isLoading, isError, error }] =
    useDeleteApiBookmarksByIdMutation();
  const { refetch } = useGetApiBookmarkFoldersQuery();

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'DeleteBookmarkError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  const handleDeleteBookmark = () => {
    deleteBookmark({ id: contextBookmark.id || '' })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'DeleteBookmarkSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        dispatch(setDeleteBookmarkDialogOpened(false));
        refetch();
      });
  };

  return (
    <CustomDialog
      titleId='DeleteBookmarkDialog'
      dialogTitle={translation('deleteBookmark')}
      show={dialogShow}
      closeFunction={() => {
        dispatch(setDeleteBookmarkDialogOpened(false));
      }}
      actionFunction={handleDeleteBookmark}
      closeTitle={translation('cancel')}
      actionTitle={translation('delete')}
      actionButtonVariant='outline-danger'
      actionButtonDisabled={isLoading}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <p>{translation('deleteBookmarkPermanently')}</p>
          <p className='fw-bold'>{contextBookmark.name}</p>
        </>
      )}
    </CustomDialog>
  );
}

export default DeleteBookmarkDialog;
