import {
  ExtendedNote,
  ExtendedSortedNotes,
} from '../../../components/notes/types';
import {
  Category,
  Article,
  ArticleReference,
  NamedVersion,
  Attachment,
  Bookmark,
  BookmarkFolder,
} from '../api/api';

export type ContentState = {
  contextCategory: Category;
  contextArticle: Article;
  contextAttachment: Attachment;
  contextVersion: NamedVersion;
  contextBookmark: Bookmark;
  contextBookmarkFolder: BookmarkFolder;
  currentVersions: NamedVersion[];
  activeArticleReference: ArticleReference;
  activeVersion: NamedVersion;
  activeNote: ExtendedNote;
  addCategoryDialogOpened: boolean;
  editCategoryDialogOpened: boolean;
  addAttachmentDialogOpened: boolean;
  renameAttachmentDialogOpened: boolean;
  deleteAttachmentDialogOpened: boolean;
  addStructureElementDialogOpened: boolean;
  renameStructureElementDialogOpened: boolean;
  elementsToRecycleBinDialogOpened: boolean;
  renameVersionDialogOpened: boolean;
  deleteVersionDialogOpened: boolean;
  renameDraftDialogOpened: boolean;
  deleteDraftDialogOpened: boolean;
  contentImportDialogOpened: boolean;
  expandedNoteVisibilityGroup: string;
  expandedRecycleBinCategories: string[];
  showMakeLinkSelectionInfo: boolean;
  showMakeMarkSelectionInfo: boolean;
  showNotePopoverOpened: boolean;
  linksNeedingAction: string[];
  movingElements: MovingElements;
  moveElementsDialogOpened: boolean;
  moveElementStarted: boolean;
  disableElementsDialogOpened: boolean;
  activateElementsDialogOpened: boolean;
  noteData: ExtendedSortedNotes;
  isReferenceImport: boolean;
  changeValidityDateDialogOpened: boolean;
  changePublishDatesDialogOpened: boolean;
  searchKeyword: string;
  selectedCategoryIdsForSearch: string[];
  controlledselectedCategoryIdsForSearch?: string[];
  searchOption: SearchOption;
  searchIncludeVersions: boolean;
  treeContentInvalid: boolean;
  comparerIsActive: boolean;
  addBookmarkDialogOpened: boolean;
  addMarkBookmarkDialogOpened: boolean;
  expandedBookmarkFolderIds: string[];
  maxNumberOfVersionsDialogOpened: boolean;
  deleteBookmarkDialogOpened: boolean;
  addBookmarkFolderDialogOpened: boolean;
  deleteBookmarkFolderDialogOpened: boolean;
  renameBookmarkFolderDialogOpened: boolean;
  controlledSelectedIds?: string[];
  versionContentIsLoading: boolean;
  markContentIsProcessing: boolean;
  activeReferenceListKey: string;
};

export type MovingElements = {
  elementIds: string[];
  newPosition: number | undefined;
  targetCategory: Category;
};

export enum SearchOption {
  AllWords,
  ExactExpression,
}
