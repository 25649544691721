import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiUserGroupsQuery,
  usePostApiUsersMoveCheckMoveMutation,
  usePutApiUsersMoveMutation,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import {
  selectSelectedUsers,
  selectContextUserGroup,
  selectMoveUserDialogOpened,
  setMoveUserDialogOpened,
  setMoveUserStarted,
  clearSelectedUsers,
  addExpandedUserGroup,
} from '../../../redux/store/user-management/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';

interface IMoveUserDialogProps {
  setLastUpdatedItem: (updatedId: string) => void;
}

function MoveUserDialog({ setLastUpdatedItem }: IMoveUserDialogProps) {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const dialogShow = useAppSelector(selectMoveUserDialogOpened);
  const currentSelectedUsers = useAppSelector(selectSelectedUsers);
  const userGroup = useAppSelector(selectContextUserGroup);
  const [moveIsPossible, setMoveIsPossible] = useState(false);
  const [
    moveUsersIsPossible,
    {
      isLoading: moveUserIsPossibleIsLoading,
      isError: moveUsersPossibleIsError,
      error: moveUsersPossibleError,
    },
  ] = usePostApiUsersMoveCheckMoveMutation();
  const [
    moveUser,
    {
      isError: putMoveUserIsError,
      error: putMoveUserError,
      isLoading: moveUserIsLoading,
    },
  ] = usePutApiUsersMoveMutation();
  const isLoading = moveUserIsLoading || moveUserIsPossibleIsLoading;
  const {
    refetch,
    isError: getUserGroupsIsError,
    error: getUserGroupsError,
  } = useGetApiUserGroupsQuery({
    includeUsers: true,
    includePermittedActions: true,
  });

  useEffect(() => {
    if (userGroup.id && currentSelectedUsers && dialogShow) {
      moveUsersIsPossible({
        targetUserGroupId: userGroup.id || '',
        body: currentSelectedUsers.map((u) => u.id || ''),
      })
        .unwrap()
        .then((response) => {
          setMoveIsPossible(response?.resultObject || false);
        });
    }
  }, [dialogShow]);

  useEffect(() => {
    if (moveUsersPossibleIsError) {
      dispatch(
        addMessage({
          id: 'MoveUsersPossibleError',
          variant: 'danger',
          messageKeyBody:
            moveUsersPossibleError && 'data' in moveUsersPossibleError
              ? moveUsersPossibleError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (putMoveUserIsError) {
      dispatch(
        addMessage({
          id: 'MoveUSerError',
          variant: 'danger',
          messageKeyBody:
            putMoveUserError && 'data' in putMoveUserError
              ? putMoveUserError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (getUserGroupsIsError) {
      dispatch(
        addMessage({
          id: 'GetUserGroupsError',
          variant: 'danger',
          messageKeyBody:
            getUserGroupsError && 'data' in getUserGroupsError
              ? getUserGroupsError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [moveUsersPossibleIsError, putMoveUserIsError, getUserGroupsIsError]);

  const handleMoveUser = (): void => {
    moveUser({
      body: currentSelectedUsers.map((u) => u.id || ''),
      targetUserGroupId: userGroup.id || '',
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'MoveUserSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        dispatch(addExpandedUserGroup(userGroup.id || ''));
        dispatch(setMoveUserStarted(false));
        dispatch(setMoveUserDialogOpened(false));
        dispatch(clearSelectedUsers());
        setLastUpdatedItem(currentSelectedUsers[0].id ?? '');
      });
  };

  return (
    <CustomDialog
      titleId='MoveUserDialog'
      actionButtonDisabled={!moveIsPossible || isLoading}
      dialogTitle={translation('moveUser')}
      show={dialogShow}
      closeFunction={() => {
        dispatch(setMoveUserDialogOpened(false));
      }}
      actionFunction={handleMoveUser}
      closeTitle={translation('cancel')}
      actionTitle={translation('move')}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          {moveIsPossible && (
            <p>
              {translation('moveUserInUserGroup', {
                targetUserGroup: userGroup.name || '',
              })}
            </p>
          )}
          {!moveIsPossible && <p>{translation('moveUserImpossible')}</p>}
        </>
      )}
    </CustomDialog>
  );
}

export default MoveUserDialog;
