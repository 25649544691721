import { Alert, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectMoveElementsStarted,
  selectMovingElements,
  setMoveElementsStarted,
} from '../../redux/store/content/slice';

function MoveContentItemAlert() {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const movingElements = useAppSelector(selectMovingElements);
  const moveElementsStarted = useAppSelector(selectMoveElementsStarted);

  return (
    <Alert variant='warning'>
      <div className='d-flex flex-row justify-content-between'>
        {moveElementsStarted && (
          <span className='align-self-center'>
            {translation(
              movingElements.elementIds.length === 1
                ? 'movingElementIsStarted'
                : 'movingElementsIsStarted',
            )}
          </span>
        )}
        <div className='p-0'>
          <Button
            onClick={() => {
              dispatch(setMoveElementsStarted(false));
            }}
            variant='outline-dark'>
            {translation('cancelMove')}
          </Button>
        </div>
      </div>
    </Alert>
  );
}

export default MoveContentItemAlert;
