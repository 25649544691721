import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import CreateArticleVersionForm from '../components/article/CreateArticleVersionForm';
import ViewFallback from '../components/error-fallback/ViewFallback';

function CreateArticle(): JSX.Element {
  useEffect(() => {
    document.getElementsByTagName('h1')[0].focus();
  }, []);

  return (
    <div className='content-inner'>
      <ErrorBoundary fallback={<ViewFallback />}>
        <CreateArticleVersionForm />
      </ErrorBoundary>
    </div>
  );
}

export default CreateArticle;
