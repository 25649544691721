import { useTranslation } from 'react-i18next';
import CustomDialog from '../../dialogs/CustomDialog';

interface IValidityDateIsNotChangedDialogProps {
  dialogShow: boolean;
  setDialogShow: (show: boolean) => void;
}

function ValidityDateIsNotChangedDialog({
  dialogShow,
  setDialogShow,
}: IValidityDateIsNotChangedDialogProps) {
  const { t: translation } = useTranslation();

  return (
    <CustomDialog
      titleId='ValidityDateNotChangedDialog'
      show={dialogShow}
      closeFunction={() => {
        setDialogShow(false);
      }}
      closeTitle={translation('close')}
      dialogTitle={translation('validityDateIsNotChanged')}>
      <p>{translation('validityDateShouldBeChanged')}</p>
    </CustomDialog>
  );
}

export default ValidityDateIsNotChangedDialog;
