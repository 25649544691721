import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../redux/hooks';
import { selectCurrentVersions } from '../../redux/store/content/slice';
import { getVersionName } from '../../shared/utils';

interface IVersionSelectProps {
  defaultSaveAsValue: string;
  saveAsValue: string;
  setSaveAsValue: (value: string) => void;
}

function VersionSelect({
  defaultSaveAsValue,
  saveAsValue,
  setSaveAsValue,
}: IVersionSelectProps): JSX.Element {
  const { t: translation } = useTranslation();
  const versions = useAppSelector(selectCurrentVersions).filter(
    (v) => !v.draft,
  );

  return (
    <Row>
      <Form.Group
        as={Col}
        xl='6'
        className='mb-3'
        controlId='VersionSaveSelect'>
        <Form.Label>{translation('saveVersionAs')}*</Form.Label>
        <Form.Select
          required
          value={saveAsValue}
          onChange={(e) => {
            setSaveAsValue(e.target.value);
          }}>
          <option value={defaultSaveAsValue}>
            {translation('newVersion')}
          </option>
          {versions.map((version) => (
            <option key={version.id || ''} value={version.id || ''}>
              {getVersionName(
                version.name || '',
                translation('versionFrom'),
                version.validFrom || '',
              )}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </Row>
  );
}

export default VersionSelect;
