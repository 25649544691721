import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiUserGroupsQuery,
  UserGroup,
} from '../../../redux/store/api/api';
import {
  selectMoveUserGroupStarted,
  selectMoveUserStarted,
  selectSelectedUsers,
  selectMovingUserGroup,
  setAddUserDialogOpened,
  setAddUserGroupDialogOpened,
  setContentPermissionsDialogOpened,
  setContextUserGroup,
  setDeleteUserGroupDialogOpened,
  setEditUserGroupDialogOpened,
  setMoveUserGroupStarted,
  setMoveUserDialogOpened,
  setMoveUserGroupDialogOpened,
  setMoveUserStarted,
  setMovingUserGroup,
  setRoleAssignmentDialogOpened,
  setUserGroupSettingsDialogOpened,
  setUserGroupPermissionsDialogOpened,
  setUserImportDialogOpened,
  setSelectedUsers,
} from '../../../redux/store/user-management/slice';
import { RightKey } from '../../../shared/enums';
import ContextMenu from '../../dropdown-menus/ContextMenu';
import { ContextAction } from '../../dropdown-menus/types';
import DescriptiveIcon from '../../descriptive-icons/DescriptiveIcon';
import { IUserGroupTreeItem } from '../types';

interface IUserGroupTreeElementProps {
  userGroupTreeElement: IUserGroupTreeItem;
  isBranch: boolean;
  isExpanded: boolean;
  permittedActions: RightKey[];
}

function UserGroupTreeElement({
  userGroupTreeElement,
  isBranch,
  isExpanded,
  permittedActions,
}: IUserGroupTreeElementProps): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const { data: userTreeData } = useGetApiUserGroupsQuery({
    includeUsers: true,
    includePermittedActions: true,
  });
  const greenColorClass = 'text-success';
  const redColorClass = 'text-danger';
  const blackColorClass = 'text-body';
  const moveUserStarted = useAppSelector(selectMoveUserStarted);
  const currentSelectedUsers = useAppSelector(selectSelectedUsers);
  const moveUserGroupStarted = useAppSelector(selectMoveUserGroupStarted);
  const movingUserGroup = useAppSelector(selectMovingUserGroup);

  const userGroup: UserGroup = {
    id: userGroupTreeElement.id,
    name: userGroupTreeElement.name,
    userGroupMemberMaximum: userGroupTreeElement.userGroupMemberMaximum,
    userGroupTreeMemberMaximum: userGroupTreeElement.userGroupTreeMemberMaximum,
    parentId: userGroupTreeElement.parent,
    permittedActions: userGroupTreeElement.permittedActions,
    systemRolesAssigned: userGroupTreeElement.systemRoleAssigned,
    userGroupPermissionsAssigned:
      userGroupTreeElement.userGroupPermissionsAssigned,
    categoryPermissionsAssigned:
      userGroupTreeElement.categoryPermissionsAssigned,
    userGroupIds: userGroupTreeElement.userGroupIds,
    userIds: userGroupTreeElement.userIds,
  };

  // permissions
  const userCanCreateUserGroups = permittedActions.includes(
    RightKey.RightGroupManagementCreateUserGroup,
  );
  const userCanEditUserGroup = permittedActions.includes(
    RightKey.RightGroupManagementEditUserGroup,
  );
  const userCanEditUsers = permittedActions.includes(
    RightKey.RightUserManagementEditUser,
  );
  const userCanDeleteUserGroup = permittedActions.includes(
    RightKey.RightGroupManagementDeleteUserGroup,
  );
  const userCanImportUsers = permittedActions.includes(
    RightKey.RightUserManagementImportUsers,
  );
  const userCanCreateUsers = permittedActions.includes(
    RightKey.RightUserManagementCreateUser,
  );
  const userCanReadPermissions = permittedActions.includes(
    RightKey.RightPermissionManagementRead,
  );
  const userCanOpenUserGroupSettings = permittedActions.includes(
    RightKey.RightSettingsManagementReadSettingsUserGroup,
  );

  const getMoveUserContextActions = (): ContextAction[] => {
    const contextActions: ContextAction[] = [];

    if (
      currentSelectedUsers?.length > 0 &&
      userGroup.id !== currentSelectedUsers[0].userGroupId
    ) {
      contextActions.push({
        iconClass: 'icon-move',
        iconColorClass: blackColorClass,
        name: translation('moveUserToThisGroup'),
        onClick: () => {
          dispatch(setContextUserGroup(userGroup));
          dispatch(setMoveUserDialogOpened(true));
        },
      });
    }
    contextActions.push({
      iconClass: 'icon-deaktivieren',
      iconColorClass: redColorClass,
      name: translation('stopMoveUser'),
      onClick: () => {
        dispatch(setMoveUserStarted(false));
      },
    });

    return contextActions;
  };

  const getMoveUserGroupContextActions = (): ContextAction[] => {
    const contextActions: ContextAction[] = [];
    if (
      userGroup.id !== movingUserGroup.id &&
      userGroup.id !== movingUserGroup.parentId
    ) {
      contextActions.push({
        iconClass: 'icon-move',
        iconColorClass: blackColorClass,
        name: translation('moveUserGroupToThisGroup'),
        onClick: () => {
          dispatch(setContextUserGroup(userGroup));
          dispatch(setMoveUserGroupDialogOpened(true));
        },
      });
    }

    contextActions.push({
      iconClass: 'icon-deaktivieren',
      iconColorClass: redColorClass,
      name: translation('stopMoveUserGroup'),
      onClick: () => {
        dispatch(setMoveUserGroupStarted(false));
      },
    });

    return contextActions;
  };

  const getContextActions = (): ContextAction[] => {
    const contextActions: ContextAction[] = [];
    const iconClassRole = 'icon-right_new';

    if (moveUserStarted && userCanEditUsers) {
      contextActions.push(...getMoveUserContextActions());

      return contextActions;
    }

    if (moveUserGroupStarted && userCanEditUserGroup) {
      contextActions.push(...getMoveUserGroupContextActions());

      return contextActions;
    }

    if (userCanEditUsers) {
      contextActions.push({
        helpId: 'help_6_9',
        iconClass: 'icon-stapel',
        iconColorClass: blackColorClass,
        name: translation('selectAllUsersInGroup'),
        onClick: () => {
          dispatch(
            setSelectedUsers(
              userTreeData?.resultObject?.users?.filter(
                (u) => userGroup.userIds?.includes(u.id || ''),
              ) || [],
            ),
          );
        },
        addDividerAfterItem: true,
      });
    }

    if (userCanCreateUserGroups) {
      contextActions.push({
        iconClass: 'icon-usergroup_new',
        iconColorClass: greenColorClass,
        name: translation('addUserGroup'),
        onClick: () => {
          dispatch(setContextUserGroup(userGroup));
          dispatch(setAddUserGroupDialogOpened(true));
        },
      });
    }

    if (userCanEditUserGroup) {
      contextActions.push({
        iconClass: 'icon-edit',
        iconColorClass: blackColorClass,
        name: translation('editUserGroup'),
        onClick: () => {
          dispatch(setContextUserGroup(userGroup));
          dispatch(setEditUserGroupDialogOpened(true));
        },
      });
    }

    if (userCanDeleteUserGroup) {
      contextActions.push({
        iconClass: 'icon-trash',
        iconColorClass: redColorClass,
        name: translation('deleteUserGroup'),
        onClick: () => {
          dispatch(setContextUserGroup(userGroup));
          dispatch(setDeleteUserGroupDialogOpened(true));
        },
        addDividerAfterItem: true,
      });
    }

    if (userCanCreateUsers) {
      contextActions.push({
        helpId: 'help_6_3',
        iconClass: 'icon-user_new',
        iconColorClass: greenColorClass,
        name: translation('addUser'),
        onClick: () => {
          dispatch(setContextUserGroup(userGroup));
          dispatch(setAddUserDialogOpened(true));
        },
      });
    }

    if (userCanImportUsers) {
      contextActions.push({
        iconClass: 'icon-user_import',
        iconColorClass: greenColorClass,
        name: translation('importUser'),
        onClick: () => {
          dispatch(setContextUserGroup(userGroup));
          dispatch(setUserImportDialogOpened(true));
        },
        addDividerAfterItem: true,
      });
    }

    if (userCanReadPermissions) {
      contextActions.push({
        helpId: 'help_6_8',
        iconClass: iconClassRole,
        iconColorClass: 'text-secondary',
        name: translation('contentPermissions'),
        onClick: () => {
          dispatch(setContextUserGroup(userGroup));
          dispatch(setContentPermissionsDialogOpened(true));
        },
      });

      contextActions.push({
        helpId: 'help_6_8',
        iconClass: iconClassRole,
        iconColorClass: 'text-primary',
        name: translation('userGroupPermissions'),
        onClick: () => {
          dispatch(setContextUserGroup(userGroup));
          dispatch(setUserGroupPermissionsDialogOpened(true));
        },
      });

      contextActions.push({
        helpId: 'help_6_8',
        iconClass: iconClassRole,
        iconColorClass: 'text-info',
        name: translation('systemRoleAssignment'),
        onClick: () => {
          dispatch(setContextUserGroup(userGroup));
          dispatch(setRoleAssignmentDialogOpened(true));
        },
        addDividerAfterItem: userCanOpenUserGroupSettings,
      });
    }

    if (userCanOpenUserGroupSettings) {
      contextActions.push({
        iconClass: 'icon-settings',
        iconColorClass: blackColorClass,
        name: translation('userGroupSettings'),
        onClick: () => {
          dispatch(setContextUserGroup(userGroup));
          dispatch(setUserGroupSettingsDialogOpened(true));
        },
        addDividerAfterItem: !!userGroup.parentId && userCanEditUserGroup,
      });
    }

    if (userGroup.parentId && userCanEditUserGroup) {
      contextActions.push({
        iconClass: 'icon-move',
        iconColorClass: blackColorClass,
        name: translation('startMoveUserGroup'),
        onClick: () => {
          setContextUserGroup(userGroup);
          dispatch(setMovingUserGroup(userGroup));
          dispatch(setMoveUserGroupStarted(true));
        },
      });
    }

    return contextActions;
  };

  return (
    <div className='user-group-data'>
      <div className='user-group-inner me-2'>
        {isBranch && isExpanded && (
          <i className='icon-carretup icon-tree-collapse' aria-hidden />
        )}
        {isBranch && !isExpanded && (
          <i className='icon-carretdown icon-tree-collapse' aria-hidden />
        )}
        <span className='align-middle'>{userGroupTreeElement.name}</span>
      </div>
      <div className='user-group-inner'>
        <span className='user-group-info ps-2 pe-2'>
          <DescriptiveIcon
            iconClass='icon-users text-success align-text-bottom'
            description={translation('countUserInGroup')}
          />
          <span className='align-middle'>
            {userGroupTreeElement.userGroupMemberCount}
            {userGroupTreeElement.userGroupMemberMaximum === -1
              ? ''
              : ` / ${userGroupTreeElement.userGroupMemberMaximum}`}
          </span>
        </span>
        <span className='user-group-info ps-2 pe-2'>
          <DescriptiveIcon
            iconClass='icon-sitemap align-text-bottom'
            description={translation('countUserInChildGroups')}
          />
          <span className='align-middle'>
            {userGroupTreeElement.userGroupTreeMemberCount}
            {userGroupTreeElement.userGroupTreeMemberMaximum === -1
              ? ''
              : ` / ${userGroupTreeElement.userGroupTreeMemberMaximum}`}
          </span>
        </span>
        {userCanReadPermissions && userGroup.categoryPermissionsAssigned && (
          <span className='user-group-info ps-2 pe-2'>
            <DescriptiveIcon
              iconClass='icon-right_category text-secondary align-text-bottom'
              description={translation('categoryPermissionsAssigned')}
            />
          </span>
        )}
        {userCanReadPermissions && userGroup.userGroupPermissionsAssigned && (
          <span className='user-group-info ps-2 pe-2'>
            <DescriptiveIcon
              iconClass='icon-right_user text-primary align-text-bottom'
              description={translation('userGroupPermissionsAssigned')}
            />
          </span>
        )}
        {userCanReadPermissions && userGroup.systemRolesAssigned && (
          <span className='user-group-info ps-2 pe-2'>
            <DescriptiveIcon
              iconClass='icon-right_system align-text-bottom'
              description={translation('systemRolesAssigned')}
            />
          </span>
        )}
      </div>
      <ContextMenu contextActions={getContextActions()} />
    </div>
  );
}

export default UserGroupTreeElement;
