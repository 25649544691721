/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { useEffect, useRef, useState } from 'react';
import { Overlay, Popover, PopoverBody, PopoverHeader } from 'react-bootstrap';
import {
  useEventListener,
  useLocalStorage,
  useSessionStorage,
} from 'usehooks-ts';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Note, useGetApiBookmarkFoldersQuery } from '../../redux/store/api/api';
import {
  selectActiveNote,
  selectActiveVersion,
  selectShowNotePopoverOpened,
  selectVersionContentIsLoading,
  setActiveNote,
  setAddMarkBookmarkDialogOpened,
  setContextBookmark,
  setDeleteBookmarkDialogOpened,
  setShowNotePopoverOpened,
} from '../../redux/store/content/slice';
import {
  EDIT_MODE_KEY,
  MARK_VISIBILITY_GROUP_KEY,
  VERSION_CONTENT_CONTAINER_ID,
} from '../../shared/constants';
import { NoteVisibilityType, RightKey } from '../../shared/enums';
import { VisibilityGroupString } from './types';
import IconButton from '../buttons/IconButton';
import useGetCategoryByArticleId from '../../hooks/useGetCategoryByArticleId';

const findTargetEl = (note: Note): HTMLElement | undefined => {
  const activeMarkId = note.mark?.id;
  if (!activeMarkId) {
    return undefined;
  }

  const activeMarkElements = document.querySelectorAll(
    `[id^='${activeMarkId}']`,
  );
  const lastNodeOfMark = activeMarkElements[activeMarkElements.length - 1];
  if (!lastNodeOfMark) {
    return undefined;
  }

  return lastNodeOfMark as HTMLElement;
};

interface IShowNotePopoverProps {
  openEditNoteDialog: () => void;
  openDeleteNoteDialog: () => void;
}

function ShowNotePopover({
  openEditNoteDialog,
  openDeleteNoteDialog,
}: IShowNotePopoverProps): JSX.Element | null {
  const { t: translations } = useTranslation();
  const headerRef = useRef<HTMLDivElement>(null);
  const headlineRef = useRef<HTMLHeadingElement>(null);
  const dispatch = useAppDispatch();
  const note = useAppSelector(selectActiveNote);
  const version = useAppSelector(selectActiveVersion);
  const versionContentContainer = document.getElementById(
    VERSION_CONTENT_CONTAINER_ID,
  );
  const versionContentIsLoading = useAppSelector(selectVersionContentIsLoading);
  const [activeMarkVisibilityGroup, setActiveMarkVisibilityGroup] =
    useSessionStorage<VisibilityGroupString | null>(
      MARK_VISIBILITY_GROUP_KEY,
      null,
    );
  const show = useAppSelector(selectShowNotePopoverOpened);
  const [targetEl, setTargetEl] = useState(findTargetEl(note));

  const { data: bookmarkTreeData } = useGetApiBookmarkFoldersQuery();
  const relatedBookmark = bookmarkTreeData?.resultObject?.bookmarks?.find(
    (b) => note.mark?.id === b.markId,
  );

  const category = useGetCategoryByArticleId(version.articleId);
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);
  // permissions
  const userHasAccessToUserNotes =
    category?.permittedActions?.includes(
      RightKey.RightNotesManagementCreateEditDeleteNote,
    ) || false;
  const userCanEditUserGroupNotes =
    (category?.permittedActions?.includes(
      RightKey.RightNotesManagementEditGroupNote,
    ) ||
      false) &&
    editModeIsActive;
  const userCanDeleteUserGroupNotes =
    (category?.permittedActions?.includes(
      RightKey.RightNotesManagementDeleteGroupNote,
    ) ||
      false) &&
    editModeIsActive;
  const userCanEditGlobalNotes =
    (category?.permittedActions?.includes(
      RightKey.RightNotesManagementEditOrganizationNote,
    ) ||
      false) &&
    editModeIsActive;
  const userCanDeleteGlobalNotes =
    (category?.permittedActions?.includes(
      RightKey.RightNotesManagementDeleteOrganizationNote,
    ) ||
      false) &&
    editModeIsActive;
  const userCanDeleteNote =
    (note.visibility === NoteVisibilityType.User && userHasAccessToUserNotes) ||
    (note.visibility === NoteVisibilityType.UserGroup &&
      userCanDeleteUserGroupNotes) ||
    (note.visibility === NoteVisibilityType.General &&
      userCanDeleteGlobalNotes);
  const userCanEditNote =
    (note.visibility === NoteVisibilityType.User && userHasAccessToUserNotes) ||
    (note.visibility === NoteVisibilityType.UserGroup &&
      userCanEditUserGroupNotes) ||
    (note.visibility === NoteVisibilityType.General && userCanEditGlobalNotes);

  const handleHide = (event: Event) => {
    const target = event.target as HTMLElement;
    if (
      !target ||
      (target.tagName !== 'MARK' && target.children[0]?.tagName !== 'MARK')
    ) {
      dispatch(setShowNotePopoverOpened(false));
      dispatch(setActiveNote({}));
    }
  };

  useEffect(() => {
    if (versionContentIsLoading) {
      return;
    }

    setTimeout(() => {
      setTargetEl(findTargetEl(note));
    });
  }, [note, versionContentIsLoading]);

  useEffect(() => {
    if (!show || !note.mark) {
      return;
    }

    if (
      activeMarkVisibilityGroup !== NoteVisibilityType[note.visibility || 0]
    ) {
      setActiveMarkVisibilityGroup(
        NoteVisibilityType[note.visibility || 0] as VisibilityGroupString,
      );
    }
  }, [show, note]);

  useEffect(() => {
    if (targetEl && targetEl.parentElement) {
      if (show) {
        targetEl.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        headlineRef.current?.focus();

        targetEl.parentElement.ariaExpanded = 'true';
      } else {
        targetEl.parentElement.ariaExpanded = 'false';
        targetEl.parentElement?.focus();
      }
    }
  }, [targetEl, show]);

  useEventListener(
    'keydown',
    (e) => {
      setTimeout(() => {
        const buttons = headerRef.current?.getElementsByTagName('button');
        const buttonCount = buttons?.length || 0;

        if (e.key === 'Tab') {
          if (e.shiftKey) {
            if (
              buttons &&
              (e.target as HTMLButtonElement).isEqualNode(buttons[0])
            ) {
              buttons[buttonCount - 1].focus();
            }
            return;
          }

          if (
            buttons &&
            (e.target as HTMLButtonElement).isEqualNode(
              buttons[buttonCount - 1],
            )
          ) {
            buttons[0].focus();
          }
        }
      });
    },
    headerRef,
  );

  return note.mark && show && targetEl ? (
    <Overlay
      target={targetEl}
      container={versionContentContainer}
      show={show}
      rootClose
      onHide={(e) => handleHide(e)}
      placement='bottom'>
      <Popover id='ShowNotePopover' role='dialog'>
        <PopoverHeader ref={headerRef}>
          <h3 ref={headlineRef} className='visually-hidden' tabIndex={0}>
            {translations('detailsOfNote')}
          </h3>
          <div className='d-flex justify-content-between align-items-center'>
            <div>
              {userCanEditNote && (
                <IconButton
                  title={translations('edit')}
                  onClick={() => {
                    openEditNoteDialog();
                    dispatch(setShowNotePopoverOpened(false));
                  }}
                  iconClassName='icon-edit'
                  textColorClass='text-body'
                  ariaLabel={translations('edit')}
                />
              )}
              {relatedBookmark ? (
                <IconButton
                  title={translations('deleteBookmark')}
                  onClick={() => {
                    dispatch(setContextBookmark(relatedBookmark));
                    dispatch(setDeleteBookmarkDialogOpened(true));
                    dispatch(setShowNotePopoverOpened(false));
                  }}
                  iconClassName='icon-merkliste_remove'
                  textColorClass='text-danger'
                  ariaLabel={translations('deleteBookmark')}
                />
              ) : (
                <IconButton
                  title={translations('addBookmark')}
                  onClick={() => {
                    dispatch(setAddMarkBookmarkDialogOpened(true));
                    dispatch(setShowNotePopoverOpened(false));
                  }}
                  iconClassName='icon-merkliste_new'
                  textColorClass='text-success'
                  ariaLabel={translations('addBookmark')}
                />
              )}
              {userCanDeleteNote && (
                <IconButton
                  title={translations('delete')}
                  onClick={() => {
                    openDeleteNoteDialog();
                    dispatch(setShowNotePopoverOpened(false));
                  }}
                  iconClassName='icon-trash'
                  textColorClass='text-danger'
                  ariaLabel={translations('delete')}
                />
              )}
            </div>
            <IconButton
              title={translations('close')}
              onClick={() => {
                dispatch(setShowNotePopoverOpened(false));
              }}
              iconClassName='icon-close_2'
              textColorClass='text-body'
              ariaLabel={translations('close')}
            />
          </div>
        </PopoverHeader>
        {note.text && (
          <PopoverBody>
            <p className='mb-0'>{note.text}</p>
          </PopoverBody>
        )}
      </Popover>
    </Overlay>
  ) : null;
}

export default ShowNotePopover;
