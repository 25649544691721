import { Dropdown, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { EDIT_MODE_KEY } from '../../shared/constants';
import DropdownMenu from '../dropdown-menus/DropdownMenu';

interface ISettingLinksProps {
  userHasAccessToUserManagement: boolean;
  userHasAccessToRoleManagement: boolean;
  userHasAccessToCategoryTypeManagement: boolean;
  userHasAccessToGlobalSettingsManagement: boolean;
  userHasAdministrationRights: boolean;
}

function SettingLinks({
  userHasAccessToUserManagement,
  userHasAccessToRoleManagement,
  userHasAccessToCategoryTypeManagement,
  userHasAccessToGlobalSettingsManagement,
  userHasAdministrationRights,
}: ISettingLinksProps) {
  const { t: translation } = useTranslation();
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);

  return editModeIsActive && userHasAdministrationRights ? (
    <Dropdown>
      <Dropdown.Toggle
        aria-label={translation('administrationLabel')}
        variant='outline-dark'
        className='rounded-circle circle-btn'>
        <i
          className='icon-settings'
          aria-hidden
          title={translation('administrationLabel')}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu as={DropdownMenu} align='end'>
        {userHasAccessToUserManagement && (
          <li>
            <NavDropdown.Item as={Link} to='/user-management'>
              <i className='icon-users me-2' aria-hidden />
              {translation('userManagement')}
            </NavDropdown.Item>
          </li>
        )}
        {userHasAccessToRoleManagement && (
          <li>
            <NavDropdown.Item as={Link} to='/role-management'>
              <i className='icon-benutzerrollen me-2' aria-hidden />
              {translation('roleManagement')}
            </NavDropdown.Item>
          </li>
        )}
        {userHasAccessToCategoryTypeManagement && (
          <li>
            <NavDropdown.Item as={Link} to='/category-type-management'>
              <i className='icon-kategorietypen me-2' aria-hidden />
              {translation('categoryTypes')}
            </NavDropdown.Item>
          </li>
        )}
        {userHasAccessToGlobalSettingsManagement && (
          <li>
            <NavDropdown.Item as={Link} to='/global-settings'>
              <i className='icon-settings me-2' aria-hidden />
              {translation('globalSettings')}
            </NavDropdown.Item>
          </li>
        )}
      </Dropdown.Menu>
    </Dropdown>
  ) : null;
}

export default SettingLinks;
