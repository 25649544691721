import { SearchResultType } from '../components/search/types';
import { SearchOption } from '../redux/store/content/types';
import {
  articleIdUrlParam,
  articleIdsWithFoundTermsUrlParam,
  categoryIdUrlParam,
  includeVersionsUrlParam,
  markIdUrlParam,
  pdfArticleUrlParam,
  searchKeywordUrlParam,
  searchOptionUrlParam,
  searchResultTypeUrlParam,
  selectedCategoriesUrlParam,
  textAreaIdUrlParam,
  versionIdUrlParam,
} from './constants';

export function getArticleUrl(
  articleId: string,
  versionId?: string,
  areaId?: string,
  markId?: string,
  searchKeywords?: string[],
) {
  const versionIdParam = versionId ? `&${versionIdUrlParam}=${versionId}` : '';
  const areaIdParam = areaId ? `&${textAreaIdUrlParam}=${areaId}` : '';
  const markIdParam = markId ? `&${markIdUrlParam}=${markId}` : '';

  const searchKeywordParam = searchKeywords
    ? `&${searchKeywordUrlParam}=${searchKeywords.toString()}`
    : '';

  return `/article?${articleIdUrlParam}=${articleId}${versionIdParam}${areaIdParam}${markIdParam}${searchKeywordParam}`;
}

export function getEditArticleUrl(articleId: string, versionId: string) {
  return `/edit-article?${articleIdUrlParam}=${articleId}&${versionIdUrlParam}=${versionId}`;
}

export function getEditDraftUrl(articleId: string, versionId: string) {
  return `/edit-draft?${articleIdUrlParam}=${articleId}&${versionIdUrlParam}=${versionId}`;
}

export function getCategoryReferenceUrl(
  categoryId: string,
  articleIdsWithFoundTerms?: { [key: string]: string | null },
  searchKeywords?: string[],
) {
  const articleIdsWithFoundTermsParam = articleIdsWithFoundTerms
    ? `&${articleIdsWithFoundTermsUrlParam}=${encodeURI(
        JSON.stringify(articleIdsWithFoundTerms),
      )}`
    : '';
  const searchKeywordParam = searchKeywords
    ? `&${searchKeywordUrlParam}=${searchKeywords.toString()}`
    : '';

  return `/category-reference?${categoryIdUrlParam}=${categoryId}&${articleIdsWithFoundTermsParam}${searchKeywordParam}`;
}

export function getCreateArticleUrl(categoryId: string, isPdfArticle: boolean) {
  return `create-article?${categoryIdUrlParam}=${categoryId}${
    isPdfArticle ? `&${pdfArticleUrlParam}=true` : ''
  }`;
}

export function getSearchUrl(
  searchKeyword: string,
  searchOption: SearchOption,
  includeVersions: boolean,
  selectedCategoryIds: string[],
  searchType?: SearchResultType,
) {
  const searchResultTypeParam = searchType
    ? `&${searchResultTypeUrlParam}=${searchType}`
    : '';
  const selectedCategoriesParam =
    selectedCategoryIds.length > 0
      ? `&${selectedCategoriesUrlParam}=${selectedCategoryIds.toString()}`
      : '';

  return `/search?${searchKeywordUrlParam}=${searchKeyword}&${searchOptionUrlParam}=${SearchOption[searchOption]}&${includeVersionsUrlParam}=${includeVersions}${searchResultTypeParam}${selectedCategoriesParam}`;
}
