import { useTranslation } from 'react-i18next';
import { Button, Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import CustomCard from '../cards/CustomCard';
import Loader from '../loader/Loader';
import { useAppDispatch } from '../../redux/hooks';
import { addMessage } from '../../redux/store/layout/slice';
import {
  SettingUpdate,
  useGetApiSettingsContainersGlobalsQuery,
  useGetApiSettingsUserQuery,
  usePutApiSettingsMutation,
} from '../../redux/store/api/api';
import GlobalSettingsInput from './GlobalSettingsInput';
import { RightKey, SettingContainerType } from '../../shared/enums';
import { settingTypeCaching } from '../../shared/constants';
import SendInvalidMarkEmailsDialog from './dialogs/SendInvalidMarkEmailsDialog';

interface IGlobalSettingsProps {
  type: SettingContainerType;
  rights: RightKey[];
}

function GlobalSettings({ type, rights }: IGlobalSettingsProps) {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const [newSettingValues, setNewSettingValues] = useState<SettingUpdate[]>([]);
  const { refetch: refetchUserSettings } = useGetApiSettingsUserQuery();
  const [
    sendInvalidMarkEmailsDialogOpened,
    setSendInvalidMarkEmailsDialogOpened,
  ] = useState(false);

  const {
    data: settingData,
    isError: getGlobalSettingsIsError,
    isFetching,
    error: getGlobalSettingsError,
  } = useGetApiSettingsContainersGlobalsQuery(
    {
      type,
      includeSettings: true,
    },
    { refetchOnMountOrArgChange: true },
  );

  const [
    updateApiSettingById,
    { isError: updateApiSettingByIdIsError, error: updateApiSettingByIdError },
  ] = usePutApiSettingsMutation();

  const handleSaveSettings = () => {
    const formData = new FormData();

    newSettingValues.forEach((s, i) => {
      formData.append(`settingUpdates[${i}].id`, s.id || '');
      if (s.value) {
        formData.append(`settingUpdates[${i}].value`, s.value || '');
      }
      if (s.file) {
        formData.append(`settingUpdates[${i}].file`, s.file || '');
      }
    });

    updateApiSettingById({
      body: formData as unknown as { settingUpdates?: SettingUpdate[] },
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'UpdateSettingSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        setNewSettingValues([]);
        refetchUserSettings();
      });
  };

  // permissions
  const userCanReadGlobalSetting = rights.includes(
    RightKey.RightSettingsManagementReadSettingsGlobal,
  );
  const userCanEditGlobalSetting = rights.includes(
    RightKey.RightSettingsManagementEditSettingsGlobal,
  );

  useEffect(() => {
    if (getGlobalSettingsIsError) {
      dispatch(
        addMessage({
          id: 'GetGlobalSettingsError',
          variant: 'danger',
          messageKeyBody:
            getGlobalSettingsError && 'data' in getGlobalSettingsError
              ? getGlobalSettingsError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (updateApiSettingByIdIsError) {
      dispatch(
        addMessage({
          id: 'UpdateSettingError',
          variant: 'danger',
          messageKeyBody:
            updateApiSettingByIdError && 'data' in updateApiSettingByIdError
              ? updateApiSettingByIdError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [getGlobalSettingsIsError, updateApiSettingByIdIsError]);

  useEffect(() => {
    setNewSettingValues([]);
  }, [type]);

  return (
    <>
      <CustomCard title={translation('globalSettingsSettings')}>
        {isFetching && <Loader />}
        {!isFetching &&
          settingData?.resultObject &&
          userCanReadGlobalSetting && (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
              }}>
              {settingData.resultObject[0].settings?.map((setting) => (
                <>
                  <GlobalSettingsInput
                    key={setting.id}
                    setting={setting}
                    userCanEditSetting={userCanEditGlobalSetting}
                    newSettingValues={newSettingValues}
                    setNewSettingValues={setNewSettingValues}
                  />
                  {setting.settingType?.key === settingTypeCaching && (
                    <>
                      <label htmlFor='SendButtonEmail' className='d-block'>
                        {translation('infoMailAboutInvalidMarks')}
                      </label>
                      <Button
                        id='SendButtonEmail'
                        onClick={() => {
                          setSendInvalidMarkEmailsDialogOpened(true);
                        }}
                        className='mb-3'>
                        Emails verschicken
                      </Button>
                    </>
                  )}
                </>
              ))}

              {userCanEditGlobalSetting && (
                <div className='float-end mt-3'>
                  <Button
                    className='mt-2'
                    variant='outline-success'
                    disabled={newSettingValues.length < 1}
                    onClick={handleSaveSettings}>
                    {translation('save')}
                  </Button>
                </div>
              )}
            </Form>
          )}
      </CustomCard>
      <SendInvalidMarkEmailsDialog
        dialogShow={sendInvalidMarkEmailsDialogOpened}
        setDialogShow={setSendInvalidMarkEmailsDialogOpened}
      />
    </>
  );
}

export default GlobalSettings;
