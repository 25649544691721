import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useDeleteApiUserGroupsByIdMutation,
  useGetApiUserGroupsQuery,
} from '../../../redux/store/api/api';
import {
  clearSelectedUsers,
  removeExpandedUserGroup,
  selectContextUserGroup,
  selectDeleteUserGroupDialogOpened,
  setDeleteUserGroupDialogOpened,
} from '../../../redux/store/user-management/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';

function DeleteUserGroupDialog(): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const dialogShow = useAppSelector(selectDeleteUserGroupDialogOpened);
  const userGroup = useAppSelector(selectContextUserGroup);
  const [
    deleteUserGroup,
    { isError: deleteUserGroupIsError, isLoading, error: deleteUserGroupError },
  ] = useDeleteApiUserGroupsByIdMutation();
  const userGroupCantDelete =
    !userGroup.userIds ||
    !userGroup.userGroupIds ||
    userGroup.userIds?.length > 0 ||
    userGroup.userGroupIds?.length > 0;
  const {
    refetch,
    isError: getUserGroupsIsError,
    error: getUserGroupsError,
  } = useGetApiUserGroupsQuery({
    includeUsers: true,
    includePermittedActions: true,
  });

  useEffect(() => {
    if (getUserGroupsIsError) {
      dispatch(
        addMessage({
          id: 'GetUserGroupsError',
          variant: 'danger',
          messageKeyBody:
            getUserGroupsError && 'data' in getUserGroupsError
              ? getUserGroupsError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (deleteUserGroupIsError) {
      dispatch(
        addMessage({
          id: 'DeleteUserGroupError',
          variant: 'danger',
          messageKeyBody:
            deleteUserGroupError && 'data' in deleteUserGroupError
              ? deleteUserGroupError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [getUserGroupsIsError, deleteUserGroupIsError]);

  const handleDeleteUserGroup = (): void => {
    deleteUserGroup({ id: userGroup.id || '' })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'DeleteUserGroupSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        dispatch(setDeleteUserGroupDialogOpened(false));
        dispatch(removeExpandedUserGroup(userGroup.id || ''));
        dispatch(clearSelectedUsers());
      });
  };

  return (
    <CustomDialog
      titleId='DeleteUserGroupDialog'
      actionButtonDisabled={userGroupCantDelete || isLoading}
      dialogTitle={translation('deleteUserGroup')}
      show={dialogShow}
      closeFunction={() => {
        dispatch(setDeleteUserGroupDialogOpened(false));
      }}
      actionFunction={handleDeleteUserGroup}
      closeTitle={translation('cancel')}
      actionTitle={translation('delete')}
      actionButtonVariant='outline-danger'>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          {userGroupCantDelete ? (
            <p>{translation('userGroupCantDelete')}</p>
          ) : (
            <p>{translation('deleteUserGroupPermanently')}</p>
          )}
          <p className='fw-bold'>{userGroup.name}</p>
        </>
      )}
    </CustomDialog>
  );
}

export default DeleteUserGroupDialog;
