import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router';
import CategoryTypeList from '../components/category-type-management/CategoryTypeList';
import CategoryTypeSettings from '../components/category-type-management/CategoryTypeSettings';
import { useAppDispatch } from '../redux/hooks';
import {
  CategoryType,
  useGetApiPermissionsUserQuery,
} from '../redux/store/api/api';
import { addMessage } from '../redux/store/layout/slice';
import { permissionCacheDelayInSeconds } from '../shared/constants';
import { RightKey } from '../shared/enums';
import ViewFallback from '../components/error-fallback/ViewFallback';

function CategoryTypeManagement(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [activeCategoryType, setActiveCategoryType] = useState<CategoryType>(
    {},
  );
  const {
    data: permissionData,
    isError,
    error,
  } = useGetApiPermissionsUserQuery(undefined, {
    refetchOnMountOrArgChange: permissionCacheDelayInSeconds,
  });

  useEffect(() => {
    if (
      permissionData?.resultObject &&
      !permissionData.resultObject.includes(
        RightKey.RightAccessCategoryTypeManagement,
      )
    ) {
      navigate('/');
    }
  }, [permissionData]);

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetPermissionsError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
      navigate('/');
    }
  }, [isError]);

  useEffect(() => {
    if (permissionData?.resultObject) {
      document.getElementsByTagName('h1')[0].focus();
    }
  }, [permissionData]);

  return (
    <div className='content-inner'>
      <ErrorBoundary fallback={<ViewFallback />}>
        {permissionData?.resultObject && (
          <>
            <CategoryTypeList
              activeCategoryType={activeCategoryType}
              setActiveCategoryType={setActiveCategoryType}
              rights={permissionData.resultObject}
            />
            <CategoryTypeSettings
              activeCategoryType={activeCategoryType}
              rights={permissionData.resultObject}
            />
          </>
        )}
      </ErrorBoundary>
    </div>
  );
}

export default CategoryTypeManagement;
