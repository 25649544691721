import { useEffect } from 'react';
import { HousesFill } from 'react-bootstrap-icons';
import { ErrorBoundary } from 'react-error-boundary';
import ArticleAttachmentsContainer from '../components/article/ArticleAttachmentsContainer';
import ArticleView from '../components/article/ArticleView';
import useGetCategoryByArticleId from '../hooks/useGetCategoryByArticleId';
import { useAppSelector } from '../redux/hooks';
import { selectActiveVersion } from '../redux/store/content/slice';
import ViewFallback from '../components/error-fallback/ViewFallback';

function Article() {
  const version = useAppSelector(selectActiveVersion);
  const category = useGetCategoryByArticleId(version.articleId);

  useEffect(() => {
    document.getElementsByTagName('h1')[0].focus();
  }, []);

  return (
    <div className='article-view'>
      <ErrorBoundary fallback={<ViewFallback />}>
        {category?.permittedPath && (
          <div className='category-path-container'>
            <p className='mb-2 ms-4 text-break'>
              <HousesFill aria-hidden className='me-2 fs-5 align-text-top' />
              {category.permittedPath}
            </p>
          </div>
        )}
        <div className='content-inner-article'>
          <ArticleView />
          <ArticleAttachmentsContainer />
        </div>
      </ErrorBoundary>
    </div>
  );
}

export default Article;
