import { PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Main from './Main';
import NavMenu from './NavMenu';
import './Layout.scss';
import GlobalFallback from '../components/error-fallback/GlobalFallback';

function Layout({ children }: PropsWithChildren<{}>): JSX.Element {
  return (
    <div>
      <ErrorBoundary fallback={<GlobalFallback />}>
        <NavMenu />
        <Main>{children}</Main>
      </ErrorBoundary>
    </div>
  );
}

export default Layout;
