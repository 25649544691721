import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Loader from '../loader/Loader';
import {
  useGetApiCategoryTypesQuery,
  useGetApiSettingsUserQuery,
} from '../../redux/store/api/api';
import {
  settingTypeMaxAbbreviationLength,
  settingTypeMaxCategoryNameLength,
} from '../../shared/constants';
import { addMessage } from '../../redux/store/layout/slice';
import { useAppDispatch } from '../../redux/hooks';

interface ICategoryFormProps {
  categoryName: string;
  setCategoryName: (name: string) => void;
  categoryAcronym: string;
  setCategoryAcronym: (acronym: string) => void;
  categoryTypeId: string;
  setCategoryTypeId: (id: string) => void;
  categoryTypeCanSet: boolean;
  setCategoryAcronymIsValid: (valid: boolean) => void;
  categoryNameIsValid: boolean;
  setCategoryNameIsValid: (valid: boolean) => void;
  categoryTypeIsValid: boolean;
  setCategoryTypeIsValid: (valid: boolean) => void;
}

function CategoryForm({
  categoryName,
  setCategoryName,
  categoryAcronym,
  setCategoryAcronym,
  categoryTypeId,
  setCategoryTypeId,
  categoryTypeCanSet,
  setCategoryAcronymIsValid,
  categoryNameIsValid,
  setCategoryNameIsValid,
  categoryTypeIsValid,
  setCategoryTypeIsValid,
}: ICategoryFormProps) {
  const { t: translation } = useTranslation();
  const {
    data: categoryTypes,
    isFetching,
    isError,
    error,
  } = useGetApiCategoryTypesQuery();
  const [acronymMaxIsError, setAcronymMaxIsError] = useState(false);
  const { data: settings } = useGetApiSettingsUserQuery();
  const dispatch = useAppDispatch();
  const categoryNameMaxSetting =
    Number(
      settings?.resultObject?.find(
        (s) => s.settingType?.key === settingTypeMaxCategoryNameLength,
      )?.value,
    ) || undefined;
  const acronymMaxSetting =
    Number(
      settings?.resultObject?.find(
        (s) => s.settingType?.key === settingTypeMaxAbbreviationLength,
      )?.value,
    ) || undefined;

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetCategoryTypesError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  return (
    <>
      {isFetching && <Loader />}
      {!isFetching && categoryTypes?.resultObject && (
        <Form>
          <Form.Group className='mb-3' controlId='CategoryName'>
            <Form.Label>{translation('categoryName')}*</Form.Label>
            <Form.Control
              required
              aria-describedby={
                !categoryNameIsValid ? 'CategoryNameInputError' : undefined
              }
              isInvalid={!categoryNameIsValid}
              onChange={(e) => {
                setCategoryName(e.target.value);
                if (e.target.value.trim().length > 0) {
                  setCategoryNameIsValid(true);
                }
              }}
              onBlur={(e) => {
                if (
                  categoryNameMaxSetting &&
                  e.target.value.length > categoryNameMaxSetting
                ) {
                  setCategoryNameIsValid(false);
                }
              }}
              type='text'
              value={categoryName}
            />
            <Form.Control.Feedback id='CategoryNameInputError' type='invalid'>
              {categoryName.trim().length > 0
                ? translation('categoryNameToLongError', {
                    max: categoryNameMaxSetting,
                  })
                : translation('fieldNotEmpty')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3' controlId='CategoryAcronym'>
            <Form.Label>{translation('categoryAcronym')}</Form.Label>
            <Form.Control
              aria-describedby={
                acronymMaxIsError ? 'AcronymInputError' : undefined
              }
              isInvalid={acronymMaxIsError}
              onChange={(e) => {
                setCategoryAcronym(e.target.value);
              }}
              onBlur={(e) => {
                if (
                  acronymMaxSetting &&
                  e.target.value.length > acronymMaxSetting
                ) {
                  setAcronymMaxIsError(true);
                  setCategoryAcronymIsValid(false);
                } else {
                  setAcronymMaxIsError(false);
                  setCategoryAcronymIsValid(true);
                }
              }}
              type='text'
              value={categoryAcronym}
            />
            <Form.Control.Feedback id='AcronymInputError' type='invalid'>
              {translation('acronymToLongError', {
                max: acronymMaxSetting,
              })}
            </Form.Control.Feedback>
          </Form.Group>
          {categoryTypeCanSet && (
            <Form.Group className='mb-3' controlId='CategoryType'>
              <Form.Label>{translation('categoryType')}*</Form.Label>
              <Form.Select
                required
                isInvalid={!categoryTypeIsValid}
                aria-describedby={
                  categoryTypeIsValid ? undefined : 'CategroyTypeInputError'
                }
                value={categoryTypeId}
                onChange={(e) => {
                  setCategoryTypeId(e.target.value);
                  if (e.target.value.trim().length > 0) {
                    setCategoryTypeIsValid(true);
                  }
                }}>
                {categoryTypes.resultObject.map((type) => (
                  <option key={type.id || ''} value={type.id || ''}>
                    {type.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback id='CategroyTypeInputError' type='invalid'>
                {translation('fieldNotEmpty')}
              </Form.Control.Feedback>
            </Form.Group>
          )}
        </Form>
      )}
    </>
  );
}

export default CategoryForm;
