import { BookmarkTree } from '../../redux/store/api/api';
import {
  BookmarkTreeItems,
  IBookmarkFolderTreeItem,
  IBookmarkTreeItem,
} from './types';

export function getBookmarkFolderTreeItems(
  treeData: BookmarkTree,
): BookmarkTreeItems {
  let treeItems: IBookmarkFolderTreeItem[] = [];

  treeData.bookmarkFolders?.forEach((bookmarkFolder) => {
    treeItems.push({
      id: bookmarkFolder.id,
      parent: bookmarkFolder.parentId || '0',
      name: bookmarkFolder.name,
      order: bookmarkFolder.order,
      children: bookmarkFolder.bookmarkFolderIds || [],
      bookmarkFolderIds: bookmarkFolder.bookmarkFolderIds || [],
    } as IBookmarkFolderTreeItem);
  });

  const rootBookmarkFolderIds: string[] = treeItems
    .filter((i) => i.parent === '0')
    .map((i) => i.id);

  // parent element for all tree items on start of array
  treeItems = (
    [
      {
        id: '0',
        parent: null,
        name: 'name',
        order: 0,
        children: rootBookmarkFolderIds,
        bookmarkFolderIds: [],
        bookmarkIds: [],
      },
    ] as IBookmarkFolderTreeItem[]
  ).concat(treeItems);

  return treeItems;
}

export function getCompleteBookmarkTreeItems(
  treeData: BookmarkTree,
): BookmarkTreeItems {
  let treeItems: BookmarkTreeItems = [];
  treeData.bookmarkFolders?.forEach((bookmarkFolder) => {
    treeItems.push({
      id: bookmarkFolder.id || '',
      parent: bookmarkFolder.parentId || '0',
      name: bookmarkFolder.name,
      order: bookmarkFolder.order,
      children: (bookmarkFolder.bookmarkIds || []).concat(
        bookmarkFolder.bookmarkFolderIds || [],
      ),
      bookmarkIds: bookmarkFolder.bookmarkIds || [],
      bookmarkFolderIds: bookmarkFolder.bookmarkFolderIds || [],
    } as IBookmarkFolderTreeItem);
  });

  // add bookmarks to treeItems
  treeData.bookmarks?.forEach((bookmark) =>
    treeItems.push({
      id: bookmark.id || '',
      parent: bookmark.bookmarkFolderId || '',
      articleId: bookmark.articleId,
      versionId: bookmark.versionId,
      order: bookmark.order || 0,
      name: bookmark.name || '',
      markId: bookmark.markId,
      iconCssClass: bookmark.iconCssClass || '',
      iconColorHexCode: bookmark.iconColorHexCode || '',
      children: [],
    } as IBookmarkTreeItem),
  );

  const rootBookmarkFolderIds: string[] = treeItems
    .filter((i) => i.parent === '0')
    .map((i) => i.id);

  // parent element for all tree items on start of array
  treeItems = (
    [
      {
        id: '0',
        parent: null,
        name: 'name',
        order: 0,
        children: rootBookmarkFolderIds,
      },
    ] as BookmarkTreeItems
  ).concat(treeItems);

  return treeItems;
}
