import { useEffect } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { useGetApiArticlesRecentChangesQuery } from '../../redux/store/api/api';
import { addMessage } from '../../redux/store/layout/slice';
import Loader from '../loader/Loader';
import LastChangesItems from './LastChangesItems';

function LastChanges(): JSX.Element {
  const dispatch = useAppDispatch();

  const {
    data: recentChanges,
    isFetching,
    isError,
    error,
  } = useGetApiArticlesRecentChangesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetLastChangesError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  return (
    <>
      {isFetching && <Loader />}
      {!isFetching && recentChanges?.resultObject && (
        <LastChangesItems recentChanges={recentChanges.resultObject} />
      )}
    </>
  );
}

export default LastChanges;
