import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLocalStorage, useSessionStorage } from 'usehooks-ts';
import authService from './AuthorizeService';
import {
  LoginActions,
  QueryParameterNames,
  ApplicationPaths,
} from './ApiAuthorizationConstants';
import Loader from '../loader/Loader';
import { EDIT_MODE_KEY, EXPANDED_CATEGORY_IDS } from '../../shared/constants';

interface ILoginProps {
  action: string;
}

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
function Login({ action }: ILoginProps): JSX.Element {
  const [message, setMessage] = useState('');
  const [searchParams] = useSearchParams();

  const navigateToReturnUrl = (returnUrl: string) => {
    // It's important that we do a replace here so that we remove the callback uri with the
    // fragment containing the tokens from the browser history.
    window.location.replace(returnUrl);
  };

  const [editModeIsActive, setEditModeIsActive] = useLocalStorage<boolean>(
    EDIT_MODE_KEY,
    false,
  );
  const [
    expandedCategoriesInSessionStorage,
    setExpandedCategoriesInSessionStorage,
  ] = useSessionStorage<string[]>(EXPANDED_CATEGORY_IDS, []);
  const [returnUrlInSessionStorage, setReturnUrlInSessionStorage] =
    useSessionStorage<string>('returnUrl', '');

  const getReturnUrlParam = () => {
    const returnUrl = searchParams.get(QueryParameterNames.ReturnUrl);
    if (returnUrl && !returnUrl.startsWith(`${window.location.origin}/`)) {
      // This is an extra check to prevent open redirects.
      throw new Error(
        'Invalid return url. The return url needs to have the same origin as the current page.',
      );
    }
    return returnUrl || `${window.location.origin}/`;
  };

  const login = async () => {
    const returnUrlParam = getReturnUrlParam();
    const state = { returnUrl: returnUrlParam };
    if (editModeIsActive) {
      setEditModeIsActive(false);
    }
    if (expandedCategoriesInSessionStorage !== undefined) {
      setExpandedCategoriesInSessionStorage([]);
    }

    const result = await authService.signIn(state);

    switch (result.status) {
      case 'redirect':
        setReturnUrlInSessionStorage(returnUrlParam);
        break;
      case 'success':
        navigateToReturnUrl(
          returnUrlInSessionStorage.length === 0
            ? '/'
            : returnUrlInSessionStorage,
        );
        break;
      case 'fail':
        setMessage(result.message);
        break;
      default:
        throw new Error('Invalid status result.');
    }
  };

  const processLoginCallback = async () => {
    const url = window.location.href;
    const result = await authService.completeSignIn(url);
    switch (result.status) {
      case 'success':
        navigateToReturnUrl(returnUrlInSessionStorage);
        break;
      case 'fail':
        setMessage(result.message);
        break;
      default:
        throw new Error('Invalid authentication result status.');
    }
  };

  const redirectToApiAuthorizationPath = (apiAuthorizationPath: string) => {
    const redirectUrl = `${window.location.origin}/${apiAuthorizationPath}`;
    // It's important that we do a replace here so that when the user hits the back arrow on the
    // browser they get sent back to where it was on the app instead of to an endpoint on this
    // component.
    window.location.replace(redirectUrl);
  };

  const redirectToProfile = () => {
    redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
  };

  useEffect(() => {
    switch (action) {
      case LoginActions.Login:
        login();
        break;
      case LoginActions.LoginCallback:
        processLoginCallback();
        break;
      case LoginActions.LoginFailed:
        setMessage(
          new URLSearchParams(window.location.search).get(
            QueryParameterNames.Message,
          ) || '',
        );
        break;
      case LoginActions.Profile:
        redirectToProfile();
        break;
      default:
        throw new Error(`Invalid action '${action}'`);
    }
  }, []);

  const getActionMessage = (): JSX.Element => {
    switch (action) {
      case LoginActions.Login:
        return <Loader />;
      case LoginActions.LoginCallback:
        return <Loader />;
      case LoginActions.Profile:
        return <Loader />;
      default:
        throw new Error(`Invalid action '${action}'`);
    }
  };

  return message ? <div>{message}</div> : getActionMessage();
}

export default Login;
