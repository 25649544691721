/* eslint-disable jsx-a11y/no-static-element-interactions */
import { SyntheticEvent } from 'react';
import { useNavigate } from 'react-router';
import { Bookmark, BookmarkFolder } from '../../redux/store/api/api';
import { paddingOffsetForTreeElements } from '../../shared/constants';
import { checkClickOnContextMenu } from '../../shared/utils';
import BookmarkFolderItem from './BookmarkFolderItem';
import BookmarkItem from './BookmarkItem';
import {
  IBookmarkFolderTreeItem,
  IBookmarkTreeItem,
  MovingBookmark,
  MovingBookmarkFolder,
} from './types';
import { getArticleUrl } from '../../shared/urlBuilder';

interface IBookmarkTreeItemProps {
  level: number;
  isBranch: boolean;
  isExpanded: boolean | undefined;
  element: IBookmarkFolderTreeItem | IBookmarkTreeItem;
  handleExpand: Function;
  getNodeProps: Function;
  setRenameBookmarkDialogOpened: (show: boolean) => void;
  setAddBookmarkFolderDialogOpened: (show: boolean) => void;
  setRenameBookmarkFolderDialogOpened: (show: boolean) => void;
  setDeleteBookmarkFolderDialogOpened: (show: boolean) => void;
  setMoveDialogOpened: (show: boolean) => void;
  movingBookmark: MovingBookmark;
  setMovingBookmark: (movingBookmark: MovingBookmark) => void;
  movingBookmarkFolder: MovingBookmarkFolder;
  setMovingBookmarkFolder: (movingBookmarkFolder: MovingBookmarkFolder) => void;
  moveBookmarkIsActive: boolean;
  setMoveBookmarkIsActive: (moveIsActive: boolean) => void;
  moveBookmarkFolderIsActive: boolean;
  setMoveBookmarkFolderIsActive: (moveIsActive: boolean) => void;
}

function getBookmark(bookmarkTreeItem: IBookmarkTreeItem): Bookmark {
  return {
    id: bookmarkTreeItem.id,
    bookmarkFolderId: bookmarkTreeItem.parent,
    articleId: bookmarkTreeItem.articleId,
    versionId: bookmarkTreeItem.versionId,
    order: bookmarkTreeItem.order,
    name: bookmarkTreeItem.name,
    markId: bookmarkTreeItem.markId,
    iconColorHexCode: bookmarkTreeItem.iconColorHexCode,
    iconCssClass: bookmarkTreeItem.iconCssClass,
  };
}

function getBookmarkFolder(
  bookmarkFolderTreeItem: IBookmarkFolderTreeItem,
): BookmarkFolder {
  return {
    id: bookmarkFolderTreeItem.id,
    parentId: bookmarkFolderTreeItem.parent,
    order: bookmarkFolderTreeItem.order,
    name: bookmarkFolderTreeItem.name,
    bookmarkIds: bookmarkFolderTreeItem.bookmarkIds,
    bookmarkFolderIds: bookmarkFolderTreeItem.bookmarkFolderIds,
  };
}

function BookmarkTreeItem({
  level,
  isBranch,
  isExpanded,
  element,
  handleExpand,
  getNodeProps,
  setRenameBookmarkDialogOpened,
  setAddBookmarkFolderDialogOpened,
  setMoveDialogOpened,
  movingBookmark,
  setMovingBookmark,
  movingBookmarkFolder,
  setMovingBookmarkFolder,
  moveBookmarkIsActive,
  setMoveBookmarkIsActive,
  moveBookmarkFolderIsActive,
  setMoveBookmarkFolderIsActive,
  setRenameBookmarkFolderDialogOpened,
  setDeleteBookmarkFolderDialogOpened,
}: IBookmarkTreeItemProps): JSX.Element {
  const isBookmarkElement: boolean = 'articleId' in element;
  const itemClass: string = isBookmarkElement ? 'child-item' : 'parent-item';
  const navigate = useNavigate();

  const handleOpenArticle = () => {
    const bookmark = element as IBookmarkTreeItem;

    navigate(
      getArticleUrl(
        bookmark.articleId || '',
        bookmark.versionId || undefined,
        undefined,
        bookmark.markId || undefined,
      ),
      undefined,
    );
  };

  return (
    <div className={`li-inner ${itemClass}`}>
      <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getNodeProps({
          onClick: (e: SyntheticEvent) => {
            if (checkClickOnContextMenu(e)) {
              return;
            }

            if (isBookmarkElement) {
              handleOpenArticle();
            } else {
              handleExpand(e);
            }
          },
        })}
        onKeyDown={(e) => {
          if (checkClickOnContextMenu(e)) {
            return;
          }

          if (e.key === 'Enter' && isBookmarkElement) {
            e.stopPropagation();
            handleOpenArticle();
          }
        }}
        role={isBookmarkElement ? 'link' : undefined}
        style={{ paddingLeft: paddingOffsetForTreeElements * (level - 1) }}>
        <div className='d-flex'>
          {isBranch && isExpanded && (
            <i className='me-2 icon-carretup fs-5 mt-1' aria-hidden />
          )}
          {isBranch && !isExpanded && (
            <i className='me-2 icon-carretdown fs-5 mt-1' aria-hidden />
          )}
          {isBookmarkElement && (
            <BookmarkItem
              bookmark={getBookmark(element as IBookmarkTreeItem)}
              setRenameBookmarkDialogOpened={setRenameBookmarkDialogOpened}
              setMoveDialogOpened={setMoveDialogOpened}
              movingBookmark={movingBookmark}
              moveBookmarkIsActive={moveBookmarkIsActive}
              moveBookmarkFolderIsActive={moveBookmarkFolderIsActive}
              setMoveBookmarkFolderIsActive={setMoveBookmarkFolderIsActive}
              setMoveBookmarkIsActive={setMoveBookmarkIsActive}
              setMovingBookmark={setMovingBookmark}
            />
          )}
          {!isBookmarkElement && (
            <BookmarkFolderItem
              bookmarkFolder={getBookmarkFolder(
                element as IBookmarkFolderTreeItem,
              )}
              setMoveDialogOpened={setMoveDialogOpened}
              movingBookmark={movingBookmark}
              movingBookmarkFolder={movingBookmarkFolder}
              moveBookmarkFolderIsActive={moveBookmarkFolderIsActive}
              moveBookmarkIsActive={moveBookmarkIsActive}
              setMoveBookmarkFolderIsActive={setMoveBookmarkFolderIsActive}
              setMoveBookmarkIsActive={setMoveBookmarkIsActive}
              setMovingBookmarkFolder={setMovingBookmarkFolder}
              setMovingBookmark={setMovingBookmark}
              setAddBookmarkFolderDialogOpened={
                setAddBookmarkFolderDialogOpened
              }
              setRenameBookmarkFolderDialogOpened={
                setRenameBookmarkFolderDialogOpened
              }
              setDeleteBookmarkFolderDialogOpened={
                setDeleteBookmarkFolderDialogOpened
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default BookmarkTreeItem;
