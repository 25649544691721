import { useTranslation } from 'react-i18next';

function VersionContentFallback() {
  const { t: translation } = useTranslation();

  return (
    <p className='fw-bold text-danger'>
      {translation('thisVersionCanNotDisplayed')}
    </p>
  );
}

export default VersionContentFallback;
