import _ from 'lodash';
import {
  CALCULATION_CONTENT_CONTAINER_ID,
  RANGE_END_ID,
  RANGE_START_ID,
} from '../../../shared/constants';
import { Mark } from '../../../redux/store/api/api';
import convertFromRange from './convertFromRange';
import preprocessRange from './preprocessRange';
import getValidatedMark from '../../marks/functions';

export interface ITagFreeContentProps {
  content: string;
  indexes: number[];
}

interface IPositionProps {
  start: number;
  end: number;
}

interface IMatchProps {
  word: string;
  position: IPositionProps;
}

interface ISuggestionProps {
  position: IPositionProps;
  lengthDelta: number;
  missingWordsCount: number;
  content?: string;
}

type SuggestionSortKeys = keyof ISuggestionProps;

const missingWordsCountPropName: SuggestionSortKeys = 'missingWordsCount';
const lengthDeltaPropName: SuggestionSortKeys = 'lengthDelta';

const getIntersectsExistingMark = (
  suggestionStart: number,
  suggestionEnd: number,
  content: string,
) => {
  const markStartTagRegex = /<mark(.*?)>/g;
  const markEndTagRegex = /<\/mark>/g;
  const pattern = content.substring(suggestionStart, suggestionEnd);
  const contentBeforePattern = content.substring(0, suggestionStart);
  const contentAfterPattern = content.substring(
    suggestionEnd,
    content.length - 1,
  );

  const patternHasMark =
    markStartTagRegex.test(pattern) || markEndTagRegex.test(pattern);
  const patternStartsInsideMark =
    (Array.from(contentBeforePattern.matchAll(markStartTagRegex)).length ??
      0) !==
    (Array.from(contentBeforePattern.matchAll(markEndTagRegex)).length ?? 0);
  const patternEndsInsideMark =
    (Array.from(contentAfterPattern.matchAll(markStartTagRegex)).length ??
      0) !==
    (Array.from(contentAfterPattern.matchAll(markEndTagRegex)).length ?? 0);
  return patternHasMark || patternStartsInsideMark || patternEndsInsideMark;
};

export const getTagfreeContent = (content: string): ITagFreeContentProps => {
  const htmlTagRegex = /<("[^"]*"|'[^']*'|[^'">])*>/g;
  let c = content;
  let i = Array.from({ length: content.length + 1 }, (_value, index) => index);
  const allMatches = [...c.matchAll(htmlTagRegex)].reverse();

  allMatches.forEach((match) => {
    if (match.index || match.index === 0) {
      c =
        c.substring(0, match.index) +
        c.substring(match.index + match[0].length, c.length);
      i = i
        .slice(0, match.index)
        .concat(i.slice(match.index + match[0].length));
    }
  });

  return { content: c, indexes: i };
};

const getPatternWordList = (pattern: string): string[] => {
  const regex = /(?:\p{L}|['\-_[\]()§]|\d)+/gu;
  return pattern.match(regex) || [];
};

const getMatchList = (
  tagFreeContent: string,
  pattern: string,
): IMatchProps[] => {
  const matchList: IMatchProps[] = [];
  const patternWordList = [...new Set(getPatternWordList(pattern))];

  patternWordList.forEach((word) => {
    const matches = tagFreeContent.matchAll(new RegExp(`${word}`, 'g'));
    Array.from(matches).forEach((match) => {
      const start = match.index ?? 0;
      const end = start + word.length;
      matchList.push({
        word,
        position: { start, end },
      });
    });
  });

  return matchList.sort((a, b) => a.position.start - b.position.start);
};

const walkThroughList = (
  matchList: IMatchProps[],
  startIndex: number,
  pattern: string,
): ISuggestionProps | undefined => {
  const suggestionPositions: number[] = [];
  let searchWordList = getPatternWordList(pattern);
  const indices: number[] = [];
  const toleranceFactor = 1.5;
  const allowedMatchDistance = Math.round(pattern.length * toleranceFactor);

  matchList.forEach((_match, index) => {
    indices.push((startIndex + index) % matchList.length);
  });

  indices.forEach((i) => {
    const match = matchList[i];
    const listIndex = searchWordList.findIndex((word) => word === match.word);
    if (listIndex > -1) {
      const matchStartPosition = match.position.start;
      const matchEndPosition = match.position.end;

      if (
        Math.abs(matchStartPosition - matchList[startIndex].position.start) <=
          allowedMatchDistance &&
        Math.abs(matchEndPosition - matchList[startIndex].position.end) <=
          allowedMatchDistance &&
        !suggestionPositions.includes(matchStartPosition) &&
        !suggestionPositions.includes(matchEndPosition)
      ) {
        // remove search word from list
        searchWordList = searchWordList
          .slice(0, listIndex)
          .concat(searchWordList.slice(listIndex + 1, searchWordList.length));

        // add suggestion position
        for (
          let index = matchStartPosition;
          index <= matchEndPosition;
          index += 1
        ) {
          suggestionPositions.push(index);
        }
      }
    }
  });

  if (suggestionPositions.length === 0) {
    return undefined;
  }

  const start = Math.min(...suggestionPositions);
  const end = Math.max(...suggestionPositions);
  const foundPatternLength = pattern.length - _.join(searchWordList).length;
  const lengthDelta = Math.abs(foundPatternLength - (end - start));
  const missingWordsCount = searchWordList.length;

  if (foundPatternLength < 0 || lengthDelta < 0) {
    return undefined;
  }

  return {
    position: { start, end },
    lengthDelta,
    missingWordsCount,
  };
};

const findSuggestion = (content: string, pattern: string) => {
  const contentObject = getTagfreeContent(content);
  const tagfreeContent = contentObject.content;
  const contentIndex = contentObject.indexes;
  const matchList = getMatchList(tagfreeContent, pattern);
  const patternWordCount = getPatternWordList(pattern).length;
  const fullMatchOnlyPatternWordCount = 3;
  let suggestions: ISuggestionProps[] = [];

  matchList.forEach((_match, index) => {
    const newSuggestion = walkThroughList(matchList, index, pattern);
    if (newSuggestion) {
      const suggestionIntersectsMark = getIntersectsExistingMark(
        contentIndex[newSuggestion.position.start],
        contentIndex[newSuggestion.position.end],
        content,
      );
      if (!suggestionIntersectsMark) {
        suggestions = [...suggestions, newSuggestion];
      }
    }
  });

  const sortedCombinations = _.sortBy(
    suggestions.filter((s) =>
      patternWordCount <= fullMatchOnlyPatternWordCount
        ? s.missingWordsCount === 0
        : patternWordCount - s.missingWordsCount >=
          fullMatchOnlyPatternWordCount,
    ),
    [missingWordsCountPropName, lengthDeltaPropName],
  );

  if (sortedCombinations.length < 1) {
    return null;
  }

  const start = contentIndex[sortedCombinations[0].position.start];
  const end = contentIndex[sortedCombinations[0].position.end];

  return {
    position: { start, end },
    content: content.substring(start, end),
  };
};

export const getUpdatedMark = (newContent: string): Mark | undefined => {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(
    `<div id='${CALCULATION_CONTENT_CONTAINER_ID}'>${newContent}</div>`,
    'text/html',
  );

  const rangeStart = htmlDoc.getElementById(RANGE_START_ID);
  const rangeEnd = htmlDoc.getElementById(RANGE_END_ID);

  let range: Range | undefined = new Range();
  if (rangeStart && rangeEnd) {
    range.setStartAfter(rangeStart);
    range.setEndBefore(rangeEnd);
    rangeStart.remove();
    rangeEnd.remove();
  }
  range = preprocessRange(range, htmlDoc);
  htmlDoc.getElementById(CALCULATION_CONTENT_CONTAINER_ID)?.normalize();

  if (range) {
    return convertFromRange(range, CALCULATION_CONTENT_CONTAINER_ID, htmlDoc);
  }

  return undefined;
};

const getNewContent = (
  content: string,
  tagFreeContentObject: ITagFreeContentProps,
  extendedPatternString: string,
  precedingCharsLength: number,
  subsequentCharsLength: number,
  patternLength: number,
) => {
  const contentIndex = tagFreeContentObject.indexes;
  const allMatches = Array.from(
    tagFreeContentObject.content.matchAll(
      new RegExp(_.escapeRegExp(extendedPatternString), 'g'),
    ),
  );

  if (allMatches?.length !== 1) {
    return null;
  }

  const index = allMatches[0]?.index ?? 0;
  const start = contentIndex[index + precedingCharsLength];
  const end = contentIndex[index + precedingCharsLength + patternLength];
  const matchInContent = content.substring(start, end);

  if (
    matchInContent.search(/<mark(?:\s(?:[^\\s=]+\s*=\s*)(?:".*?"))*\s*>/g) >
      -1 ||
    matchInContent.includes('</mark>')
  ) {
    return null;
  }

  const precedingCharsInContent =
    content.substring(
      contentIndex[index],
      contentIndex[index + precedingCharsLength],
    ) ?? '';
  const subsequentCharsInContent =
    content.substring(
      contentIndex[index + precedingCharsLength + patternLength],
      contentIndex[
        index + precedingCharsLength + patternLength + subsequentCharsLength
      ],
    ) ?? '';

  // use void elememt to mark start and end of range
  return content.replace(
    precedingCharsInContent + matchInContent + subsequentCharsInContent,
    `${precedingCharsInContent}<br id='${RANGE_START_ID}'>${matchInContent}<br id='${RANGE_END_ID}'>${subsequentCharsInContent}`,
  );
};

const getMarkFromTrustedSuggestion = (
  content: string,
  tagFreeContentObject: ITagFreeContentProps,
  mark: Mark,
): Mark | null => {
  const tagfreeContent = tagFreeContentObject.content;
  const fullyExtendedPatternString = `${mark.precedingCharacters ?? ''}${
    mark.patternString ?? ''
  }${mark.subsequentCharacters ?? ''}`;
  const inFrontExtendedPatternString = `${mark.precedingCharacters ?? ''}${
    mark.patternString ?? ''
  }`;
  const atEndExtendedPatternString = `${mark.patternString ?? ''}${
    mark.subsequentCharacters ?? ''
  }`;

  let precedingCharsLength = 0;
  let subsequentCharsLength = 0;
  let extendedPatternString;

  if (
    new RegExp(_.escapeRegExp(fullyExtendedPatternString), 'g').test(
      tagfreeContent,
    )
  ) {
    precedingCharsLength = mark.precedingCharacters?.length ?? 0;
    subsequentCharsLength = mark.subsequentCharacters?.length ?? 0;
    extendedPatternString = fullyExtendedPatternString;
  } else if (
    new RegExp(_.escapeRegExp(inFrontExtendedPatternString), 'g').test(
      tagfreeContent,
    )
  ) {
    precedingCharsLength = mark.precedingCharacters?.length ?? 0;
    extendedPatternString = inFrontExtendedPatternString;
  } else if (
    new RegExp(_.escapeRegExp(atEndExtendedPatternString), 'g').test(
      tagfreeContent,
    )
  ) {
    subsequentCharsLength = mark.subsequentCharacters?.length ?? 0;
    extendedPatternString = atEndExtendedPatternString;
  } else {
    return null;
  }

  const newContent = getNewContent(
    content,
    tagFreeContentObject,
    extendedPatternString,
    precedingCharsLength,
    subsequentCharsLength,
    mark.patternString?.length ?? 0,
  );

  if (newContent) {
    return getValidatedMark(
      {
        ...mark,
        ...getUpdatedMark(newContent),
        markAutoUpdated: true,
      },
      mark,
    );
  }

  return null;
};

export { findSuggestion, getMarkFromTrustedSuggestion };
