import parse from 'html-react-parser';
import { Link, useNavigate } from 'react-router-dom';
import {
  SearchCategoryResultItem,
  useGetApiCategoryTreeQuery,
} from '../../redux/store/api/api';
import CustomListGroup from '../lists/CustomListGroup';
import { getCategoryReferenceUrl } from '../../shared/urlBuilder';

interface ISearchCategoryResultProps {
  searchCategoryResult: SearchCategoryResultItem[];
  foundTerms: string[];
}

function SearchCategoryResult({
  searchCategoryResult,
  foundTerms,
}: ISearchCategoryResultProps): JSX.Element {
  const { data: contentTreeData } = useGetApiCategoryTreeQuery();
  const navigate = useNavigate();

  function getCategory(categoryId: string | undefined | null) {
    if (categoryId) {
      return (
        contentTreeData?.resultObject?.categories?.find(
          (c) => c.id === categoryId,
        ) || undefined
      );
    }
    return undefined;
  }

  const getResultItem = (resultItem: SearchCategoryResultItem): JSX.Element => (
    <>
      <h3 className='result-item-header'>
        <Link
          to={getCategoryReferenceUrl(
            resultItem.categoryId || '',
            resultItem.articleIdsWithFoundTerms || undefined,
            foundTerms || undefined,
          )}
          className='text-break fw-semibold d-flex mb-0 text-body text-decoration-none'>
          <i
            className={`me-2 ${
              getCategory(resultItem.categoryId)?.categoryTypeIconClass || ''
            }`}
            style={
              getCategory(resultItem.categoryId)?.categoryTypeColorHexCode
                ? {
                    color:
                      getCategory(resultItem.categoryId)
                        ?.categoryTypeColorHexCode || '',
                  }
                : undefined
            }
            aria-hidden
          />
          <span>
            {getCategory(resultItem.categoryId)?.abbreviation
              ? `${getCategory(resultItem.categoryId)?.abbreviation}, `
              : ''}
            {parse(getCategory(resultItem.categoryId)?.name || '')}
          </span>
        </Link>
      </h3>
      <span className='text-muted fs-6 fst-italic'>
        (
        {getCategory(getCategory(resultItem.categoryId)?.parentId)
          ?.permittedPath || ''}
        )
      </span>
    </>
  );

  return (
    <CustomListGroup
      borderForItems
      noTabIndexForListItems
      listItems={searchCategoryResult.map((r, index) => ({
        id: index.toString(),
        onClick: () => {
          navigate(
            getCategoryReferenceUrl(
              r.categoryId || '',
              r.articleIdsWithFoundTerms || undefined,
              foundTerms || undefined,
            ),
            { replace: true },
          );
        },
        content: getResultItem(r),
      }))}
    />
  );
}

export default SearchCategoryResult;
