import { Mark, SourceContentArea } from '../../../redux/store/api/api';
import {
  contentReferenceClass,
  externalReferenceClass,
  getNodeIgnoreFiberNodes,
  isFiberNode,
  isMarkOrLink,
  isTextNode,
} from './nodes';

const getNextElementIgnoreMarksAndLinks = (node: Node): Node => {
  const nextSibling = getNodeIgnoreFiberNodes(node, 'nextSibling');
  if (!nextSibling) {
    return node;
  }

  if (isMarkOrLink(nextSibling) || isTextNode(nextSibling)) {
    return getNextElementIgnoreMarksAndLinks(nextSibling);
  }

  return nextSibling;
};

const findContainer = (
  doc: Document,
  containerId: string,
  nodePath: number[],
): Node | null => {
  let currentNode: Node | null = doc.getElementById(containerId) as Node;

  // For each level find the node to walk past on the path down to the node
  nodePath.forEach((number) => {
    if (!currentNode) {
      return;
    }

    const currentEl = currentNode as Element;
    let hasMarksOrLinks = false;

    try {
      hasMarksOrLinks =
        currentEl.getElementsByTagName('mark').length > 0 ||
        currentEl.getElementsByTagName('a').length > 0 ||
        currentEl.querySelectorAll(
          `.${contentReferenceClass}, .${externalReferenceClass}`,
        ).length > 0;
    } catch {
      hasMarksOrLinks = true;
    }

    if (hasMarksOrLinks) {
      currentNode = getNodeIgnoreFiberNodes(currentNode, 'firstChild');

      for (let i = 1; i < number; i += 1) {
        if (!currentNode) {
          return;
        }

        if (isMarkOrLink(currentNode) || isTextNode(currentNode)) {
          currentNode = getNextElementIgnoreMarksAndLinks(currentNode);
        } else {
          currentNode = getNodeIgnoreFiberNodes(currentNode, 'nextSibling');
        }
      }
    } else {
      const currentChildNodes = Array.from(
        currentNode?.childNodes || [],
      ).filter((node) => !isFiberNode(node));
      currentNode = currentChildNodes[number - 1];
    }
  });

  return currentNode || null;
};

const findOffsetAndUpdateNode = (
  node: Node,
  offset: number,
): { node: Node; offset: number } | undefined => {
  const nodeTextLength = node?.textContent?.length || 0;

  if (nodeTextLength >= offset) {
    if (isTextNode(node)) {
      return { node, offset };
    }
    return { node: node.firstChild || node, offset };
  }

  const nextNode = getNodeIgnoreFiberNodes(node, 'nextSibling');
  const newOffset = offset - nodeTextLength;

  return nextNode
    ? findOffsetAndUpdateNode(nextNode, newOffset)
    : { node, offset: newOffset };
};

const convertToRange = (
  doc: Document,
  containerId: string,
  contentAreaObject: Mark | SourceContentArea,
): Range | undefined => {
  let startDataJSON;
  let endDataJSON;

  try {
    startDataJSON = JSON.parse(contentAreaObject?.startNodeData || '');
    endDataJSON = JSON.parse(contentAreaObject?.endNodeData || '');
  } catch {
    return undefined;
  }

  const startNode = findContainer(
    doc,
    containerId,
    [...startDataJSON.nodePath].reverse(),
  );
  const endNode = findContainer(
    doc,
    containerId,
    [...endDataJSON.nodePath].reverse(),
  );
  if (!startNode || !endNode) {
    return undefined;
  }

  const startData = findOffsetAndUpdateNode(startNode, startDataJSON.offset);
  const endData = findOffsetAndUpdateNode(endNode, endDataJSON.offset);
  if (!startData || !endData) {
    return undefined;
  }

  const range: Range = document.createRange();
  try {
    range.setStart(startData.node, startData.offset);
    range.setEnd(endData.node, endData.offset);
  } catch {
    return undefined;
  }

  if (contentAreaObject.patternString !== range.toString()) {
    return undefined;
  }

  return range;
};

export default convertToRange;
