import { useEffect, useState } from 'react';
import { useLocalStorage, useSessionStorage } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';
import { Alert, Button } from 'react-bootstrap';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import AccordionItem from '../accordion/AccordionItem';
import CustomAccordion from '../accordion/CustomAccordion';
import NoteList from './NoteList';
import { defaultVisibilityGroup, visibilityGroups } from './variables';
import { RightKey, NoteVisibilityType } from '../../shared/enums';
import { ExtendedNote, VisibilityGroupString } from './types';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectActiveNote,
  selectActiveVersion,
  selectExpandedNoteVisibilityGroup,
  selectMarkContentIsProcessing,
  selectNoteData,
  selectShowMakeMarkSelectionInfo,
  selectVersionContentIsLoading,
  setExpandedNoteVisibilityGroup,
  setShowMakeMarkSelectionInfo,
} from '../../redux/store/content/slice';
import {
  Mark,
  Note,
  useGetApiNotesByVersionIdQuery,
} from '../../redux/store/api/api';
import {
  getNotesForAccordionList,
  getSortedNotesPropertyByVisibilityGroup,
} from './functions';
import './Notes.scss';
import useGetCategoryByArticleId from '../../hooks/useGetCategoryByArticleId';
import {
  EDIT_MODE_KEY,
  MARK_VISIBILITY_GROUP_KEY,
  VERSION_CONTENT_CONTAINER_ID,
} from '../../shared/constants';
import testForIntersection from '../version/functions/testForIntersection';
import { getRange } from '../version/functions/validateRange';

import CreateOrUpdateNoteDialog from './dialogs/CreateOrUpdateNoteDialog';
import IntersectionDetectedDialog from '../version/dialogs/version-content/IntersectionDetectedDialog';
import convertFromRange from '../version/functions/convertFromRange';
import ShowNoteDialog from './dialogs/ShowNoteDialog';
import ShowNotePopover from './ShowNotePopover';
import DeleteNoteDialog from './dialogs/DeleteNoteDialog';
import AddMarkToNoteDialog from '../marks/dialogs/AddMarkToNoteDialog';
import VisibilitiesNotMatchingDialog from '../marks/dialogs/VisibilitiesNotMatchingDialog';
import NoValidSelectionDialog from '../marks/dialogs/NoValidSelectionDialog';
import CreateMarkBookmarkDialog from '../marks/dialogs/CreateMarkBookmarkDialog';
import DeleteMarkFromNoteDialog from '../marks/dialogs/DeleteMarkFromNoteDialog';
import MarkNeedsActionDialog from '../marks/dialogs/MarkNeedsActionDialog';
import MoveNoteDialog from './dialogs/MoveNoteDialog';

function NotesAccordion(): JSX.Element {
  const { t: translation } = useTranslation('wisentro');
  const dispatch = useAppDispatch();
  const version = useAppSelector(selectActiveVersion);
  const noteData = useAppSelector(selectNoteData);
  const markContentIsProcessing = useAppSelector(selectMarkContentIsProcessing);
  const versionContentIsLoading = useAppSelector(selectVersionContentIsLoading);
  const activeKey = useAppSelector(selectExpandedNoteVisibilityGroup);
  const setActiveKey = (key: string): void => {
    dispatch(setExpandedNoteVisibilityGroup(key));
  };
  const [activeMarkVisibilityGroup, setActiveMarkVisibilityGroup] =
    useSessionStorage<VisibilityGroupString | null>(
      MARK_VISIBILITY_GROUP_KEY,
      null,
    );
  const [newMark, setNewMark] = useState<Mark | undefined>(undefined);
  const [newNote, setNewNote] = useState<Note | undefined>(undefined);
  const activeNote = useAppSelector(selectActiveNote);
  const [moveNoteIsStarted, setMoveNoteIsStarted] = useState(false);
  const [noteToMove, setNoteToMove] = useState<ExtendedNote>({});
  const [targetNotePosition, setTargetNotePosition] = useState<number | null>(
    null,
  );
  const [moveNoteDialogOpened, setMoveNoteDialogOpened] =
    useState<boolean>(false);
  const [showCreateNoteDialogOpened, setShowCreateNoteDialogOpened] =
    useState<boolean>(false);
  const [
    intersectionDetectedDialogOpened,
    setIntersectionDetectedDialogOpened,
  ] = useState<boolean>(false);
  const [showNoteDialogOpened, setShowNoteDialogOpened] =
    useState<boolean>(false);
  const [updateNoteDialogOpened, setUpdateNoteDialogOpened] =
    useState<boolean>(false);
  const [deleteNoteDialogOpened, setDeleteNoteDialogOpened] =
    useState<boolean>(false);
  const [addMarkDialogOpened, setAddMarkDialogOpened] =
    useState<boolean>(false);
  const [deleteMarkDialogOpened, setDeleteMarkDialogOpened] =
    useState<boolean>(false);
  const [
    visibilitiesNotMatchingDialogOpened,
    setVisibilitiesNotMatchingDialogOpened,
  ] = useState<boolean>(false);
  const [noValidSelectionDialogOpened, setNoValidSelectionDialogOpened] =
    useState<boolean>(false);
  const [markNeedsActionDialogOpened, setMarkNeedsActionDialogOpened] =
    useState<boolean>(false);
  const showMakeMarkSelectionInfo = useAppSelector(
    selectShowMakeMarkSelectionInfo,
  );

  const category = useGetCategoryByArticleId(version.articleId);
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);
  // permissions
  const userHasAccessToUserNotes =
    category?.permittedActions?.includes(
      RightKey.RightNotesManagementCreateEditDeleteNote,
    ) || false;
  const userCanCreateUserGroupNotes =
    (category?.permittedActions?.includes(
      RightKey.RightNotesManagementCreateGroupNote,
    ) ||
      false) &&
    editModeIsActive;
  const userCanCreateGlobalNotes =
    (category?.permittedActions?.includes(
      RightKey.RightNotesManagementCreateOrganizationNote,
    ) ||
      false) &&
    editModeIsActive;
  const userCanCreateNotes =
    userHasAccessToUserNotes ||
    userCanCreateUserGroupNotes ||
    userCanCreateGlobalNotes ||
    false;
  const userCanReadUserGroupNotes =
    category?.permittedActions?.includes(
      RightKey.RightNotesManagementReadGroupNote,
    ) || false;
  const userCanEditUserGroupNotes =
    (category?.permittedActions?.includes(
      RightKey.RightNotesManagementEditGroupNote,
    ) ||
      false) &&
    editModeIsActive;
  const userCouldEditUserGroupNotes =
    category?.permittedActions?.includes(
      RightKey.RightNotesManagementEditGroupNote,
    ) || false;
  const userCanDeleteUserGroupNotes =
    (category?.permittedActions?.includes(
      RightKey.RightNotesManagementDeleteGroupNote,
    ) ||
      false) &&
    editModeIsActive;
  const userCanReadOrganizationNote =
    category?.permittedActions?.includes(
      RightKey.RightNotesManagementReadOrganizationNote,
    ) || false;
  const userCanEditGlobalNotes =
    (category?.permittedActions?.includes(
      RightKey.RightNotesManagementEditOrganizationNote,
    ) ||
      false) &&
    editModeIsActive;
  const userCouldEditGlobalNotes =
    category?.permittedActions?.includes(
      RightKey.RightNotesManagementEditOrganizationNote,
    ) || false;
  const userCanDeleteGlobalNotes =
    (category?.permittedActions?.includes(
      RightKey.RightNotesManagementDeleteOrganizationNote,
    ) ||
      false) &&
    editModeIsActive;
  const userHasAccessToContextActions =
    userHasAccessToUserNotes ||
    userCanEditUserGroupNotes ||
    userCanDeleteUserGroupNotes ||
    userCanEditGlobalNotes ||
    userCanDeleteGlobalNotes;

  const getUserCanDeleteNote = (note: Note) =>
    (note.visibility === NoteVisibilityType.User && userHasAccessToUserNotes) ||
    (note.visibility === NoteVisibilityType.UserGroup &&
      userCanDeleteUserGroupNotes) ||
    (note.visibility === NoteVisibilityType.General &&
      userCanDeleteGlobalNotes);

  const getUserCanEditNote = (note: Note) =>
    (note.visibility === NoteVisibilityType.User && userHasAccessToUserNotes) ||
    (note.visibility === NoteVisibilityType.UserGroup &&
      userCanEditUserGroupNotes) ||
    (note.visibility === NoteVisibilityType.General && userCanEditGlobalNotes);

  const getUserCouldEditNote = (note: Note) =>
    (note.visibility === NoteVisibilityType.UserGroup &&
      userCouldEditUserGroupNotes) ||
    (note.visibility === NoteVisibilityType.General &&
      userCouldEditGlobalNotes);

  const getUserCanReadNotesByVisibility = (vG: NoteVisibilityType) => {
    if (vG === NoteVisibilityType.User && !userHasAccessToUserNotes) {
      return false;
    }
    if (vG === NoteVisibilityType.UserGroup && !userCanReadUserGroupNotes) {
      return false;
    }
    if (vG === NoteVisibilityType.General && !userCanReadOrganizationNote) {
      return false;
    }
    return true;
  };

  const getUserCanCreateNotesByVisibility = (vG: NoteVisibilityType) => {
    if (vG === NoteVisibilityType.User && !userHasAccessToUserNotes) {
      return false;
    }
    if (vG === NoteVisibilityType.UserGroup && !userCanCreateUserGroupNotes) {
      return false;
    }
    if (vG === NoteVisibilityType.General && !userCanCreateGlobalNotes) {
      return false;
    }
    return true;
  };

  const getMarkIsError = (note: ExtendedNote): boolean =>
    note.mark?.markIsError || false;

  const { isFetching } = useGetApiNotesByVersionIdQuery(
    version.id
      ? {
          versionId: version.id,
        }
      : skipToken,
  );

  const selection = window.getSelection();

  const handleAddNote = (vG: NoteVisibilityType) => {
    setNewMark(undefined);
    setNewNote({ visibility: vG });
    setActiveKey(vG.toString());
    setShowCreateNoteDialogOpened(true);
  };

  const processSelection = () => {
    const range = getRange(selection, VERSION_CONTENT_CONTAINER_ID);

    if (!range) {
      return;
    }

    dispatch(setShowMakeMarkSelectionInfo(false));

    if (testForIntersection(range, VERSION_CONTENT_CONTAINER_ID, 'mark')) {
      setIntersectionDetectedDialogOpened(true);

      return;
    }

    setNewMark(convertFromRange(range, VERSION_CONTENT_CONTAINER_ID, document));
    setNewNote({
      visibility: activeMarkVisibilityGroup
        ? NoteVisibilityType[activeMarkVisibilityGroup]
        : defaultVisibilityGroup,
    });

    setShowCreateNoteDialogOpened(true);
  };

  const handleAddNoteWithMark = (vG: NoteVisibilityType) => {
    const selectionIsValid = selection && !selection.isCollapsed;
    if (activeMarkVisibilityGroup !== NoteVisibilityType[vG]) {
      setActiveMarkVisibilityGroup(
        NoteVisibilityType[vG] as VisibilityGroupString,
      );
      if (selectionIsValid) {
        selection?.collapseToStart();
      }
      dispatch(setShowMakeMarkSelectionInfo(true));

      return;
    }

    if (!selectionIsValid) {
      dispatch(setShowMakeMarkSelectionInfo(true));

      return;
    }

    setActiveKey(vG.toString());
    processSelection();
  };

  const getTitleExtention = (noteType: NoteVisibilityType): string => {
    const vGString = NoteVisibilityType[noteType] as VisibilityGroupString;
    const noteDataProp = getSortedNotesPropertyByVisibilityGroup(vGString);
    if (noteDataProp) {
      const noteDataCount = noteData
        ? getNotesForAccordionList(noteData[`${noteDataProp}`] || []).length
        : 0;

      return ` (${noteDataCount})`;
    }

    return ' (0)';
  };

  const getNotesByVisibilityGroup = (
    noteType: NoteVisibilityType,
  ): ExtendedNote[] => {
    const vGString = NoteVisibilityType[noteType] as VisibilityGroupString;
    const noteDataProp = getSortedNotesPropertyByVisibilityGroup(vGString);

    if (!noteDataProp || !noteData) {
      return [];
    }

    return noteData[`${noteDataProp}`] || [];
  };

  const noNotesToShow = (el?: VisibilityGroupString) => {
    const userNotesEmpty = noteData?.userNotes?.length === 0;
    const userGroupNotesEmpty = noteData?.userGroupNotes?.length === 0;
    const generalNotesEmpty = noteData?.generalNotes?.length === 0;

    switch (el) {
      case 'User':
        return userNotesEmpty;
      case 'UserGroup':
        return userGroupNotesEmpty;
      case 'General':
        return generalNotesEmpty;
      default:
        return userNotesEmpty && userGroupNotesEmpty && generalNotesEmpty;
    }
  };

  const getAccordionTitle = (
    visibilityGroup: NoteVisibilityType,
  ): JSX.Element => {
    let noteMarkerClass = '';

    switch (visibilityGroup) {
      case NoteVisibilityType.User:
        noteMarkerClass = 'note-user-marker';
        break;
      case NoteVisibilityType.UserGroup:
        noteMarkerClass = 'note-group-marker';
        break;
      case NoteVisibilityType.General:
        noteMarkerClass = 'note-global-marker';
        break;
      default:
        break;
    }

    return (
      <div className='d-flex align-items-center'>
        <div className={`me-2 mt-1 note-marker ${noteMarkerClass}`} />
        <span>{`${translation(
          `visibilityGroup${NoteVisibilityType[visibilityGroup]}`,
        )}${getTitleExtention(visibilityGroup)}`}</span>
      </div>
    );
  };

  const getUnderLineColorClass = (
    visibilityGroup: NoteVisibilityType,
  ): string => {
    switch (visibilityGroup) {
      case NoteVisibilityType.User:
        return 'user-mark-underline';
      case NoteVisibilityType.UserGroup:
        return 'group-mark-underline';
      case NoteVisibilityType.General:
        return 'global-mark-underline';
      default:
        return '';
    }
  };

  useEffect(() => {
    const onMouseUpOrEnter = (e: MouseEvent | KeyboardEvent) => {
      if (
        selection &&
        !selection.isCollapsed &&
        (e.type === 'mouseup' || (e as KeyboardEvent).key === 'Enter')
      ) {
        processSelection();
      }
    };

    if (showMakeMarkSelectionInfo) {
      document.addEventListener('mouseup', onMouseUpOrEnter);
      document.addEventListener('keydown', onMouseUpOrEnter);
    }

    return () => {
      document.removeEventListener('mouseup', onMouseUpOrEnter);
      document.removeEventListener('keydown', onMouseUpOrEnter);
    };
  }, [showMakeMarkSelectionInfo]);

  useEffect(() => {
    if (!activeNote.id || !noteData) {
      return;
    }

    const noteListItem = document.getElementById(activeNote.id);

    if (noteListItem) {
      noteListItem.focus();
    }
  }, [activeNote, noteData]);

  return (
    <>
      {moveNoteIsStarted && (
        <Alert variant='warning'>
          <div className='d-flex flex-column text-center'>
            {translation('movingIsStarted')}
            <Button
              className='mt-2'
              onClick={() => {
                setMoveNoteIsStarted(false);
              }}
              variant='outline-dark'>
              {translation('cancelMove')}
            </Button>
          </div>
        </Alert>
      )}

      {(isFetching || markContentIsProcessing || versionContentIsLoading) && (
        <p>{translation('loaded')}</p>
      )}
      {!isFetching &&
        !markContentIsProcessing &&
        !versionContentIsLoading &&
        noteData && (
          <CustomAccordion
            defaultActiveKey={defaultVisibilityGroup.toString()}
            activeKey={activeKey}>
            {visibilityGroups
              .filter((el) => getUserCanReadNotesByVisibility(el))
              .map((el) => (
                <AccordionItem
                  key={el}
                  title={getAccordionTitle(el)}
                  activeKey={activeKey}
                  setActiveKey={setActiveKey}
                  eventKey={el.toString()}
                  actions={
                    getUserCanCreateNotesByVisibility(el)
                      ? [
                          {
                            name: translation('addNoteWithMark'),
                            iconClassName: 'icon-pencil',
                            underlineColorClass: getUnderLineColorClass(el),
                            onClick: () => {
                              handleAddNoteWithMark(el);
                            },
                          },
                          {
                            name: translation('addNote'),
                            iconClassName: 'icon-plus',
                            onClick: () => handleAddNote(el),
                          },
                        ]
                      : undefined
                  }>
                  <NoteList
                    notes={getNotesByVisibilityGroup(el)}
                    noNotesToShow={noNotesToShow(
                      NoteVisibilityType[el] as VisibilityGroupString,
                    )}
                    getUserCanDeleteNote={getUserCanDeleteNote}
                    getUserCanEditNote={getUserCanEditNote}
                    userHasAccessToContextActions={
                      userHasAccessToContextActions
                    }
                    setShowNoteDialogOpened={setShowNoteDialogOpened}
                    setUpdateNoteDialogOpened={setUpdateNoteDialogOpened}
                    setDeleteNoteDialogOpened={setDeleteNoteDialogOpened}
                    setAddMarkDialogOpened={setAddMarkDialogOpened}
                    setVisibilitiesNotMatchingDialogOpened={
                      setVisibilitiesNotMatchingDialogOpened
                    }
                    setNoValidSelectionDialogOpened={
                      setNoValidSelectionDialogOpened
                    }
                    setDeleteMarkDialogOpened={setDeleteMarkDialogOpened}
                    setMarkNeedsActionDialogOpened={
                      setMarkNeedsActionDialogOpened
                    }
                    setMoveNoteDialogOpened={setMoveNoteDialogOpened}
                    moveNoteIsStarted={moveNoteIsStarted}
                    setMoveNoteIsStarted={setMoveNoteIsStarted}
                    noteToMove={noteToMove}
                    setNoteToMove={setNoteToMove}
                    setTargetNotePosition={setTargetNotePosition}
                  />
                </AccordionItem>
              ))}
          </CustomAccordion>
        )}

      {userCanCreateNotes && (
        <>
          <CreateOrUpdateNoteDialog
            show={showCreateNoteDialogOpened}
            setShow={setShowCreateNoteDialogOpened}
            type={newNote ? 'create' : 'update'}
            note={newNote ?? activeNote}
            setNote={setNewNote}
            newMark={newMark}
            setNewMark={setNewMark}
          />
          <IntersectionDetectedDialog
            show={intersectionDetectedDialogOpened}
            setShow={setIntersectionDetectedDialogOpened}
            selection={selection}
            typeOfIntersectedElement='Mark'
          />
          <ShowNoteDialog
            show={showNoteDialogOpened}
            setShow={setShowNoteDialogOpened}
            showUpdateDialog={updateNoteDialogOpened}
            setShowUpdateDialog={setUpdateNoteDialogOpened}
          />
          <ShowNotePopover
            openEditNoteDialog={() => setShowCreateNoteDialogOpened(true)}
            openDeleteNoteDialog={() => setDeleteNoteDialogOpened(true)}
          />
          <DeleteNoteDialog
            show={deleteNoteDialogOpened}
            setShow={setDeleteNoteDialogOpened}
          />
          <AddMarkToNoteDialog
            show={addMarkDialogOpened}
            setShow={setAddMarkDialogOpened}
          />
          <VisibilitiesNotMatchingDialog
            show={visibilitiesNotMatchingDialogOpened}
            setShow={setVisibilitiesNotMatchingDialogOpened}
          />
          <NoValidSelectionDialog
            show={noValidSelectionDialogOpened}
            setShow={setNoValidSelectionDialogOpened}
          />
          <DeleteMarkFromNoteDialog
            show={deleteMarkDialogOpened}
            setShow={setDeleteMarkDialogOpened}
          />
          <MarkNeedsActionDialog
            show={markNeedsActionDialogOpened}
            setShow={setMarkNeedsActionDialogOpened}
            getUserCanDeleteNote={getUserCanDeleteNote}
            getUserCanEditNote={getUserCanEditNote}
            getUserCouldEditNote={getUserCouldEditNote}
            getMarkIsError={getMarkIsError}
          />
          <CreateMarkBookmarkDialog />
          <MoveNoteDialog
            show={moveNoteDialogOpened}
            setShow={setMoveNoteDialogOpened}
            setMoveNoteIsStarted={setMoveNoteIsStarted}
            note={noteToMove}
            targetPosition={targetNotePosition || 0}
            resetMoveParams={() => {
              setNoteToMove({});
              setTargetNotePosition(null);
            }}
          />
        </>
      )}
    </>
  );
}

export default NotesAccordion;
