import { VERSION_CONTENT_CONTAINER_ID } from '../../../shared/constants';
import { getAccessibleIconBtn, linkButtonClass } from './makeContent';

interface IAddButtonsToImportedLinksProps {
  iconClassName: string;
  description: string;
}

const addButtonsToImportedLinks = ({
  iconClassName,
  description,
}: IAddButtonsToImportedLinksProps) => {
  const contentContainer = document.getElementById(
    VERSION_CONTENT_CONTAINER_ID,
  );
  const importedRefs = Array.from(
    contentContainer?.querySelectorAll(
      'a[refnorm], a[refpara], a[refabs], a[refsatz], a[refnr]',
    ) || [],
  );

  importedRefs.forEach((ref) => {
    if (ref.nextElementSibling?.nodeName === 'BUTTON') {
      return;
    }

    const id = ref.id || '';
    const button = getAccessibleIconBtn({
      id,
      iconClassName,
      description,
    });
    ref.insertAdjacentElement('afterend', button);
  });
};

const removeButtonsFromImportedLinks = () => {
  const contentContainer = document.getElementById(
    VERSION_CONTENT_CONTAINER_ID,
  );
  const refButtons = Array.from(
    contentContainer?.querySelectorAll(`.${linkButtonClass}`) || [],
  );

  refButtons.forEach((btn) => {
    btn?.remove();
  });
};

export { addButtonsToImportedLinks, removeButtonsFromImportedLinks };
