import { useTranslation } from 'react-i18next';
import CustomDialog from '../../dialogs/CustomDialog';
import { usePostApiUsersInvalidMarksEmailMutation } from '../../../redux/store/api/api';
import { useAppDispatch } from '../../../redux/hooks';
import { addMessage, removeMessage } from '../../../redux/store/layout/slice';
import Loader from '../../loader/Loader';

interface ISendInvalidMarkEmailsDialogProps {
  dialogShow: boolean;
  setDialogShow: (show: boolean) => void;
}

function SendInvalidMarkEmailsDialog({
  dialogShow,
  setDialogShow,
}: ISendInvalidMarkEmailsDialogProps) {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const [sendInvalidMarkEmails, { isLoading }] =
    usePostApiUsersInvalidMarksEmailMutation();

  const handleSendInvalidMarkEmails = () => {
    dispatch(
      addMessage({
        id: 'SendInvalidMarkEmails',
        variant: 'info',
        messageKeyBody: translation('emailsAreSending'),
        timeInMs: 90000,
      }),
    );
    sendInvalidMarkEmails()
      .then(() => {
        setDialogShow(false);
        dispatch(removeMessage('SendInvalidMarkEmails'));
        dispatch(
          addMessage({
            id: 'SendInvalidMarkEmailsSuccess',
            variant: 'success',
            messageKeyBody: translation('sendInvalidMarkEmailsSuccess'),
          }),
        );
      })
      .catch(() => {
        dispatch(removeMessage('SendInvalidMarkEmails'));
        dispatch(
          addMessage({
            id: 'SendInvalidMarkEmailsError',
            variant: 'danger',
            messageKeyBody: translation('unknownError'),
          }),
        );
      });
  };

  return (
    <CustomDialog
      titleId='SendInvalidMarkEmailDialog'
      dialogTitle={translation('infoMailAboutInvalidMarks')}
      show={dialogShow}
      closeFunction={() => {
        setDialogShow(false);
      }}
      actionFunction={handleSendInvalidMarkEmails}
      closeTitle={translation('cancel')}
      actionTitle={translation('send')}
      actionButtonVariant='outline-danger'
      actionButtonDisabled={isLoading}>
      {isLoading && <Loader />}
      {!isLoading && <p>{translation('sendInvalidMarkEmailsToUser')}</p>}
    </CustomDialog>
  );
}

export default SendInvalidMarkEmailsDialog;
