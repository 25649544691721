import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../redux/hooks';
import {
  Article,
  Category,
  useDeleteApiRecycleBinEmptyMutation,
  useGetApiRecycleBinQuery,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';
import { clearExpandedRecycleBinCategories } from '../../../redux/store/content/slice';

interface IEmptyRecycleBinDialogProps {
  dialogShow: boolean;
  setDialogShow: (show: boolean) => void;
  setSelectedItems: (items: (Article | Category)[]) => void;
}

function EmptyRecycleBinDialog({
  dialogShow,
  setDialogShow,
  setSelectedItems,
}: IEmptyRecycleBinDialogProps) {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const { refetch } = useGetApiRecycleBinQuery();
  const [emptyRecycleBin, { isError, isLoading, error }] =
    useDeleteApiRecycleBinEmptyMutation();

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'EmptyRecycleBinError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  const handleEmptyRecycleBin = () => {
    emptyRecycleBin()
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'EmptyRecycleBinSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        setDialogShow(false);
        refetch();
        dispatch(clearExpandedRecycleBinCategories());
        setSelectedItems([]);
      });
  };

  return (
    <CustomDialog
      titleId='DeleteArticleDialog'
      dialogTitle={translation('emptyRecycleBin')}
      show={dialogShow}
      closeFunction={() => {
        setDialogShow(false);
      }}
      actionFunction={handleEmptyRecycleBin}
      closeTitle={translation('cancel')}
      actionTitle={translation('delete')}
      actionButtonVariant='outline-danger'
      actionButtonDisabled={isLoading}>
      <div aria-busy={isLoading}>
        {isLoading && <Loader />}
        {!isLoading && (
          <p>{translation('deleteAllElementsInRecycleBinPermanently')}</p>
        )}
      </div>
    </CustomDialog>
  );
}

export default EmptyRecycleBinDialog;
