import { ErrorBoundary } from 'react-error-boundary';
import ViewFallback from '../components/error-fallback/ViewFallback';
import SearchCards from '../components/search/SearchCards';

function Search(): JSX.Element {
  return (
    <ErrorBoundary fallback={<ViewFallback />}>
      <SearchCards />
    </ErrorBoundary>
  );
}

export default Search;
