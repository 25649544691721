import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../redux/hooks';
import { addMessage } from '../../redux/store/layout/slice';

function GlobalFallback() {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    dispatch(
      addMessage({
        id: 'GlobalError',
        messageKeyHeader: 'error',
        messageKeyBody: 'unknownError',
        variant: 'danger',
      }),
    );
  }, []);

  return <h2 className='fw-bold text-danger'>{translation('unknownError')}</h2>;
}

export default GlobalFallback;
