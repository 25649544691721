import { useEffect, useMemo, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useGetApiNotesByVersionIdQuery,
  usePutApiMarksMutation,
} from '../../../redux/store/api/api';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectActiveVersion,
  selectNoteData,
  setMarkContentIsProcessing,
  setNoteData,
} from '../../../redux/store/content/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import {
  CONTENT_EVALUATION_CYCLE_COUNT,
  MARK_VISIBILITY_GROUP_KEY,
} from '../../../shared/constants';
import { VisibilityGroupString } from '../../notes/types';
import { RefetchNoteQueryType } from '../types';
import MarkContent from './MarkContent';
import getContentAndNotIncludedMarks from '../functions/getContentAndNotIncludedMarks';

interface IMarkContentPreProcessorProps {
  content: string;
  refetchNotes: RefetchNoteQueryType;
}

function MarkContentPreProcessor({
  content,
  refetchNotes,
}: IMarkContentPreProcessorProps): JSX.Element | null {
  const dispatch = useAppDispatch();
  const version = useAppSelector(selectActiveVersion);
  const noteData = useAppSelector(selectNoteData);
  const [observedNoteData, setObservedNoteData] = useState(noteData);
  const [noteDataIsError, setNoteDataIsError] = useState(false);
  const [activeMarkVisibilityGroup] =
    useSessionStorage<VisibilityGroupString | null>(
      MARK_VISIBILITY_GROUP_KEY,
      null,
    );
  const [contentEvaluationCyleCount, setContentEvaluationCyleCount] =
    useSessionStorage<number>(CONTENT_EVALUATION_CYCLE_COUNT, 0);
  const userMarkContentData = useMemo(
    () =>
      getContentAndNotIncludedMarks(
        content,
        observedNoteData.userNotes?.filter((n) => n.mark) || [],
      ),
    [version, observedNoteData, content],
  );
  const userGroupMarkContentData = useMemo(
    () =>
      getContentAndNotIncludedMarks(
        content,
        observedNoteData.userGroupNotes?.filter((n) => n.mark) || [],
      ),
    [version, observedNoteData, content],
  );
  const generalMarkContentData = useMemo(
    () =>
      getContentAndNotIncludedMarks(
        content,
        observedNoteData.generalNotes?.filter((n) => n.mark) || [],
      ),
    [version, observedNoteData, content],
  );

  const { isFetching } = useGetApiNotesByVersionIdQuery(
    version.id
      ? {
          versionId: version.id,
        }
      : skipToken,
  );
  const [
    updateMarks,
    { isError: updateMarksIsError, error: updateMarksError },
  ] = usePutApiMarksMutation();

  useEffect(() => {
    if (updateMarksIsError) {
      dispatch(
        addMessage({
          id: 'UpdateMarkError',
          variant: 'danger',
          messageKeyBody:
            updateMarksError && 'data' in updateMarksError
              ? updateMarksError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [updateMarksIsError]);

  // content evaluation should only be triggered when a version is newly displayed
  useEffect(() => {
    setContentEvaluationCyleCount(0);
  }, [version]);

  // reset evaluation count when version is currently edited
  useEffect(
    () => () => {
      setContentEvaluationCyleCount(0);
    },
    [],
  );

  // changes to noteData need to be observed but should not trigger recalculation if there are valid marks that do not appear in content
  useEffect(() => {
    if (!noteDataIsError) {
      setObservedNoteData(noteData);
    }
  }, [noteData]);

  useEffect(() => {
    // if the notes do not belong to the version return
    if (version.id !== noteData.versionId) {
      return;
    }

    const marksToUpdate = [
      ...userMarkContentData.marks,
      ...userGroupMarkContentData.marks,
      ...generalMarkContentData.marks,
    ];

    if (marksToUpdate.length > 0 && contentEvaluationCyleCount < 2) {
      updateMarks({ body: marksToUpdate }).then(() => {
        refetchNotes().then(() => {
          setContentEvaluationCyleCount((prev) => prev + 1);
        });
      });
    }

    // if marks are not correctly updated after content evaluation (mark flagged as valid but not present in content) only update noteData
    if (marksToUpdate.length > 0 && contentEvaluationCyleCount >= 2) {
      setNoteDataIsError(true);
      const errorMarkIds = marksToUpdate.map((m) => m.id);
      dispatch(
        setNoteData({
          ...noteData,
          userNotes: noteData.userNotes?.map((n) =>
            errorMarkIds.includes(n.mark?.id)
              ? { ...n, mark: { ...n.mark, markIsError: true } }
              : n,
          ),
          userGroupNotes: noteData.userGroupNotes?.map((n) =>
            errorMarkIds.includes(n.mark?.id)
              ? { ...n, mark: { ...n.mark, markIsError: true } }
              : n,
          ),
          generalNotes: noteData.generalNotes?.map((n) =>
            errorMarkIds.includes(n.mark?.id)
              ? { ...n, mark: { ...n.mark, markIsError: true } }
              : n,
          ),
        }),
      );
    }

    dispatch(setMarkContentIsProcessing(false));
  }, [
    userMarkContentData.marks,
    userGroupMarkContentData.marks,
    generalMarkContentData.marks,
  ]);

  return activeMarkVisibilityGroup !== null && !isFetching ? (
    <>
      {activeMarkVisibilityGroup === 'User' && (
        <MarkContent content={userMarkContentData.content} />
      )}
      {activeMarkVisibilityGroup === 'UserGroup' && (
        <MarkContent content={userGroupMarkContentData.content} />
      )}
      {activeMarkVisibilityGroup === 'General' && (
        <MarkContent content={generalMarkContentData.content} />
      )}
    </>
  ) : null;
}

export default MarkContentPreProcessor;
