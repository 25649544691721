import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiRolesContextQuery,
  useGetApiUserGroupsQuery,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import {
  clearSelectedUsers,
  selectContextUserGroup,
  selectUserGroupPermissionsDialogOpened,
  setUserGroupPermissionsDialogOpened,
} from '../../../redux/store/user-management/slice';
import { PermissionContext } from '../../../shared/enums';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';
import UserGroupPermissionsTree from '../user-group-permissions/UserGroupPermissionsTree';

interface IUserGroupPermissionsDialogProps {
  setLastUpdatedItem: (updatedId: string) => void;
}

function UserGroupPermissionsDialog({
  setLastUpdatedItem,
}: IUserGroupPermissionsDialogProps) {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const userGroup = useAppSelector(selectContextUserGroup);
  const dialogShow = useAppSelector(selectUserGroupPermissionsDialogOpened);
  const [selectedRole, setSelectedRole] = useState<string>('0');
  const {
    data: roles,
    isFetching,
    isError,
    error,
  } = useGetApiRolesContextQuery(
    dialogShow && userGroup.id
      ? {
          context: PermissionContext.UserGroup,
          userGroupId: userGroup.id,
        }
      : skipToken,
    { refetchOnMountOrArgChange: true },
  );
  const { refetch: refetchGetUserGroups } = useGetApiUserGroupsQuery({
    includeUsers: true,
    includePermittedActions: true,
  });
  const rolesInUse = roles?.resultObject?.filter((r) => r.usedInContext) || [];

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetRolesError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  return (
    <CustomDialog
      titleId='UserGroupPermissionsDialog'
      dialogTitle={translation('userGroupPermissions')}
      show={dialogShow}
      closeFunction={() => {
        dispatch(setUserGroupPermissionsDialogOpened(false));
        setSelectedRole('0');
        refetchGetUserGroups();
        dispatch(clearSelectedUsers());
        setLastUpdatedItem(userGroup.id ?? '');
      }}
      closeTitle={translation('close')}>
      {isFetching && <Loader />}
      {!isFetching && roles && (
        <>
          <p className='fw-bold'>
            {`${translation('editRightsForFollowingGroup')} ${userGroup.name}`}
          </p>
          <p className='m-0'>{translation('rolesThatAreInUse')}</p>
          {rolesInUse.length === 0 && <p>{translation('noRolesInUse')}</p>}
          {rolesInUse.length > 0 && (
            <ul>
              {rolesInUse.map((r) => (
                <li key={r.id || ''}>{r.name}</li>
              ))}
            </ul>
          )}
          <Form>
            <Form.Group className='mb-3' controlId='RoleForUserGroupPermission'>
              <Form.Label>{translation('selectRole')}</Form.Label>
              <Form.Select
                required
                value={selectedRole}
                onChange={(e) => {
                  setSelectedRole(e.target.value);
                }}>
                <option value='0' disabled hidden>
                  {translation('role')}
                </option>
                {roles.resultObject?.map((role) => (
                  <option key={role.id} value={role.id || ''}>
                    {role.name}
                    {role.usedInContext ? ` - (${translation('inUse')})` : ''}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Form>
          {selectedRole !== '0' && (
            <UserGroupPermissionsTree roleId={selectedRole} />
          )}
        </>
      )}
    </CustomDialog>
  );
}

export default UserGroupPermissionsDialog;
