import parse from 'html-react-parser';
import Loader from '../loader/Loader';
import { settingTypeFaqContent } from '../../shared/constants';
import { useGetApiSettingsUserQuery } from '../../redux/store/api/api';

function FaqContent(): JSX.Element {
  const { data: settings, isFetching } = useGetApiSettingsUserQuery();
  const faqSetting = settings?.resultObject?.find(
    (s) => s.settingType?.key === settingTypeFaqContent,
  );

  return (
    <>
      {isFetching && <Loader />}
      {!isFetching && faqSetting?.value && <div>{parse(faqSetting.value)}</div>}
    </>
  );
}

export default FaqContent;
