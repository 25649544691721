import { skipToken } from '@reduxjs/toolkit/dist/query';
import { PresenceType } from 'react-idle-timer';
import { useNavigate } from 'react-router';
import { useLocalStorage } from 'usehooks-ts';
import { useGetApiSettingsUserBySettingTypeKeyQuery } from '../../redux/store/api/api';
import {
  EDIT_MODE_KEY,
  settingTypeClientSessionTimeoutInterval,
  settingTypeSessionEditModeTimeoutInterval,
} from '../../shared/constants';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import IdleTimer from './IdleTimer';

interface IAppIdleTimeProps {
  isAuthenticated: boolean;
}

function AppIdleTimer({ isAuthenticated }: IAppIdleTimeProps): JSX.Element {
  const navigate = useNavigate();
  const [editModeIsActive, setEditModeIsActive] = useLocalStorage<boolean>(
    EDIT_MODE_KEY,
    false,
  );

  const {
    isFetching: isFetchingClientSessionTimeout,
    data: dataClientSessionTimeout,
  } = useGetApiSettingsUserBySettingTypeKeyQuery(
    isAuthenticated
      ? {
          settingTypeKey: settingTypeClientSessionTimeoutInterval,
        }
      : skipToken,
  );
  const {
    isFetching: isFetchingSessionEditModeTimeout,
    data: dataSessionEditModeTimeout,
  } = useGetApiSettingsUserBySettingTypeKeyQuery(
    isAuthenticated
      ? {
          settingTypeKey: settingTypeSessionEditModeTimeoutInterval,
        }
      : skipToken,
  );

  const onPresenceChange = (
    presence: PresenceType,
    isSessionTimeout: boolean,
  ): void => {
    if (presence.type === 'idle') {
      if (isSessionTimeout) {
        navigate(ApplicationPaths.LogOut, { replace: true });
      } else if (editModeIsActive) {
        setEditModeIsActive(false);
      }
    }
  };

  const getMinutes = (strNumber: string): number | undefined => {
    const decimalBase = 10;
    const minutesData = parseInt(strNumber, decimalBase);
    if (!Number.isNaN(minutesData)) {
      return minutesData;
    }
    return undefined;
  };

  let clientTimeoutInMinutes: number | undefined;
  let sessionEditModeTimeoutInMinutes: number | undefined;

  if (
    !isFetchingClientSessionTimeout &&
    dataClientSessionTimeout?.resultObject?.value
  ) {
    clientTimeoutInMinutes = getMinutes(
      dataClientSessionTimeout.resultObject.value,
    );
  }

  if (
    !isFetchingSessionEditModeTimeout &&
    dataSessionEditModeTimeout?.resultObject?.value
  ) {
    sessionEditModeTimeoutInMinutes = getMinutes(
      dataSessionEditModeTimeout.resultObject.value,
    );
  }

  return (
    <>
      {!isFetchingClientSessionTimeout && (
        <IdleTimer
          timeoutInMinutes={clientTimeoutInMinutes}
          onPresenceChange={(presenceType) =>
            onPresenceChange(presenceType, true)
          }
        />
      )}
      {!isFetchingSessionEditModeTimeout && sessionEditModeTimeoutInMinutes && (
        <IdleTimer
          timeoutInMinutes={sessionEditModeTimeoutInMinutes}
          onPresenceChange={(presenceType) =>
            onPresenceChange(presenceType, false)
          }
        />
      )}
    </>
  );
}

export default AppIdleTimer;
