import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ArticleAttachmentsContainer from '../components/article/ArticleAttachmentsContainer';
import EditVersionForm from '../components/version/EditVersionForm';
import useGetCategoryByArticleId from '../hooks/useGetCategoryByArticleId';
import { useAppSelector } from '../redux/hooks';
import { selectActiveVersion } from '../redux/store/content/slice';
import ViewFallback from '../components/error-fallback/ViewFallback';

function EditArticle(): JSX.Element {
  const version = useAppSelector(selectActiveVersion);
  const category = useGetCategoryByArticleId(version.articleId);

  useEffect(() => {
    document.getElementsByTagName('h1')[0].focus();
  }, []);

  return (
    <div className='article-view article-edit-container'>
      <ErrorBoundary fallback={<ViewFallback />}>
        {category?.permittedPath && (
          <div className='category-path-container'>
            <p className='mb-1 text-break'>{category.permittedPath}</p>
          </div>
        )}
        <div className='content-inner-article'>
          <EditVersionForm />
          <ArticleAttachmentsContainer />
        </div>
      </ErrorBoundary>
    </div>
  );
}

export default EditArticle;
