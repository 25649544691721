import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiUserGroupsQuery,
  usePostApiUsersImportMutation,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import {
  addExpandedUserGroup,
  clearSelectedUsers,
  selectContextUserGroup,
  selectUserImportDialogOpened,
  setUserImportDialogOpened,
} from '../../../redux/store/user-management/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import FileInput from '../../inputs/FileInput';
import Loader from '../../loader/Loader';

interface IUserImportDialogProps {
  setLastUpdatedItem: (updatedId: string) => void;
}

function UserImportDialog({
  setLastUpdatedItem,
}: IUserImportDialogProps): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const userGroup = useAppSelector(selectContextUserGroup);
  const dialogShow = useAppSelector(selectUserImportDialogOpened);
  const [file, setFile] = useState<File | null>(null);
  const [fileIsValid, setFileIsValid] = useState(true);
  const { refetch } = useGetApiUserGroupsQuery({
    includeUsers: true,
    includePermittedActions: true,
  });
  const [importUser, { isError, isLoading, error }] =
    usePostApiUsersImportMutation();

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'ImportUsersError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
      setFile(null);
      refetch();
      dispatch(setUserImportDialogOpened(false));
      dispatch(addExpandedUserGroup(userGroup.id || ''));
      dispatch(clearSelectedUsers());
    }
  }, [isError]);

  const handleImportUser = () => {
    if (!file) {
      setFileIsValid(false);
      return;
    }

    const formData = new FormData();
    formData.append('importFile', file);
    importUser({
      userGroupId: userGroup.id ?? '',
      body: formData as unknown as { importFile: Blob },
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'ImportUsersSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        setFile(null);
        refetch();
        dispatch(setUserImportDialogOpened(false));
        dispatch(addExpandedUserGroup(userGroup.id ?? ''));
        dispatch(clearSelectedUsers());
        setLastUpdatedItem(userGroup.id ?? '');
      });
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setFileIsValid(true);
    }
  };

  return (
    <CustomDialog
      titleId='UserImportDialog'
      actionButtonDisabled={isLoading}
      dialogTitle={translation('importUser')}
      show={dialogShow}
      closeFunction={() => {
        setFile(null);
        dispatch(setUserImportDialogOpened(false));
      }}
      actionFunction={handleImportUser}
      closeTitle={translation('cancel')}
      actionTitle={translation('import')}>
      <div aria-busy={isLoading}>
        {isLoading && <Loader />}
        {!isLoading && (
          <FileInput
            required
            isValid={fileIsValid}
            labelName={`${translation('selectImportFile')}*`}
            onChange={handleOnChange}
            accept='.csv,text/csv'
            files={file ? [file] : null}
            invalidMessage={translation('fieldNotEmpty')}
            onClearFiles={() => {
              setFileIsValid(true);
            }}
          />
        )}
      </div>
    </CustomDialog>
  );
}

export default UserImportDialog;
