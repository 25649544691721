import { useEffect } from 'react';
import Loader from '../loader/Loader';

interface IChangePasswordProps {
  action: string;
}

function ChangePassword({ action }: IChangePasswordProps): JSX.Element {
  const redirectToApiAuthorizationPath = (apiAuthorizationPath: string) => {
    const redirectUrl = `${window.location.origin}/${apiAuthorizationPath}`;
    window.location.replace(redirectUrl);
  };

  useEffect(() => {
    redirectToApiAuthorizationPath(action);
  }, []);

  return <Loader />;
}

export default ChangePassword;
