import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useDeleteApiUsersMutation,
  useGetApiUserGroupsQuery,
} from '../../../redux/store/api/api';
import {
  clearSelectedUsers,
  selectDeleteUserDialogOpened,
  selectSelectedUsers,
  setDeleteUserDialogOpened,
} from '../../../redux/store/user-management/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';

function DeleteUserDialog(): JSX.Element {
  const { t: translation } = useTranslation();
  const dialogShow = useAppSelector(selectDeleteUserDialogOpened);
  const currentSelectedUsers = useAppSelector(selectSelectedUsers);
  const dispatch = useAppDispatch();
  const [
    deleteUsers,
    { isError: deleteUsersIsError, isLoading, error: deleteUsersError },
  ] = useDeleteApiUsersMutation();
  const {
    refetch,
    isError: getUserGroupsIsError,
    error: getUserGroupsError,
  } = useGetApiUserGroupsQuery({
    includeUsers: true,
    includePermittedActions: true,
  });

  useEffect(() => {
    if (getUserGroupsIsError) {
      dispatch(
        addMessage({
          id: 'GetUserGroupError',
          variant: 'danger',
          messageKeyBody:
            getUserGroupsError && 'data' in getUserGroupsError
              ? getUserGroupsError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (deleteUsersIsError) {
      dispatch(
        addMessage({
          id: 'DeleteUsersError',
          variant: 'danger',
          messageKeyBody:
            deleteUsersError && 'data' in deleteUsersError
              ? deleteUsersError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [getUserGroupsIsError, deleteUsersIsError]);

  const handleDeleteUser = (): void => {
    deleteUsers({ body: currentSelectedUsers.map((u) => u.id || '') })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'DeleteUsersSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        dispatch(setDeleteUserDialogOpened(false));
        dispatch(clearSelectedUsers());
      });
  };

  return (
    <CustomDialog
      titleId='DeleteUserDialog'
      dialogTitle={translation('deleteUser')}
      show={dialogShow}
      closeFunction={() => {
        dispatch(setDeleteUserDialogOpened(false));
      }}
      actionFunction={handleDeleteUser}
      closeTitle={translation('cancel')}
      actionTitle={translation('delete')}
      actionButtonVariant='outline-danger'
      actionButtonDisabled={isLoading}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          {currentSelectedUsers.length === 1 && (
            <>
              <p>{translation('deleteUserPermanently')}</p>
              <p className='fw-bold'>
                {`${currentSelectedUsers[0].lastname}, ${currentSelectedUsers[0].firstname}`}
              </p>
            </>
          )}
          {currentSelectedUsers.length > 1 && (
            <>
              <p>{translation('deleteUsersPermanently')}</p>
              {currentSelectedUsers.map((u) => (
                <p
                  key={u.id}
                  className='fw-bold'>{`${u.lastname}, ${u.firstname}`}</p>
              ))}
            </>
          )}
        </>
      )}
    </CustomDialog>
  );
}

export default DeleteUserDialog;
