import { Mark } from '../../redux/store/api/api';

const getMarkAttributesValid = (mark: Mark) =>
  (mark.id?.length ?? 0) > 0 &&
  (mark.startNodeData?.length ?? 0) > 0 &&
  (mark.endNodeData?.length ?? 0) > 0 &&
  (mark.patternString?.length ?? 0) > 0 &&
  (mark.noteId?.length ?? 0) > 0;

const getValidatedMark = (updatedMark: Mark, oldMark: Mark) => {
  const markAttributesValid = getMarkAttributesValid(updatedMark);
  if (markAttributesValid) {
    return updatedMark;
  }
  return { ...oldMark, markInvalid: true };
};

export default getValidatedMark;
