import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useDeleteApiMarksByIdMutation,
  useGetApiNotesByVersionIdQuery,
} from '../../../redux/store/api/api';
import {
  selectActiveArticleReference,
  selectActiveVersion,
} from '../../../redux/store/content/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';

interface IDeleteSourceContentAreaDialogProps {
  show: boolean;
  setShow: (show: boolean) => void;
}

function DeleteSourceContentAreaDialog({
  show,
  setShow,
}: IDeleteSourceContentAreaDialogProps): JSX.Element {
  const { t: translation } = useTranslation();
  const reference = useAppSelector(selectActiveArticleReference);
  const version = useAppSelector(selectActiveVersion);
  const [
    deleteLink,
    { isError: deleteLinkIsError, isLoading, error: deleteLinkError },
  ] = useDeleteApiMarksByIdMutation();
  const {
    refetch,
    isError: getNotesIsError,
    error: getNotesError,
  } = useGetApiNotesByVersionIdQuery(
    version.id
      ? {
          versionId: version.id,
        }
      : skipToken,
  );
  const dispatch = useAppDispatch();

  const handleDeleteLink = (): void => {
    deleteLink({ id: reference?.sourceContentArea?.id || '' })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'DeleteSourceContentAreaSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        setShow(false);
        refetch();
      });
  };

  useEffect(() => {
    if (deleteLinkIsError) {
      dispatch(
        addMessage({
          id: 'DeleteSourceContentAreaError',
          variant: 'danger',
          messageKeyBody:
            deleteLinkError && 'data' in deleteLinkError
              ? deleteLinkError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (getNotesIsError) {
      dispatch(
        addMessage({
          id: 'GetNotesError',
          variant: 'danger',
          messageKeyBody:
            getNotesError && 'data' in getNotesError
              ? getNotesError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [deleteLinkIsError, getNotesIsError]);

  return (
    <CustomDialog
      titleId='DeleteLinkFromReferenceDialog'
      dialogTitle={translation('deleteLink')}
      show={show}
      closeFunction={() => setShow(false)}
      actionFunction={handleDeleteLink}
      closeTitle={translation('cancel')}
      actionTitle={translation('delete')}
      actionButtonVariant='outline-danger'
      actionButtonDisabled={isLoading}>
      {isLoading && <Loader />}
      {!isLoading && <p>{translation('deleteLinkPermanently')}</p>}
    </CustomDialog>
  );
}

export default DeleteSourceContentAreaDialog;
