import { SyntheticEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from 'usehooks-ts';

interface IAutoCompleteInputProps<T> {
  initialValue?: string;
  options: T[];
  onConfirm: (option: T) => void;
  getDisplayName: (option: T) => string;
  getId: (option: T) => string;
  getSearchString: (option: T) => string;
  controlId: string;
  label: string;
  valueMinLengthForSuggestion: number;
  clearInputAfterSelection?: boolean;
}

function AutoCompleteInput<T>({
  initialValue,
  options,
  onConfirm,
  getDisplayName,
  getId,
  getSearchString,
  controlId,
  label,
  valueMinLengthForSuggestion = 0,
  clearInputAfterSelection,
}: IAutoCompleteInputProps<T>): JSX.Element {
  const { t: translation } = useTranslation('wisentro');
  const [query, setQuery] = useState(initialValue || '');
  const [results, setResults] = useState<T[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const resultsListRef = useRef<HTMLUListElement>(null);

  useOnClickOutside(resultsListRef, (e) => {
    if ((e.target as HTMLElement).id !== 'AutocompleteInput') {
      setResults([]);
    }
  });

  const handleInputChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const input = (event.target as HTMLInputElement).value;
    setQuery(input);

    if (input.length >= valueMinLengthForSuggestion) {
      const matchingResults = options.filter((option) =>
        getSearchString(option).toLowerCase().includes(input.toLowerCase()),
      );
      setResults(matchingResults);
    } else {
      setResults([]);
    }
  };

  const handleOptionClick = (option: T) => {
    if (clearInputAfterSelection) {
      setQuery('');
    } else {
      setQuery(getDisplayName(option));
    }
    setResults([]);
    onConfirm(option);
    inputRef.current?.focus();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      (resultsListRef.current?.firstChild as HTMLLIElement).focus();
    }
  };

  const handleResultKeyDown = (
    event: React.KeyboardEvent<HTMLLIElement>,
    option: T,
  ) => {
    const result = results.find(
      (o) => JSON.stringify(o) === JSON.stringify(option),
    );
    const index = result ? results.indexOf(result) : 0;

    if (event.key === 'ArrowUp') {
      event.preventDefault();
      if (index === -1 || index === 0) {
        inputRef.current?.focus();
      } else {
        (
          resultsListRef.current?.childNodes[index - 1] as HTMLLIElement
        ).focus();
      }
    }

    if (event.key === 'ArrowDown') {
      event.preventDefault();
      if (index === -1) {
        (resultsListRef.current?.firstChild as HTMLLIElement).focus();
      } else if (index === results.length - 1) {
        inputRef.current?.focus();
      } else {
        (
          resultsListRef.current?.childNodes[index + 1] as HTMLLIElement
        ).focus();
      }
    }

    if (event.key === 'Enter' && index > -1) {
      event.preventDefault();
      handleOptionClick(results[index]);
    }
  };

  return (
    <div className='autocomplete-container'>
      <label htmlFor={controlId}>{label}</label>
      <input
        type='text'
        id={controlId}
        value={query}
        onClick={(e) => {
          handleInputChange(e);
        }}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        ref={inputRef}
        aria-controls='AutocompleteResults'
        aria-expanded={results.length > 0}
        aria-autocomplete='list'
        role='combobox'
        autoComplete='off'
        className='form-control mb-3'
      />
      {results.length > 0 && (
        <ul
          id='AutocompleteResults'
          className='autocomplete-results shadow-sm border border-light'
          ref={resultsListRef}
          role='listbox'
          aria-label={translation('searchResults')}>
          {results.map((option) => (
            <li
              key={getId(option)}
              onClick={() => handleOptionClick(option)}
              onKeyDown={(e) => handleResultKeyDown(e, option)}
              tabIndex={0}
              role='option'
              aria-selected={getDisplayName(option) === query}>
              {getDisplayName(option)}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

AutoCompleteInput.defaultProps = {
  initialValue: undefined,
  clearInputAfterSelection: false,
};

export default AutoCompleteInput;
