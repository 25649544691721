import { paddingOffsetForTreeElements } from '../../../shared/constants';
import { RightKey } from '../../../shared/enums';
import { IUserGroupTreeItem, IUserTreeItem } from '../types';
import UserGroupTreeElement from './UserGroupTreeElement';
import UserTreeElement from './UserTreeElement';

type TreeElement = IUserGroupTreeItem | IUserTreeItem;

interface IUserGroupTreeItemProps {
  level: number;
  isBranch: boolean;
  isExpanded: boolean | undefined;
  element: TreeElement;
  getNodeProps: Function;
  permittedActions: RightKey[];
}

function UserGroupTreeItem({
  level,
  isBranch,
  isExpanded,
  element,
  getNodeProps,
  permittedActions,
}: IUserGroupTreeItemProps): JSX.Element {
  const isUserElement: boolean = 'firstname' in element;
  const itemClass: string = isUserElement
    ? 'user-item child-item'
    : 'user-group-item parent-item';

  return (
    <div className={`li-inner ${itemClass}`}>
      <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getNodeProps()}
        id={element.id || undefined}
        style={{ paddingLeft: paddingOffsetForTreeElements * (level - 1) }}>
        {isUserElement && (
          <UserTreeElement
            userTreeElement={element as IUserTreeItem}
            permittedActions={permittedActions}
          />
        )}
        {!isUserElement && (
          <UserGroupTreeElement
            userGroupTreeElement={element as IUserGroupTreeItem}
            isBranch={isBranch}
            isExpanded={isExpanded || false}
            permittedActions={permittedActions}
          />
        )}
      </div>
    </div>
  );
}

export default UserGroupTreeItem;
