import { Form } from 'react-bootstrap';

interface ICheckboxProps {
  controlId: string;
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  readonly?: boolean;
}

function CustomCheckbox({
  controlId,
  label,
  checked,
  onChange,
  readonly,
}: ICheckboxProps): JSX.Element {
  return (
    <Form.Group controlId={controlId}>
      <Form.Check
        type='checkbox'
        label={label}
        checked={checked}
        readOnly={readonly}
        onChange={() => {
          const newCheckedState = !checked;
          onChange(newCheckedState);
        }}
      />
    </Form.Group>
  );
}

CustomCheckbox.defaultProps = { readonly: false };

export default CustomCheckbox;
