import { useTranslation } from 'react-i18next';
import {
  InvalidMarkVersion,
  useGetApiVersionsInvalidMarksQuery,
} from '../../redux/store/api/api';
import Loader from '../loader/Loader';
import CustomListGroup from '../lists/CustomListGroup';
import { getArticleUrl } from '../../shared/urlBuilder';
import { getVersionName } from '../../shared/utils';
import { NoteVisibilityType } from '../../shared/enums';

interface IIconInformationProps {
  iconColorClass: string;
  iconDescription: string;
}

function InvalidMarks() {
  const { t: translation } = useTranslation();
  const { data, isFetching } = useGetApiVersionsInvalidMarksQuery();

  const getIconInformation = (
    version: InvalidMarkVersion,
  ): IIconInformationProps => {
    if (
      version.noteVisibilityGroups?.some(
        (group) => group === NoteVisibilityType.UserGroup,
      )
    ) {
      return {
        iconColorClass: 'group-mark-version-icon',
        iconDescription: translation('versionWithInvalidGroupNote'),
      };
    }

    if (
      version.noteVisibilityGroups?.some(
        (group) => group === NoteVisibilityType.General,
      )
    ) {
      return {
        iconColorClass: 'general-mark-version-icon',
        iconDescription: translation('versionWithInvalidGeneralNote'),
      };
    }

    return {
      iconColorClass: 'user-mark-version-icon',
      iconDescription: translation('versionWithInvalidUserNote'),
    };
  };

  return (
    <div className='pt-1'>
      {isFetching && <Loader />}
      {!isFetching && (data?.resultObject?.length || 0) === 0 && (
        <p>{translation('noInvalidMarks')}</p>
      )}
      {!isFetching &&
        data?.resultObject &&
        (data?.resultObject?.length || 0) > 0 && (
          <CustomListGroup
            borderForItems
            listItems={data?.resultObject.map((v, i) => {
              const iconInformation = getIconInformation(v);
              return {
                id: i.toString(),
                content: `${v.treeTitle || v.title}, ${getVersionName(
                  v.name || '',
                  translation('versionFrom'),
                  v.validFrom || '',
                )}`,
                iconClass: 'icon-artikel',
                iconColorClass: iconInformation.iconColorClass,
                iconDescription: iconInformation.iconDescription,
                href: getArticleUrl(v.articleId || '', v.id || ''),
              };
            })}
          />
        )}
    </div>
  );
}

export default InvalidMarks;
