export type ContextAction = {
  iconClass?: string;
  iconColorClass?: string;
  name: string;
  onClick?: () => void;
  href?: string;
  openInNewTab?: boolean;
  addDividerAfterItem?: boolean;
  helpId?: string;
};

export const menuItemClass = 'context-menu-item';
