/* eslint-disable jsx-a11y/no-static-element-interactions */
import { SyntheticEvent } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import {
  articleIdUrlParam,
  EDIT_MODE_KEY,
  paddingOffsetForTreeElements,
} from '../../shared/constants';
import { ArticleType, RightKey } from '../../shared/enums';
import { checkClickOnContextMenu } from '../../shared/utils';
import ArticleTreeElement from './ArticleTreeElement';
import CategoryTreeElement from './CategoryTreeElement';
import { IArticleTreeItem, ICategoryTreeItem } from './types';
import { getArticleUrl } from '../../shared/urlBuilder';

interface IContentTreeItemProps {
  level: number;
  isBranch: boolean;
  isExpanded: boolean | undefined;
  element: ICategoryTreeItem | IArticleTreeItem;
  handleExpand: Function;
  getNodeProps: Function;
  permittedActions: RightKey[];
  handleSelect: Function;
  isSelected: boolean;
  selectedIds: string[];
}

function ContentTreeItem({
  level,
  isBranch,
  isExpanded,
  element,
  handleExpand,
  getNodeProps,
  permittedActions,
  handleSelect,
  isSelected,
  selectedIds,
}: IContentTreeItemProps): JSX.Element {
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);
  const isArticleElement: boolean = 'recentVersionId' in element;
  const itemClass: string = isArticleElement ? 'child-item' : 'parent-item';
  const [searchParams] = useSearchParams();
  const activeArticleId: string | null = searchParams.get(articleIdUrlParam);
  const isActiveArticle = isArticleElement && element.id === activeArticleId;

  const navigate = useNavigate();

  const handleOpenArticle = () => {
    const article = element as IArticleTreeItem;

    if (article.id) {
      navigate(getArticleUrl(article.id));
    }
  };

  return (
    <div
      id={element.id}
      className={`li-inner ${itemClass} ${
        isActiveArticle ? 'active-li-element' : ''
      } ${
        !editModeIsActive &&
        (element.disabled ||
          (isArticleElement && (element as IArticleTreeItem).versionsInvalid))
          ? 'd-none'
          : ''
      }
      `}>
      <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getNodeProps({
          onClick: (e: SyntheticEvent) => {
            if (checkClickOnContextMenu(e)) {
              return;
            }

            if (
              (e.target as HTMLElement).classList.contains('form-check-input')
            ) {
              return;
            }

            const article = element as IArticleTreeItem;
            if (
              isArticleElement &&
              article.type !== ArticleType.StructureElement &&
              !article.disabled &&
              !article.versionsOnlyDrafts
            ) {
              handleOpenArticle();
            } else {
              handleExpand(e);
            }
          },
        })}
        onKeyDown={(e) => {
          if (checkClickOnContextMenu(e)) {
            e.stopPropagation();
            return;
          }

          const article = element as IArticleTreeItem;
          if (
            e.key === 'Enter' &&
            isArticleElement &&
            article.type !== ArticleType.StructureElement &&
            !article.disabled
          ) {
            e.stopPropagation();
            handleOpenArticle();
          }
        }}
        style={{ paddingLeft: paddingOffsetForTreeElements * (level - 1) }}>
        {isArticleElement && (
          <ArticleTreeElement
            articleTreeElement={element as IArticleTreeItem}
            permittedActions={permittedActions}
            handleSelect={handleSelect}
            isSelected={isSelected}
            selectedIds={selectedIds}
          />
        )}
        {!isArticleElement && (
          <CategoryTreeElement
            categoryTreeElement={element as ICategoryTreeItem}
            isBranch={isBranch}
            isExpanded={isExpanded || false}
            permittedActions={permittedActions}
            handleSelect={handleSelect}
            isSelected={isSelected}
            selectedIds={selectedIds}
          />
        )}
      </div>
    </div>
  );
}

export default ContentTreeItem;
