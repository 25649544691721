import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../redux/hooks';
import { usePostApiSupportMutation } from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import CustomDialog from '../../dialogs/CustomDialog';

interface SendErrorReportDialogProps {
  show: boolean;
  onClose: () => void;
}

function SendErrorReportDialog({
  show,
  onClose,
}: SendErrorReportDialogProps): JSX.Element {
  const { t: translation } = useTranslation();
  const [errorText, setErrorText] = useState<string>('');
  const dispatch = useAppDispatch();

  const [sendError, { isError, error }] = usePostApiSupportMutation();

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'ErrorReportError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  const resetAndClose = () => {
    setErrorText('');
    onClose();
  };

  const handleSendErrorReport = () => {
    sendError({ emailMessage: { message: errorText } })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'ErrorReportSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        resetAndClose();
      });
  };

  return (
    <CustomDialog
      dialogId='SendErrorReportDialog'
      titleId='SendErrorReportDialogTitle'
      actionButtonDisabled={errorText.trim() === ''}
      dialogTitle={translation('sendErrorReport')}
      show={show}
      closeFunction={resetAndClose}
      actionFunction={handleSendErrorReport}
      closeTitle={translation('cancel')}
      actionTitle={translation('send')}>
      <Form>
        <Form.Group className='mb-3' controlId='SendErrorReport'>
          <Form.Label>{translation('errorDescription')}</Form.Label>
          <Form.Control
            as='textarea'
            rows={7}
            onChange={(e) => {
              setErrorText(e.target.value);
            }}
            value={errorText}
          />
        </Form.Group>
      </Form>
    </CustomDialog>
  );
}

export default SendErrorReportDialog;
