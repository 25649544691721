import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import CustomCard from '../cards/CustomCard';
import CustomListGroup, { ListItem } from '../lists/CustomListGroup';
import AddRoleDialog from './dialogs/AddRoleDialog';
import RenameRoleDialog from './dialogs/RenameRoleDialog';
import DeleteRoleDialog from './dialogs/DeleteRoleDialog';
import { Role, useGetApiRolesQuery } from '../../redux/store/api/api';
import Loader from '../loader/Loader';
import { addMessage } from '../../redux/store/layout/slice';
import { useAppDispatch } from '../../redux/hooks';
import { PermissionContext, RightKey } from '../../shared/enums';
import { ContextAction } from '../dropdown-menus/types';

interface IRoleListProps {
  activeRole: Role;
  setActiveRole: (role: Role) => void;
  rights: RightKey[];
}

function RoleList({
  activeRole,
  setActiveRole,
  rights,
}: IRoleListProps): JSX.Element {
  const { t: translation } = useTranslation();
  const [addDialogOpened, setAddDialogOpened] = useState<boolean>(false);
  const [renameDialogOpened, setRenameDialogOpened] = useState<boolean>(false);
  const [deleteDialogOpened, setDeleteDialogOpened] = useState<boolean>(false);
  const [contextRole, setContextRole] = useState<Role>({});
  const {
    data: roleData,
    isLoading,
    isError: getRolesIsError,
    error,
  } = useGetApiRolesQuery();
  const dispatch = useAppDispatch();
  const [sortedRoleData, setSortedRoleData] = useState<Role[]>([]);

  // permissions
  const userCanReadRole = rights.includes(RightKey.RightRoleManagementReadRole);
  const userCanEditRole = rights.includes(RightKey.RightRoleManagementEditRole);
  const userCanAddRole = rights.includes(
    RightKey.RightRoleManagementCreateRole,
  );
  const userCanDeleteRole = rights.includes(
    RightKey.RightRoleManagementDeleteRole,
  );

  useEffect(() => {
    if (roleData?.resultObject) {
      const newSortedData = _.sortBy(roleData.resultObject, 'order');
      setSortedRoleData(newSortedData);

      // If activeRole is not set or activeRole does not exist set first role as active if roles are available
      if (
        !activeRole.id ||
        !newSortedData.find((role) => role.id === activeRole.id)
      ) {
        setActiveRole(newSortedData.length > 0 ? newSortedData[0] : {});
      }
    }
  }, [roleData]);

  useEffect(() => {
    if (getRolesIsError) {
      dispatch(
        addMessage({
          id: 'GetRolesError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [getRolesIsError]);

  const getContextActions = (role: Role): ContextAction[] => {
    const contextActions: ContextAction[] = [];

    if (userCanEditRole) {
      contextActions.push({
        iconClass: 'icon-umbenennen',
        iconColorClass: 'text-body',
        name: translation('renameRole'),
        onClick: () => {
          setContextRole(role);
          setRenameDialogOpened(true);
        },
      });
    }

    if (userCanDeleteRole) {
      contextActions.push({
        iconClass: 'icon-trash',
        iconColorClass: 'text-danger',
        name: translation('deleteRole'),
        onClick: () => {
          setContextRole(role);
          setDeleteDialogOpened(true);
        },
      });
    }

    return contextActions;
  };

  return (
    <>
      <CustomCard
        focusableHeadline
        headlineAsH1
        title={translation('roles')}
        actions={
          userCanAddRole
            ? [
                {
                  name: translation('addRole'),
                  iconClassName: 'icon-plus',
                  onClick: () => {
                    setAddDialogOpened(true);
                  },
                },
              ]
            : undefined
        }>
        {isLoading && <Loader />}
        {!isLoading && (
          <CustomListGroup
            activeListItem={activeRole?.id || ''}
            listItems={sortedRoleData.map((role) => {
              const permissionContextTranslationKey = `permissionContext${
                PermissionContext[role.permissionContext || 1]
              }`;
              return {
                id: role.id,
                content: `${role.name} (${translation(
                  permissionContextTranslationKey,
                )})`,
                onClick: userCanReadRole
                  ? () => {
                      setActiveRole(role);
                    }
                  : undefined,
                contextActions: getContextActions(role),
              } as ListItem;
            })}
          />
        )}
      </CustomCard>
      <AddRoleDialog
        setActiveRole={setActiveRole}
        show={addDialogOpened}
        onClose={() => {
          setAddDialogOpened(false);
        }}
      />
      <RenameRoleDialog
        setActiveRole={setActiveRole}
        role={contextRole}
        show={renameDialogOpened}
        onClose={() => {
          setRenameDialogOpened(false);
        }}
      />
      <DeleteRoleDialog
        show={deleteDialogOpened}
        onClose={() => {
          setDeleteDialogOpened(false);
        }}
        role={contextRole}
      />
    </>
  );
}

export default RoleList;
