import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectActiveVersion,
  selectCurrentVersions,
  setActiveVersion,
  setContextVersion,
  setDeleteDraftDialogOpened,
  setRenameDraftDialogOpened,
} from '../../redux/store/content/slice';
import { getVersionName } from '../../shared/utils';
import { getEditDraftUrl } from '../../shared/urlBuilder';
import CustomListGroup from '../lists/CustomListGroup';

function DraftList(): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const activeVersion = useAppSelector(selectActiveVersion);
  const versions = useAppSelector(selectCurrentVersions).filter((v) => v.draft);

  return (
    <>
      {versions.length === 0 && <p>{translation('noDrafts')}</p>}
      {versions.length > 0 && (
        <CustomListGroup
          activeListItem={activeVersion.id || ''}
          listItems={versions.map((version) => {
            const versionName = getVersionName(
              version.name || '',
              translation('versionFrom'),
              version.validFrom || '',
            );
            return {
              id: version.id || '',
              content: <span title={versionName}>{versionName}</span>,
              onClick: () => {
                dispatch(setActiveVersion(version));
                navigate(
                  getEditDraftUrl(version.articleId || '', version.id || ''),
                );
              },
              contextActions: [
                {
                  helpId: 'help_3_2_5',
                  iconClass: 'icon-umbenennen',
                  iconColorClass: 'text-body',
                  name: translation('renameDraft'),
                  onClick: () => {
                    dispatch(setContextVersion(version));
                    dispatch(setRenameDraftDialogOpened(true));
                  },
                },
                {
                  iconClass: 'icon-trash',
                  iconColorClass: 'text-danger',
                  name: translation('deleteDraft'),
                  onClick: () => {
                    dispatch(setContextVersion(version));
                    dispatch(setDeleteDraftDialogOpened(true));
                  },
                },
              ],
              iconClass: 'icon-entwuerfe',
            };
          })}
        />
      )}
    </>
  );
}

export default DraftList;
