import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Bookmark } from '../../redux/store/api/api';
import ContextMenu from '../dropdown-menus/ContextMenu';
import { ContextAction } from '../dropdown-menus/types';
import { MovingBookmark } from './types';
import { useAppDispatch } from '../../redux/hooks';
import {
  setContextBookmark,
  setDeleteBookmarkDialogOpened,
} from '../../redux/store/content/slice';

interface IBookmarkItemProps {
  bookmark: Bookmark;
  setRenameBookmarkDialogOpened: (show: boolean) => void;
  setMoveDialogOpened: (show: boolean) => void;
  movingBookmark: MovingBookmark;
  setMovingBookmark: (movingBookmark: MovingBookmark) => void;
  moveBookmarkIsActive: boolean;
  setMoveBookmarkIsActive: (moveIsActive: boolean) => void;
  moveBookmarkFolderIsActive: boolean;
  setMoveBookmarkFolderIsActive: (moveIsActive: boolean) => void;
}

function BookmarkItem({
  bookmark,
  setRenameBookmarkDialogOpened,
  setMoveDialogOpened,
  movingBookmark,
  moveBookmarkIsActive,
  setMovingBookmark,
  setMoveBookmarkIsActive,
  moveBookmarkFolderIsActive,
  setMoveBookmarkFolderIsActive,
}: IBookmarkItemProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { t: translation } = useTranslation();

  const contextActions = useMemo<ContextAction[]>(() => {
    const actions: ContextAction[] = [];

    if (moveBookmarkIsActive) {
      const curMovingBookmark = movingBookmark;

      if (curMovingBookmark.bookmark.id !== bookmark.id) {
        actions.push({
          iconClass: 'icon-move',
          name: translation('moveBookmarkOverThisElement'),
          onClick: () => {
            curMovingBookmark.targetBookmarkFolderId =
              bookmark.bookmarkFolderId || undefined;
            curMovingBookmark.newPosition = bookmark.order || 0;
            setMovingBookmark(curMovingBookmark);
            setMoveDialogOpened(true);
          },
        });

        actions.push({
          iconClass: 'icon-move',
          name: translation('moveBookmarkBelowThisElement'),
          onClick: () => {
            curMovingBookmark.targetBookmarkFolderId =
              bookmark.bookmarkFolderId || undefined;
            curMovingBookmark.newPosition = (bookmark.order || 0) + 1;
            setMovingBookmark(curMovingBookmark);
            setMoveDialogOpened(true);
          },
          addDividerAfterItem: true,
        });
      }

      actions.push({
        iconClass: 'icon-deaktivieren',
        iconColorClass: 'text-danger',
        name: translation('stopMove'),
        onClick: () => {
          setMoveBookmarkIsActive(false);
        },
      });

      return actions;
    }

    if (moveBookmarkFolderIsActive) {
      actions.push({
        iconClass: 'icon-deaktivieren',
        iconColorClass: 'text-danger',
        name: translation('stopMove'),
        onClick: () => {
          setMoveBookmarkFolderIsActive(false);
        },
      });

      return actions;
    }

    actions.push({
      helpId: 'help_2_3_3',
      name: translation('renameBookmark'),
      iconClass: 'icon-umbenennen',
      onClick: () => {
        dispatch(setContextBookmark(bookmark));
        setRenameBookmarkDialogOpened(true);
      },
    });

    actions.push({
      helpId: 'help_2_3_2',
      name: translation('deleteBookmark'),
      iconClass: 'icon-merkliste_remove',
      iconColorClass: 'text-danger',
      onClick: () => {
        dispatch(setContextBookmark(bookmark));
        dispatch(setDeleteBookmarkDialogOpened(true));
      },
      addDividerAfterItem: true,
    });

    actions.push({
      helpId: 'help_2_3_4',
      iconClass: 'icon-move',
      name: translation('startMoveBookmark'),
      onClick: () => {
        setMovingBookmark({ bookmark });
        setMoveBookmarkIsActive(true);
      },
    });

    return actions;
  }, [
    bookmark,
    moveBookmarkFolderIsActive,
    moveBookmarkIsActive,
    movingBookmark,
  ]);

  return (
    <div className='d-flex w-100 position-relative'>
      <i
        className={`me-2 ${
          bookmark.markId ? 'icon-notice_with_marker' : bookmark.iconCssClass
        }`}
        style={
          bookmark.iconColorHexCode
            ? { color: bookmark.iconColorHexCode }
            : undefined
        }
        aria-hidden
      />
      <span className='flex-grow-1'>{bookmark.name}</span>
      <ContextMenu contextActions={contextActions} />
    </div>
  );
}

export default BookmarkItem;
