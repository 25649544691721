import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { RecentChangesItem } from '../../redux/store/api/api';
import CustomListGroup from '../lists/CustomListGroup';
import './LastChanges.scss';
import { getArticleUrl } from '../../shared/urlBuilder';
import { formatToLocalDateString, getVersionName } from '../../shared/utils';

interface ILastChangesProps {
  recentChanges: RecentChangesItem[];
}

function LastChangesItems({ recentChanges }: ILastChangesProps): JSX.Element {
  const { t: translation } = useTranslation();

  const getLastChangesItem = (item: RecentChangesItem): JSX.Element => (
    <div className='last-changes-item-header'>
      <span className='text-break d-flex'>
        <i
          className={`me-2 align-text-top ${item.iconCssClass || ''}`}
          style={item.iconColor ? { color: item.iconColor } : undefined}
          aria-hidden
        />
        <span>
          {item.categoryAbbreviation ? `${item.categoryAbbreviation}, ` : ''}
          {`${parse(
            item.treeTitle || item.versionTitle || '',
          )} (${getVersionName(
            item.versionName || '',
            translation('versionFrom'),
            item.validFrom || '',
          )})`}
        </span>
      </span>
      <span className='last-changes-date text-muted'>
        {item.lastModified ? formatToLocalDateString(item.lastModified) : ''}
      </span>
    </div>
  );

  return (
    <CustomListGroup
      listItems={recentChanges.map((r, index) => ({
        id: index.toString(),
        href: getArticleUrl(r.articleId || '', r.versionId || undefined),
        content: getLastChangesItem(r),
      }))}
    />
  );
}

export default LastChangesItems;
