import { Mark, SourceContentArea } from '../../../redux/store/api/api';
import {
  CALCULATION_CONTENT_CONTAINER_ID,
  VERSION_CONTENT_CONTAINER_ID,
} from '../../../shared/constants';
import {
  getNodeIgnoreFiberNodes,
  isFiberNode,
  isMarkOrLink,
  isTextNode,
} from './nodes';

const additionalCharacterLength = 10;

const calculateOffset = (node: Node, offset: number): number => {
  const prevSibling = getNodeIgnoreFiberNodes(node, 'previousSibling');
  const parent = getNodeIgnoreFiberNodes(node, 'parentNode');

  if (parent && isMarkOrLink(parent)) {
    return calculateOffset(parent, offset);
  }

  if (
    !prevSibling ||
    (!isMarkOrLink(prevSibling) && !isTextNode(prevSibling))
  ) {
    return offset;
  }

  const nodeContentLength = prevSibling.textContent?.length || 0;
  const newOffset = offset + nodeContentLength;

  return calculateOffset(prevSibling, newOffset);
};

const findPreviousSiblings = (node: Node): number => {
  const previousSibling = getNodeIgnoreFiberNodes(node, 'previousSibling');

  if (previousSibling === null) {
    return 1;
  }

  if (
    (isMarkOrLink(node) || isTextNode(node) || isFiberNode(node)) &&
    (isMarkOrLink(previousSibling) || isTextNode(previousSibling))
  ) {
    return findPreviousSiblings(previousSibling);
  }

  return 1 + findPreviousSiblings(previousSibling);
};

// nodePath: Array of numbers
// Each number represents how much siblings a node has to the left
// Each index represents a level in the DOM
const findPath = (
  node: Node,
  nodePath: number[],
  contentContainerId: string,
  doc: Document,
): number[] => {
  if (isFiberNode(node) || isMarkOrLink(node)) {
    if (node.parentElement) {
      return findPath(node.parentElement, nodePath, contentContainerId, doc);
    }

    return nodePath;
  }

  const rootEl: Element | null | undefined =
    doc.getElementById(contentContainerId);
  const siblingsOnLevel = findPreviousSiblings(node);
  nodePath.push(siblingsOnLevel);

  if (node.parentElement !== rootEl && node.parentElement) {
    return findPath(node.parentElement, nodePath, contentContainerId, doc);
  }

  return nodePath;
};

export const getStartNodeContainerId = (
  element: HTMLElement | null,
): string => {
  if (
    !element ||
    element.id === VERSION_CONTENT_CONTAINER_ID ||
    element.id === CALCULATION_CONTENT_CONTAINER_ID
  ) {
    return '';
  }
  if (isMarkOrLink(element as Node)) {
    return getStartNodeContainerId(element.parentElement);
  }
  if (element.id) {
    return element.id;
  }
  return getStartNodeContainerId(element.closest('[id]'));
};

export const getPrecedingCharacters = (
  range: Range,
  contentContainerId: string,
  doc: Document,
): string => {
  const firstChild = doc.getElementById(contentContainerId)?.firstElementChild;
  if (!firstChild) {
    return '';
  }
  const patternLength = range.toString().length;

  range.setStartBefore(firstChild);

  const rangeString = range.toString();
  return rangeString.substring(
    rangeString.length - patternLength - additionalCharacterLength,
    rangeString.length - patternLength,
  );
};

export const getSubsequentCharacters = (
  range: Range,
  contentContainerId: string,
  doc: Document,
): string => {
  const lastChild = doc.getElementById(contentContainerId)?.lastElementChild;
  if (!lastChild) {
    return '';
  }
  const patternLength = range.toString().length;

  range.setEndAfter(lastChild);

  const rangeString = range.toString();
  return rangeString.substring(
    patternLength,
    patternLength + additionalCharacterLength,
  );
};

const convertFromRange = (
  range: Range,
  contentContainerId: string,
  doc: Document,
): Mark | SourceContentArea => {
  const startNodeContainerId = getStartNodeContainerId(
    range.startContainer.parentElement,
  );
  const precedingCharacters = getPrecedingCharacters(
    range.cloneRange(),
    contentContainerId,
    doc,
  );

  const subsequentCharacters = getSubsequentCharacters(
    range.cloneRange(),
    contentContainerId,
    doc,
  );
  const startOffset = calculateOffset(range.startContainer, range.startOffset);
  const endOffset = calculateOffset(range.endContainer, range.endOffset);
  const startPath: number[] = findPath(
    range.startContainer,
    [],
    contentContainerId,
    doc,
  );
  const endPath: number[] = findPath(
    range.endContainer,
    [],
    contentContainerId,
    doc,
  );

  return {
    startNodeContainerId,
    precedingCharacters,
    subsequentCharacters,
    patternString: range?.toString(),
    startNodeData: `{"nodePath": [${startPath}], "offset": ${startOffset}}`,
    endNodeData: `{"nodePath": [${endPath}], "offset": ${endOffset}}`,
  };
};

export default convertFromRange;
