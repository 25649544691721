import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiCategoryTreeQuery,
  usePutApiCategoriesSetValidFromMutation,
} from '../../../redux/store/api/api';
import {
  selectChangeValidityDateDialogOpened,
  selectContextCategory,
  setChangeValidityDateDialogOpened,
  setContextCategory,
} from '../../../redux/store/content/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import { formatToInputDateString } from '../../../shared/utils';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';

interface IChangeValidDateDialogProps {
  setLastUpdatedItem: (updatedId: string) => void;
}

function ChangeValidDateDialog({
  setLastUpdatedItem,
}: IChangeValidDateDialogProps) {
  const dispatch = useAppDispatch();
  const { t: translation } = useTranslation();
  const dialogShow = useAppSelector(selectChangeValidityDateDialogOpened);
  const category = useAppSelector(selectContextCategory);
  const [validityDate, setValidityDate] = useState(
    formatToInputDateString(new Date().toString()),
  );
  const [dateIsValid, setDateIsValid] = useState(true);
  const [setValidityDateRecursively, { isError, isLoading, error }] =
    usePutApiCategoriesSetValidFromMutation();
  const { refetch } = useGetApiCategoryTreeQuery();

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'ChangeValidFromDateError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  const handleSetValidityDate = (): void => {
    if (validityDate.trim().length === 0) {
      setDateIsValid(false);
      return;
    }

    setValidityDateRecursively({
      categoryId: category.id || '',
      validFrom: validityDate,
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'ChangeValidFromDateSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        dispatch(setChangeValidityDateDialogOpened(false));
        setValidityDate(formatToInputDateString(new Date().toString()));
        dispatch(setContextCategory({}));
        setDateIsValid(true);
        setLastUpdatedItem(category.id ?? '');
      });
  };

  return (
    <CustomDialog
      titleId='ChangeValidityDateDialog'
      show={dialogShow}
      closeFunction={() => {
        dispatch(setChangeValidityDateDialogOpened(false));
        setValidityDate(formatToInputDateString(new Date().toString()));
        dispatch(setContextCategory({}));
        setDateIsValid(true);
      }}
      closeTitle={translation('cancel')}
      actionFunction={handleSetValidityDate}
      actionTitle={translation('changeDate')}
      actionButtonDisabled={isLoading}
      dialogTitle={translation('changeValidityDate')}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <p>{translation('fieldsAreRequiredLegend')}</p>
          <Form.Group className='mb-3 mt-3' controlId='ValidityDate'>
            <Form.Label>{translation('validityDate')}*</Form.Label>
            <Form.Control
              required
              aria-describedby={dateIsValid ? undefined : 'DateInputError'}
              isInvalid={!dateIsValid}
              value={validityDate}
              type='date'
              onChange={(e) => {
                setValidityDate(e.target.value);
                if (e.target.value.trim()) {
                  setDateIsValid(true);
                }
              }}
            />
            <Form.Control.Feedback id='DateInputError' type='invalid'>
              {translation('fieldNotEmpty')}
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}
    </CustomDialog>
  );
}

export default ChangeValidDateDialog;
