import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLayoutEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import DropdownMenu from '../dropdown-menus/DropdownMenu';

function LoginMenu(): JSX.Element {
  const { t: translation } = useTranslation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userName, setUserName] = useState('');
  const [, setPageUrl] = useLocalStorage('pageUrl', '');
  let subscription: number;

  const populateState = async () => {
    const [authenticated, user] = await Promise.all([
      authService.isAuthenticated(),
      authService.getUser(),
    ]);
    setIsAuthenticated(authenticated);
    setUserName(user && user.name);
  };

  useLayoutEffect(() => {
    subscription = authService.subscribe(() => populateState());
    populateState();

    return () => {
      authService.unsubscribe(subscription);
    };
  }, []);

  const setReturnUrl = () => {
    setPageUrl(() => window.location.href);
  };

  function getAuthenticatedView(
    name: string,
    changePasswordPath: string,
    logoutPath: any,
  ): JSX.Element {
    return (
      <Dropdown>
        <Dropdown.Toggle
          aria-label={translation('profileLabel')}
          variant='outline-dark'
          className='rounded-circle circle-btn'>
          <i
            className='icon-account'
            aria-hidden
            title={translation('profileLabel')}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu as={DropdownMenu} align='end'>
          <li className='ps-3 pe-3 pb-2'>
            <i className='icon-account me-2' aria-hidden />
            {name}
          </li>
          <li>
            <Dropdown.Item
              as={Link}
              to={changePasswordPath}
              onClick={setReturnUrl}>
              <i className='icon-password_reset me-2' aria-hidden />
              {translation('changePassword')}
            </Dropdown.Item>
          </li>
          <li>
            <Dropdown.Item as={Link} to={logoutPath}>
              <i className='icon-abmelden me-2' aria-hidden />
              {translation('logout')}
            </Dropdown.Item>
          </li>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  function getAnonymousView(loginPath: string): JSX.Element {
    return (
      <Dropdown>
        <Dropdown.Toggle
          aria-label={translation('profileLabel')}
          variant='outline-dark'
          className='rounded-circle circle-btn'>
          <i
            className='icon-account'
            aria-hidden
            title={translation('profileLabel')}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu as={DropdownMenu} align='end'>
          <Dropdown.Item as={Link} to={loginPath}>
            {translation('login')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  return !isAuthenticated
    ? getAnonymousView(ApplicationPaths.Login)
    : getAuthenticatedView(
        userName,
        ApplicationPaths.IdentityChangePasswordPath,
        {
          pathname: ApplicationPaths.LogOut,
          state: { local: true },
        },
      );
}

export default LoginMenu;
