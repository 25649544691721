import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { useSessionStorage } from 'usehooks-ts';
import { useSearchParams } from 'react-router-dom';
import { VisibilityGroupString } from '../../notes/types';
import {
  MARK_VISIBILITY_GROUP_KEY,
  SEARCH_RESULT_SPAN,
  searchKeywordUrlParam,
} from '../../../shared/constants';
import { getMultipleWordSearchRegex } from '../../../shared/utils';

interface ISearchContentProps {
  content: string;
}

function SearchContent({ content }: ISearchContentProps): JSX.Element {
  const [searchRegex, setSearchRegex] = useState<RegExp | null>(null);
  const [searchContent, setSearchContent] = useState<string>(content);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchKeywordsParam: string | null = searchParams.get(
    searchKeywordUrlParam,
  );
  const [activeMarkVisibilityGroup, setActiveMarkVisibilityGroup] =
    useSessionStorage<VisibilityGroupString | null>(
      MARK_VISIBILITY_GROUP_KEY,
      null,
    );

  useEffect(() => {
    if (searchKeywordsParam) {
      setActiveMarkVisibilityGroup(null);
      const searchKeywords: string[] = searchKeywordsParam.split(',');
      const regex = getMultipleWordSearchRegex(searchKeywords);

      setSearchRegex(regex);
    } else {
      setSearchRegex(null);
    }
  }, [searchKeywordsParam, content]);

  useEffect(() => {
    // Add spans with highlight border to search results
    if (!searchRegex) {
      return;
    }

    const newContent = content.replace(
      searchRegex,
      (match) =>
        `<span class='${SEARCH_RESULT_SPAN} border border-2 border-primary'>${match}</span>`,
    );

    setSearchContent(newContent);

    setTimeout(() => {
      const focusEl = document.getElementsByClassName(SEARCH_RESULT_SPAN)[0];

      if (focusEl) {
        focusEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    });
  }, [searchRegex]);

  useEffect(() => {
    if (searchParams.has(searchKeywordUrlParam) && activeMarkVisibilityGroup) {
      searchParams.delete(searchKeywordUrlParam);
      setSearchParams(searchParams);
      setSearchRegex(null);
    }
  }, [activeMarkVisibilityGroup]);

  return <>{parse(searchContent)}</>;
}

export default SearchContent;
