import _ from 'lodash';
import { ArticleReference, Mark, Note } from '../../../redux/store/api/api';
import { CALCULATION_CONTENT_CONTAINER_ID } from '../../../shared/constants';
import convertToRange from './convertToRange';
import surroundTextNodesInRange from './surroundTextNodesInRange';

export const iconBtnIdExtension = '-icon-btn';
export const linkButtonClass = 'link-button';

interface IContentProps {
  content: string;
  elementsNotFound: string[];
}
interface IMakeContentProps {
  content: string;
  elements: Note[] | ArticleReference[];
  tagName: 'mark' | 'a';
  isSuggestionContent?: boolean;
  suggestionElId?: string;
  iconClassName?: string;
  description?: string;
}
interface IGetAccessibleIconBtnProps {
  id: string;
  iconClassName: string;
  description: string;
}

export const getAccessibleIconBtn = ({
  id,
  iconClassName,
  description,
}: IGetAccessibleIconBtnProps) => {
  const button = document.createElement('button');
  const icon = document.createElement('i');

  button.setAttribute('id', `${id}${iconBtnIdExtension}`);
  button.classList.add(
    'py-0',
    'px-1',
    'border-0',
    'bg-transparent',
    linkButtonClass,
  );
  button.appendChild(icon);
  button.setAttribute('title', description || '');
  button.setAttribute('aria-label', description || '');

  icon.classList.add(iconClassName, 'ref-icon');
  icon.setAttribute('aria-hidden', 'true');

  return button;
};

const getAdditionalCharsMatch = (
  range: Range,
  htmlDoc: Document,
  mark: Mark,
) => {
  const contentNode =
    document.getElementById(CALCULATION_CONTENT_CONTAINER_ID) || htmlDoc.body;

  const rangeBeforePattern = range.cloneRange();
  rangeBeforePattern.collapse(true);
  rangeBeforePattern.setStartBefore(contentNode);
  const textBeforePattern = rangeBeforePattern.toString();
  const precedingCharsMatch = textBeforePattern.match(
    new RegExp(`${_.escapeRegExp(mark?.precedingCharacters || '')}$`),
  );

  const rangeAfterPattern = range.cloneRange();
  rangeAfterPattern.collapse();
  rangeAfterPattern.setEndAfter(contentNode);
  const textAfterPattern = rangeAfterPattern.toString();
  const subsequentCharsMatch = textAfterPattern.match(
    new RegExp(`^${_.escapeRegExp(mark?.subsequentCharacters || '')}`),
  );

  return precedingCharsMatch && subsequentCharsMatch;
};

const makeContentWithMarks = ({
  content,
  elements,
  tagName,
  isSuggestionContent = false,
  suggestionElId = undefined,
  iconClassName = undefined,
  description = undefined,
}: IMakeContentProps): IContentProps => {
  if (elements.length === 0) {
    return { content, elementsNotFound: [] };
  }

  // Parse content string to html document
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(
    `<div id='${CALCULATION_CONTENT_CONTAINER_ID}'>${content}</div>`,
    'text/html',
  );

  // Convert objects from database to range objects
  let elementsNotFound: string[] = [];

  elements.forEach((element) => {
    const note = tagName === 'mark' ? (element as Note) : null;
    const reference = tagName === 'a' ? (element as ArticleReference) : null;
    const mark = note?.mark;
    const sourceContentArea = reference?.sourceContentArea;
    const el = mark || sourceContentArea;
    let range;

    if (el) {
      range = convertToRange(htmlDoc, CALCULATION_CONTENT_CONTAINER_ID, el);
    }

    if (
      range === undefined ||
      (note?.mark && !getAdditionalCharsMatch(range, htmlDoc, note.mark))
    ) {
      elementsNotFound = [...elementsNotFound, el?.id || ''];
    } else {
      // add icon after each range
      if (iconClassName) {
        const accessibleIconBtn = getAccessibleIconBtn({
          id: element.id || '',
          iconClassName,
          description: description || '',
        });
        const clonedRange = range.cloneRange();

        clonedRange.collapse();
        clonedRange.insertNode(accessibleIconBtn);
      }
      // Enclose every text node in range with a <mark>/<a>-tag
      surroundTextNodesInRange({
        range,
        element,
        tagName,
        isSuggestionContent,
        suggestionElId,
      });
    }
  });

  // Parse altered html content back to string
  const serializer = new XMLSerializer();
  const htmlFragment = new DocumentFragment();
  const rootEl = htmlDoc.getElementById(CALCULATION_CONTENT_CONTAINER_ID);
  let newContent = '';

  if (rootEl) {
    htmlFragment.append(rootEl);
  }

  if (htmlFragment) {
    newContent = serializer
      .serializeToString(htmlFragment)
      .replaceAll(/(?:^<div(.*?)>)|(?:<\/div>$)/g, '');
  }

  return { content: newContent, elementsNotFound };
};

export default makeContentWithMarks;
