/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  CategoryType,
  useDeleteApiCategoryTypesByIdMutation,
  useGetApiCategoryTypesQuery,
  useGetApiCategoryTypesIsInUseByIdQuery,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import { useAppDispatch } from '../../../redux/hooks';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';

interface IDeleteCategoryTypeProps {
  show: boolean;
  categoryType: CategoryType;
  onClose: () => void;
}

function DeleteCategoryTypeDialog({
  show,
  categoryType,
  onClose,
}: IDeleteCategoryTypeProps) {
  const { t: translation } = useTranslation();
  const [
    deleteCategoryType,
    {
      isError: deleteCategoryTypeIsError,
      isLoading,
      error: deleteCategoryTypeError,
    },
  ] = useDeleteApiCategoryTypesByIdMutation();
  const {
    refetch,
    isError: getCategoryTypesIsError,
    error: getCategoryTypesError,
  } = useGetApiCategoryTypesQuery();
  const {
    data: categoryTypeInUse,
    isFetching,
    isError: getCategoryTypeInUseIsError,
    error: getCategoryTypeInUseError,
  } = useGetApiCategoryTypesIsInUseByIdQuery(
    categoryType.id && show
      ? {
          id: categoryType.id,
        }
      : skipToken,
    { refetchOnMountOrArgChange: true },
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (getCategoryTypesIsError) {
      dispatch(
        addMessage({
          id: 'GetCategoryTypesError',
          variant: 'danger',
          messageKeyBody:
            getCategoryTypesError && 'data' in getCategoryTypesError
              ? getCategoryTypesError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (getCategoryTypeInUseIsError) {
      dispatch(
        addMessage({
          id: 'CategoryTypeInUseError',
          variant: 'danger',
          messageKeyBody:
            getCategoryTypeInUseError && 'data' in getCategoryTypeInUseError
              ? getCategoryTypeInUseError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (deleteCategoryTypeIsError) {
      dispatch(
        addMessage({
          id: 'DeleteCategoryTypeError',
          variant: 'danger',
          messageKeyBody:
            deleteCategoryTypeError && 'data' in deleteCategoryTypeError
              ? deleteCategoryTypeError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [
    getCategoryTypesIsError,
    getCategoryTypeInUseIsError,
    deleteCategoryTypeIsError,
  ]);

  const handleDeleteCategoryType = (): void => {
    deleteCategoryType({ id: categoryType.id || '' })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'DeleteCategoryTypeSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        onClose();
      });
  };

  return (
    <CustomDialog
      titleId='DeleteCategoryTypeDialog'
      actionButtonDisabled={categoryTypeInUse?.resultObject || isLoading}
      dialogTitle={translation('deleteCategoryType')}
      show={show}
      closeFunction={onClose}
      actionFunction={handleDeleteCategoryType}
      closeTitle={translation('cancel')}
      actionTitle={translation('delete')}
      actionButtonVariant='outline-danger'>
      {(isFetching || isLoading) && <Loader />}
      {!isFetching && !isLoading && (
        <>
          {categoryTypeInUse?.resultObject ? (
            <p>{translation('categoryTypeAssignedWarning')}</p>
          ) : (
            <p>{translation('deleteCategoryTypePermanently')}</p>
          )}
          <p className='fw-bold'>{categoryType.name}</p>
        </>
      )}
    </CustomDialog>
  );
}

export default DeleteCategoryTypeDialog;
