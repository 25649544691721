import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import CustomCard from '../components/cards/CustomCard';
import LastChanges from '../components/last-changes/LastChanges';
import DashboardText from '../components/infos/DashboardText';
import GlobalFallback from '../components/error-fallback/GlobalFallback';

function Home(): JSX.Element {
  const { t: translation } = useTranslation();

  return (
    <ErrorBoundary fallback={<GlobalFallback />}>
      <div className='hint-container'>
        <CustomCard
          title={translation('hint')}
          iconClass='icon-dashboard'
          helpId='help_2_1'>
          <DashboardText />
        </CustomCard>
      </div>
      <div className='last-changes-container'>
        <CustomCard
          title={translation('lastChanges')}
          iconClass='icon-aenderungen'
          helpId='help_2_4'>
          <LastChanges />
        </CustomCard>
      </div>
    </ErrorBoundary>
  );
}

export default Home;
