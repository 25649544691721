/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Article,
  ArticleReference,
  Attachment,
  Bookmark,
  BookmarkFolder,
  Category,
  NamedVersion,
} from '../api/api';
import { RootState } from '../../shared/types';
import { ContentState, SearchOption } from './types';
import {
  ExtendedNote,
  ExtendedSortedNotes,
} from '../../../components/notes/types';

const initialState: ContentState = {
  contextCategory: {},
  contextArticle: {},
  contextAttachment: {},
  contextVersion: {},
  contextBookmark: {},
  contextBookmarkFolder: {},
  currentVersions: [],
  activeArticleReference: {},
  activeVersion: {},
  activeNote: {},
  addCategoryDialogOpened: false,
  editCategoryDialogOpened: false,
  addAttachmentDialogOpened: false,
  renameAttachmentDialogOpened: false,
  deleteAttachmentDialogOpened: false,
  addStructureElementDialogOpened: false,
  renameStructureElementDialogOpened: false,
  elementsToRecycleBinDialogOpened: false,
  renameVersionDialogOpened: false,
  deleteVersionDialogOpened: false,
  contentImportDialogOpened: false,
  renameDraftDialogOpened: false,
  deleteDraftDialogOpened: false,
  expandedRecycleBinCategories: [],
  expandedNoteVisibilityGroup: '2',
  showMakeLinkSelectionInfo: false,
  showMakeMarkSelectionInfo: false,
  showNotePopoverOpened: false,
  linksNeedingAction: [],
  movingElements: {
    elementIds: [],
    newPosition: undefined,
    targetCategory: {},
  },
  moveElementsDialogOpened: false,
  moveElementStarted: false,
  disableElementsDialogOpened: false,
  activateElementsDialogOpened: false,
  noteData: {
    userNotes: [],
    userGroupNotes: [],
    generalNotes: [],
  },
  isReferenceImport: false,
  changeValidityDateDialogOpened: false,
  changePublishDatesDialogOpened: false,
  searchKeyword: '',
  selectedCategoryIdsForSearch: [],
  searchOption: SearchOption.AllWords,
  searchIncludeVersions: false,
  treeContentInvalid: false,
  comparerIsActive: false,
  addBookmarkDialogOpened: false,
  addMarkBookmarkDialogOpened: false,
  expandedBookmarkFolderIds: [],
  maxNumberOfVersionsDialogOpened: false,
  deleteBookmarkDialogOpened: false,
  addBookmarkFolderDialogOpened: false,
  deleteBookmarkFolderDialogOpened: false,
  renameBookmarkFolderDialogOpened: false,
  versionContentIsLoading: true,
  markContentIsProcessing: true,
  activeReferenceListKey: '',
};

export const contentSlice = createSlice({
  initialState,
  name: 'content',
  reducers: {
    setContextCategory: (state, action: PayloadAction<Category>) => {
      state.contextCategory = action.payload;
    },
    setContextArticle: (state, action: PayloadAction<Article>) => {
      state.contextArticle = action.payload;
    },
    setContextAttachment: (state, action: PayloadAction<Attachment>) => {
      state.contextAttachment = action.payload;
    },
    setContextVersion: (state, action: PayloadAction<NamedVersion>) => {
      state.contextVersion = action.payload;
    },
    setContextBookmark: (state, action: PayloadAction<Bookmark>) => {
      state.contextBookmark = action.payload;
    },
    setContextBookmarkFolder: (
      state,
      action: PayloadAction<BookmarkFolder>,
    ) => {
      state.contextBookmarkFolder = action.payload;
    },
    setCurrentVersions: (state, action: PayloadAction<NamedVersion[]>) => {
      state.currentVersions = action.payload;
    },
    setActiveArticleReference: (
      state,
      action: PayloadAction<ArticleReference>,
    ) => {
      state.activeArticleReference = action.payload;
    },
    setActiveVersion: (state, action: PayloadAction<NamedVersion>) => {
      state.activeVersion = action.payload;
    },
    setActiveNote: (state, action: PayloadAction<ExtendedNote>) => {
      state.activeNote = action.payload;
    },
    setAddCategoryDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.addCategoryDialogOpened = action.payload;
    },
    setEditCategoryDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.editCategoryDialogOpened = action.payload;
    },
    setAddStructureElementDialogOpened: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.addStructureElementDialogOpened = action.payload;
    },
    setRenameStructureElementDialogOpened: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.renameStructureElementDialogOpened = action.payload;
    },
    setElementsToRecycleBinDialogOpened: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.elementsToRecycleBinDialogOpened = action.payload;
    },
    setAddAttachmentDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.addAttachmentDialogOpened = action.payload;
    },
    setRenameAttachmentDialogOpened: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.renameAttachmentDialogOpened = action.payload;
    },
    setRenameVersionDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.renameVersionDialogOpened = action.payload;
    },
    setDeleteAttachmentDialogOpened: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.deleteAttachmentDialogOpened = action.payload;
    },
    setDeleteVersionDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.deleteVersionDialogOpened = action.payload;
    },
    setRenameDraftDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.renameDraftDialogOpened = action.payload;
    },
    setDeleteDraftDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.deleteDraftDialogOpened = action.payload;
    },
    setContentImportDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.contentImportDialogOpened = action.payload;
    },
    setExpandedNoteVisibilityGroup: (state, action: PayloadAction<string>) => {
      state.expandedNoteVisibilityGroup = action.payload;
    },
    addExpandedRecycleBinCategory: (state, action: PayloadAction<string>) => {
      if (!state.expandedRecycleBinCategories.includes(action.payload)) {
        state.expandedRecycleBinCategories.push(action.payload);
      }
    },
    removeExpandedRecycleBinCategory: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      state.expandedRecycleBinCategories =
        state.expandedRecycleBinCategories.filter(
          (r) => !action.payload.includes(r),
        );
    },
    clearExpandedRecycleBinCategories: (state) => {
      state.expandedRecycleBinCategories = [];
    },
    setLinksNeedingAction: (state, action: PayloadAction<string[]>) => {
      state.linksNeedingAction = action.payload;
    },

    setMovingElements: (state, action: PayloadAction<string[]>) => {
      state.movingElements = {
        elementIds: action.payload,
        newPosition: undefined,
        targetCategory: {},
      };
    },
    setNewPositionForMovingElements: (
      state,
      action: PayloadAction<{ newPosition?: number; targetCategory: Category }>,
    ) => {
      state.movingElements = {
        ...state.movingElements,
        newPosition: action.payload.newPosition,
        targetCategory: action.payload.targetCategory,
      };
    },
    setMoveElementsDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.moveElementsDialogOpened = action.payload;
    },
    setMoveElementsStarted: (state, action: PayloadAction<boolean>) => {
      state.moveElementStarted = action.payload;
    },
    setDisableElementsDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.disableElementsDialogOpened = action.payload;
    },
    setActivateElementsDialogOpened: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.activateElementsDialogOpened = action.payload;
    },
    setNoteData: (state, action: PayloadAction<ExtendedSortedNotes>) => {
      state.noteData = action.payload;
    },
    setShowMakeLinkSelectionInfo: (state, action: PayloadAction<boolean>) => {
      state.showMakeLinkSelectionInfo = action.payload;
    },
    setShowMakeMarkSelectionInfo: (state, action: PayloadAction<boolean>) => {
      state.showMakeMarkSelectionInfo = action.payload;
    },
    setShowNotePopoverOpened: (state, action: PayloadAction<boolean>) => {
      state.showNotePopoverOpened = action.payload;
    },
    setIsReferenceImport: (state, action: PayloadAction<boolean>) => {
      state.isReferenceImport = action.payload;
    },
    setChangeValidityDateDialogOpened: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.changeValidityDateDialogOpened = action.payload;
    },
    setChangePublishDatesDialogOpened: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.changePublishDatesDialogOpened = action.payload;
    },
    setSearchKeyword: (state, action: PayloadAction<string>) => {
      state.searchKeyword = action.payload;
    },
    setSelectedCategoryIdsForSearch: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      state.selectedCategoryIdsForSearch = action.payload;
    },
    setControlledSelectedCategoryIdsForSearch: (
      state,
      action: PayloadAction<string[] | undefined>,
    ) => {
      state.controlledselectedCategoryIdsForSearch = action.payload;
    },
    setSearchOption: (state, action: PayloadAction<SearchOption>) => {
      state.searchOption = action.payload;
    },
    setSearchIncludeVersions: (state, action: PayloadAction<boolean>) => {
      state.searchIncludeVersions = action.payload;
    },
    setTreeContentInvalid: (state, action: PayloadAction<boolean>) => {
      state.treeContentInvalid = action.payload;
    },
    setComparerIsActive: (state, action: PayloadAction<boolean>) => {
      state.comparerIsActive = action.payload;
    },
    setAddBookmarkDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.addBookmarkDialogOpened = action.payload;
    },
    setAddMarkBookmarkDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.addMarkBookmarkDialogOpened = action.payload;
    },
    addExpandedBookmarkFolderId: (state, action: PayloadAction<string>) => {
      if (!state.expandedBookmarkFolderIds.includes(action.payload)) {
        state.expandedBookmarkFolderIds.push(action.payload);
      }
    },
    removeExpandedBookmarkFolderId: (state, action: PayloadAction<string>) => {
      state.expandedBookmarkFolderIds = state.expandedBookmarkFolderIds.filter(
        (b) => b !== action.payload,
      );
    },
    setMaxNumberOfVersionsDialogOpened: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.maxNumberOfVersionsDialogOpened = action.payload;
    },
    setDeleteBookmarkDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.deleteBookmarkDialogOpened = action.payload;
    },
    setAddBookmarkFolderDialogOpened: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.addBookmarkFolderDialogOpened = action.payload;
    },
    setDeleteBookmarkFolderDialogOpened: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.deleteBookmarkFolderDialogOpened = action.payload;
    },
    setRenameBookmarkFolderDialogOpened: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.renameBookmarkFolderDialogOpened = action.payload;
    },
    setControlledSelectedIds: (
      state,
      action: PayloadAction<string[] | undefined>,
    ) => {
      state.controlledSelectedIds = action.payload;
    },
    setVersionContentIsLoading: (state, action: PayloadAction<boolean>) => {
      state.versionContentIsLoading = action.payload;
    },
    setMarkContentIsProcessing: (state, action: PayloadAction<boolean>) => {
      state.markContentIsProcessing = action.payload;
    },
    setActiveReferenceListKey: (state, action: PayloadAction<string>) => {
      state.activeReferenceListKey = action.payload;
    },
  },
});

export const {
  setAddCategoryDialogOpened,
  setContextCategory,
  setContextArticle,
  setContextAttachment,
  setContextVersion,
  setContextBookmark,
  setContextBookmarkFolder,
  setCurrentVersions,
  setActiveArticleReference,
  setActiveVersion,
  setActiveNote,
  setEditCategoryDialogOpened,
  setExpandedNoteVisibilityGroup,
  setAddStructureElementDialogOpened,
  setRenameStructureElementDialogOpened,
  setElementsToRecycleBinDialogOpened,
  setContentImportDialogOpened,
  setAddAttachmentDialogOpened,
  setRenameAttachmentDialogOpened,
  setDeleteAttachmentDialogOpened,
  setRenameVersionDialogOpened,
  setDeleteVersionDialogOpened,
  setRenameDraftDialogOpened,
  setDeleteDraftDialogOpened,
  addExpandedRecycleBinCategory,
  setLinksNeedingAction,
  removeExpandedRecycleBinCategory,
  clearExpandedRecycleBinCategories,
  setMovingElements,
  setNewPositionForMovingElements,
  setMoveElementsDialogOpened,
  setMoveElementsStarted,
  setDisableElementsDialogOpened,
  setActivateElementsDialogOpened,
  setNoteData,
  setShowMakeLinkSelectionInfo,
  setShowMakeMarkSelectionInfo,
  setShowNotePopoverOpened,
  setIsReferenceImport,
  setChangeValidityDateDialogOpened,
  setChangePublishDatesDialogOpened,
  setSearchKeyword,
  setSelectedCategoryIdsForSearch,
  setControlledSelectedCategoryIdsForSearch,
  setSearchOption,
  setSearchIncludeVersions,
  setTreeContentInvalid,
  setComparerIsActive,
  setAddBookmarkDialogOpened,
  setAddMarkBookmarkDialogOpened,
  addExpandedBookmarkFolderId,
  removeExpandedBookmarkFolderId,
  setMaxNumberOfVersionsDialogOpened,
  setDeleteBookmarkDialogOpened,
  setAddBookmarkFolderDialogOpened,
  setDeleteBookmarkFolderDialogOpened,
  setRenameBookmarkFolderDialogOpened,
  setControlledSelectedIds,
  setVersionContentIsLoading,
  setMarkContentIsProcessing,
  setActiveReferenceListKey,
} = contentSlice.actions;

export const selectContextCategory = (state: RootState) =>
  state.content.contextCategory;

export const selectContextArticle = (state: RootState) =>
  state.content.contextArticle;

export const selectContextAttachment = (state: RootState) =>
  state.content.contextAttachment;

export const selectContextVersion = (state: RootState) =>
  state.content.contextVersion;

export const selectContextBookmark = (state: RootState) =>
  state.content.contextBookmark;

export const selectContextBookmarkFolder = (state: RootState) =>
  state.content.contextBookmarkFolder;

export const selectCurrentVersions = (state: RootState) =>
  state.content.currentVersions;

export const selectActiveArticleReference = (state: RootState) =>
  state.content.activeArticleReference;

export const selectActiveVersion = (state: RootState) =>
  state.content.activeVersion;

export const selectActiveNote = (state: RootState) => state.content.activeNote;

export const selectAddCategoryDialogOpened = (state: RootState) =>
  state.content.addCategoryDialogOpened;

export const selectEditCategoryDialogOpened = (state: RootState) =>
  state.content.editCategoryDialogOpened;

export const selectAddStructureElementDialogOpened = (state: RootState) =>
  state.content.addStructureElementDialogOpened;

export const selectRenameStructureElementDialogOpened = (state: RootState) =>
  state.content.renameStructureElementDialogOpened;

export const selectElementsToRycycleBinDialogOpened = (state: RootState) =>
  state.content.elementsToRecycleBinDialogOpened;

export const selectAddAttachmentDialogOpened = (state: RootState) =>
  state.content.addAttachmentDialogOpened;

export const selectRenameAttachmentDialogOpened = (state: RootState) =>
  state.content.renameAttachmentDialogOpened;

export const selectDeleteAttachmentDialogOpened = (state: RootState) =>
  state.content.deleteAttachmentDialogOpened;

export const selectDeleteVersionDialogOpened = (state: RootState) =>
  state.content.deleteVersionDialogOpened;

export const selectRenameVersionDialogOpened = (state: RootState) =>
  state.content.renameVersionDialogOpened;

export const selectDeleteDraftDialogOpened = (state: RootState) =>
  state.content.deleteDraftDialogOpened;

export const selectRenameDraftDialogOpened = (state: RootState) =>
  state.content.renameDraftDialogOpened;

export const selectImportContentDialogOpened = (state: RootState) =>
  state.content.contentImportDialogOpened;

export const selectExpandedRecycleBinCategories = (state: RootState) =>
  state.content.expandedRecycleBinCategories;

export const selectExpandedNoteVisibilityGroup = (state: RootState) =>
  state.content.expandedNoteVisibilityGroup;

export const selectLinksNeedingAction = (state: RootState) =>
  state.content.linksNeedingAction;

export const selectMovingElements = (state: RootState) =>
  state.content.movingElements;

export const selectMoveElementsDialogOpened = (state: RootState) =>
  state.content.moveElementsDialogOpened;

export const selectMoveElementsStarted = (state: RootState) =>
  state.content.moveElementStarted;

export const selectDisableElementsDialogOpened = (state: RootState) =>
  state.content.disableElementsDialogOpened;

export const selectActivateElementsDialogOpened = (state: RootState) =>
  state.content.activateElementsDialogOpened;

export const selectNoteData = (state: RootState) => state.content.noteData;

export const selectShowMakeLinkSelectionInfo = (state: RootState) =>
  state.content.showMakeLinkSelectionInfo;

export const selectShowMakeMarkSelectionInfo = (state: RootState) =>
  state.content.showMakeMarkSelectionInfo;

export const selectShowNotePopoverOpened = (state: RootState) =>
  state.content.showNotePopoverOpened;

export const selectIsReferenceImport = (state: RootState) =>
  state.content.isReferenceImport;

export const selectChangeValidityDateDialogOpened = (state: RootState) =>
  state.content.changeValidityDateDialogOpened;

export const selectChangePublishDatesDialogOpened = (state: RootState) =>
  state.content.changePublishDatesDialogOpened;

export const selectSearchKeyword = (state: RootState) =>
  state.content.searchKeyword;

export const selectSelectedCategoryIdsForSearch = (state: RootState) =>
  state.content.selectedCategoryIdsForSearch;

export const selectControlledSelectedCategoryIdsForSearch = (
  state: RootState,
) => state.content.controlledselectedCategoryIdsForSearch;

export const selectSearchOption = (state: RootState) =>
  state.content.searchOption;

export const selectSearchIncludeVersions = (state: RootState) =>
  state.content.searchIncludeVersions;

export const selectTreeContentInvalid = (state: RootState) =>
  state.content.treeContentInvalid;

export const selectComparerIsActive = (state: RootState) =>
  state.content.comparerIsActive;

export const selectAddBookmarkDialogOpened = (state: RootState) =>
  state.content.addBookmarkDialogOpened;

export const selectAddMarkBookmarkDialogOpened = (state: RootState) =>
  state.content.addMarkBookmarkDialogOpened;

export const selectExpandedBookmarkFolderIds = (state: RootState) =>
  state.content.expandedBookmarkFolderIds;

export const selectMaxNumberOfVersionsDialogOpened = (state: RootState) =>
  state.content.maxNumberOfVersionsDialogOpened;

export const selectDeleteBookmarkDialogOpened = (state: RootState) =>
  state.content.deleteBookmarkDialogOpened;

export const selectAddBookmarkFolderDialogOpened = (state: RootState) =>
  state.content.addBookmarkFolderDialogOpened;

export const selectDeleteBookmarkFolderDialogOpened = (state: RootState) =>
  state.content.deleteBookmarkFolderDialogOpened;

export const selectRenameBookmarkFolderDialogOpened = (state: RootState) =>
  state.content.renameBookmarkFolderDialogOpened;

export const selectControlledSelectedIds = (state: RootState) =>
  state.content.controlledSelectedIds;

export const selectVersionContentIsLoading = (state: RootState) =>
  state.content.versionContentIsLoading;

export const selectMarkContentIsProcessing = (state: RootState) =>
  state.content.markContentIsProcessing;

export const selectActiveReferenceListKey = (state: RootState) =>
  state.content.activeReferenceListKey;

export default contentSlice.reducer;
