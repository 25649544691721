import { ForwardedRef, forwardRef } from 'react';

interface IAccordionCollapseContainerProps {
  id: string;
  'aria-labelledby': string;
  className: string;
}

function AccordionCollapseContainer(
  {
    id,
    'aria-labelledby': ariaLabelledby,
    className,
    children,
  }: React.PropsWithChildren<IAccordionCollapseContainerProps>,
  ref: ForwardedRef<HTMLDivElement>,
): JSX.Element {
  return (
    <div
      ref={ref}
      role='region'
      id={id}
      aria-labelledby={ariaLabelledby}
      className={className}>
      {children}
    </div>
  );
}

export default forwardRef(AccordionCollapseContainer);
