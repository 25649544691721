import { findSuggestion, getUpdatedMark } from './findSuggestion';
import { Mark } from '../../../redux/store/api/api';
import { RANGE_END_ID, RANGE_START_ID } from '../../../shared/constants';

const makeSuggestionEl = (
  content: string,
  patternContent: string,
  markId: string,
): Mark | null => {
  const suggestionPattern = findSuggestion(content, patternContent);

  if (!suggestionPattern) {
    return null;
  }

  const contentBeforePattern = content.substring(
    0,
    suggestionPattern.position.start,
  );
  const contentAfterPattern = content.substring(
    suggestionPattern.position.end,
    content.length,
  );

  // use void elememt to mark start and end of range
  const newContent = `${contentBeforePattern}${`<br id='${RANGE_START_ID}'>${suggestionPattern.content}<br id='${RANGE_END_ID}'>`}${contentAfterPattern}`;
  const updatedMark = getUpdatedMark(newContent);

  if (updatedMark) {
    return {
      id: markId,
      ...updatedMark,
    };
  }

  return null;
};

export default makeSuggestionEl;
