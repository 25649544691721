/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../shared/types';
import { LayoutState, Message, UserGuideDialogShowOptions } from './types';

const initialState: LayoutState = {
  sidebarOpened: false,
  messages: [],
  userGuideDialogOpened: { show: false },
};

export const layoutSlice = createSlice({
  initialState,
  name: 'layout',
  reducers: {
    setSidebarOpened: (state, action: PayloadAction<boolean>) => {
      state.sidebarOpened = action.payload;
    },
    addMessage: (state, action: PayloadAction<Message>) => {
      if (action.payload.messageKeyBody) {
        state.messages.push(action.payload);
      }
    },
    removeMessage: (state, action: PayloadAction<string>) => {
      state.messages = state.messages.filter((m) => m.id !== action.payload);
    },
    setUserGuideDialogOpened: (
      state,
      action: PayloadAction<UserGuideDialogShowOptions>,
    ) => {
      state.userGuideDialogOpened = action.payload;
    },
  },
});

export const {
  setSidebarOpened,
  addMessage,
  removeMessage,
  setUserGuideDialogOpened,
} = layoutSlice.actions;

export const selectSidebarOpened = (state: RootState) =>
  state.layout.sidebarOpened;

export const selectMessages = (state: RootState) => state.layout.messages;

export const selectUserGuideDialogOpened = (state: RootState) =>
  state.layout.userGuideDialogOpened;

export default layoutSlice.reducer;
