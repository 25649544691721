import _ from 'lodash';
import { Mark, Note } from '../../../redux/store/api/api';
import {
  getMarkFromTrustedSuggestion,
  getTagfreeContent,
} from './findSuggestion';
import getMigratedMark from './getMigratedMarks';
import makeContent from './makeContent';

type MarkUpdateListType = {
  [id: string]: Mark;
};

const getContentAndNotIncludedMarks = (
  content: string,
  notes: Note[],
): { marks: Mark[]; content: string } => {
  const marksToUpdate: MarkUpdateListType = {};
  const addMarkToUpdate = (id: string, mark: Mark) => {
    marksToUpdate[id] = mark;
  };

  const notesWithMigratedMarks = notes
    .filter((n) => n.mark)
    .map((n) => {
      const migratedMark = getMigratedMark(n.mark as Mark, content);
      if (migratedMark) {
        addMarkToUpdate(migratedMark.id || '', migratedMark);
      }

      return {
        ...n,
        mark: migratedMark || n.mark,
      };
    });

  const testContentData = makeContent({
    content,
    elements: notesWithMigratedMarks,
    tagName: 'mark',
  });
  const tagFreeTestContentData = getTagfreeContent(testContentData.content);

  const notesWithUpdatedMarks = notesWithMigratedMarks.map((n) => {
    const recalculatedMark =
      testContentData.elementsNotFound.includes(n.mark?.id || '') &&
      n.mark?.precedingCharacters !== null &&
      n.mark?.subsequentCharacters !== null
        ? getMarkFromTrustedSuggestion(
            testContentData.content,
            { ...tagFreeTestContentData },
            n.mark as Mark,
          )
        : null;
    if (recalculatedMark) {
      addMarkToUpdate(recalculatedMark.id || '', recalculatedMark);
    }

    return {
      ...n,
      mark: recalculatedMark || n.mark,
    };
  });

  const contentData = makeContent({
    content,
    elements: notesWithUpdatedMarks,
    tagName: 'mark',
  });

  notesWithUpdatedMarks.forEach((note) => {
    if (!note.mark) {
      return;
    }

    if (
      contentData.elementsNotFound.includes(note.mark.id || '') &&
      !note.mark.markInvalid
    ) {
      addMarkToUpdate(note.mark.id || '', {
        ...note.mark,
        markInvalid: true,
      });
    }

    if (
      !contentData.elementsNotFound.includes(note.mark.id || '') &&
      note.mark.markInvalid
    ) {
      addMarkToUpdate(note.mark.id || '', {
        ...note.mark,
        markInvalid: false,
      });
    }
  });

  return { marks: _.values(marksToUpdate), content: contentData.content };
};

export default getContentAndNotIncludedMarks;
