import { ContextAction } from './types';

interface IContextActionItemContentProps {
  action: ContextAction;
}

function ContextActionItemContent({ action }: IContextActionItemContentProps) {
  return (
    <>
      {action.iconClass && (
        <i
          className={`${action.iconClass} ${action.iconColorClass} fs-5 align-text-top me-2`}
          aria-hidden
        />
      )}
      {action.name}
    </>
  );
}

export default ContextActionItemContent;
