import { SyntheticEvent } from 'react';
import { Button } from 'react-bootstrap';

interface IIconButtonProps {
  iconClassName: string;
  onClick: (event: SyntheticEvent) => void;
  ariaLabel: string;
  title: string;
  textColorClass?: string;
  buttonClassName?: string;
}

function IconButton({
  iconClassName,
  onClick,
  ariaLabel,
  title,
  textColorClass,
  buttonClassName,
}: IIconButtonProps): JSX.Element {
  return (
    <Button
      className={`bg-transparent border-0 ${textColorClass} ${buttonClassName}`}
      title={title}
      aria-label={ariaLabel}
      onClick={(e) => onClick(e)}>
      <i aria-hidden className={iconClassName} />
    </Button>
  );
}

IconButton.defaultProps = {
  textColorClass: 'text-white',
  buttonClassName: '',
};

export default IconButton;
