import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiPermissionsQuery,
  useGetApiRolesContextQuery,
  usePostApiPermissionsMutation,
  useDeleteApiPermissionsByIdMutation,
  Permission,
  useGetApiUserGroupsQuery,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import {
  clearSelectedUsers,
  selectContextUserGroup,
  selectRoleAssignmentDialogOpened,
  setRoleAssignmentDialogOpened,
} from '../../../redux/store/user-management/slice';
import { PermissionContext, RightKey } from '../../../shared/enums';
import CustomDialog from '../../dialogs/CustomDialog';
import CustomCheckbox from '../../forms/CustomCheckbox';
import Loader from '../../loader/Loader';

interface IRoleAssignmentDialogProps {
  setLastUpdatedItem: (updatedId: string) => void;
}

function RoleAssignmentDialog({
  setLastUpdatedItem,
}: IRoleAssignmentDialogProps) {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const dialogShow = useAppSelector(selectRoleAssignmentDialogOpened);
  const userGroup = useAppSelector(selectContextUserGroup);
  const [lastClickedElementId, setLastClickedElementId] = useState<
    string | null
  >(null);
  const {
    data: permissionData,
    isFetching: getPermissionIsFetching,
    isError: getPermissionsIsError,
    error: getPermissionsError,
    refetch: refetchGetPermissions,
  } = useGetApiPermissionsQuery(
    userGroup.id && dialogShow
      ? { userGroupId: userGroup.id || '' }
      : skipToken,
    { refetchOnMountOrArgChange: true },
  );
  const {
    data: roles,
    isFetching: getRolesIsFetching,
    isError: getRolesIsError,
    error: getRolesError,
  } = useGetApiRolesContextQuery(
    dialogShow
      ? {
          context: PermissionContext.System,
        }
      : skipToken,
    { refetchOnMountOrArgChange: true },
  );
  const { refetch: refetchGetUserGroups } = useGetApiUserGroupsQuery({
    includeUsers: true,
    includePermittedActions: true,
  });
  const [
    addRoleToUserGroup,
    { isError: postPermissionIsError, error: postPermissionError },
  ] = usePostApiPermissionsMutation();
  const [
    removeRoleAssignmentFromUserGroup,
    { isError: deletePermissionIsError, error: deletePermissionError },
  ] = useDeleteApiPermissionsByIdMutation();

  const thereAreRoles = roles?.resultObject && roles.resultObject.length > 0;
  const isFetching = getPermissionIsFetching || getRolesIsFetching;

  // permission
  const userCanAddRoleAssignentToUserGroup =
    userGroup.permittedActions?.includes(
      RightKey.RightPermissionManagementCreate,
    ) || false;

  const userCanDeleteRoleAssignmentToUserGroup =
    userGroup.permittedActions?.includes(
      RightKey.RightPermissionManagementDelete,
    ) || false;

  useEffect(() => {
    if (postPermissionIsError) {
      dispatch(
        addMessage({
          id: 'CreatePermissionError',
          variant: 'danger',
          messageKeyBody:
            postPermissionError && 'data' in postPermissionError
              ? postPermissionError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (getRolesIsError) {
      dispatch(
        addMessage({
          id: 'GetRolesError',
          variant: 'danger',
          messageKeyBody:
            getRolesError && 'data' in getRolesError
              ? getRolesError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (deletePermissionIsError) {
      dispatch(
        addMessage({
          id: 'DeletePermissionError',
          variant: 'danger',
          messageKeyBody:
            deletePermissionError && 'data' in deletePermissionError
              ? deletePermissionError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (getPermissionsIsError) {
      dispatch(
        addMessage({
          id: 'GetPermissionsError',
          variant: 'danger',
          messageKeyBody:
            getPermissionsError && 'data' in getPermissionsError
              ? getPermissionsError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [
    postPermissionIsError,
    getRolesIsError,
    deletePermissionIsError,
    getPermissionsIsError,
  ]);

  const handleAddRoleToUserGroup = (roleId: string) => {
    addRoleToUserGroup({
      permission: { roleId, userGroupId: userGroup.id || '' },
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'CreatePermissionSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetchGetPermissions();
        setLastClickedElementId(roleId);
      });
  };

  const handleDeleteRoleAssignment = (permission: Permission) => {
    removeRoleAssignmentFromUserGroup({
      id: permission.id || '',
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'DeletePermissionSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetchGetPermissions();
        setLastClickedElementId(permission.roleId || '');
      });
  };

  useEffect(() => {
    if (lastClickedElementId) {
      document.getElementById(lastClickedElementId)?.focus();
    }
  }, [permissionData]);

  return (
    <CustomDialog
      titleId='RoleAssignmentDialog'
      dialogTitle={translation('systemRoleAssignment')}
      show={dialogShow}
      closeFunction={() => {
        refetchGetUserGroups();
        dispatch(clearSelectedUsers());
        dispatch(setRoleAssignmentDialogOpened(false));
        setLastUpdatedItem(userGroup.id ?? '');
      }}
      closeTitle={translation('close')}>
      <p className='fw-bold'>
        {`${translation('editRightsForFollowingGroup')} ${userGroup.name}`}
      </p>
      <p className='m-0'>{translation('assignedRoles')}</p>
      <div className='permission-container' aria-busy={isFetching}>
        {isFetching && <Loader />}
        {!isFetching && thereAreRoles && (
          <ListGroup>
            {roles?.resultObject?.map((role) => {
              const permission: Permission | undefined =
                permissionData?.resultObject?.permissions?.find(
                  (p) => p.roleId === role.id,
                );
              return (
                <ListGroup.Item key={role.id}>
                  <CustomCheckbox
                    checked={typeof permission !== 'undefined'}
                    readonly={
                      !userCanAddRoleAssignentToUserGroup &&
                      !userCanDeleteRoleAssignmentToUserGroup
                    }
                    onChange={(checked) => {
                      if (checked && userCanAddRoleAssignentToUserGroup) {
                        handleAddRoleToUserGroup(role.id || '');
                      }
                      if (!checked && userCanDeleteRoleAssignmentToUserGroup) {
                        handleDeleteRoleAssignment(permission || {});
                      }
                    }}
                    controlId={role.id || ''}
                    label={role.name || ''}
                  />
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        )}
        {!isFetching && !thereAreRoles && (
          <p>{translation('thereAreNoRoles')}</p>
        )}
      </div>
    </CustomDialog>
  );
}

export default RoleAssignmentDialog;
