import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiNotesByVersionIdQuery,
  usePutApiNotesMoveByIdMutation,
} from '../../../redux/store/api/api';
import {
  selectActiveVersion,
  setActiveNote,
} from '../../../redux/store/content/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';
import { ExtendedNote } from '../types';

interface IMoveNoteDialogProps {
  show: boolean;
  setShow: (show: boolean) => void;
  setMoveNoteIsStarted: (val: boolean) => void;
  note: ExtendedNote;
  targetPosition: number;
  resetMoveParams: () => void;
}

function MoveNoteDialog({
  show,
  setShow,
  setMoveNoteIsStarted,
  note,
  targetPosition,
  resetMoveParams,
}: IMoveNoteDialogProps) {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const version = useAppSelector(selectActiveVersion);

  const [moveNote, { isError, isLoading, error }] =
    usePutApiNotesMoveByIdMutation();

  const { refetch } = useGetApiNotesByVersionIdQuery(
    version.id
      ? {
          versionId: version.id,
        }
      : skipToken,
  );

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'MoveNoteError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  const handleMoveNote = (): void => {
    moveNote({ id: note.id || '', targetPosition })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'MoveNoteSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        dispatch(setActiveNote(note));
        resetMoveParams();
        refetch();
        setMoveNoteIsStarted(false);
        setShow(false);
      });
  };

  return (
    <CustomDialog
      titleId='MoveNoteDialog'
      dialogTitle={translation('moveNote')}
      show={show}
      closeFunction={() => {
        setMoveNoteIsStarted(false);
        setShow(false);
      }}
      actionFunction={handleMoveNote}
      closeTitle={translation('cancel')}
      actionTitle={translation('move')}
      actionButtonDisabled={isLoading}>
      {isLoading && <Loader />}
      {!isLoading && <p>{translation('moveNoteToThisPosition')}</p>}
    </CustomDialog>
  );
}

export default MoveNoteDialog;
