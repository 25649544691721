import { useEffect } from 'react';
import parse from 'html-react-parser';
import { useGetApiSettingsUserQuery } from '../../redux/store/api/api';
import { settingTypeInformationText } from '../../shared/constants';
import { useAppDispatch } from '../../redux/hooks';
import { addMessage } from '../../redux/store/layout/slice';

function DashboardText(): JSX.Element {
  const dispatch = useAppDispatch();
  const { data: settings, isError, error } = useGetApiSettingsUserQuery();
  const infoSetting = settings?.resultObject?.find(
    (s) => s.settingType?.key === settingTypeInformationText,
  );

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetSettingError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  return (
    <>
      {!infoSetting?.value && <p />}
      {infoSetting?.value && <>{parse(infoSetting.value || '')}</>}
    </>
  );
}

export default DashboardText;
