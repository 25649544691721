import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import CustomCard from '../cards/CustomCard';
import CreateAttachmentDialog from './dialogs/CreateAttachmentDialog';
import AttachmentsList from './AttachmentsList';
import DeleteAttachmentDialog from './dialogs/DeleteAttachmentDialog';
import RenameAttachmentDialog from './dialogs/RenameAttachmentDialog';
import {
  selectActiveVersion,
  setAddAttachmentDialogOpened,
} from '../../redux/store/content/slice';
import { EDIT_MODE_KEY } from '../../shared/constants';
import { RightKey } from '../../shared/enums';
import useGetCategoryByArticleId from '../../hooks/useGetCategoryByArticleId';

function Attachments() {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);
  const activeVersion = useAppSelector(selectActiveVersion);

  const category = useGetCategoryByArticleId(activeVersion.articleId);

  // permission
  const userCanEditArticle = category?.permittedActions?.includes(
    RightKey.RightArticleManagementEditArticle,
  );

  return (
    <>
      <CustomCard
        helpId='help_3_6'
        expandable
        title={translation('attachments').toUpperCase()}
        iconClass='icon-attachments'
        actions={
          editModeIsActive && userCanEditArticle
            ? [
                {
                  name: translation('addAttachment'),
                  iconClassName: 'icon-plus',
                  onClick: () => {
                    dispatch(setAddAttachmentDialogOpened(true));
                  },
                },
              ]
            : undefined
        }>
        <AttachmentsList />
      </CustomCard>
      <CreateAttachmentDialog />
      <RenameAttachmentDialog />
      <DeleteAttachmentDialog />
    </>
  );
}

export default Attachments;
