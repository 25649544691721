import { PropsWithChildren } from 'react';
import { Accordion } from 'react-bootstrap';
import './Accordion.scss';

interface ICustomAccordionProps {
  defaultActiveKey?: string;
  activeKey: string;
}

function CustomAccordion({
  children,
  activeKey,
  defaultActiveKey,
}: PropsWithChildren<ICustomAccordionProps>): JSX.Element {
  return (
    <Accordion defaultActiveKey={defaultActiveKey} activeKey={activeKey}>
      {children}
    </Accordion>
  );
}

CustomAccordion.defaultProps = { defaultActiveKey: '' };

export default CustomAccordion;
