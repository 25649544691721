import { baseApi as api } from './baseApi';

const statusCodeOk = 200;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    articlesExportById: build.mutation<
      ArticlesExportByIdResponse,
      ArticlesExportById
    >({
      query: (queryArg) => ({
        url: `/api/articles/export/${queryArg.id}`,
        method: 'POST',
        params: { includeVersions: queryArg.includeVersions },
        responseHandler: async (response) => {
          if (response.status === statusCodeOk) {
            return window.URL.createObjectURL(await response.blob());
          }
          return response;
        },
      }),
    }),
    categoriesExportById: build.mutation<
      CategoriesExportByIdResponse,
      CategoriesExportByIdArg
    >({
      query: (queryArg) => ({
        url: `/api/categories/export/${queryArg.id}`,
        method: 'POST',
        responseHandler: async (response) => {
          if (response.status === statusCodeOk) {
            return window.URL.createObjectURL(await response.blob());
          }
          return response;
        },
      }),
    }),
    filesById: build.mutation<
      PostApiFilesByIdApiResponse,
      PostApiFilesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/files/${queryArg.id}`,
        method: 'POST',
        responseHandler: async (response) => {
          if (response.status === statusCodeOk) {
            return window.URL.createObjectURL(await response.blob());
          }
          return response;
        },
      }),
    }),
    filesVersionById: build.mutation<
      PostApiFilesVersionByIdApiResponse,
      PostApiFilesVersionByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/files/version/${queryArg.id}`,
        method: 'POST',
        responseHandler: async (response) => {
          if (response.status === statusCodeOk) {
            return window.URL.createObjectURL(await response.blob());
          }
          return response;
        },
      }),
    }),
  }),
});
export { injectedRtkApi as api };
export type ArticlesExportByIdResponse = /** status 200 Success */ string;
export type ArticlesExportById = {
  /** Key Id (22 characters) */
  id: string;
  /** Indicates if all versions should be exported */
  includeVersions?: boolean;
};
export type CategoriesExportByIdResponse = /** status 200 Success */ string;
export type CategoriesExportByIdArg = {
  /** Key Id (22 characters) */
  id: string;
};

export type PostApiFilesByIdApiResponse = /** status 200 Success */ string;
export type PostApiFilesByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};

export type PostApiFilesVersionByIdApiResponse =
  /** status 200 Success */ string;
export type PostApiFilesVersionByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};

export const {
  useArticlesExportByIdMutation,
  useCategoriesExportByIdMutation,
  useFilesByIdMutation,
  useFilesVersionByIdMutation,
} = injectedRtkApi;
