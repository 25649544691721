import { Mark } from '../../../redux/store/api/api';
import { CALCULATION_CONTENT_CONTAINER_ID } from '../../../shared/constants';
import getValidatedMark from '../../marks/functions';
import {
  getStartNodeContainerId,
  getPrecedingCharacters,
  getSubsequentCharacters,
} from './convertFromRange';
import convertToRange from './convertToRange';

const getMigratedMark = (mark: Mark, htmlContent: string): Mark | null => {
  if (
    mark.precedingCharacters === null ||
    mark.subsequentCharacters === null ||
    mark.startNodeContainerId === null
  ) {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(
      `<div id='${CALCULATION_CONTENT_CONTAINER_ID}'>${htmlContent}</div>`,
      'text/html',
    );

    // Create range from mark
    const range = convertToRange(
      htmlDoc,
      CALCULATION_CONTENT_CONTAINER_ID,
      mark,
    );

    if (range) {
      // Add additional data to mark based on positon of range in document
      const startNodeContainerId = getStartNodeContainerId(
        range.startContainer.parentElement,
      );
      const precedingCharacters = getPrecedingCharacters(
        range.cloneRange(),
        CALCULATION_CONTENT_CONTAINER_ID,
        htmlDoc,
      );
      const subsequentCharacters = getSubsequentCharacters(
        range.cloneRange(),
        CALCULATION_CONTENT_CONTAINER_ID,
        htmlDoc,
      );
      return getValidatedMark(
        {
          ...mark,
          markInvalid: false,
          precedingCharacters,
          subsequentCharacters,
          startNodeContainerId,
        },
        mark,
      );
    }
  }
  return null;
};

export default getMigratedMark;
