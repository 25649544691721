import { PropsWithChildren, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, Navigate, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import {
  EDIT_MODE_KEY,
  settingTypeApplicationName,
} from '../../shared/constants';
import {
  ApplicationPaths,
  QueryParameterNames,
} from './ApiAuthorizationConstants';
import authService from './AuthorizeService';
import { useGetApiSettingsPublicBySettingTypeKeyQuery } from '../../redux/store/api/api';
import { useAppDispatch } from '../../redux/hooks';
import { addMessage } from '../../redux/store/layout/slice';

interface IAuthorizeRouteProps {
  forceLogin?: boolean;
  accessibleWithoutEditMode?: boolean;
  title: string;
}

function AuthorizeRoute({
  forceLogin,
  accessibleWithoutEditMode,
  children,
  title,
}: PropsWithChildren<IAuthorizeRouteProps>): JSX.Element | null {
  const location = useLocation();
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);
  const navigate = useNavigate();
  const [ready, setReady] = useState<boolean>(false);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  let subscription: number;

  const populateAuthenticationState = async () => {
    const isAuthenticated = await authService.isAuthenticated();
    setAuthenticated(isAuthenticated);
    setReady(true);
  };

  const {
    data: applicationNameSetting,
    isError,
    error,
  } = useGetApiSettingsPublicBySettingTypeKeyQuery({
    settingTypeKey: settingTypeApplicationName,
  });

  const authenticationChanged = async () => {
    setReady(false);
    setAuthenticated(false);
    await populateAuthenticationState();
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetSettingError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  useEffect(() => {
    if (!accessibleWithoutEditMode && !editModeIsActive) {
      navigate('/');
    }
  }, [editModeIsActive]);

  useEffect(() => {
    document.title = `${
      applicationNameSetting?.resultObject?.value
        ? `${applicationNameSetting.resultObject.value} - `
        : ''
    }${title}`;
  }, [title, applicationNameSetting]);

  useLayoutEffect(() => {
    subscription = authService.subscribe(() => authenticationChanged());
    populateAuthenticationState();

    return () => {
      authService.unsubscribe(subscription);
    };
  }, []);

  const link = document.createElement('a');
  link.href = `${location.pathname}${location.search}`;

  const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
  const redirectUrl = `${ApplicationPaths.Login}?${
    QueryParameterNames.ReturnUrl
  }=${encodeURIComponent(returnUrl)}`;

  const anonymousView = (): JSX.Element | null =>
    forceLogin ? <Navigate to={redirectUrl} replace /> : null;

  const readyView = (): JSX.Element | null =>
    // eslint-disable-next-line react/jsx-no-useless-fragment
    authenticated ? <>{children}</> : anonymousView();

  return ready ? readyView() : null;
}

AuthorizeRoute.defaultProps = {
  forceLogin: false,
  accessibleWithoutEditMode: false,
};

export default AuthorizeRoute;
