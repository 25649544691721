import { PropsWithChildren, useEffect, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useEventListener } from 'usehooks-ts';
import './CustomDialog.scss';
import { focusableElementsString } from '../../shared/constants';

interface ICustomDialogProps {
  dialogId?: string;
  titleId: string;
  show: boolean;
  closeFunction: () => void;
  actionFunction?: (() => void) | null;
  dialogTitle: string;
  closeTitle: string;
  actionTitle?: string;
  actionButtonDisabled?: boolean;
  actionButtonVariant?: string;
  wideDialog?: boolean;
  idOfFocusElement?: string;
}

function CustomDialog({
  dialogId,
  titleId,
  show,
  closeFunction,
  actionFunction,
  dialogTitle,
  closeTitle,
  actionTitle,
  actionButtonDisabled,
  actionButtonVariant,
  children,
  wideDialog,
  idOfFocusElement,
}: PropsWithChildren<ICustomDialogProps>): JSX.Element {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const dialogContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (show) {
      if (idOfFocusElement) {
        document.getElementById(idOfFocusElement)?.focus();
        return;
      }

      titleRef.current?.focus();
    }
  }, [show]);

  useEventListener(
    'keydown',
    (e) => {
      const focusableElements = dialogContentRef.current?.querySelectorAll(
        focusableElementsString,
      );

      if (!focusableElements) {
        return;
      }

      const firstElement = focusableElements[0];

      if (
        e.key === 'Tab' &&
        e.shiftKey &&
        document.activeElement === firstElement
      ) {
        e.preventDefault();

        const footerButtons = footerRef.current?.getElementsByTagName('button');

        if (!footerButtons) {
          return;
        }

        if (actionButtonDisabled) {
          footerButtons[0].focus();
        } else {
          footerButtons[1].focus();
        }
      }
    },
    dialogContentRef,
  );

  return (
    <Modal
      id={dialogId}
      aria-labelledby={titleId}
      className={wideDialog ? 'wide-dialog' : undefined}
      centered
      scrollable
      show={show}
      onHide={() => {
        closeFunction();
      }}>
      <Modal.Header className='bg-transparent border-0'>
        <Modal.Title
          id={titleId}
          ref={titleRef}
          as='h2'
          className='text-body w-100'
          tabIndex={-1}>
          {dialogTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={dialogContentRef}>{children}</Modal.Body>
      <Modal.Footer ref={footerRef} className='border-0'>
        <Button
          variant='outline-dark'
          onClick={() => {
            closeFunction();
          }}>
          {closeTitle}
        </Button>
        {actionTitle !== '' && actionFunction && (
          <Button
            disabled={actionButtonDisabled}
            variant={actionButtonVariant}
            onClick={() => {
              actionFunction();
            }}>
            {actionTitle}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

CustomDialog.defaultProps = {
  actionButtonVariant: 'outline-success',
  actionButtonDisabled: false,
  actionFunction: null,
  actionTitle: '',
  wideDialog: false,
  idOfFocusElement: null,
  dialogId: null,
};

export default CustomDialog;
