import { useMemo } from 'react';
import { useGetApiCategoryTreeQuery } from '../redux/store/api/api';

export function useGetArticleIdsForElementIds(ids: string[]): string[] {
  const { data: contentTreeData } = useGetApiCategoryTreeQuery();

  return useMemo<string[]>(() => {
    if (ids.length < 1) {
      return [];
    }

    if (contentTreeData?.resultObject?.articles) {
      return contentTreeData.resultObject.articles
        .filter((a) => ids.includes(a.id || ''))
        .map((a) => a.id || '');
    }

    return [];
  }, [ids, contentTreeData]);
}

export function useGetCategoryIdsForElementIds(ids: string[]): string[] {
  const { data: contentTreeData } = useGetApiCategoryTreeQuery();

  return useMemo<string[]>(() => {
    if (ids.length < 1) {
      return [];
    }

    if (contentTreeData?.resultObject?.categories) {
      return contentTreeData.resultObject.categories
        .filter((c) => ids.includes(c.id || ''))
        .map((a) => a.id || '');
    }

    return [];
  }, [ids, contentTreeData]);
}
