interface IDescriptiveIconProps {
  colorClass?: string;
  colorHexCode?: string;
  iconClass: string;
  description: string;
}

function DescriptiveIcon({
  colorClass,
  colorHexCode,
  iconClass,
  description,
}: IDescriptiveIconProps) {
  return (
    <>
      <i
        className={`${iconClass} ${colorClass ?? ''} me-1`}
        aria-hidden
        style={colorHexCode ? { color: colorHexCode } : undefined}
        title={description}
      />
      <span className='visually-hidden'>{description}</span>
    </>
  );
}

DescriptiveIcon.defaultProps = {
  colorClass: undefined,
  colorHexCode: undefined,
};

export default DescriptiveIcon;
