import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import _ from 'lodash';
import CustomCard from '../cards/CustomCard';
import Loader from '../loader/Loader';
import CustomListGroup, { ListItem } from '../lists/CustomListGroup';
import {
  SettingContainer,
  useGetApiSettingsContainersGlobalsQuery,
} from '../../redux/store/api/api';
import { useAppDispatch } from '../../redux/hooks';
import { addMessage } from '../../redux/store/layout/slice';
import { RightKey, SettingContainerType } from '../../shared/enums';

interface IGlobalSettingListProps {
  activeSettingContainer: SettingContainer;
  setActiveSettingContainer: (container: SettingContainer) => void;
  rights: RightKey[];
}

function GlobalSettingList({
  activeSettingContainer,
  setActiveSettingContainer,
  rights,
}: IGlobalSettingListProps) {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    data: globalSettingsData,
    isLoading,
    isError,
    error,
  } = useGetApiSettingsContainersGlobalsQuery(
    {
      type: SettingContainerType.AllGlobalSettings,
      includeSettings: false,
    },
    { refetchOnMountOrArgChange: true },
  );

  // permissions
  const userCanReadGlobalSetting = rights.includes(
    RightKey.RightSettingsManagementReadSettingsGlobal,
  );

  const getListItem = (container: SettingContainer): ListItem => {
    const isDefaultSetting =
      container.settingContainerType ===
        SettingContainerType.ApplicationSettingUserGroup ||
      container.settingContainerType === SettingContainerType.ReportError ||
      container.settingContainerType === SettingContainerType.Editor;
    return {
      id: container.id || '',
      content: `${container.name}${
        isDefaultSetting ? ` (${translation('defaultValues')})` : ''
      }`,
      onClick: userCanReadGlobalSetting
        ? () => {
            setActiveSettingContainer(container || {});
          }
        : undefined,
    };
  };

  useEffect(() => {
    // Set first setting container as default when none is set
    if (globalSettingsData?.resultObject && _.isEmpty(activeSettingContainer)) {
      setActiveSettingContainer(globalSettingsData.resultObject[0]);
    }
  }, [globalSettingsData]);

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetGlobalSettingContainersError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  return (
    <CustomCard
      title={translation('globalSettingsSettingGroups')}
      focusableHeadline
      headlineAsH1>
      {isLoading && <Loader />}
      {!isLoading && globalSettingsData?.resultObject && (
        <CustomListGroup
          activeListItem={activeSettingContainer.id || ''}
          listItems={globalSettingsData.resultObject.map((container) =>
            getListItem(container),
          )}
        />
      )}
    </CustomCard>
  );
}

export default GlobalSettingList;
