import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage, useSessionStorage } from 'usehooks-ts';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiArticlesReferencesByIdQuery,
  useGetApiCategoriesArticleReferencesByIdQuery,
  useGetApiCategoryTreeQuery,
  useGetApiRecycleBinQuery,
  usePutApiCategoryTreeRecycleMutation,
} from '../../../redux/store/api/api';
import {
  selectContextArticle,
  selectContextCategory,
  selectElementsToRycycleBinDialogOpened,
  setContextArticle,
  setContextCategory,
  setElementsToRecycleBinDialogOpened,
} from '../../../redux/store/content/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';
import {
  EDIT_MODE_KEY,
  EXPANDED_CATEGORY_IDS,
  articleIdUrlParam,
  categoryIdUrlParam,
} from '../../../shared/constants';
import {
  useGetArticleIdsForElementIds,
  useGetCategoryIdsForElementIds,
} from '../../../hooks/useGetArticlesOrCategoriesForElementIds';

interface IElementsToRecycleBinDialogProps {
  selectedIds: string[];
}

function ElementsToRecycleBinDialog({
  selectedIds,
}: IElementsToRecycleBinDialogProps): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const dialogShow = useAppSelector(selectElementsToRycycleBinDialogOpened);
  const articles = useGetArticleIdsForElementIds(dialogShow ? selectedIds : []);
  const categories = useGetCategoryIdsForElementIds(
    dialogShow ? selectedIds : [],
  );
  const contextCategory = useAppSelector(selectContextCategory);
  const contextArticle = useAppSelector(selectContextArticle);
  const [searchParams] = useSearchParams();
  const articleIdInParam: string | null = searchParams.get(articleIdUrlParam);
  const categoryIdInParam = searchParams.get(categoryIdUrlParam);
  const navigate = useNavigate();
  const { refetch: refetchArticleReferences } =
    useGetApiArticlesReferencesByIdQuery(
      articleIdInParam
        ? {
            id: articleIdInParam,
          }
        : skipToken,
    );
  const { refetch: refetchCategoryReferences } =
    useGetApiCategoriesArticleReferencesByIdQuery(
      categoryIdInParam
        ? {
            id: categoryIdInParam,
          }
        : skipToken,
    );
  const [moveArticleToRecycleBin, { isError, isLoading, error }] =
    usePutApiCategoryTreeRecycleMutation();
  const { refetch: refetchGetCategoriesTree } = useGetApiCategoryTreeQuery();
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);
  const { refetch: refetchGetCategoryRecycleBin } = useGetApiRecycleBinQuery(
    !editModeIsActive ? skipToken : undefined,
  );
  const [
    expandedCategoriesInSessionStorage,
    setExpandedCategoriesInSessionStorage,
  ] = useSessionStorage<string[]>(EXPANDED_CATEGORY_IDS, []);

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'MoveElementsToRecycleBinError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  const handleMoveElementsToRecycleBin = (): void => {
    moveArticleToRecycleBin({
      categoryTreeBatchOperationItems: {
        articleIds: contextArticle.id ? [contextArticle.id] : articles,
        categoryIds: contextCategory.id ? [contextCategory.id] : categories,
      },
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'MoveElementsToRecycleBinSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        // correct expandedCategories
        setExpandedCategoriesInSessionStorage(
          expandedCategoriesInSessionStorage.filter(
            (i) => !selectedIds.includes(i) && i !== contextCategory.id,
          ),
        );

        dispatch(setContextCategory({}));
        dispatch(setContextArticle({}));
        dispatch(setElementsToRecycleBinDialogOpened(false));
        refetchGetCategoriesTree();
        refetchGetCategoryRecycleBin();

        // close article or refetch article references after delete
        if (articleIdInParam) {
          if (selectedIds.includes(articleIdInParam)) {
            navigate('/');
          } else {
            refetchArticleReferences();
          }
        }

        // close category overview or refetch category references after delete
        if (categoryIdInParam) {
          if (selectedIds.includes(categoryIdInParam)) {
            navigate('/');
          } else {
            refetchCategoryReferences();
          }
        }
      });
  };

  return (
    <CustomDialog
      titleId='ElementToRecycleBinDialog'
      dialogTitle={translation('moveElementsToRecycleBin')}
      show={dialogShow}
      closeFunction={() => {
        dispatch(setContextCategory({}));
        dispatch(setContextArticle({}));
        dispatch(setElementsToRecycleBinDialogOpened(false));
      }}
      actionFunction={handleMoveElementsToRecycleBin}
      closeTitle={translation('cancel')}
      actionTitle={translation('moveToRecycleBin')}
      actionButtonVariant='outline-danger'
      actionButtonDisabled={isLoading}>
      <div aria-busy={isLoading}>
        {isLoading && <Loader />}
        {!isLoading && (
          <p>
            {translation(
              contextArticle.id || contextCategory.id
                ? 'shouldMoveSelectedElementToRecycleBin'
                : 'shouldMoveSelectedElementsToRecycleBin',
            )}
          </p>
        )}
      </div>
    </CustomDialog>
  );
}

export default ElementsToRecycleBinDialog;
