import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  NamedVersion,
  useGetApiCategoryTreeQuery,
  useGetApiSettingsUserQuery,
  useGetApiVersionsArticleByIdQuery,
  useGetApiVersionsInvalidMarksQuery,
  usePostApiVersionsFileMutation,
  usePostApiVersionsMutation,
  usePutApiVersionsByIdMutation,
  usePutApiVersionsFileMutation,
  Version,
} from '../../redux/store/api/api';
import {
  selectActiveVersion,
  selectCurrentVersions,
  setActiveVersion,
  setMaxNumberOfVersionsDialogOpened,
} from '../../redux/store/content/slice';
import { addMessage } from '../../redux/store/layout/slice';
import { ArticleType } from '../../shared/enums';
import { formatToInputDateString } from '../../shared/utils';
import { IVersionUpdateFileUpload } from '../article/types';
import CustomCard from '../cards/CustomCard';
import Loader from '../loader/Loader';
import VersionForm from './VersionForm';
import VersionSelect from './VersionSelect';
import useGetCategoryByArticleId from '../../hooks/useGetCategoryByArticleId';
import {
  EDIT_MODE_KEY,
  lineBreakRegex,
  settingTypeMaxVersionCount,
} from '../../shared/constants';
import ValidityDateIsNotChangedDialog from './dialogs/ValidityDateIsNotChangedDialog';
import VersionSaveDialog from './dialogs/VersionSaveDialog';
import { getArticleUrl, getEditDraftUrl } from '../../shared/urlBuilder';
import MaxNumberOfVersionsDialog from './dialogs/MaxNumberOfVersionsDialog';

function EditVersionForm(): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const activeVersion = useAppSelector(selectActiveVersion);
  const versions = useAppSelector(selectCurrentVersions);
  const [versionContent, setVersionContent] = useState(
    activeVersion.htmlContent || '',
  );
  const [versionTitle, setVersionTitle] = useState(activeVersion.title || '');
  const [validityDate, setValidityDate] = useState(
    activeVersion.validFrom
      ? formatToInputDateString(activeVersion.validFrom)
      : '',
  );
  const [publicationDateStart, setPublicationDateStart] = useState(
    activeVersion.publishedFrom
      ? formatToInputDateString(activeVersion.publishedFrom)
      : '',
  );
  const [publicationDateEnd, setPublicationDateEnd] = useState(
    activeVersion.publishedUntil
      ? formatToInputDateString(activeVersion.publishedUntil)
      : '',
  );
  const defaultSaveAsValue = '0';
  const [saveAsValue, setSaveAsValue] = useState(defaultSaveAsValue);
  const [file, setFile] = useState<File | null>(null);
  const [isValidFile, setIsValidFile] = useState<boolean>(true);
  const [
    validityDateNotChangedDialogOpened,
    setValidityDateNotChangedDialogOpened,
  ] = useState(false);
  const [isValidTitle, setIsValidTitle] = useState<boolean>(true);
  const [isValidValidityDate, setIsValidValidityDate] = useState(true);
  const [versionSaveDialogOpened, setVersionSaveDialogOpened] = useState(false);
  const [
    addVersion,
    {
      isError: addVersionIsError,
      error: addVersionError,
      isLoading: addVersionIsLoading,
    },
  ] = usePostApiVersionsMutation();
  const [
    addVersionFile,
    {
      isError: addVersionFileIsError,
      error: addVersionFileError,
      isLoading: addVersionFileIsLoading,
    },
  ] = usePostApiVersionsFileMutation();

  const [
    updateVersion,
    {
      isError: updateVersionIsError,
      error: updateVersionError,
      isLoading: updateVersionIsLoading,
    },
  ] = usePutApiVersionsByIdMutation();
  const [
    updateVersionFile,
    { isError: updateVersionFileIsError, error: updateVersionFileError },
  ] = usePutApiVersionsFileMutation();
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);
  const { refetch: refetchVersions } = useGetApiVersionsArticleByIdQuery(
    activeVersion.articleId
      ? { id: activeVersion.articleId, editMode: editModeIsActive }
      : skipToken,
  );
  const { refetch: refetchCategoryTree } = useGetApiCategoryTreeQuery();
  const { refetch: refetchInvalidMarks } = useGetApiVersionsInvalidMarksQuery();

  const versionTitleIsChanged = activeVersion.title !== versionTitle.trim();
  const validityDateIsChanged =
    formatToInputDateString(activeVersion.validFrom || '') !==
    formatToInputDateString(validityDate);
  const publicationDateIsChanged =
    formatToInputDateString(activeVersion.publishedFrom || '') !==
      formatToInputDateString(publicationDateStart) ||
    formatToInputDateString(activeVersion.publishedUntil || '') !==
      formatToInputDateString(publicationDateEnd);

  const valuesNotChanged =
    !file &&
    activeVersion.htmlContent?.replace(lineBreakRegex, '') ===
      versionContent.replace(lineBreakRegex, '') &&
    !versionTitleIsChanged &&
    !validityDateIsChanged &&
    !publicationDateIsChanged;

  const { data: settings } = useGetApiSettingsUserQuery();
  const versionMaxSetting =
    Number(
      settings?.resultObject?.find(
        (s) => s.settingType?.key === settingTypeMaxVersionCount,
      )?.value,
    ) || undefined;

  useEffect(() => {
    setVersionContent(activeVersion.htmlContent || '');
    setVersionTitle(activeVersion.title || '');
    setValidityDate(
      activeVersion.validFrom
        ? formatToInputDateString(activeVersion.validFrom)
        : '',
    );
    setPublicationDateStart(
      activeVersion.publishedFrom
        ? formatToInputDateString(activeVersion.publishedFrom)
        : '',
    );
    setPublicationDateEnd(
      activeVersion.publishedUntil
        ? formatToInputDateString(activeVersion.publishedUntil)
        : '',
    );
    setIsValidFile(true);
    setIsValidTitle(true);
    setIsValidValidityDate(true);
  }, [activeVersion]);

  useEffect(() => {
    if (addVersionIsError) {
      dispatch(
        addMessage({
          id: 'CreateVersionError',
          variant: 'danger',
          messageKeyBody:
            addVersionError && 'data' in addVersionError
              ? addVersionError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (addVersionFileIsError) {
      dispatch(
        addMessage({
          id: 'CreateVersionFileError',
          variant: 'danger',
          messageKeyBody:
            addVersionFileError && 'data' in addVersionFileError
              ? addVersionFileError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (updateVersionIsError) {
      dispatch(
        addMessage({
          id: 'UpdateVersionError',
          variant: 'danger',
          messageKeyBody:
            updateVersionError && 'data' in updateVersionError
              ? updateVersionError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (updateVersionFileIsError) {
      dispatch(
        addMessage({
          id: 'UpdateVersionFileError',
          variant: 'danger',
          messageKeyBody:
            updateVersionFileError && 'data' in updateVersionFileError
              ? updateVersionFileError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [
    addVersionIsError,
    addVersionFileIsError,
    updateVersionIsError,
    updateVersionFileIsError,
  ]);

  const handleCreateVersionPdf = (version: NamedVersion) => {
    const formData = new FormData();
    if (file as Blob) {
      formData.append('ArticlePdf', file as Blob);
    }
    formData.append('VersionId', version.id || '');
    formData.append('Version.Type', ArticleType.Pdf.toString());
    formData.append('Version.Disabled', version.disabled ? 'true' : 'false');
    formData.append('Version.Draft', 'false');
    formData.append('Version.Title', version.title || '');
    formData.append('Version.Name', version.name || '');
    formData.append('Version.ValidFrom', validityDate);
    formData.append('Version.PublishedFrom', publicationDateStart);
    formData.append('Version.PublishedUntil', publicationDateEnd);
    formData.append('Version.PdfFileId', version.pdfFileId || '');
    formData.append('Version.ArticleId', version.articleId || '');

    addVersionFile({
      oldVersionId: activeVersion.id || '',
      body: formData as IVersionUpdateFileUpload,
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'CreateVersionFileSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetchVersions();
        refetchInvalidMarks();
        navigate(
          getArticleUrl(
            activeVersion.articleId || '',
            result?.resultObject?.id || '',
          ),
          {
            replace: true,
          },
        );
      });
  };

  const handleUpdateVersionPdf = (version: NamedVersion) => {
    const formData = new FormData();
    if (file as Blob) {
      formData.append('ArticlePdf', file as Blob);
    }
    formData.append('VersionId', saveAsValue);
    formData.append('Version.Type', ArticleType.Pdf.toString());
    formData.append('Version.Disabled', version.disabled ? 'true' : 'false');
    formData.append('Version.Draft', 'false');
    formData.append('Version.Title', version.title || '');
    formData.append('Version.Name', version.name || '');
    formData.append('Version.ValidFrom', validityDate);
    formData.append('Version.PublishedFrom', publicationDateStart);
    formData.append('Version.PublishedUntil', publicationDateEnd);
    formData.append('Version.PdfFileId', version.pdfFileId || '');
    formData.append('Version.Id', saveAsValue);
    formData.append('Version.ArticleId', version.articleId || '');

    updateVersionFile({
      body: formData as IVersionUpdateFileUpload,
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'UpdateVersionFileSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetchVersions();
        refetchCategoryTree();
        navigate(getArticleUrl(activeVersion.articleId || '', saveAsValue), {
          replace: true,
        });
      });
  };

  const getVersionToAdd = (draftVersion = false): Version => ({
    type: activeVersion.type,
    articleId: activeVersion.articleId || '',
    disabled: activeVersion.disabled,
    draft: draftVersion,
    title: versionTitle.trim(),
    validFrom: validityDate,
    publishedFrom:
      publicationDateStart === '' ? undefined : publicationDateStart,
    publishedUntil: publicationDateEnd === '' ? undefined : publicationDateEnd,
    htmlContent: versionContent,
    pdfFileId: activeVersion.pdfFileId,
  });

  const getVersionToUpdate = (): NamedVersion => ({
    ...getVersionToAdd(),
    id: saveAsValue,
    name: activeVersion.name,
  });

  const validateInputs = () => {
    let valid = true;
    if (versionTitle.trim() === '') {
      setIsValidTitle(false);
      valid = false;
    }
    if (activeVersion.type === ArticleType.Pdf && (!file || !isValidFile)) {
      setIsValidFile(false);
      valid = false;
    }
    if (validityDate.trim() === '') {
      setIsValidValidityDate(false);
      valid = false;
    }
    return valid;
  };

  const handleAddVersion = (draftVersion = false) => {
    if (!validateInputs()) {
      return;
    }

    const version = getVersionToAdd(draftVersion);

    if (version.type === ArticleType.Pdf) {
      handleCreateVersionPdf(activeVersion);
    } else {
      addVersion({
        version,
        oldVersionId: activeVersion.id || '',
      })
        .unwrap()
        .then((result) => {
          if (result.messageKey && result.messageKey !== '') {
            dispatch(
              addMessage({
                id: 'CreateVersionSuccess',
                variant: 'success',
                messageKeyBody: result.messageKey,
              }),
            );
          }
          refetchVersions();
          refetchInvalidMarks();
          if (!draftVersion) {
            dispatch(setActiveVersion(result?.resultObject || {}));
            refetchCategoryTree();
            navigate(
              getArticleUrl(
                activeVersion.articleId || '',
                result?.resultObject?.id || undefined,
              ),
              {
                replace: true,
              },
            );
          } else {
            navigate(
              getEditDraftUrl(
                activeVersion.articleId || '',
                result?.resultObject?.id || '',
              ),
              {
                replace: true,
              },
            );
          }
        });
    }
  };

  const handleUpdateVersion = () => {
    const version = getVersionToUpdate();

    if (version.type === ArticleType.Pdf) {
      handleUpdateVersionPdf(version);
    } else {
      updateVersion({
        id: saveAsValue,
        namedVersion: version,
      })
        .unwrap()
        .then((result) => {
          if (result.messageKey && result.messageKey !== '') {
            dispatch(
              addMessage({
                id: 'UpdateVersionSuccess',
                variant: 'success',
                messageKeyBody: result.messageKey,
              }),
            );
          }
          refetchVersions();
          refetchInvalidMarks();
          if (
            (versionTitleIsChanged && activeVersion.isRecentVersion) ||
            validityDateIsChanged ||
            publicationDateIsChanged
          ) {
            refetchCategoryTree();
          }
          navigate(getArticleUrl(activeVersion.articleId || '', saveAsValue), {
            replace: true,
          });
        });
    }
  };

  const handleOnClickSaveVersion = () => {
    if (!validateInputs()) {
      return;
    }

    if (
      saveAsValue === defaultSaveAsValue &&
      new Date(
        formatToInputDateString(activeVersion.validFrom || ''),
      ).getTime() >= new Date(validityDate).getTime()
    ) {
      setValidityDateNotChangedDialogOpened(true);
      return;
    }

    if (
      saveAsValue === defaultSaveAsValue &&
      versionMaxSetting &&
      versions.length >= versionMaxSetting
    ) {
      dispatch(setMaxNumberOfVersionsDialogOpened(true));
      return;
    }

    setVersionSaveDialogOpened(true);
  };

  const category = useGetCategoryByArticleId(activeVersion.articleId);
  const isLoading =
    addVersionIsLoading || addVersionFileIsLoading || updateVersionIsLoading;

  return (
    <div className='article-content'>
      <CustomCard
        helpId='help_3_4'
        focusableHeadline
        headlineAsH1
        iconClass={category?.categoryTypeIconClass || ''}
        title={translation('editArticle')}
        customCardStyle={{ flex: 1 }}>
        <div aria-busy={isLoading}>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <VersionForm
                versionContent={versionContent}
                setVersionContent={setVersionContent}
                versionTitle={versionTitle}
                setVersionTitle={setVersionTitle}
                validityDate={validityDate}
                setValidityDate={setValidityDate}
                publicationStart={publicationDateStart}
                setPublicationStart={setPublicationDateStart}
                publicationEnd={publicationDateEnd}
                setPublicationEnd={setPublicationDateEnd}
                pdfArticle={activeVersion.type === ArticleType.Pdf}
                setFile={setFile}
                file={file || undefined}
                setIsValidFile={setIsValidFile}
                isValidFile={isValidFile}
                isValidTitle={isValidTitle}
                setIsValidTitle={setIsValidTitle}
                isValidValidityDate={isValidValidityDate}
                setIsValidValidityDate={setIsValidValidityDate}
              />
              <VersionSelect
                defaultSaveAsValue={defaultSaveAsValue}
                saveAsValue={saveAsValue}
                setSaveAsValue={setSaveAsValue}
              />
              <div className='text-end'>
                <Link
                  className='btn btn-outline-danger me-1 mt-1'
                  to={getArticleUrl(
                    activeVersion.articleId || '',
                    activeVersion.id || undefined,
                  )}>
                  {translation('discard')}
                </Link>
                {activeVersion.type !== ArticleType.Pdf && (
                  <Button
                    variant='outline-dark'
                    className='me-1 mt-1'
                    onClick={() => {
                      handleAddVersion(true);
                    }}>
                    {translation('saveAsDraft')}
                  </Button>
                )}
                <Button
                  variant='outline-success'
                  className='me-1 mt-1'
                  disabled={valuesNotChanged}
                  onClick={handleOnClickSaveVersion}>
                  {translation('saveVersion')}
                </Button>
              </div>
            </>
          )}
        </div>
      </CustomCard>
      <ValidityDateIsNotChangedDialog
        dialogShow={validityDateNotChangedDialogOpened}
        setDialogShow={setValidityDateNotChangedDialogOpened}
      />
      <VersionSaveDialog
        dialogShow={versionSaveDialogOpened}
        setDialogShow={setVersionSaveDialogOpened}
        saveAsNewVersion={saveAsValue === defaultSaveAsValue}
        handleAddVersion={handleAddVersion}
        handleUpdateVersion={handleUpdateVersion}
      />
      <MaxNumberOfVersionsDialog />
    </div>
  );
}

export default EditVersionForm;
