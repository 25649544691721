import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../../redux/hooks';
import {
  ArticleReference,
  ExternalReference,
  useGetApiArticlesReferencesByIdQuery,
  useGetApiCategoriesArticleReferencesByIdQuery,
  usePutApiArticleReferencesByIdMutation,
  usePutApiExternalReferencesByIdMutation,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import {
  articleIdUrlParam,
  categoryIdUrlParam,
} from '../../../shared/constants';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';

interface IRenameReferenceDialogProps {
  reference: ArticleReference | ExternalReference;
  dialogShow: boolean;
  setDialogShow: (show: boolean) => void;
}

function RenameReferenceDialog({
  reference,
  dialogShow,
  setDialogShow,
}: IRenameReferenceDialogProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { t: translation } = useTranslation();
  const [searchParams] = useSearchParams();
  const articleId: string | null = searchParams.get(articleIdUrlParam);
  const categoryId: string | null = searchParams.get(categoryIdUrlParam);
  const isArticleReference = !!(reference as ArticleReference).sourceArticleId;
  const defaultReferenceName =
    (isArticleReference &&
    (reference as ArticleReference).targetArticleId === articleId
      ? (reference as ArticleReference).backwardName
      : reference.name) || '';
  const originalReferenceName =
    (isArticleReference &&
    (reference as ArticleReference).targetArticleId === articleId
      ? (reference as ArticleReference).originalBackwardName
      : (reference as ArticleReference).originalName) || '';
  const [referenceName, setReferenceName] = useState(defaultReferenceName);
  const [referenceNameIsValid, setReferenceNameIsValid] = useState(true);
  const [
    updateArticleReference,
    {
      isError: updateArticleReferenceIsError,
      error: updateArticleReferenceError,
      isLoading: updateArticleReferenceIsLoading,
    },
  ] = usePutApiArticleReferencesByIdMutation();
  const [
    updateExternalReference,
    {
      isError: updateExternalReferenceIsError,
      error: updateExternalReferenceError,
      isLoading: updateExternalReferenceIsLoading,
    },
  ] = usePutApiExternalReferencesByIdMutation();
  const isLoading =
    updateArticleReferenceIsLoading || updateExternalReferenceIsLoading;
  const { refetch: refetchGetArticleReferences } =
    useGetApiArticlesReferencesByIdQuery(
      articleId
        ? {
            id: articleId,
          }
        : skipToken,
    );
  const { refetch: refetchGetCategoryReferences } =
    useGetApiCategoriesArticleReferencesByIdQuery(
      categoryId ? { id: categoryId } : skipToken,
    );

  useEffect(() => {
    if (updateArticleReferenceIsError) {
      dispatch(
        addMessage({
          id: 'UpdateArticleReferenceError',
          variant: 'danger',
          messageKeyBody:
            updateArticleReferenceError && 'data' in updateArticleReferenceError
              ? updateArticleReferenceError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (updateExternalReferenceIsError) {
      dispatch(
        addMessage({
          id: 'UpdateExternalReferenceError',
          variant: 'danger',
          messageKeyBody:
            updateExternalReferenceError &&
            'data' in updateExternalReferenceError
              ? updateExternalReferenceError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [updateArticleReferenceIsError, updateExternalReferenceIsError]);

  useEffect(() => {
    if (dialogShow) {
      setReferenceName(
        defaultReferenceName.length === 0
          ? originalReferenceName
          : defaultReferenceName,
      );
      setReferenceNameIsValid(true);
    }
  }, [dialogShow]);

  const handleUpdateReference = () => {
    if (referenceName.trim().length === 0) {
      setReferenceNameIsValid(false);
      return;
    }

    if ((reference as ArticleReference).sourceArticleId) {
      updateArticleReference({
        id: reference.id || '',
        articleReference: {
          ...reference,
          name:
            (reference as ArticleReference).sourceArticleId === articleId
              ? referenceName.trim()
              : reference.name,
          backwardName:
            (reference as ArticleReference).targetArticleId === articleId
              ? referenceName.trim()
              : (reference as ArticleReference).backwardName,
        },
      })
        .unwrap()
        .then((result) => {
          if (result.messageKey && result.messageKey !== '') {
            dispatch(
              addMessage({
                id: 'UpdateArticleReferenceSuccess',
                variant: 'success',
                messageKeyBody: result.messageKey,
              }),
            );
          }
          setDialogShow(false);
          if (articleId) {
            refetchGetArticleReferences();
          }
          if (categoryId) {
            refetchGetCategoryReferences();
          }
        });
    } else {
      updateExternalReference({
        id: reference.id || '',
        externalReference: {
          ...reference,
          name: referenceName.trim(),
        },
      })
        .unwrap()
        .then((result) => {
          if (result.messageKey && result.messageKey !== '') {
            dispatch(
              addMessage({
                id: 'UpdateExternalReferenceSuccess',
                variant: 'success',
                messageKeyBody: result.messageKey,
              }),
            );
          }
          setDialogShow(false);
          refetchGetArticleReferences();
        });
    }
  };

  return (
    <CustomDialog
      titleId='RenameReferenceDialog'
      show={dialogShow}
      closeFunction={() => {
        setDialogShow(false);
        setReferenceNameIsValid(true);
      }}
      closeTitle={translation('cancel')}
      actionFunction={() => {
        handleUpdateReference();
      }}
      actionTitle={translation('save')}
      actionButtonDisabled={
        reference.name === referenceName.trim() ||
        isLoading ||
        (!reference.name && originalReferenceName === referenceName.trim())
      }
      dialogTitle={translation('renameReference')}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <p>{translation('fieldsAreRequiredLegend')}</p>
          <Form.Group className='mb-3' controlId='ReferenceName'>
            <Form.Label>{translation('referenceName')}*</Form.Label>
            <InputGroup>
              <Form.Control
                required
                aria-describedby={
                  referenceNameIsValid ? undefined : 'ReferenceNameIsError'
                }
                isInvalid={!referenceNameIsValid}
                onChange={(e) => {
                  setReferenceName(e.target.value);
                  if (e.target.value.trim().length > 0) {
                    setReferenceNameIsValid(true);
                  }
                }}
                type='text'
                value={referenceName}
              />
              <Button
                aria-label={translation('restoreOriginalName')}
                title={translation('restoreOriginalName')}
                variant='outline-dark'
                className='rounded-circle circle-btn'
                onClick={() => {
                  setReferenceName(originalReferenceName);
                }}>
                <i aria-hidden className='icon-wiederherstellen' />
              </Button>
            </InputGroup>
            <Form.Control.Feedback id='ReferenceNameIsError' type='invalid'>
              {translation('fieldNotEmpty')}
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}
    </CustomDialog>
  );
}

export default RenameReferenceDialog;
