import { ThreeDotsVertical } from 'react-bootstrap-icons';
import { useOnClickOutside } from 'usehooks-ts';
import { Dropdown } from 'react-bootstrap';
import { SyntheticEvent, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContextAction } from './types';
import './ContextMenu.scss';
import ContextToggler from './ContextToggler';
import IconButton from '../buttons/IconButton';
import ContextMenuLink from './ContextMenuLink';
import ContextMenuButton from './ContextMenuButton';
import DropdownMenu from './DropdownMenu';
import OnlineHelpButton from '../buttons/OnlineHelpButton';

interface IContextMenuProps {
  contextActions: ContextAction[];
  onlyOneActionAsButton?: boolean;
}

function ContextMenu({
  contextActions,
  onlyOneActionAsButton,
}: IContextMenuProps): JSX.Element | null {
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropdownRef, () => {
    setShow(false);
  });

  const openLink = (href: string, openInNewTab: boolean): void => {
    if (openInNewTab) {
      window.open(href, '_blank', 'noopener,noreferrer');
    } else {
      navigate(href || '', {
        replace: true,
      });
    }
  };

  if (contextActions.length === 0) {
    return null;
  }

  const handleOnKeyDown = (event: KeyboardEvent) => {
    const focusedElement = event.target as HTMLElement;
    const correspondingLiOfFocusedElement: HTMLElement | null =
      focusedElement.closest('li');
    const ulElementOfMenu: HTMLElement | null | undefined =
      correspondingLiOfFocusedElement?.parentElement;
    const allMenuItems: HTMLCollectionOf<HTMLLIElement> | undefined =
      ulElementOfMenu?.getElementsByTagName('li');
    const indexOfTargetElement = [].slice
      .call(allMenuItems)
      .indexOf(correspondingLiOfFocusedElement as never);

    event.stopPropagation();

    if (
      event.key === 'Tab' &&
      ((indexOfTargetElement === (allMenuItems?.length ?? 0) - 1 &&
        !focusedElement.nextElementSibling) ||
        (event.shiftKey && indexOfTargetElement === 0))
    ) {
      setShow(false);
      return;
    }

    if (event.key === 'Escape') {
      setShow(false);
    }
  };

  return onlyOneActionAsButton ? (
    <div className='icon-button'>
      <IconButton
        title={contextActions[0].name}
        iconClassName={contextActions[0].iconClass || ''}
        textColorClass={contextActions[0].iconColorClass}
        ariaLabel={contextActions[0].name}
        onClick={contextActions[0].onClick as (event: SyntheticEvent) => void}
      />
    </div>
  ) : (
    <Dropdown
      ref={dropdownRef}
      className='context-menu'
      show={show}
      onToggle={() => {
        setShow(!show);
      }}>
      <Dropdown.Toggle as={ContextToggler} aria-expanded={show}>
        <ThreeDotsVertical aria-hidden />
      </Dropdown.Toggle>
      <Dropdown.Menu
        as={DropdownMenu}
        onKeyDown={(e) => {
          handleOnKeyDown(e as unknown as KeyboardEvent);
        }}
        align='end'>
        {contextActions.map((action) => (
          <li key={action.name}>
            <span className='d-flex'>
              {action.href ? (
                <ContextMenuLink
                  action={action}
                  openLink={openLink}
                  setShow={setShow}
                />
              ) : (
                <ContextMenuButton action={action} setShow={setShow} />
              )}
              {action.helpId && <OnlineHelpButton helpId={action.helpId} />}
            </span>
            {action.addDividerAfterItem && <Dropdown.Divider />}
          </li>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

ContextMenu.defaultProps = { onlyOneActionAsButton: false };

export default ContextMenu;
