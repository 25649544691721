import parse from 'html-react-parser';
import { useGetApiSettingsUserQuery } from '../../redux/store/api/api';
import Loader from '../loader/Loader';
import { settingTypeVideoContent } from '../../shared/constants';

function VideoHelpContent(): JSX.Element {
  const { data: settings, isFetching } = useGetApiSettingsUserQuery();
  const videoContentSetting = settings?.resultObject?.find(
    (s) => s.settingType?.key === settingTypeVideoContent,
  );

  return (
    <>
      {isFetching && <Loader />}
      {!isFetching && videoContentSetting?.value && (
        <div>{parse(videoContentSetting.value)}</div>
      )}
    </>
  );
}

export default VideoHelpContent;
