import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import CustomCard from '../components/cards/CustomCard';
import ImprintText from '../components/infos/ImprintText';
import { useGetApiSettingsPublicBySettingTypeKeyQuery } from '../redux/store/api/api';
import { settingTypeApplicationName } from '../shared/constants';
import { addMessage } from '../redux/store/layout/slice';
import { useAppDispatch } from '../redux/hooks';
import ViewFallback from '../components/error-fallback/ViewFallback';

function Imprint(): JSX.Element {
  const { t: translation } = useTranslation();

  const {
    data: applicationNameSetting,
    isError,
    error,
  } = useGetApiSettingsPublicBySettingTypeKeyQuery({
    settingTypeKey: settingTypeApplicationName,
  });

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetSettingError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  useEffect(() => {
    document.title = `${
      applicationNameSetting?.resultObject?.value
        ? `${applicationNameSetting.resultObject.value} - `
        : ''
    }${translation('imprint')}`;
  }, [applicationNameSetting]);

  useEffect(() => {
    document.getElementsByTagName('h1')[0].focus();
  }, []);

  return (
    <ErrorBoundary fallback={<ViewFallback />}>
      <CustomCard
        title={translation('imprint')}
        cardId='ImprintCard'
        focusableHeadline
        headlineAsH1>
        <ImprintText />
      </CustomCard>
    </ErrorBoundary>
  );
}

export default Imprint;
