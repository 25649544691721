import _ from 'lodash';
import { SyntheticEvent } from 'react';
import {
  IArticleTreeItem,
  ICategoryTreeItem,
} from '../components/content-tree/types';
import { Article, Category, Version } from '../redux/store/api/api';
import { countOfTimeDigitsInDate } from './constants';
import { ArticleType } from './enums';

export const checkClickOnContextMenu = (
  event: SyntheticEvent<HTMLElement | Element>,
): boolean =>
  event.target instanceof HTMLButtonElement ||
  (event.target as HTMLElement).parentElement instanceof HTMLButtonElement ||
  (event.target as HTMLElement).parentElement?.parentElement instanceof
    HTMLButtonElement ||
  event.target instanceof HTMLAnchorElement;

export function formatToInputDateString(dateString: string): string {
  let date = new Date(dateString);
  date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  if (date instanceof Date && !Number.isNaN(date.getTime())) {
    return date.toISOString().slice(0, countOfTimeDigitsInDate);
  }
  return 'invalid date';
}

export function formatToLocalDateString(dateString: string): string {
  let date = new Date(dateString);
  date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  if (date instanceof Date && !Number.isNaN(date.getTime())) {
    return date.toLocaleDateString('de', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }
  return 'invalid date';
}

export function getVersionName(
  name: string,
  connector: string,
  validityDate: string,
): string {
  return `${name} ${connector} ${formatToLocalDateString(validityDate)}`;
}

export function downloadFile(objectURL: string, fileName: string) {
  const anchor = window.document.createElement('a');
  anchor.href = objectURL;
  anchor.download = fileName;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  window.URL.revokeObjectURL(anchor.href);
}

type ArticleIconClass = 'icon-artikel' | 'icon-artikel_pdf' | 'icon-struktur';

export function getArticleIcon(type: ArticleType): ArticleIconClass {
  let iconClass: ArticleIconClass = 'icon-artikel';

  if (type === ArticleType.Pdf) {
    iconClass = 'icon-artikel_pdf';
  }

  if (type === ArticleType.StructureElement) {
    iconClass = 'icon-struktur';
  }

  return iconClass;
}

type VersionIconClass =
  | 'icon-artikel'
  | 'icon-artikel_pdf'
  | 'icon-deaktivieren'
  | 'icon-version_old'
  | 'icon-version_future';

export function getVersionIcon(version: Version): VersionIconClass {
  if (version.disabled) {
    return 'icon-deaktivieren';
  }

  if (
    new Date().getTime() <
    new Date(formatToInputDateString(version.validFrom || '')).getTime()
  ) {
    return 'icon-version_future';
  }

  if (
    new Date().getTime() <
    new Date(formatToInputDateString(version.publishedFrom || '')).getTime()
  ) {
    return 'icon-deaktivieren';
  }

  if (!version.isRecentVersion) {
    return 'icon-version_old';
  }

  if (version.type === ArticleType.Pdf) {
    return 'icon-artikel_pdf';
  }

  return 'icon-artikel';
}

type VersionIconColorClass =
  | 'text-danger'
  | 'text-warning'
  | 'text-muted'
  | undefined;

export function getVersionIconColorClass(
  version: Version,
): VersionIconColorClass {
  if (version.disabled) {
    return 'text-danger';
  }

  if (
    new Date().getTime() <
    new Date(formatToInputDateString(version.validFrom || '')).getTime()
  ) {
    return 'text-muted';
  }

  if (
    new Date().getTime() <
    new Date(formatToInputDateString(version.publishedFrom || '')).getTime()
  ) {
    return 'text-warning';
  }

  return undefined;
}

type VersionIconDescriptionKey =
  | 'currentHtmlVersion'
  | 'currentPdfVersion'
  | 'versionIsDisabled'
  | 'versionIsNotValid'
  | 'oldVersion'
  | 'versionIsInFuture';

export function getVersionTranslationKeyOfIconDescription(
  version: Version,
): VersionIconDescriptionKey {
  if (version.disabled) {
    return 'versionIsDisabled';
  }

  if (
    new Date().getTime() <
    new Date(formatToInputDateString(version.validFrom || '')).getTime()
  ) {
    return 'versionIsInFuture';
  }

  if (
    new Date().getTime() <
    new Date(formatToInputDateString(version.publishedFrom || '')).getTime()
  ) {
    return 'versionIsNotValid';
  }

  if (!version.isRecentVersion) {
    return 'oldVersion';
  }

  if (version.type === ArticleType.Pdf) {
    return 'currentPdfVersion';
  }

  return 'currentHtmlVersion';
}

type ArticleIconDescriptionKey =
  | 'htmlArticle'
  | 'pdfArticle'
  | 'structureElement';

export function getArticleTranslationKeyOfIconDescription(
  type: ArticleType,
): ArticleIconDescriptionKey {
  let iconKey: ArticleIconDescriptionKey = 'htmlArticle';

  if (type === ArticleType.Pdf) {
    iconKey = 'pdfArticle';
  }

  if (type === ArticleType.StructureElement) {
    iconKey = 'structureElement';
  }
  return iconKey;
}

export function getFileExtension(filename: string | null | undefined) {
  if (!filename) {
    return null;
  }

  const r = /(?<=\.)\w+$/.exec(filename);

  return r ? r[0] : null;
}

function isWordExtension(fileExtension: string | null) {
  if (fileExtension) {
    if (fileExtension === 'doc') {
      return true;
    }
    if (fileExtension === 'docx') {
      return true;
    }
    if (fileExtension === 'docm') {
      return true;
    }
  }

  return false;
}

function isExcelExtension(fileExtension: string | null) {
  if (fileExtension) {
    if (fileExtension === 'xls') {
      return true;
    }
    if (fileExtension === 'xlsx') {
      return true;
    }
    if (fileExtension === 'xlsm') {
      return true;
    }
  }

  return false;
}

function isPowerPointExtension(fileExtension: string | null) {
  if (fileExtension) {
    if (fileExtension === 'ppt') {
      return true;
    }
    if (fileExtension === 'pptx') {
      return true;
    }
  }

  return false;
}

function isImageExtension(fileExtension: string | null) {
  if (fileExtension) {
    if (fileExtension === 'jpg') {
      return true;
    }
    if (fileExtension === 'png') {
      return true;
    }
    if (fileExtension === 'gif') {
      return true;
    }
  }

  return false;
}

function isPdfExtension(fileExtension: string | null) {
  if (fileExtension && fileExtension === 'pdf') {
    return true;
  }

  return false;
}

export function getFileIcon(fileName: string | null | undefined) {
  const fileExtension = getFileExtension(fileName);

  if (isWordExtension(fileExtension)) {
    return 'icon-word';
  }

  if (isExcelExtension(fileExtension)) {
    return 'icon-excel';
  }

  if (isPowerPointExtension(fileExtension)) {
    return 'icon-powerpoint';
  }

  if (isImageExtension(fileExtension)) {
    return 'icon-image';
  }

  if (isPdfExtension(fileExtension)) {
    return 'icon-artikel_pdf';
  }

  return 'icon-attachments';
}

export function camelizeString(str: string): string {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (substring, index) =>
      index === 0 ? substring.toLowerCase() : substring.toUpperCase(),
    )
    .replace(/\s+/g, '');
}

export function convertCategoryTreeItemToCategory(
  categoryTreeElement: ICategoryTreeItem,
): Category {
  return {
    id: categoryTreeElement.id,
    name: categoryTreeElement.name,
    order: categoryTreeElement.order,
    abbreviation: categoryTreeElement.abbreviation,
    deleted: categoryTreeElement.deleted,
    disabled: categoryTreeElement.disabled,
    categoryTypeId: categoryTreeElement.categoryTypeId,
    categoryTypeColorHexCode: categoryTreeElement.categoryTypeColorHexCode,
    categoryTypeIconClass: categoryTreeElement.categoryTypeIconClass,
    parentId: categoryTreeElement.parent,
    permittedActions: categoryTreeElement.permittedActions,
    lastModified: categoryTreeElement.lastModifiedDate,
    permittedPath: categoryTreeElement.permittedPath,
    treePath: categoryTreeElement.parents,
    articleIds: categoryTreeElement.articleIds,
    categoryIds: categoryTreeElement.categoryIds,
  };
}

export function convertArticleTreeItemToArticle(
  articleTreeElement: IArticleTreeItem,
): Article {
  return {
    id: articleTreeElement.id,
    categoryId: articleTreeElement.parent,
    order: articleTreeElement.order,
    type: articleTreeElement.type,
    deleted: articleTreeElement.deleted,
    disabled: articleTreeElement.disabled,
    recentVersionId: articleTreeElement.recentVersionId,
    recentVersionTitle: articleTreeElement.name,
    versionsDisabled: articleTreeElement.versionsDisabled,
    versionsOnlyDrafts: articleTreeElement.versionsOnlyDrafts,
    versionsInvalid: articleTreeElement.versionsInvalid,
  };
}

// Returns regular expression that finds every exact occurrence of every word in searchKeywords
export function getMultipleWordSearchRegex(searchKeywords: string[]): RegExp {
  let regexStr = '';

  _.orderBy(searchKeywords, (s) => s.length, 'desc').forEach((s, index) => {
    if (index > 0) {
      regexStr += '|';
    }
    if (s.startsWith('§')) {
      regexStr += `${s.replaceAll('.', '').replaceAll('*', '.?')}`;
    } else {
      regexStr += `\\b${s.replaceAll('.', '').replaceAll('*', '.*?')}\\b`;
    }
  });

  return new RegExp(regexStr, 'giu');
}

export function preventApplicationReloadAndOpenInternalRoute(
  e: Event,
  anchorElement: HTMLAnchorElement,
) {
  e.preventDefault();
  window.history.pushState({}, '', anchorElement.href);
  const navEvent = new PopStateEvent('popstate');
  window.dispatchEvent(navEvent);

  return false;
}
