import { useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface IUserFormProps {
  email: string;
  setEmail: (email: string) => void;
  isValidEmail: boolean;
  setIsValidEmail: (isValid: boolean) => void;
  firstName: string;
  setFirstName: (firstName: string) => void;
  isValidFirstName: boolean;
  setIsValidFirstName: (isValid: boolean) => void;
  lastName: string;
  setLastName: (lastName: string) => void;
  isValidLastName: boolean;
  setIsValidLastName: (isValid: boolean) => void;
}

function UserForm({
  email,
  setEmail,
  isValidEmail,
  setIsValidEmail,
  firstName,
  setFirstName,
  isValidFirstName,
  setIsValidFirstName,
  lastName,
  setLastName,
  isValidLastName,
  setIsValidLastName,
}: IUserFormProps): JSX.Element {
  const { t: translation } = useTranslation();
  const emailInputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <p>{translation('fieldsAreRequiredLegend')}</p>
      <Form.Group className='mb-3' controlId='UserEmail'>
        <Form.Label>{translation('emailAddress')}*</Form.Label>
        <Form.Control
          required
          ref={emailInputRef}
          onChange={(e) => {
            setEmail(e.target.value);
            if (e.target.value.length > 0) {
              setIsValidEmail(true);
            }
          }}
          value={email}
          isInvalid={!isValidEmail}
          type='email'
          aria-describedby={!isValidEmail ? 'UserEmailError' : undefined}
          pattern="^[\w!#$%&'*+\-/=?\^_`{|}~]+(\.[\w!#$%&'*+\-/=?\^_`{|}~]+)*@((([\-\w]+\.)+[a-zA-Z]{2,4})|(([0-9]{1,3}\.){3}[0-9]{1,3}))$"
          onBlur={() => {
            const isValidValue = emailInputRef.current?.validity.valid || false;
            setIsValidEmail(isValidValue);
          }}
        />
        <Form.Control.Feedback id='UserEmailError' type='invalid'>
          {translation(
            email.trim().length > 0 ? 'emailInvalidMessage' : 'fieldNotEmpty',
          )}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className='mb-3' controlId='UserFirstName'>
        <Form.Label>{translation('firstName')}*</Form.Label>
        <Form.Control
          required
          isInvalid={!isValidFirstName}
          aria-describedby={
            isValidFirstName ? undefined : 'FirstNameInputError'
          }
          onChange={(e) => {
            setFirstName(e.target.value);
            if (e.target.value.length > 0) {
              setIsValidFirstName(true);
            }
          }}
          value={firstName}
          type='text'
        />
        <Form.Control.Feedback id='FirstNameInputError' type='invalid'>
          {translation('fieldNotEmpty')}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className='mb-3' controlId='UserLastName'>
        <Form.Label>{translation('lastName')}*</Form.Label>
        <Form.Control
          required
          isInvalid={!isValidLastName}
          aria-describedby={isValidLastName ? undefined : 'FirstNameInputError'}
          onChange={(e) => {
            setLastName(e.target.value);
            if (e.target.value.length > 0) {
              setIsValidLastName(true);
            }
          }}
          value={lastName}
          type='text'
        />
        <Form.Control.Feedback id='LastNameInputError' type='invalid'>
          {translation('fieldNotEmpty')}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
}

export default UserForm;
