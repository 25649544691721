import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import 'react-color-palette/lib/css/styles.css';
import { useTranslation } from 'react-i18next';
import {
  CategoryType,
  useGetApiCategoryTreeQuery,
  useGetApiCategoryTypesByIdQuery,
  useGetApiCategoryTypesQuery,
  usePutApiCategoryTypesByIdMutation,
} from '../../redux/store/api/api';
import { addMessage } from '../../redux/store/layout/slice';
import { useAppDispatch } from '../../redux/hooks';
import Loader from '../loader/Loader';
import CategoryTypeColorPicker from './CategoryTypeColorPicker';
import CategoryTypeIcons from './CategoryTypeIcons';
import './CategoryTypes.scss';
import CustomCard from '../cards/CustomCard';
import { RightKey } from '../../shared/enums';
import { defaultColor, defaultIcon } from './defaultValues';

interface ICategorySettingsProps {
  activeCategoryType: CategoryType;
  rights: RightKey[];
}

function CategoryTypeSettings({
  activeCategoryType,
  rights,
}: ICategorySettingsProps): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const [
    updateCategoryType,
    { isError: putCategoryTypeIsError, error: putCategoryTypeError, isLoading },
  ] = usePutApiCategoryTypesByIdMutation();
  const {
    data,
    isFetching,
    refetch: refetchCategoryType,
    isError: getCategoryTypeIsError,
    error: getCategoryTypeError,
  } = useGetApiCategoryTypesByIdQuery({
    id: activeCategoryType.id || '',
  });
  const { refetch: refetchGetCategoryTypes } = useGetApiCategoryTypesQuery();
  const { refetch: refetchCategoryTree } = useGetApiCategoryTreeQuery();
  const [iconClass, setIconClass] = useState<string>(
    data?.resultObject?.iconCssClass || defaultIcon,
  );
  const [colorHexCode, setColorHexCode] = useState<string>(
    data?.resultObject?.colorHexCode || defaultColor,
  );
  const [isValidColor, setIsValidColor] = useState<boolean>(true);
  const [isValidIcon, setIsValidIcon] = useState(true);

  // permission
  const userCanEdit = rights.includes(RightKey.RightCategoryTypeManagementEdit);

  useEffect(() => {
    setIconClass(data?.resultObject?.iconCssClass || defaultIcon);
    setColorHexCode(data?.resultObject?.colorHexCode || defaultColor);
    setIsValidColor(true);
    setIsValidIcon(true);
  }, [data]);

  useEffect(() => {
    if (getCategoryTypeIsError) {
      dispatch(
        addMessage({
          id: 'GetCategoryTypesError',
          variant: 'danger',
          messageKeyBody:
            getCategoryTypeError && 'data' in getCategoryTypeError
              ? getCategoryTypeError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (putCategoryTypeIsError) {
      dispatch(
        addMessage({
          id: 'UpdateCategoryTypeError',
          variant: 'danger',
          messageKeyBody:
            putCategoryTypeError && 'data' in putCategoryTypeError
              ? putCategoryTypeError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [getCategoryTypeIsError, putCategoryTypeIsError]);

  const handleUpdateIcon = (): void => {
    if (!userCanEdit) {
      return;
    }

    if (colorHexCode.trim().length === 0 || iconClass.trim().length === 0) {
      if (colorHexCode.trim().length === 0) {
        setIsValidColor(false);
      }

      if (iconClass.trim().length === 0) {
        setIsValidIcon(false);
      }
      return;
    }

    const categoryTypeToUpdate: CategoryType = {
      id: data?.resultObject?.id || '',
      name: data?.resultObject?.name || '',
      colorHexCode: colorHexCode.trim(),
      iconCssClass: iconClass,
      order: data?.resultObject?.order,
    };

    updateCategoryType({
      id: categoryTypeToUpdate.id || '',
      categoryType: categoryTypeToUpdate,
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'UpdateCatgoryTypeSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetchCategoryType();
        refetchGetCategoryTypes();
        refetchCategoryTree();
      });
  };

  return (
    <CustomCard title={translation('categoryTypeSettings')}>
      <div aria-busy={isFetching || isLoading}>
        {(isFetching || isLoading) && <Loader />}
        {!isFetching && !isLoading && data?.resultObject?.id && (
          <Form>
            <p>{translation('fieldsAreRequiredLegend')}</p>
            <h3 className='fs-5 w-100 bg-light p-2 mb-3'>
              {translation('color')}
            </h3>
            <CategoryTypeColorPicker
              colorHexCode={colorHexCode}
              setColorHexCode={setColorHexCode}
              isValidColor={isValidColor}
              setIsValidColor={setIsValidColor}
              userCanEdit={userCanEdit}
            />
            <h3 className='fs-5 w-100 bg-light p-2'>{translation('icon')}</h3>
            <CategoryTypeIcons
              iconClass={iconClass}
              setIconClass={setIconClass}
              userCanEdit={userCanEdit}
              isValidIcon={isValidIcon}
              setIsValidIcon={setIsValidIcon}
            />
            {userCanEdit && (
              <div className='float-end'>
                <Button
                  className='mt-2'
                  variant='outline-success'
                  disabled={
                    iconClass === data?.resultObject?.iconCssClass &&
                    data?.resultObject?.colorHexCode === colorHexCode.trim()
                  }
                  onClick={handleUpdateIcon}>
                  {translation('save')}
                </Button>
              </div>
            )}
          </Form>
        )}
      </div>
    </CustomCard>
  );
}

export default CategoryTypeSettings;
