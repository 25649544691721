import { ArticleReference, Note } from '../../../redux/store/api/api';
import { SUGGESTED_EL_ID_EXTENSION } from '../../../shared/constants';
import { NoteVisibilityType } from '../../../shared/enums';
import {
  getArticleUrl,
  getCategoryReferenceUrl,
} from '../../../shared/urlBuilder';
import { VisibilityGroupString } from '../../notes/types';
import { contentReferenceClass, isFiberNode } from './nodes';

interface IAddTagToSubrangeProps {
  subRange: Range;
  element: Note | ArticleReference;
  tagName: 'mark' | 'a';
  idAndAriaExtension: string;
  id?: string;
  isSuggestionContent?: boolean;
  suggestionElId?: string;
}

interface ISurroundTextNodesInRangeProps {
  range: Range;
  element: Note | ArticleReference;
  tagName: 'mark' | 'a';
  isSuggestionContent?: boolean;
  suggestionElId?: string;
}

const addTagToSubrange = ({
  subRange,
  element,
  tagName,
  idAndAriaExtension,
  id,
  isSuggestionContent = false,
  suggestionElId = undefined,
}: IAddTagToSubrangeProps) => {
  const newParent = document.createElement(tagName);
  newParent.setAttribute(
    'id',
    `${id}-${idAndAriaExtension}${isSuggestionContent ? '-temp' : ''}${
      id === suggestionElId ? SUGGESTED_EL_ID_EXTENSION : ''
    }`,
  );
  newParent.classList.add(tagName === 'a' ? contentReferenceClass : tagName);

  if (tagName === 'mark') {
    const note = element as Note;
    const visibility = NoteVisibilityType[
      note.visibility || 0
    ] as VisibilityGroupString;
    if (isSuggestionContent) {
      if (id === suggestionElId) {
        newParent.classList.add('suggestion-mark');
      } else {
        newParent.classList.add('suggestion-content-mark');
      }
    } else {
      switch (visibility) {
        case NoteVisibilityType[NoteVisibilityType.User]:
          newParent.classList.add('user-mark');
          break;
        case NoteVisibilityType[NoteVisibilityType.UserGroup]:
          newParent.classList.add('user-group-mark');
          break;
        case NoteVisibilityType[NoteVisibilityType.General]:
          newParent.classList.add('general-mark');
          break;
        default:
          break;
      }
    }
  }

  if (tagName === 'a') {
    const reference = element as ArticleReference;
    const articleIdForHref = reference.targetArticleId;
    const contentAreaId =
      !reference.targetCategoryId && reference.targetContentAreaId
        ? reference.targetContentAreaId
        : undefined;
    const href = reference.targetCategoryId
      ? getCategoryReferenceUrl(reference.targetCategoryId)
      : getArticleUrl(articleIdForHref || '', undefined, contentAreaId);

    newParent.setAttribute('href', href);

    if (isSuggestionContent && id === suggestionElId) {
      newParent.classList.add('suggestion-link');
    }
  }

  subRange.surroundContents(newParent);

  if (tagName === 'mark' && !isSuggestionContent) {
    const clickableSpan = document.createElement('span');
    clickableSpan.role = 'button';
    clickableSpan.tabIndex = 0;
    subRange.surroundContents(clickableSpan);
  }
};

const createSubRange = (node: Node): Range => {
  const subRange = document.createRange();
  subRange.selectNodeContents(node);

  return subRange;
};

const findAllTextNodesInRange = (rootNode: Node, range: Range): Node[] => {
  if (!rootNode.hasChildNodes()) {
    return [rootNode];
  }

  const childNodes = Array.from(rootNode.childNodes).filter(
    (node) => !isFiberNode(node),
  );
  let textNodes: Node[] = [];

  childNodes.forEach((child) => {
    // save all text nodes that are part of the range and are not empty
    if (range.intersectsNode(child)) {
      if (child.nodeType === Node.TEXT_NODE) {
        textNodes = [...textNodes, child];
      } else {
        textNodes = [...textNodes, ...findAllTextNodesInRange(child, range)];
      }
    }
  });

  return textNodes;
};

const surroundTextNodesInRange = ({
  range,
  element,
  tagName,
  isSuggestionContent = false,
  suggestionElId = undefined,
}: ISurroundTextNodesInRangeProps): void => {
  // find all children in the range
  const childrenInRange = findAllTextNodesInRange(
    range.commonAncestorContainer as Element,
    range,
  );

  // add a unique mark tag to each of the children
  // start and end container might be partially selected (offset needs to be set)
  childrenInRange.forEach((child, index) => {
    const idAndAriaExtension = `${index + 1}/${childrenInRange.length}`;
    const subRange = createSubRange(child);

    if (subRange.endOffset < 1) {
      return;
    }

    if (child === range.startContainer) {
      subRange.setStart(range.startContainer, range.startOffset);
    }

    if (child === range.endContainer) {
      subRange.setEnd(range.endContainer, range.endOffset);
    }

    const note = tagName === 'mark' ? (element as Note) : null;
    const reference = tagName === 'a' ? (element as ArticleReference) : null;
    const mark = note?.mark;
    const sourceContentArea = reference?.sourceContentArea;
    const id = mark?.id || sourceContentArea?.id || undefined;

    addTagToSubrange({
      subRange,
      element,
      tagName,
      idAndAriaExtension,
      id,
      isSuggestionContent,
      suggestionElId,
    });
  });
};

export default surroundTextNodesInRange;
