import loader from '../../assets/images/loader/loader.svg';

function Loader(): JSX.Element {
  return (
    <div className='w-100 h-100 text-center'>
      <img className='mt-5' alt='Loading Icon' src={loader} />
    </div>
  );
}

export default Loader;
