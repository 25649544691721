import { ChangeEventHandler, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './Inputs.scss';

interface IFileInput {
  required?: boolean;
  labelName: string;
  multiple?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  files: File[] | null;
  setFiles?: (f: File[]) => void;
  accept?: string;
  isValid?: boolean;
  invalidMessage?: string;
  onClearFiles?: () => void;
}

function FileInput({
  required,
  labelName,
  multiple,
  onChange,
  files,
  setFiles,
  accept,
  isValid,
  invalidMessage,
  onClearFiles,
}: IFileInput): JSX.Element {
  const { t: translation } = useTranslation();
  const [inputIsFocused, setInputIsFocused] = useState(false);
  const [fileNames, setFileNames] = useState<string[]>([]);

  useEffect(() => {
    if (files) {
      setFileNames(files.map((f) => f.name));
    }
  }, [files]);

  const formatFileNames = (names: string[]) => {
    if (names.length === 0) {
      return `${translation('noFileSelected')}`;
    }

    if (names.length === 1) {
      return fileNames[0];
    }

    return `${names.length} ${translation('filesSelected')}`;
  };

  const handleClearAndShow = () => {
    if (setFiles) {
      setFiles([]);
    }

    if (onClearFiles) {
      onClearFiles();
    }
  };

  const displayFileInput = (): boolean => {
    if (multiple) {
      if (files?.length === 0) {
        return true;
      }

      if (files && files.length > 0) {
        return false;
      }
    }

    return true;
  };

  return (
    <>
      {displayFileInput() && (
        <div className='file-up' data-file-input>
          <label
            htmlFor='FileUpload'
            className={`file-up-label btn btn-light ${
              inputIsFocused ? 'input-focused' : ''
            }`}>
            <span className='file-up-label-text p-2'>{labelName}</span>
            <input
              onFocus={() => {
                setInputIsFocused(true);
              }}
              onBlur={() => {
                setInputIsFocused(false);
              }}
              required={required}
              className='file-up-input'
              multiple={multiple}
              type='file'
              id='FileUpload'
              accept={accept}
              onChange={(e) => {
                setInputIsFocused(false);
                onChange(e);
              }}
              aria-describedby={
                !isValid && invalidMessage
                  ? 'FileInputError'
                  : 'FileUploadOutput'
              }
            />
          </label>
          <span
            className={`file-up-output p-2${
              isValid === false ? ' border-danger' : ''
            }`}
            aria-hidden='true'
            id='FileUploadOutput'>
            {formatFileNames(fileNames)}
          </span>
        </div>
      )}
      {multiple && files && files.length > 0 && (
        <Button
          onClick={() => {
            handleClearAndShow();
          }}
          variant='outline-dark'>
          {translation('uploadNewFiles')}
        </Button>
      )}
      {!isValid && invalidMessage && (
        <Form.Control.Feedback
          id='FileInputError'
          type='invalid'
          className='d-block'>
          {invalidMessage}
        </Form.Control.Feedback>
      )}
    </>
  );
}

FileInput.defaultProps = {
  accept: null,
  multiple: null,
  isValid: null,
  invalidMessage: null,
  required: null,
  setFiles: null,
  onClearFiles: null,
};

export default FileInput;
