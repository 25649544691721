import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';
import { useAppSelector } from '../../../redux/hooks';
import { selectActiveNote } from '../../../redux/store/content/slice';
import { MARK_VISIBILITY_GROUP_KEY } from '../../../shared/constants';
import { NoteVisibilityType } from '../../../shared/enums';
import CustomDialog from '../../dialogs/CustomDialog';
import { VisibilityGroupString } from '../../notes/types';

interface IVisibilitiesNotMatchingDialogProps {
  show: boolean;
  setShow: (show: boolean) => void;
}

function VisibilitiesNotMatchingDialog({
  show,
  setShow,
}: IVisibilitiesNotMatchingDialogProps): JSX.Element {
  const { t: translation } = useTranslation();
  const visibility = useAppSelector(selectActiveNote).visibility || 0;
  const [, setActiveMarkVisibilityGroup] =
    useSessionStorage<VisibilityGroupString | null>(
      MARK_VISIBILITY_GROUP_KEY,
      null,
    );

  return (
    <CustomDialog
      titleId='VisibilitiesNotMatchingDialog'
      dialogTitle={translation('checkVisibilityGroups')}
      show={show}
      closeFunction={() => setShow(false)}
      actionFunction={() => {
        setActiveMarkVisibilityGroup(
          NoteVisibilityType[visibility] as VisibilityGroupString,
        );
        setShow(false);
      }}
      closeTitle={translation('cancel')}
      actionTitle={translation('change')}
      actionButtonVariant='outline-danger'>
      {translation('visibilityGroupsNotMatching')}
    </CustomDialog>
  );
}

export default VisibilitiesNotMatchingDialog;
