import { Form } from 'react-bootstrap';
import { SyntheticEvent } from 'react';
import { paddingOffsetForTreeElements } from '../../shared/constants';
import { ICategoryTreeItem } from '../content-tree/types';
import { RightKey } from '../../shared/enums';

interface IRestoreTreeItemProps {
  level: number;
  isBranch: boolean;
  isExpanded: boolean | undefined;
  getNodeProps: Function;
  handleExpand: Function;
  isSelected: boolean;
  handleSelect: Function;
  element: ICategoryTreeItem;
}

function RestoreTreeItem({
  level,
  isBranch,
  isExpanded,
  getNodeProps,
  isSelected,
  handleSelect,
  handleExpand,
  element,
}: IRestoreTreeItemProps) {
  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...getNodeProps({
        onClick: (e: SyntheticEvent) => {
          handleExpand(e);
        },
      })}
      style={{ paddingLeft: paddingOffsetForTreeElements * (level - 1) }}>
      <div className='d-flex'>
        {isBranch && isExpanded && (
          <i className='me-2 mt-1 fs-5 icon-carretup' aria-hidden />
        )}
        {isBranch && !isExpanded && (
          <i className='me-2 mt-1 fs-5 icon-carretdown' aria-hidden />
        )}
        <Form.Check id={element.id}>
          <Form.Check.Input
            disabled={
              !element.permittedActions?.includes(
                RightKey.RightArticleManagementCreateArticle,
              )
            }
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              handleSelect(e);
              e.stopPropagation();
            }}
            tabIndex={-1}
            className='mt-2'
            checked={isSelected}
            type='checkbox'
          />
          <Form.Check.Label>{element.name}</Form.Check.Label>
        </Form.Check>
      </div>
    </div>
  );
}

export default RestoreTreeItem;
