import { useTranslation } from 'react-i18next';
import CustomCard from '../cards/CustomCard';
import DeleteDraftDialog from './dialogs/DeleteDraftDialog';
import RenameDraftDialog from './dialogs/RenameDraftDialog';
import DraftList from './DraftList';

function Drafts() {
  const { t: translation } = useTranslation();

  return (
    <>
      <CustomCard
        helpId='help_3_2'
        expandable
        title={translation('drafts').toUpperCase()}
        iconClass='icon-entwuerfe'>
        <DraftList />
      </CustomCard>
      <DeleteDraftDialog />
      <RenameDraftDialog />
    </>
  );
}

export default Drafts;
