import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiCategoryTreeQuery,
  usePutApiCategoriesSetPublishDatesMutation,
} from '../../../redux/store/api/api';
import {
  selectChangePublishDatesDialogOpened,
  selectContextCategory,
  setChangePublishDatesDialogOpened,
  setContextCategory,
} from '../../../redux/store/content/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';

interface IChangePublishDatesDialogProps {
  setLastUpdatedItem: (updatedId: string) => void;
}

function ChangePublishDatesDialog({
  setLastUpdatedItem,
}: IChangePublishDatesDialogProps) {
  const dispatch = useAppDispatch();
  const { t: translation } = useTranslation();
  const dialogShow = useAppSelector(selectChangePublishDatesDialogOpened);
  const category = useAppSelector(selectContextCategory);
  const [publicationStart, setPublicationStart] = useState('');
  const [publicationEnd, setPublicationEnd] = useState('');
  const [startDateIsValid, setStartDateIsValid] = useState(true);
  const [endDateIsValid, setEndDateIsValid] = useState(true);
  const [setPublishDates, { isError, isLoading, error }] =
    usePutApiCategoriesSetPublishDatesMutation();
  const { refetch } = useGetApiCategoryTreeQuery();

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'ChangePublishDatesError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  const resetStates = (): void => {
    setPublicationStart('');
    setPublicationEnd('');
    setEndDateIsValid(true);
    setStartDateIsValid(true);
    dispatch(setContextCategory({}));
  };

  const handleSetPublishDates = (): void => {
    if (
      publicationStart.trim().length === 0 ||
      publicationEnd.trim().length === 0
    ) {
      if (publicationStart.trim().length === 0) {
        setStartDateIsValid(false);
      }
      if (publicationEnd.trim().length === 0) {
        setEndDateIsValid(false);
      }
      return;
    }

    setPublishDates({
      categoryId: category.id || '',
      publishFrom: publicationStart,
      publishTo: publicationEnd,
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'ChangePublishDatesSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        dispatch(setChangePublishDatesDialogOpened(false));
        resetStates();
        setLastUpdatedItem(category.id ?? '');
      });
  };

  return (
    <CustomDialog
      titleId='ChangePublishDatesDialog'
      show={dialogShow}
      closeFunction={() => {
        dispatch(setChangePublishDatesDialogOpened(false));
        resetStates();
      }}
      closeTitle={translation('cancel')}
      actionFunction={handleSetPublishDates}
      actionTitle={translation('changeDate')}
      actionButtonDisabled={isLoading}
      dialogTitle={translation('changePublicationPeriod')}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <p>{translation('fieldsAreRequiredLegend')}</p>
          <Form.Group className='mb-3' controlId='PublicationStart'>
            <Form.Label>{translation('publicationStart')}*</Form.Label>
            <Form.Control
              required
              aria-describedby={
                startDateIsValid ? undefined : 'StartDateInputError'
              }
              isInvalid={!startDateIsValid}
              value={publicationStart}
              type='date'
              onChange={(e) => {
                setPublicationStart(e.target.value);
                if (e.target.value.trim().length > 0) {
                  setStartDateIsValid(true);
                }
              }}
            />
            <Form.Control.Feedback id='StartDateInputError' type='invalid'>
              {translation('fieldNotEmpty')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3' controlId='PublicationEnd'>
            <Form.Label>{translation('publicationEnd')}*</Form.Label>
            <Form.Control
              required
              aria-describedby={
                endDateIsValid ? undefined : 'EndDateInputError'
              }
              isInvalid={!endDateIsValid}
              value={publicationEnd}
              type='date'
              onChange={(e) => {
                setPublicationEnd(e.target.value);
                if (e.target.value.trim().length > 0) {
                  setEndDateIsValid(true);
                }
              }}
            />
            <Form.Control.Feedback id='EndDateInputError' type='invalid'>
              {translation('fieldNotEmpty')}
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}
    </CustomDialog>
  );
}

export default ChangePublishDatesDialog;
