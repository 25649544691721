import { useTranslation } from 'react-i18next';
import CustomDialog from '../../dialogs/CustomDialog';

interface IDraftToVersionDialogProps {
  dialogShow: boolean;
  setDialogShow: (show: boolean) => void;
  handleConvertToVersion: (oldVersionId: string) => void;
  targetVersionId: string;
}

function DraftToVersionDialog({
  dialogShow,
  setDialogShow,
  handleConvertToVersion,
  targetVersionId,
}: IDraftToVersionDialogProps) {
  const { t: translation } = useTranslation();

  const handleSaveDraft = (): void => {
    handleConvertToVersion(targetVersionId);
    setDialogShow(false);
  };

  return (
    <CustomDialog
      titleId='DraftSaveDialog'
      show={dialogShow}
      closeFunction={() => {
        setDialogShow(false);
      }}
      closeTitle={translation('cancel')}
      dialogTitle={translation('saveNewVersion')}
      actionTitle={translation('save')}
      actionFunction={handleSaveDraft}>
      <p>{translation('shouldContentSaveAsNewVersion')}</p>
    </CustomDialog>
  );
}

export default DraftToVersionDialog;
