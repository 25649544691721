import { useEffect, DependencyList, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { useGetApiCategoryTreeQuery } from '../redux/store/api/api';
import {
  selectTreeContentInvalid,
  setTreeContentInvalid,
} from '../redux/store/content/slice';

function useRefetchContentTreeIfInvalid(deps: DependencyList) {
  const dispatch = useAppDispatch();
  const isContentTreeInvalid = useAppSelector(selectTreeContentInvalid);
  const { refetch } = useGetApiCategoryTreeQuery();

  const refetchAction = useCallback(() => {
    if (isContentTreeInvalid) {
      refetch();
      dispatch(setTreeContentInvalid(false));
    }
  }, [isContentTreeInvalid]);

  useEffect(() => {
    refetchAction();
  }, deps);

  return refetchAction;
}

export default useRefetchContentTreeIfInvalid;
