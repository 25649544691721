import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import CustomCard from '../cards/CustomCard';
import { useAppDispatch } from '../../redux/hooks';
import { setComparerIsActive } from '../../redux/store/content/slice';

function ComparerLegend() {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <>
      <CustomCard title={translation('legend')}>
        <div>
          <p className='m-0 fw-bold'>{translation('contentAdded')}:</p>
          <p>
            <ins>{translation('contentAdded')}</ins>
          </p>
        </div>
        <div>
          <p className='m-0 fw-bold'>{translation('contentRemoved')}:</p>
          <p>
            <del>{translation('contentRemoved')}</del>
          </p>
        </div>
      </CustomCard>
      <Button
        variant='outline-dark'
        className='w-100'
        onClick={() => {
          dispatch(setComparerIsActive(false));
        }}>
        {translation('backToArticle')}
      </Button>
    </>
  );
}

export default ComparerLegend;
