import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import ViewFallback from '../components/error-fallback/ViewFallback';
import CustomCard from '../components/cards/CustomCard';
import FaqContent from '../components/infos/FaqContent';

function Faq(): JSX.Element {
  const { t: translation } = useTranslation();

  return (
    <ErrorBoundary fallback={<ViewFallback />}>
      <CustomCard
        iconClass='icon-change'
        title={translation('faq')}
        cardId='FaqCard'
        focusableHeadline
        headlineAsH1>
        <FaqContent />
      </CustomCard>
    </ErrorBoundary>
  );
}

export default Faq;
