import { SyntheticEvent, useEffect, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import { ArrowBarUp } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useEventListener, useOnClickOutside } from 'usehooks-ts';
import SearchFilter from './SearchFilter';
import { useAppSelector } from '../../redux/hooks';
import {
  selectSearchIncludeVersions,
  selectSearchKeyword,
  selectSearchOption,
  selectSelectedCategoryIdsForSearch,
} from '../../redux/store/content/slice';
import { getSearchUrl } from '../../shared/urlBuilder';

interface ISearchFilterInNavbarProps {
  setSearchFilterOpened: (open: boolean) => void;
}

function SearchFilterInNavbar({
  setSearchFilterOpened,
}: ISearchFilterInNavbarProps) {
  const refSearchFilter = useRef<HTMLDivElement>(null);
  const startSearchBtnRef = useRef<HTMLAnchorElement>(null);
  const navigate = useNavigate();
  const { t: translation } = useTranslation();
  const location = useLocation();
  const searchKeyword = useAppSelector(selectSearchKeyword);
  const selectedCategoryIds = useAppSelector(
    selectSelectedCategoryIdsForSearch,
  );
  const searchOption = useAppSelector(selectSearchOption);
  const includeVersions = useAppSelector(selectSearchIncludeVersions);
  const searchFilterBtn = document.getElementsByClassName(
    'search-filter-btn',
  )[0] as HTMLElement;
  const startSearchBtnInInput = document.getElementsByClassName(
    'search-btn',
  )[0] as HTMLElement;

  useOnClickOutside(refSearchFilter, (e) => {
    if (
      (e.target as HTMLElement).id !== 'SearchKeywordInHeader' &&
      (e.target as HTMLElement) !== searchFilterBtn &&
      !searchFilterBtn.contains(e.target as HTMLElement)
    ) {
      setSearchFilterOpened(false);
    }
  });

  useEffect(() => {
    if (location.pathname.includes('/search')) {
      setSearchFilterOpened(false);
    }
  }, [location.pathname]);

  const closeFilterContainer = (e: SyntheticEvent) => {
    e.preventDefault();
    setSearchFilterOpened(false);
    startSearchBtnInInput.focus();
  };

  useEventListener(
    'keydown',
    (e) => {
      if (e.key === 'Tab') {
        e.preventDefault();
        startSearchBtnInInput.focus();
        setSearchFilterOpened(false);
      }
    },
    startSearchBtnRef,
  );

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      ref={refSearchFilter}
      id='SearchFilterContainer'
      className='bg-white p-3'
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          closeFilterContainer(e);
        }
      }}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setSearchFilterOpened(false);
          navigate(
            getSearchUrl(
              searchKeyword,
              searchOption,
              includeVersions,
              selectedCategoryIds,
            ),
            { replace: true },
          );
        }}>
        <SearchFilter />
        <Button
          onClick={(e) => {
            closeFilterContainer(e);
          }}
          className='close-search-filter'
          aria-controls='SearchFilterContainer'
          aria-expanded='true'
          aria-label={translation('closeSearchFilter')}
          title={translation('closeSearchFilter')}>
          <ArrowBarUp aria-hidden />
        </Button>
        <Link
          ref={startSearchBtnRef}
          onKeyDown={(e) => {
            if (e.key === 'Tab' && !e.shiftKey) {
              e.preventDefault();
              document.getElementById('SearchKeywordInHeader')?.focus();
            }
          }}
          onClick={() => {
            setSearchFilterOpened(false);
          }}
          to={getSearchUrl(
            searchKeyword,
            searchOption,
            includeVersions,
            selectedCategoryIds,
          )}
          className='btn btn-outline-success mt-4 float-end'>
          {translation('startSearch')}
        </Link>
      </Form>
    </div>
  );
}

export default SearchFilterInNavbar;
