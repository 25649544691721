import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import References from '../components/references/reference-view/References';
import '../components/article/Article.scss';
import CategoryReferenceView from '../components/references/CategoryReferenceView';
import { useAppSelector } from '../redux/hooks';
import { selectSidebarOpened } from '../redux/store/layout/slice';
import ViewFallback from '../components/error-fallback/ViewFallback';

function CategoryReference(): JSX.Element {
  const sidebarOpened = useAppSelector(selectSidebarOpened);

  useEffect(() => {
    document.getElementsByTagName('h1')[0].focus();
  }, []);

  return (
    <div className='content-inner'>
      <ErrorBoundary fallback={<ViewFallback />}>
        <CategoryReferenceView />
        <div
          className={`article-attachment-container ${
            sidebarOpened ? '' : 'wide-container'
          }`}>
          <References />
        </div>
      </ErrorBoundary>
    </div>
  );
}

export default CategoryReference;
