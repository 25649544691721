import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useDeleteApiVersionsByIdMutation,
  useGetApiCategoryTreeQuery,
  useGetApiVersionsArticleByIdQuery,
} from '../../../redux/store/api/api';
import {
  selectContextVersion,
  selectDeleteVersionDialogOpened,
  setDeleteVersionDialogOpened,
} from '../../../redux/store/content/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';
import { EDIT_MODE_KEY } from '../../../shared/constants';
import { getVersionName } from '../../../shared/utils';

function DeleteVersionDialog(): JSX.Element {
  const { t: translation } = useTranslation();
  const version = useAppSelector(selectContextVersion);
  const dialogShow = useAppSelector(selectDeleteVersionDialogOpened);
  const dispatch = useAppDispatch();
  const [deleteVersion, { isError: deleteVersionIsError, isLoading, error }] =
    useDeleteApiVersionsByIdMutation();
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);
  const { refetch } = useGetApiVersionsArticleByIdQuery(
    version.articleId
      ? { id: version.articleId, editMode: editModeIsActive }
      : skipToken,
  );
  const { refetch: refetchCategoryTree } = useGetApiCategoryTreeQuery();

  useEffect(() => {
    if (deleteVersionIsError) {
      dispatch(
        addMessage({
          id: 'DeleteVersionError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [deleteVersionIsError]);

  const handleDeleteVersion = (): void => {
    deleteVersion({ id: version.id || '' })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'DeleteVersionSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        refetchCategoryTree();
        dispatch(setDeleteVersionDialogOpened(false));
      });
  };

  return (
    <CustomDialog
      titleId='DeleteVersionDialog'
      dialogTitle={translation('deleteVersion')}
      show={dialogShow}
      closeFunction={() => {
        dispatch(setDeleteVersionDialogOpened(false));
      }}
      actionFunction={handleDeleteVersion}
      closeTitle={translation('cancel')}
      actionTitle={translation('delete')}
      actionButtonVariant='outline-danger'
      actionButtonDisabled={isLoading}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <p>{translation('deleteVersionPermanently')}</p>
          <p className='fw-bold'>
            {version.title}
            {`- ${getVersionName(
              version.name || '',
              translation('versionFrom'),
              version.validFrom || '',
            )}`}
          </p>
        </>
      )}
    </CustomDialog>
  );
}

export default DeleteVersionDialog;
