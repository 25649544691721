import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';
import parse from 'html-react-parser';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';
import { useGetApiSettingsUserQuery } from '../../../redux/store/api/api';
import { settingTypeManualContent } from '../../../shared/constants';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectUserGuideDialogOpened,
  setUserGuideDialogOpened,
} from '../../../redux/store/layout/slice';
import { preventApplicationReloadAndOpenInternalRoute } from '../../../shared/utils';

function scrollHelpContentIntoView(
  htmlHelpContainer: HTMLDivElement,
  helpId: string,
) {
  const curHelpContent = htmlHelpContainer.querySelector(`#${helpId}`);
  if (curHelpContent) {
    curHelpContent.scrollIntoView({ block: 'start', behavior: 'smooth' });
    (curHelpContent as HTMLElement).focus();
  }
}

function preventReloadAndScrollToId(
  event: Event,
  anchorElement: HTMLAnchorElement,
  htmlHelpContainer: HTMLDivElement,
) {
  preventApplicationReloadAndOpenInternalRoute(event, anchorElement);
  const url = window.location.href;
  const helpId = url.split('#')[1] || undefined;
  if (helpId) {
    scrollHelpContentIntoView(htmlHelpContainer, helpId);
  }
}

function UserGuideDialog(): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const { data: settings, isFetching } = useGetApiSettingsUserQuery();
  const { show, helpId } = useAppSelector(selectUserGuideDialogOpened);
  const contentDivRef = useRef<HTMLDivElement>(null);
  const manualSetting = settings?.resultObject?.find(
    (s) => s.settingType?.key === settingTypeManualContent,
  );

  useEffect(() => {
    const internalAnchorTags: NodeListOf<HTMLAnchorElement> | undefined | null =
      contentDivRef.current?.querySelectorAll(`a[class='internal-guide-link']`);

    if (settings && contentDivRef.current && show) {
      if (helpId) {
        // timeout is important, as otherwise the images may not yet be in the content and it scrolls to the wrong position
        const msForWaitingofImages = 30;
        setTimeout(() => {
          if (contentDivRef.current) {
            scrollHelpContentIntoView(contentDivRef.current, helpId);
          }
        }, msForWaitingofImages);
      }

      if (!internalAnchorTags) {
        return;
      }

      internalAnchorTags.forEach((a) => {
        ['click', 'onkeydown'].forEach((evt) =>
          a.addEventListener(
            evt,
            (e) => {
              if (contentDivRef.current) {
                preventReloadAndScrollToId(e, a, contentDivRef.current);
              }
            },
            false,
          ),
        );
      });
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (!internalAnchorTags) {
        return;
      }

      internalAnchorTags.forEach((a) => {
        ['click', 'onkeydown'].forEach((evt) =>
          a.removeEventListener(
            evt,
            (e) => {
              if (contentDivRef.current) {
                preventReloadAndScrollToId(e, a, contentDivRef.current);
              }
            },
            false,
          ),
        );
      });
    };
  }, [settings, show]);

  return (
    <CustomDialog
      wideDialog
      dialogId='UserGuideDialog'
      titleId='UserGuideDialogTitle'
      dialogTitle={translation('userGuide')}
      show={show}
      closeFunction={() => {
        dispatch(setUserGuideDialogOpened({ show: false }));
      }}
      closeTitle={translation('close')}>
      {isFetching && <Loader />}
      {!isFetching && manualSetting?.value && (
        <div ref={contentDivRef}>{parse(manualSetting.value)}</div>
      )}
    </CustomDialog>
  );
}

export default UserGuideDialog;
