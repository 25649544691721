import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../redux/hooks';
import { setUserGuideDialogOpened } from '../../redux/store/layout/slice';
import IconButton from './IconButton';
import './Buttons.scss';

interface IOnlineHelpButtonProps {
  helpId: string;
}

function OnlineHelpButton({ helpId }: IOnlineHelpButtonProps) {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <IconButton
      title={translation('openHelp')}
      ariaLabel={translation('openHelp')}
      textColorClass='text-dark'
      key='HelpButton'
      iconClassName='icon-help'
      buttonClassName='help-button'
      onClick={() => {
        dispatch(setUserGuideDialogOpened({ show: true, helpId }));
      }}
    />
  );
}

export default OnlineHelpButton;
