import { Alert, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  SettingContainer,
  SettingUpdate,
  useGetApiSettingsUserQuery,
  usePutApiSettingsMutation,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import SettingItem from './SettingItem';
import './SettingContainerItem.scss';
import { useAppDispatch } from '../../../redux/hooks';
import AccordionItem, { IAccordionAction } from '../../accordion/AccordionItem';

interface ISettingContainerItemProps {
  settingContainer: SettingContainer;
  activateFunction: (settingContainer: SettingContainer) => void;
  deleteFunction: (settingContainer: SettingContainer) => void;
  refetchFunction: () => void;
  activeKey: string;
  setActiveKey: (key: string) => void;
  userCanEditSettings: boolean;
  userCanDeleteSettings: boolean;
}

function SettingContainerItem({
  settingContainer,
  activateFunction,
  deleteFunction,
  refetchFunction,
  activeKey,
  setActiveKey,
  userCanDeleteSettings,
  userCanEditSettings,
}: ISettingContainerItemProps): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const { refetch: refetchUserSettings } = useGetApiSettingsUserQuery();

  const [
    updateApiSettingById,
    { isError: updateApiSettingByIdIsError, error: updateApiSettingByIdError },
  ] = usePutApiSettingsMutation();

  const [isDeletable, setIsDeletable] = useState<boolean>(false);

  useEffect(() => {
    if (updateApiSettingByIdIsError) {
      dispatch(
        addMessage({
          id: 'GetSettingError',
          variant: 'danger',
          messageKeyBody:
            updateApiSettingByIdError && 'data' in updateApiSettingByIdError
              ? updateApiSettingByIdError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [updateApiSettingByIdIsError]);

  const handleSaveValue = (
    settingId: string,
    settingValue?: string,
    settingListValues?: string[],
  ) => {
    const formData = new FormData();
    formData.append('settingUpdates[0].id', settingId);
    formData.append(
      'settingUpdates[0].value',
      settingValue || settingListValues?.toString() || '',
    );

    updateApiSettingById({
      body: formData as unknown as { settingUpdates?: SettingUpdate[] },
    })
      .unwrap()
      .then(() => {
        refetchFunction();
        refetchUserSettings();
      });
  };

  const getPermittedActions = (): IAccordionAction[] => {
    const actions: IAccordionAction[] = [];

    if (userCanEditSettings) {
      actions.push({
        name: settingContainer.isActive
          ? translation('deactivateSettings')
          : translation('activateSettings'),
        onClick: () => {
          activateFunction(settingContainer);
        },
        isSwitchButton: true,
        switchIsActive: settingContainer.isActive || false,
      });
    }

    if (userCanDeleteSettings) {
      actions.push({
        name: translation('deleteSetting'),
        iconClassName: 'icon-trash',
        iconColorClass: 'text-danger',
        onClick: () => {
          setActiveKey(settingContainer.id || '');
          setIsDeletable(true);
        },
      });
    }

    return actions;
  };

  return (
    <AccordionItem
      activeKey={activeKey}
      setActiveKey={setActiveKey}
      eventKey={settingContainer.id || ''}
      title={settingContainer.name || ''}
      actions={getPermittedActions()}>
      {isDeletable && (
        <Alert variant='danger' className='usergroup-delete-alert'>
          <p>{translation('deleteUserGroupSettingPermanently')}</p>
          <Button
            variant='outline-dark'
            aria-label={translation('cancel')}
            onClick={() => {
              setIsDeletable(false);
            }}>
            {translation('cancel')}
          </Button>
          <Button
            variant='outline-danger'
            aria-label={translation('delete')}
            onClick={() => {
              deleteFunction(settingContainer);
              setIsDeletable(false);
            }}>
            {translation('delete')}
          </Button>
        </Alert>
      )}
      <Form>
        {settingContainer.settings?.map((setting) => (
          <SettingItem
            setting={setting}
            key={setting.id}
            saveFunction={handleSaveValue}
            userCanEditSettings={userCanEditSettings}
          />
        ))}
      </Form>
    </AccordionItem>
  );
}

export default SettingContainerItem;
