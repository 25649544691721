function testForIntersection(
  range: Range,
  containerId: string,
  tagName: 'mark' | 'a',
): boolean {
  const rootEl: Element | null | undefined =
    document.getElementById(containerId);
  const elements = rootEl?.getElementsByTagName(tagName);
  let intersectionDetected = false;

  if (!elements) {
    return intersectionDetected;
  }

  // find all link or mark elements within the root container
  // check if the new mark/link (represented by the range) would overlap with existing mark(s)/link(s)
  const elementsArr = Array.from(elements);
  elementsArr.forEach((el) => {
    if (range.intersectsNode(el)) {
      intersectionDetected = true;
    }
  });

  return intersectionDetected;
}

export default testForIntersection;
