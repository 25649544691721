import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import ViewFallback from '../components/error-fallback/ViewFallback';
import CustomCard from '../components/cards/CustomCard';
import VideoHelpContent from '../components/infos/VideoHelpContent';

function VideoHelp(): JSX.Element {
  const { t: translation } = useTranslation();

  return (
    <ErrorBoundary fallback={<ViewFallback />}>
      <CustomCard
        iconClass='icon-help'
        title={translation('videoHelp')}
        cardId='VideoHelpCard'
        focusableHeadline
        headlineAsH1>
        <VideoHelpContent />
      </CustomCard>
    </ErrorBoundary>
  );
}

export default VideoHelp;
