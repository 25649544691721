import {
  CALCULATION_CONTENT_CONTAINER_ID,
  VERSION_CONTENT_CONTAINER_ID,
} from '../../../shared/constants';
import { getNodeIgnoreFiberNodes, isAccessibleButton } from './nodes';

const getDownToStartTextNode = (node?: Node): Node | null => {
  if (!node) {
    return null;
  }

  if (node.nodeType === Node.TEXT_NODE || !node.firstChild) {
    return node;
  }

  return getDownToStartTextNode(node.firstChild);
};

const getDownToEndTextNode = (node: Node): Node => {
  if (node?.nodeType === Node.TEXT_NODE || !node?.lastChild) {
    return node;
  }

  return getDownToEndTextNode(node.lastChild);
};

const findEndContainer = (node?: Node): Node | null => {
  const versionContentContainer = document.getElementById(
    VERSION_CONTENT_CONTAINER_ID,
  );
  const calculationContentContainer = document.getElementById(
    CALCULATION_CONTENT_CONTAINER_ID,
  );

  if (!node) {
    return null;
  }

  const parent = getNodeIgnoreFiberNodes(node, 'parentNode');
  const prevSibling = getNodeIgnoreFiberNodes(node, 'previousSibling');

  if (node.nodeType === Node.TEXT_NODE) {
    return node;
  }

  if (
    node === versionContentContainer ||
    node === calculationContentContainer
  ) {
    return null;
  }

  if (prevSibling) {
    return getDownToEndTextNode(prevSibling);
  }

  if (parent) {
    return findEndContainer(parent);
  }

  return null;
};

const preprocessRange = (range: Range, doc: Document): Range | undefined => {
  const versionContentContainer = doc.getElementById(
    VERSION_CONTENT_CONTAINER_ID,
  );
  const calculationContentContainer = doc.getElementById(
    CALCULATION_CONTENT_CONTAINER_ID,
  );

  if (
    range.startContainer === versionContentContainer ||
    range.startContainer === calculationContentContainer
  ) {
    try {
      const newStartContainer = range.startContainer.firstChild;
      if (newStartContainer) {
        range.setStart(newStartContainer, 0);
      }
    } catch {
      return undefined;
    }
  }

  if (
    range.endContainer === versionContentContainer ||
    range.endContainer === calculationContentContainer
  ) {
    try {
      const newEndContainer = range.endContainer.lastChild;
      const newEndOffset =
        newEndContainer?.nodeType === Node.TEXT_NODE
          ? newEndContainer.textContent?.length ?? 0
          : newEndContainer?.childNodes.length;
      if (newEndContainer && newEndOffset) {
        range.setEnd(newEndContainer, newEndOffset);
      }
    } catch {
      return undefined;
    }
  }

  // do not accept accessible link buttons as start or end container of range
  if (isAccessibleButton(range.startContainer)) {
    try {
      range.setStartAfter(range.startContainer);
      return preprocessRange(range, doc);
    } catch {
      return undefined;
    }
  }

  if (isAccessibleButton(range.endContainer)) {
    try {
      range.setEndBefore(range.endContainer);
      return preprocessRange(range, doc);
    } catch {
      return undefined;
    }
  }

  let newStartContainer: Node | null = null;
  let newEndContainer: Node | null = null;

  if (range.startContainer?.nodeType !== Node.TEXT_NODE) {
    newStartContainer = getDownToStartTextNode(
      range.startContainer.childNodes[range.startOffset],
    );
  }

  if (range.endContainer?.nodeType !== Node.TEXT_NODE) {
    newEndContainer = findEndContainer(
      range.endOffset === 0
        ? range.endContainer
        : range.endContainer.childNodes[range.endOffset - 1],
    );
  }

  try {
    if (newStartContainer) {
      range.setStart(newStartContainer, 0);
    }

    if (newEndContainer) {
      range.setEnd(newEndContainer, newEndContainer?.textContent?.length || 0);
    }
  } catch (error) {
    return undefined;
  }

  return range;
};

export default preprocessRange;
