import { useMemo } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface ICategoryIconsProps {
  iconClass: string;
  setIconClass: (cssClass: string) => void;
  userCanEdit: boolean;
  isValidIcon: boolean;
  setIsValidIcon: (isValid: boolean) => void;
}

function getCssIconClasses(): string[] {
  // get style sheet which contain icon fonts
  // local there are many sheets, if app is deployed there are only one
  const cssStyleSheet = Object.values(document.styleSheets).find((sheet) =>
    Object.values(sheet.cssRules).find((r) =>
      ((r as CSSStyleRule).selectorText || '').startsWith('.icon-'),
    ),
  ) || { cssRules: {} };

  // get icon rules: icon rules includes "::" and starts with ".icon-"
  const cssFontStyleRules = Object.values(cssStyleSheet.cssRules).filter(
    (c) =>
      c instanceof CSSStyleRule &&
      c.selectorText.includes('::') &&
      c.selectorText.startsWith('.icon'),
  ) as CSSStyleRule[];

  // get only class names of the rules
  return Object.values(cssFontStyleRules).map((i) =>
    i.selectorText.split('::')[0].slice(1),
  );
}

function CategoryTypeIcons({
  iconClass,
  setIconClass,
  userCanEdit,
  isValidIcon,
  setIsValidIcon,
}: ICategoryIconsProps): JSX.Element {
  const { t: translation } = useTranslation();
  const fontIconClasses = useMemo(getCssIconClasses, []);

  return (
    <>
      <Form.Group className='mb-3' controlId='IconName'>
        <Form.Label>{translation('iconName')}*</Form.Label>
        <Form.Control
          aria-describedby={!isValidIcon ? 'IconInputError' : undefined}
          required
          isInvalid={!isValidIcon}
          type='text'
          value={iconClass}
          readOnly={!userCanEdit}
          onChange={(e) => {
            if (userCanEdit) {
              setIconClass(e.target.value);
              if (e.target.value.trim().length > 0) {
                setIsValidIcon(true);
              }
            }
          }}
        />
        <Form.Control.Feedback id='IconInputError' type='invalid'>
          {translation('fieldNotEmpty')}
        </Form.Control.Feedback>
      </Form.Group>
      {userCanEdit && (
        <div className='category-type-icons display-inline'>
          {fontIconClasses.map((icon) => (
            <Button
              key={icon}
              variant='light'
              className='bg-transparent border-0'
              aria-label={icon}
              title={icon}
              onClick={() => {
                if (iconClass === icon) {
                  return;
                }
                setIconClass(icon);
                setIsValidIcon(true);
              }}
              aria-current={iconClass === icon}>
              <i aria-hidden className={icon} />
            </Button>
          ))}
        </div>
      )}
    </>
  );
}

export default CategoryTypeIcons;
