type NodeProperty =
  | 'parentNode'
  | 'previousSibling'
  | 'nextSibling'
  | 'firstChild';

export const contentReferenceClass = 'content-ref';
export const externalReferenceClass = 'external-ref';

const isFiberNode = (node: Node) =>
  node?.nodeType === Node.TEXT_NODE &&
  node.textContent &&
  (node.textContent.match(/^\n$/g) || node.textContent.match(/^$/g)) &&
  node.textContent?.length === 1;

const isTextNode = (node: Node) =>
  node?.nodeType === Node.TEXT_NODE && !isFiberNode(node);

const isImportedLinkSpan = (node: Node): boolean => {
  const el = node as Element;
  return (
    node.nodeName === 'SPAN' &&
    (el.classList.contains(contentReferenceClass) ||
      el.classList.contains(externalReferenceClass))
  );
};

export const isAccessibleButton = (node: Node): boolean =>
  node.nodeName === 'BUTTON' ||
  node.nodeName === 'I' ||
  (node as Element).role === 'button';

const isMarkOrLink = (node: Node): boolean =>
  node.nodeName === 'MARK' ||
  node.nodeName === 'A' ||
  isImportedLinkSpan(node) ||
  isAccessibleButton(node);

const getNodeIgnoreFiberNodes = (
  node: Node,
  nodeProperty: NodeProperty,
): Node | null => {
  const n = node[`${nodeProperty}`] as Node;

  if (isFiberNode(n)) {
    return getNodeIgnoreFiberNodes(n, nodeProperty);
  }

  return n;
};

export { isFiberNode, isTextNode, isMarkOrLink, getNodeIgnoreFiberNodes };
