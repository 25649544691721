import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';
import authService from './AuthorizeService';
import {
  QueryParameterNames,
  LogoutActions,
  ApplicationPaths,
} from './ApiAuthorizationConstants';
import Loader from '../loader/Loader';
import { EXPANDED_CATEGORY_IDS } from '../../shared/constants';

interface ILogoutProps {
  action: string;
}

function Logout({ action }: ILogoutProps): JSX.Element {
  const [message, setMessage] = useState('');
  const [isReady, setIsReady] = useState(false);
  const [
    expandedCategoriesInSessionStorage,
    setExpandedCategoriesInSessionStorage,
  ] = useSessionStorage<string[]>(EXPANDED_CATEGORY_IDS, []);
  const navigate = useNavigate();

  const navigateToReturnUrl = (returnUrl: string) =>
    window.location.replace(returnUrl);

  const logout = async (returnUrl: string) => {
    const state = { returnUrl };
    const isauthenticated = await authService.isAuthenticated();
    if (isauthenticated) {
      const result = await authService.signOut(state);
      switch (result.status) {
        case 'redirect':
          break;
        case 'success':
          navigateToReturnUrl(returnUrl);
          break;
        case 'fail':
          setMessage(result.message);
          break;
        default:
          throw new Error('Invalid authentication result status.');
      }
    } else {
      setMessage('You successfully logged out!');
    }
  };

  const getReturnUrl = (state?: any): string => {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(QueryParameterNames.ReturnUrl);
    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
      // This is an extra check to prevent open redirects.
      throw new Error(
        'Invalid return url. The return url needs to have the same origin as the current page.',
      );
    }
    return (
      (state && state.returnUrl) ||
      fromQuery ||
      `${window.location.origin}${ApplicationPaths.LoggedOut}`
    );
  };

  const processLogoutCallback = async () => {
    const url = window.location.href;
    const result = await authService.completeSignOut(url);
    switch (result.status) {
      case 'success':
        navigateToReturnUrl(getReturnUrl(result.state));
        if (expandedCategoriesInSessionStorage !== undefined) {
          setExpandedCategoriesInSessionStorage([]);
        }
        break;
      case 'fail':
        setMessage(result.message);
        break;
      default:
        throw new Error('Invalid authentication result status.');
    }
  };

  useEffect(() => {
    switch (action) {
      case LogoutActions.Logout:
        logout(getReturnUrl());
        break;
      case LogoutActions.LogoutCallback:
        processLogoutCallback();
        break;
      case LogoutActions.LoggedOut:
        setIsReady(true);
        setMessage('You successfully logged out!');
        navigate('/');
        break;
      default:
        throw new Error(`Invalid action '${action}'`);
    }
  }, []);

  const getActionMessage = (): JSX.Element => {
    switch (action) {
      case LogoutActions.Logout:
        return <Loader />;
      case LogoutActions.LogoutCallback:
        return <Loader />;
      case LogoutActions.LoggedOut:
        return <div>{message}</div>;
      default:
        throw new Error(`Invalid action '${action}'`);
    }
  };

  return (
    <>
      {!isReady && <Loader />}
      {message ? <div>{message}</div> : getActionMessage()}
    </>
  );
}

export default Logout;
