import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'usehooks-ts';
import useRefetchContentTree from '../../hooks/useRefetchContentTreeIfInvalid';
import SearchField from './SearchField';
import SearchFilter from './SearchFilter';
import { mdBreakpoint } from '../../shared/constants';
import { useAppSelector } from '../../redux/hooks';
import { selectSearchKeyword } from '../../redux/store/content/slice';

interface ISearchFormProps {
  startOffset: number;
  setStartOffset: (offset: number) => void;
  startSearch: () => void;
}

function SearchForm({
  startOffset,
  setStartOffset,
  startSearch,
}: ISearchFormProps) {
  const { t: translation } = useTranslation();
  const { width } = useWindowSize();
  const refetchTreeIfInvalid = useRefetchContentTree([]);
  const searchKeyword = useAppSelector(selectSearchKeyword);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        if (startOffset !== 0) {
          setStartOffset(0);
        } else {
          startSearch();
        }
        refetchTreeIfInvalid();
      }}>
      {width < mdBreakpoint && <SearchField />}
      <SearchFilter />
      <Button
        variant='outline-success'
        disabled={searchKeyword.trim() === ''}
        className='mt-3 float-end'
        type='submit'>
        {translation('startSearch')}
      </Button>
    </Form>
  );
}

export default SearchForm;
