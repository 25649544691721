import {
  ArticleReference as ArticleReferenceType,
  ExternalReference as ExternalReferenceType,
} from '../../redux/store/api/api';
import { ReferenceBehaviour } from '../../shared/enums';

export type ReferenceBehaviourString = keyof typeof ReferenceBehaviour;

export enum ReferenceType {
  ExternalReference = 'ExternalReference',
  InternalReferenceSingle = 'InternalReferenceSingle',
  InternalReferenceMultiple = 'InternalReferenceMultiple',
  InternalReferenceInArticle = 'InternalReferenceInArticle',
  InternalReferenceWithTextPassage = 'InternalReferenceWithTextPassage',
}

export type ContextReferenceType = {
  reference: ArticleReferenceType | ExternalReferenceType;
  categoryTypeId: string;
};
