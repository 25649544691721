import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppDispatch } from '../../../redux/hooks';
import {
  ArticleReference,
  ExternalReference,
  SourceContentArea,
  useGetApiArticlesReferencesByIdQuery,
  usePostApiArticleReferencesMutation,
  usePostApiExternalReferencesMutation,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import { IArticleTreeItem, ICategoryTreeItem } from '../../content-tree/types';
import CustomDialog from '../../dialogs/CustomDialog';
import '../References.scss';
import {
  ContextReferenceType,
  ReferenceBehaviourString,
  ReferenceType,
} from '../types';
import MultipleInternalReferenceForm from '../MultipleInternalReferenceForm';
import ExternalReferenceForm from '../ExternalReferenceForm';
import InternalReferenceWithTextPassageForm from '../InternalReferenceWithTextPassageForm';
import InternalReferenceInArticle from '../InternalReferenceInArticle';
import { ReferenceBehaviour } from '../../../shared/enums';
import {
  articleIdUrlParam,
  externalReferencesAccordionKey,
} from '../../../shared/constants';
import Loader from '../../loader/Loader';
import { setShowMakeLinkSelectionInfo } from '../../../redux/store/content/slice';

interface IAddReferenceDialogProps {
  dialogShow: boolean;
  setDialogShow: (show: boolean) => void;
  sourceContentArea?: SourceContentArea;
  resetSourceContentArea: () => void;
  sourceContentAreaAncestorIds: string[];
  setContextReference: (contextReference: ContextReferenceType) => void;
}

function AddReferenceDialog({
  dialogShow,
  setDialogShow,
  sourceContentArea,
  resetSourceContentArea,
  sourceContentAreaAncestorIds,
  setContextReference,
}: IAddReferenceDialogProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { t: translation } = useTranslation();
  const [searchParams] = useSearchParams();
  const articleId: string | null = searchParams.get(articleIdUrlParam);
  const [isValidUrl, setIsValidUrl] = useState(true);
  const [referenceType, setReferenceType] = useState<ReferenceType | null>(
    null,
  );
  const [referenceTypeIsValid, setReferenceTypeIsValid] = useState(true);
  const [textPassage, setTextPassage] = useState<string | null>(null);
  const [textPassageIsValid, setTextPassageIsValid] = useState(true);
  const [selectedElements, setSelectedElements] = useState<
    (IArticleTreeItem | ICategoryTreeItem)[] | null
  >(null);
  const [selectedReferenceIsValid, setSelectedReferenceIsValid] =
    useState(true);
  const [referenceBehaviour, setReferenceBehaviour] =
    useState<ReferenceBehaviourString>(
      ReferenceBehaviour[
        ReferenceBehaviour.Bidirectional
      ] as ReferenceBehaviourString,
    );
  const [displayNameForExternalTarget, setDisplayNameForExternalTarget] =
    useState('');
  const [
    displayNameForExternalTargetIsValid,
    setDisplayNameForExternalTargetIsValid,
  ] = useState(true);
  const [targetUrl, setTargetUrl] = useState('');

  const [
    addInternalReference,
    {
      isError: addInternalReferenceIsError,
      error: addInternalReferenceError,
      isLoading: addInternalReferenceIsLoading,
    },
  ] = usePostApiArticleReferencesMutation();
  const [
    addExternalReferences,
    {
      isError: addExternalReferenceIsErrror,
      error: addExternalReferenceErrror,
      isLoading: addExternalReferenceIsLoading,
    },
  ] = usePostApiExternalReferencesMutation();
  const isLoading =
    addInternalReferenceIsLoading || addExternalReferenceIsLoading;
  const { refetch } = useGetApiArticlesReferencesByIdQuery(
    articleId
      ? {
          id: articleId,
        }
      : skipToken,
  );

  useEffect(() => {
    if (addExternalReferenceIsErrror) {
      dispatch(
        addMessage({
          id: 'AddExternalReferenceError',
          variant: 'danger',
          messageKeyBody:
            addExternalReferenceErrror && 'data' in addExternalReferenceErrror
              ? addExternalReferenceErrror.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (addInternalReferenceIsError) {
      dispatch(
        addMessage({
          id: 'AddInternalReferenceError',
          variant: 'danger',
          messageKeyBody:
            addInternalReferenceError && 'data' in addInternalReferenceError
              ? addInternalReferenceError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [addInternalReferenceIsError, addExternalReferenceIsErrror]);

  const resetSelection = () => {
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
    }
  };

  const resetStates = () => {
    setReferenceType(null);
    setSelectedElements(null);
    setReferenceBehaviour(
      ReferenceBehaviour[
        ReferenceBehaviour.Bidirectional
      ] as ReferenceBehaviourString,
    );
    setDisplayNameForExternalTarget('');
    setIsValidUrl(true);
    setDisplayNameForExternalTargetIsValid(true);
    setTextPassage(null);
    setTextPassageIsValid(true);
    setSelectedReferenceIsValid(true);
    resetSourceContentArea();
    resetSelection();
    dispatch(setShowMakeLinkSelectionInfo(false));
  };

  const validateInternalReferenceInputs = () => {
    let valid = true;

    if (!referenceType) {
      setReferenceTypeIsValid(false);
      valid = false;
    }

    if (
      (referenceType === ReferenceType.InternalReferenceInArticle ||
        referenceType === ReferenceType.InternalReferenceWithTextPassage) &&
      !textPassage
    ) {
      setTextPassageIsValid(false);
      valid = false;
    }

    if (referenceType === ReferenceType.ExternalReference) {
      if (displayNameForExternalTarget.trim().length === 0) {
        setDisplayNameForExternalTargetIsValid(false);
        valid = false;
      }

      if (targetUrl.trim().length === 0) {
        setIsValidUrl(false);
        valid = false;
      }
    }

    if (
      (referenceType === ReferenceType.InternalReferenceMultiple ||
        referenceType === ReferenceType.InternalReferenceSingle ||
        referenceType === ReferenceType.InternalReferenceWithTextPassage) &&
      (!selectedElements || selectedElements.length === 0)
    ) {
      setSelectedReferenceIsValid(false);
      valid = false;
    }

    return valid;
  };

  const getReferences = (): ArticleReference[] =>
    referenceType === ReferenceType.InternalReferenceInArticle
      ? [
          {
            sourceContentArea,
            targetArticleId: articleId || '',
            sourceArticleId: articleId || '',
            targetContentAreaId: textPassage,
            referenceBehaviour: ReferenceBehaviour[referenceBehaviour],
          },
        ]
      : selectedElements?.map((e) => {
          const targetArticleId: string | null = (e as IArticleTreeItem)
            .recentVersionId
            ? e.id
            : null;
          const targetCategoryId: string | null = (e as ICategoryTreeItem)
            .categoryTypeId
            ? e.id
            : null;
          return {
            targetArticleId,
            targetCategoryId,
            sourceContentArea,
            sourceArticleId: articleId,
            targetContentAreaId: textPassage,
            referenceBehaviour: ReferenceBehaviour[referenceBehaviour],
          };
        }) || [];

  const handleAddReference = () => {
    if (!validateInternalReferenceInputs()) {
      return;
    }

    if (referenceType === ReferenceType.ExternalReference) {
      addExternalReferences({
        externalReference: {
          articleId: articleId || '',
          name: displayNameForExternalTarget.trim(),
          hyperlink: targetUrl.trim(),
        },
      })
        .unwrap()
        .then((result) => {
          if (result.messageKey && result.messageKey !== '') {
            dispatch(
              addMessage({
                id: 'AddExternalReferenceSuccess',
                variant: 'success',
                messageKeyBody: result.messageKey,
              }),
            );
          }
          setDialogShow(false);
          resetStates();
          refetch().then(() =>
            setContextReference({
              reference: result.resultObject as ExternalReference,
              categoryTypeId: externalReferencesAccordionKey,
            }),
          );
        });
    } else {
      const references: ArticleReference[] = getReferences();

      addInternalReference({
        body: references,
      })
        .unwrap()
        .then((result) => {
          if (result.messageKey && result.messageKey !== '') {
            dispatch(
              addMessage({
                id: 'AddInternalReferenceSuccess',
                variant: 'success',
                messageKeyBody: result.messageKey,
              }),
            );
          }
          setDialogShow(false);
          resetStates();
          refetch()
            .unwrap()
            .then((t) => {
              const reference = references[0];
              const categoryTypeId =
                t.resultObject?.articleReferenceLists?.find(
                  (rL) =>
                    rL.articleReferences?.some(
                      (r) =>
                        r.referenceBehaviour === reference.referenceBehaviour &&
                        r.sourceArticleId === reference.sourceArticleId &&
                        r.targetArticleId === reference.targetArticleId &&
                        r.targetCategoryId === reference.targetCategoryId,
                    ),
                )?.categoryType?.id || '';
              setContextReference({
                reference,
                categoryTypeId,
              });
            });
        });
    }
  };

  const getReferenceTypes = () => {
    let referenceTypes = Object.keys(ReferenceType);
    if (sourceContentArea) {
      referenceTypes = referenceTypes.filter(
        (t) =>
          t !== ReferenceType.ExternalReference &&
          t !== ReferenceType.InternalReferenceMultiple,
      );
    } else {
      referenceTypes = referenceTypes.filter(
        (t) =>
          t !== ReferenceType.InternalReferenceInArticle &&
          t !== ReferenceType.InternalReferenceSingle,
      );
    }

    // === DISABLE ADDING REFERENCES WITH TARGET CONTENT AREA
    referenceTypes = referenceTypes.filter(
      (t) =>
        t !== ReferenceType.InternalReferenceWithTextPassage &&
        t !== ReferenceType.InternalReferenceInArticle,
    );
    // ===

    return referenceTypes;
  };

  const getReferenceBehaviourKeys = () => {
    let referenceBehaviourKeys = Object.keys(ReferenceBehaviour).filter((key) =>
      Number.isNaN(Number(key)),
    );

    if (sourceContentArea) {
      referenceBehaviourKeys = referenceBehaviourKeys.filter(
        (k) => k !== ReferenceBehaviour[ReferenceBehaviour.BackwardOnly],
      );
    }

    return referenceBehaviourKeys as ReferenceBehaviourString[];
  };

  return (
    <CustomDialog
      dialogId='AddReferenceDialog'
      titleId='AddReferenceDialogTitle'
      wideDialog
      show={dialogShow}
      closeFunction={() => {
        resetStates();
        setDialogShow(false);
      }}
      closeTitle={translation('cancel')}
      actionFunction={handleAddReference}
      actionTitle={translation('add')}
      actionButtonDisabled={isLoading}
      dialogTitle={translation('addReference')}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <p>{translation('fieldsAreRequiredLegend')}</p>
          <Form>
            {sourceContentArea && (
              <p>
                {`${translation('referenceWillCreateLinkInContent')} "${
                  sourceContentArea.patternString
                }"`}
              </p>
            )}
            <fieldset
              aria-describedby={
                referenceTypeIsValid ? undefined : 'ReferenceTypeIsError'
              }>
              <legend className='mb-1 fw-bold fs-6'>
                {translation('chooseTypeOfReference')}*
              </legend>
              {getReferenceTypes().map((key) => (
                <Form.Check
                  key={key}
                  id={`${key}_Radio`}
                  value='external'
                  checked={referenceType === key}
                  label={translation(`referenceType${key}`)}
                  name='ReferenceType'
                  type='radio'
                  onChange={(e) => {
                    setSelectedElements(null);
                    setTextPassage(null);
                    setTargetUrl('');
                    setIsValidUrl(true);
                    setReferenceBehaviour(
                      key === ReferenceType.InternalReferenceInArticle
                        ? (ReferenceBehaviour[
                            ReferenceBehaviour.ForwardOnly
                          ] as ReferenceBehaviourString)
                        : (ReferenceBehaviour[
                            ReferenceBehaviour.Bidirectional
                          ] as ReferenceBehaviourString),
                    );

                    if (e.target.checked) {
                      setReferenceType(key as ReferenceType);
                      setReferenceTypeIsValid(true);
                    }
                  }}
                />
              ))}
              {!referenceTypeIsValid && (
                <Form.Control.Feedback
                  id='ReferenceTypeIsError'
                  type='invalid'
                  className='d-block'>
                  {translation('fieldNotEmpty')}
                </Form.Control.Feedback>
              )}
            </fieldset>
            {(referenceType === ReferenceType.InternalReferenceMultiple ||
              referenceType === ReferenceType.InternalReferenceSingle) && (
              <MultipleInternalReferenceForm
                referenceType={referenceType}
                selectedReferences={selectedElements}
                selectedReferenceIsValid={selectedReferenceIsValid}
                setSelectedReferenceIsValid={setSelectedReferenceIsValid}
                referenceBehaviour={referenceBehaviour}
                setReferenceBehaviour={setReferenceBehaviour}
                setSelectedReferences={setSelectedElements}
                getReferenceBehaviourKeys={getReferenceBehaviourKeys}
                hasSourceContentArea={sourceContentArea !== null}
              />
            )}
            {referenceType ===
              ReferenceType.InternalReferenceWithTextPassage && (
              <InternalReferenceWithTextPassageForm
                textPassage={textPassage}
                setTextPassage={setTextPassage}
                textPassageIsValid={textPassageIsValid}
                setTextPassageIsValid={setTextPassageIsValid}
                referenceBehaviour={referenceBehaviour}
                setReferenceBehaviour={setReferenceBehaviour}
                setSelectedReferences={setSelectedElements}
                selectedReferences={selectedElements}
                selectedReferenceIsValid={selectedReferenceIsValid}
                setSelectedReferenceIsValid={setSelectedReferenceIsValid}
              />
            )}
            {referenceType === ReferenceType.ExternalReference && (
              <ExternalReferenceForm
                displayName={displayNameForExternalTarget}
                setDisplayName={setDisplayNameForExternalTarget}
                isValidName={displayNameForExternalTargetIsValid}
                setIsValidName={setDisplayNameForExternalTargetIsValid}
                targetUrl={targetUrl}
                setTargetUrl={setTargetUrl}
                isValidUrl={isValidUrl}
                setIsValidUrl={setIsValidUrl}
              />
            )}
            {referenceType === ReferenceType.InternalReferenceInArticle && (
              <InternalReferenceInArticle
                textPassage={textPassage}
                setTextPassage={setTextPassage}
                textPassageIsValid={textPassageIsValid}
                setTextPassageIsValid={setTextPassageIsValid}
                sourceContentAreaAncestorIds={sourceContentAreaAncestorIds}
              />
            )}
          </Form>
        </>
      )}
    </CustomDialog>
  );
}

AddReferenceDialog.defaultProps = { sourceContentArea: null };

export default AddReferenceDialog;
