import { Note } from '../../redux/store/api/api';
import {
  ExtendedNote,
  ExtendedSortedNotes,
  VisibilityGroupString,
} from './types';

type NoteDataProperty = 'userNotes' | 'userGroupNotes' | 'generalNotes';

export const getSortedNotesPropertyByVisibilityGroup = (
  visibilityGroup: VisibilityGroupString | null,
): NoteDataProperty | undefined => {
  if (!visibilityGroup) {
    return undefined;
  }

  const firstLetterUpperCase = visibilityGroup[0];
  const firstLetterLowerCase = firstLetterUpperCase.toLocaleLowerCase();
  return `${visibilityGroup.replace(
    firstLetterUpperCase,
    firstLetterLowerCase,
  )}Notes` as NoteDataProperty;
};

export const getNotesByVisibilityGroup = (
  visibilityGroup: VisibilityGroupString | null,
  noteData: ExtendedSortedNotes,
): ExtendedNote[] => {
  switch (visibilityGroup) {
    case 'User':
      return noteData.userNotes || [];
    case 'UserGroup':
      return noteData.userGroupNotes || [];
    case 'General':
      return noteData.generalNotes || [];
    default:
      return [];
  }
};

export const scrollMarkIntoView = (note: Note) => {
  if (!note.mark) {
    return;
  }

  const markEl: HTMLElement | null = document.getElementById(
    `${note.mark.id}-1/1`,
  );
  if (markEl) {
    markEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

export const getFirstWords = (fullText: string | null | undefined) => {
  if (!fullText) {
    return undefined;
  }

  const maxWords = 5;
  return fullText.split(/\s/g).slice(0, maxWords).join(' ');
};

export const getNotesForAccordionList = (notes: ExtendedNote[]) =>
  notes.filter(
    (note) =>
      !note.mark ||
      (note.text?.length ?? 0) > 0 ||
      note.mark.markInvalid ||
      note.mark.markIsError,
  );
