import { CheckSquareFill, Square, DashSquareFill } from 'react-bootstrap-icons';

interface ITriStateCheckboxProps {
  id: string;
  label: string;
  checked: boolean | 'mixed';
  disabled?: boolean;
  iconClass?: string;
  iconColor?: string;
  onChange: (checked: boolean | 'mixed') => void;
}

function TriStateCheckbox({
  id,
  label,
  checked,
  disabled,
  iconClass,
  iconColor,
  onChange,
}: ITriStateCheckboxProps) {
  // false -> mixed -> true -> false
  const handleChangeState = () => {
    if (!checked) {
      onChange('mixed');
      return;
    }
    if (checked === 'mixed') {
      onChange(true);
      return;
    }
    if (checked) {
      onChange(false);
    }
  };

  return (
    <div
      id={id}
      className={disabled ? 'text-muted' : ''}
      role='checkbox'
      tabIndex={0}
      aria-checked={checked}
      onClick={(e) => {
        if (disabled) {
          return;
        }
        e.stopPropagation();
        handleChangeState();
      }}
      onKeyDown={(e) => {
        if (disabled) {
          return;
        }
        if (e.code === 'Space') {
          handleChangeState();
        }
      }}>
      {checked === true && (
        <CheckSquareFill
          aria-hidden
          className={`${!disabled ? 'text-primary' : ''} align-baseline me-2`}
        />
      )}
      {checked === false && (
        <Square aria-hidden className='align-baseline me-2' />
      )}
      {checked === 'mixed' && (
        <DashSquareFill
          aria-hidden
          className={`${!disabled ? 'text-primary' : ''} align-baseline me-2`}
        />
      )}
      <i
        className={`${iconClass} me-1`}
        aria-hidden
        style={{ color: iconColor || '' }}
      />
      {label}
    </div>
  );
}

TriStateCheckbox.defaultProps = {
  disabled: false,
  iconClass: '',
  iconColor: '',
};

export default TriStateCheckbox;
