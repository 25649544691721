import { useRef } from 'react';
import { PresenceType, useIdleTimer } from 'react-idle-timer';

interface IIdleTimerProps {
  timeoutInMinutes: number | undefined;
  onPresenceChange: (presence: PresenceType) => void;
}

function IdleTimer({
  timeoutInMinutes,
  onPresenceChange,
}: IIdleTimerProps): JSX.Element {
  const idleTimeRef = useRef(null);
  const defaultTimeOutInMinutes = 30;

  const convertToMilliSeconds = (minutes: number | undefined): number => {
    const secondInMs = 1000;
    const minuteInSec = 60;

    if (minutes) {
      return secondInMs * minuteInSec * minutes;
    }

    return secondInMs * minuteInSec * defaultTimeOutInMinutes;
  };

  useIdleTimer({
    crossTab: true,
    ref: idleTimeRef,
    timeout: convertToMilliSeconds(timeoutInMinutes),
    onPresenceChange,
  });

  return <span />;
}

export default IdleTimer;
