import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import CustomDialog from '../../dialogs/CustomDialog';
import { PermissionContextString } from '../types';
import {
  Role,
  useGetApiRolesQuery,
  usePostApiRolesMutation,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import { useAppDispatch } from '../../../redux/hooks';
import { PermissionContext } from '../../../shared/enums';
import Loader from '../../loader/Loader';

interface IAddRoleDialogProps {
  show: boolean;
  setActiveRole: (role: Role) => void;
  onClose: () => void;
}

function AddRoleDialog({
  show,
  setActiveRole,
  onClose,
}: IAddRoleDialogProps): JSX.Element {
  const { t: translation } = useTranslation();
  const [roleName, setRoleName] = useState<string>('');
  const [roleNameIsValid, setRoleNameIsValid] = useState(true);
  const defaultPermissionContext = '0';
  const [permissionContext, setPermissionContext] = useState<string>(
    defaultPermissionContext,
  );
  const [permissionContextIsValid, setPermissionContextIsValid] =
    useState(true);
  const [
    addRole,
    { isError: postRoleIsError, isLoading, error: postRoleError },
  ] = usePostApiRolesMutation();
  const {
    refetch,
    isError: getRolesIsError,
    error: getRolesError,
  } = useGetApiRolesQuery();
  const roleNameInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (show) {
      roleNameInputRef.current?.focus();
    }
  }, [show]);

  useEffect(() => {
    if (getRolesIsError) {
      dispatch(
        addMessage({
          id: 'GetRolesError',
          variant: 'danger',
          messageKeyBody:
            getRolesError && 'data' in getRolesError
              ? getRolesError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (postRoleIsError) {
      dispatch(
        addMessage({
          id: 'CreateRoleError',
          variant: 'danger',
          messageKeyBody:
            postRoleError && 'data' in postRoleError
              ? postRoleError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [getRolesIsError, postRoleIsError]);

  const handleChangePermissionContext = (value: string) => {
    setPermissionContext(value);
  };

  const resetInputValues = () => {
    setPermissionContext(defaultPermissionContext);
    setRoleName('');
    setRoleNameIsValid(true);
    setPermissionContextIsValid(true);
  };

  const handleAddRole = (): void => {
    if (
      roleName.trim().length === 0 ||
      permissionContext === defaultPermissionContext
    ) {
      if (roleName.trim().length === 0) {
        setRoleNameIsValid(false);
      }
      if (permissionContext === defaultPermissionContext) {
        setPermissionContextIsValid(false);
      }

      return;
    }

    addRole({
      role: {
        name: roleName.trim(),
        permissionContext:
          PermissionContext[permissionContext as PermissionContextString],
      },
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'CreateRoleSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        resetInputValues();
        onClose();
        setActiveRole(result.resultObject || {});
      });
  };

  return (
    <CustomDialog
      titleId='AddRoleDialog'
      actionButtonDisabled={isLoading}
      dialogTitle={translation('addRole')}
      show={show}
      closeFunction={() => {
        resetInputValues();
        onClose();
      }}
      actionFunction={handleAddRole}
      closeTitle={translation('cancel')}
      actionTitle={translation('add')}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <p>{translation('fieldsAreRequiredLegend')}</p>
          <Form>
            <Form.Group className='mb-3' controlId='RoleNameForAdd'>
              <Form.Label>{translation('nameOfRole')}*</Form.Label>
              <Form.Control
                aria-describedby={
                  roleNameIsValid ? undefined : 'RoleNameInputError'
                }
                isInvalid={!roleNameIsValid}
                ref={roleNameInputRef}
                required
                onChange={(e) => {
                  setRoleName(e.target.value);
                  if (e.target.value.trim().length > 0) {
                    setRoleNameIsValid(true);
                  }
                }}
                value={roleName}
                type='text'
              />
              <Form.Control.Feedback id='RoleNameInputError' type='invalid'>
                {translation('fieldNotEmpty')}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-3' controlId='RoleContext'>
              <Form.Label>{translation('context')}*</Form.Label>
              <Form.Select
                aria-describedby={
                  permissionContextIsValid
                    ? undefined
                    : 'PermissionContextInputError'
                }
                isInvalid={!permissionContextIsValid}
                required
                value={permissionContext || 0}
                onChange={(e) => {
                  handleChangePermissionContext(e.target.value);
                  setPermissionContextIsValid(true);
                }}>
                <option value='0' disabled hidden>
                  {translation('chooseContextOfRole')}
                </option>
                {Object.keys(PermissionContext)
                  .filter((key) => Number.isNaN(Number(key)))
                  .map((key) => (
                    <option key={key} value={key}>
                      {translation(`permissionContext${key}`)}
                    </option>
                  ))}
              </Form.Select>

              <Form.Control.Feedback
                id='PermissionContextInputError'
                type='invalid'>
                {translation('fieldNotEmpty')}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </>
      )}
    </CustomDialog>
  );
}

export default AddRoleDialog;
