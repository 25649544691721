import { useMemo } from 'react';
import { Category, useGetApiCategoryTreeQuery } from '../redux/store/api/api';

function useGetCategoryByArticleId(
  articleId: string | null | undefined,
): Category | undefined {
  const { data: contentTreeData } = useGetApiCategoryTreeQuery();

  return useMemo<Category | undefined>(() => {
    if (contentTreeData?.resultObject?.categories && !!articleId) {
      return contentTreeData.resultObject.categories.find(
        (c) => c.articleIds && c.articleIds.includes(articleId),
      );
    }
    return undefined;
  }, [contentTreeData, articleId]);
}

export default useGetCategoryByArticleId;
