import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import { useGetApiCategoriesOverviewByIdQuery } from '../../redux/store/api/api';
import { addMessage } from '../../redux/store/layout/slice';
import { getArticleUrl } from '../../shared/urlBuilder';
import CustomCard from '../cards/CustomCard';
import CustomListGroup from '../lists/CustomListGroup';
import Loader from '../loader/Loader';
import {
  articleIdsWithFoundTermsUrlParam,
  categoryIdUrlParam,
  searchKeywordUrlParam,
} from '../../shared/constants';
import { getArticleIcon } from '../../shared/utils';
import { ArticleType } from '../../shared/enums';

function CategoryReferenceView() {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const categoryId: string | null = searchParams.get(categoryIdUrlParam);
  const articleIdsWithFoundTermsParam: string | null = searchParams.get(
    articleIdsWithFoundTermsUrlParam,
  );
  const { data, isError, isFetching, error } =
    useGetApiCategoriesOverviewByIdQuery(
      categoryId ? { id: categoryId } : skipToken,
    );
  const categoryOfReference = data?.resultObject?.categories?.find(
    (c) => c.id === categoryId,
  );
  const articleIdsWithFoundTerms: { [key: string]: string | null } =
    articleIdsWithFoundTermsParam
      ? JSON.parse(articleIdsWithFoundTermsParam)
      : {};

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetCategoryOverviewError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  return (
    <CustomCard
      headlineAsH1
      focusableHeadline
      iconClass={categoryOfReference?.categoryTypeIconClass || ''}
      title={categoryOfReference?.name || translation('categoryReference')}
      iconColorHexCode={categoryOfReference?.categoryTypeColorHexCode || ''}
      customCardStyle={{ flex: 1 }}>
      {isFetching && <Loader />}
      {data?.resultObject?.articles && (
        <CustomListGroup
          listItems={
            data.resultObject.articles.map((a) => ({
              id: a.id || '',
              content: (
                <div
                  className={`p-1 ${
                    Object.keys(articleIdsWithFoundTerms).includes(a.id || '')
                      ? 'border border-primary'
                      : ''
                  }`}>
                  {a.recentVersionTitle || ''}
                  {Object.keys(articleIdsWithFoundTerms).includes(
                    a.id || '',
                  ) && (
                    <span className='found-terms ms-4'>{`(${translation(
                      'foundTerms',
                    )}: ${articleIdsWithFoundTerms[a.id || '']})`}</span>
                  )}
                </div>
              ),
              href:
                a.type !== ArticleType.StructureElement
                  ? getArticleUrl(
                      a.id || '',
                      undefined,
                      undefined,
                      undefined,
                      searchParams.get(searchKeywordUrlParam)?.split(',') ||
                        undefined,
                    )
                  : undefined,
              iconClass: `pt-1 ${getArticleIcon(a.type || ArticleType.Html)}`,
            })) || []
          }
        />
      )}
      {}
    </CustomCard>
  );
}

export default CategoryReferenceView;
