import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { JSEncrypt } from 'jsencrypt';
import { addMessage } from '../../../redux/store/layout/slice';
import { useAppDispatch } from '../../../redux/hooks';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';
import {
  useGetApiUsersPublicKeyQuery,
  usePostApiUsersValidPasswordMutation,
} from '../../../redux/store/api/api';

interface IEnterPasswordDialogProps {
  show: boolean;
  onClose: () => void;
  setEditModeActive: (active: boolean) => void;
}

function EnterPasswordDialog({
  show,
  onClose,
  setEditModeActive,
}: IEnterPasswordDialogProps): JSX.Element {
  const { t: translation } = useTranslation();
  const [password, setPassword] = useState<string>('');
  const [passwordInvalid, setPasswordInvalid] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const {
    data,
    isError: publicKeyIsError,
    isLoading,
    error: publicKeyError,
  } = useGetApiUsersPublicKeyQuery();

  const [
    validatePassword,
    { isError: validatePasswordIsError, error: validatePasswordError },
  ] = usePostApiUsersValidPasswordMutation();

  useEffect(() => {
    if (publicKeyIsError) {
      dispatch(
        addMessage({
          id: 'GetPublicKeyError',
          variant: 'danger',
          messageKeyBody:
            publicKeyError && 'data' in publicKeyError
              ? publicKeyError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (validatePasswordIsError) {
      dispatch(
        addMessage({
          id: 'ValidatePasswordError',
          variant: 'danger',
          messageKeyBody:
            validatePasswordError && 'data' in validatePasswordError
              ? validatePasswordError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [publicKeyIsError, validatePasswordIsError]);

  const resetAndClose = () => {
    setPasswordInvalid(false);
    setPassword('');
    onClose();
  };

  const encryptMessage = (): string | false => {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(data?.resultObject || '');
    return encrypt.encrypt(password);
  };

  const handleValidatePassword = () => {
    if (password.trim().length === 0) {
      setPasswordInvalid(true);
      return;
    }

    const encryptedPassword = encryptMessage();
    validatePassword({
      password: { encryptedPassword: encryptedPassword || '' },
    })
      .unwrap()
      .then((isValid) => {
        if (isValid.resultObject) {
          setEditModeActive(true);
          resetAndClose();
        } else {
          setPasswordInvalid(true);
        }
      });
  };

  return (
    <CustomDialog
      titleId='EnterPasswordDialog'
      actionButtonDisabled={isLoading}
      dialogTitle={translation('activateEditMode')}
      show={show}
      closeFunction={resetAndClose}
      actionFunction={handleValidatePassword}
      closeTitle={translation('cancel')}
      actionTitle={translation('activate')}
      idOfFocusElement='EnterPassword'>
      {isLoading && <Loader />}
      {!isLoading && (
        <Form>
          <Form.Group className='mb-3' controlId='EnterPassword'>
            <Form.Label>{translation('password')}*</Form.Label>
            <Form.Control
              onChange={(e) => {
                setPassword(e.target.value);

                if (e.target.value.length > 0) {
                  setPasswordInvalid(false);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleValidatePassword();
                }
              }}
              type='password'
              value={password}
              isInvalid={passwordInvalid}
              aria-describedby={
                passwordInvalid ? 'UserPasswordError' : undefined
              }
            />
            <Form.Control.Feedback id='UserPasswordError' type='invalid'>
              {translation(
                password.trim().length === 0
                  ? 'fieldNotEmpty'
                  : 'wrongPassword',
              )}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      )}
    </CustomDialog>
  );
}

export default EnterPasswordDialog;
