import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  ArticleReference,
  ArticleReferenceList,
  useGetApiArticlesReferencesByIdQuery,
  useGetApiCategoriesArticleReferencesByIdQuery,
  usePutApiArticleReferencesByIdMutation,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import { ReferenceBehaviour } from '../../../shared/enums';
import {
  getArticleUrl,
  getCategoryReferenceUrl,
} from '../../../shared/urlBuilder';
import {
  articleIdUrlParam,
  categoryIdUrlParam,
  EDIT_MODE_KEY,
  REFERENCE_LIST_ITEM_ID_EXTENSION,
} from '../../../shared/constants';
import AccordionItem from '../../accordion/AccordionItem';
import { ContextAction } from '../../dropdown-menus/types';
import CustomListGroup from '../../lists/CustomListGroup';
import {
  selectActiveVersion,
  selectLinksNeedingAction,
  setActiveArticleReference,
} from '../../../redux/store/content/slice';
import { ContextReferenceType } from '../types';

interface IArticleReferencesProps {
  currentOpenedArticleId: string | null;
  currentOpenedCategoryId: string | null;
  articleReferenceLists: ArticleReferenceList[] | null | undefined;
  setActiveReferenceListKey: (key: string) => void;
  activeReferenceListKey: string;
  setContextReference: (contextReference: ContextReferenceType) => void;
  setDeleteDialogShow: (show: boolean) => void;
  setRenameDialogShow: (show: boolean) => void;
  userCanEditReference: boolean;
  setLinkNeedsActionDialogOpened: (show: boolean) => void;
}

function ArticleReferences({
  currentOpenedArticleId,
  currentOpenedCategoryId,
  articleReferenceLists,
  setActiveReferenceListKey,
  activeReferenceListKey,
  setContextReference,
  setDeleteDialogShow,
  setRenameDialogShow,
  userCanEditReference,
  setLinkNeedsActionDialogOpened,
}: IArticleReferencesProps) {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);
  const [searchParams] = useSearchParams();
  const articleId: string | null = searchParams.get(articleIdUrlParam);
  const categoryId: string | null = searchParams.get(categoryIdUrlParam);
  const linksNeedingAction = useAppSelector(selectLinksNeedingAction);
  const activeVersion = useAppSelector(selectActiveVersion);
  const [updateReference, { isError: updateReferenceIsError, error }] =
    usePutApiArticleReferencesByIdMutation();
  const { refetch: refetchGetArticleReferences } =
    useGetApiArticlesReferencesByIdQuery(
      articleId
        ? {
            id: articleId,
          }
        : skipToken,
    );
  const { refetch: refetchGetCategoryReferences } =
    useGetApiCategoriesArticleReferencesByIdQuery(
      categoryId ? { id: categoryId } : skipToken,
    );

  useEffect(() => {
    if (updateReferenceIsError) {
      dispatch(
        addMessage({
          id: 'UpdateArticleReferenceError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [updateReferenceIsError]);

  const handleUpdateReference = (
    reference: ArticleReference,
    newBehaviour: ReferenceBehaviour,
  ): void => {
    updateReference({
      id: reference.id || '',
      articleReference: {
        ...reference,
        referenceBehaviour: newBehaviour,
      },
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'UpdateArticleReferenceSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        if (articleId) {
          refetchGetArticleReferences();
        }
        if (categoryId) {
          refetchGetCategoryReferences();
        }
      });
  };

  const getContextActionsForEditMode = (
    contextReference: ContextReferenceType,
  ): ContextAction[] => {
    const actions: ContextAction[] = [];
    const reference = contextReference.reference as ArticleReference;

    actions.push({
      iconClass: 'icon-umbenennen',
      iconColorClass: 'text-body',
      name: translation('renameReference'),
      onClick: () => {
        setContextReference(contextReference);
        setRenameDialogShow(true);
      },
    });

    if (
      reference.referenceBehaviour === ReferenceBehaviour.Bidirectional &&
      (!reference.sourceContentArea || reference.sourceArticleId === articleId)
    ) {
      actions.push({
        iconClass: 'icon-backlink',
        iconColorClass: 'text-danger',
        name: translation('deleteReferenceAtTarget'),
        onClick: () => {
          handleUpdateReference(
            reference,
            currentOpenedArticleId === reference.sourceArticleId
              ? ReferenceBehaviour.ForwardOnly
              : ReferenceBehaviour.BackwardOnly,
          );
        },
      });
    }

    if (reference.referenceBehaviour !== ReferenceBehaviour.Bidirectional) {
      actions.push({
        iconClass: 'icon-backlink',
        iconColorClass: 'text-success',
        name: translation('addReferenceAtTarget'),
        onClick: () => {
          handleUpdateReference(reference, ReferenceBehaviour.Bidirectional);
        },
      });
    }

    actions.push({
      iconClass: 'icon-trash',
      iconColorClass: 'text-danger',
      name: translation('deleteReference'),
      onClick: () => {
        setContextReference(contextReference);
        setDeleteDialogShow(true);
      },
    });

    return actions;
  };

  const getContextActions = (
    contextReference: ContextReferenceType,
  ): ContextAction[] => {
    const actions: ContextAction[] = [];
    const reference = contextReference.reference as ArticleReference;

    const articleIdForHref =
      (reference.sourceArticleId === currentOpenedArticleId
        ? reference.targetArticleId
        : reference.sourceArticleId) || '';

    const targetContentAreaId =
      reference.sourceArticleId === currentOpenedArticleId
        ? reference.targetContentAreaId || undefined
        : undefined;

    actions.push({
      iconClass: 'icon-verlinkungen',
      iconColorClass: 'text-body',
      name: translation('openReferenceInNewTab'),
      href:
        reference.targetCategoryId && !currentOpenedCategoryId
          ? getCategoryReferenceUrl(reference.targetCategoryId)
          : getArticleUrl(articleIdForHref, undefined, targetContentAreaId),
      openInNewTab: true,
      addDividerAfterItem: editModeIsActive,
    });

    if (editModeIsActive && userCanEditReference) {
      actions.push(...getContextActionsForEditMode(contextReference));
    }

    return actions;
  };

  const createReferenceTitle = (
    abbreviation: string | null | undefined,
    name: string | null | undefined,
    originalName: string | null | undefined,
  ): string | undefined => {
    if (abbreviation === originalName) {
      return abbreviation ?? '';
    }
    if (abbreviation && originalName?.includes(abbreviation)) {
      return originalName;
    }
    return `${abbreviation && !name ? `${abbreviation} - ` : ''}${
      name || originalName || ''
    }`;
  };

  const getReferenceDisplayName = (
    reference: ArticleReference,
  ): JSX.Element => {
    if (reference.sourceArticleId === currentOpenedArticleId) {
      return (
        <span
          title={createReferenceTitle(
            reference.categoryAbbreviation,
            reference.name,
            reference.originalName,
          )}>
          {createReferenceTitle(
            reference.categoryAbbreviation,
            reference.name,
            reference.originalName,
          )}
        </span>
      );
    }

    return (
      <span
        title={createReferenceTitle(
          reference.backwardCategoryAbbreviation,
          reference.backwardName,
          reference.originalBackwardName,
        )}>
        {createReferenceTitle(
          reference.backwardCategoryAbbreviation,
          reference.backwardName,
          reference.originalBackwardName,
        )}
      </span>
    );
  };

  return (
    <div>
      {articleReferenceLists &&
        articleReferenceLists.map((a) => (
          <AccordionItem
            key={a.categoryType?.id}
            title={
              <>
                <i
                  aria-hidden
                  className={`${a.categoryType?.iconCssClass} me-1 fs-5 align-text-bottom`}
                  style={
                    a.categoryType?.colorHexCode
                      ? { color: a.categoryType.colorHexCode }
                      : undefined
                  }
                />
                {a.categoryType?.name || ''}
              </>
            }
            setActiveKey={setActiveReferenceListKey}
            activeKey={activeReferenceListKey}
            eventKey={a.categoryType?.id || ''}>
            <CustomListGroup
              listItems={
                a.articleReferences
                  ?.filter(
                    (ar) =>
                      !ar.sourceVersionId ||
                      ar.sourceVersionId === activeVersion.id,
                  )
                  .map((r) => {
                    const articleIdForHref =
                      r.sourceArticleId === currentOpenedArticleId
                        ? r.targetArticleId
                        : r.sourceArticleId;
                    const isSource =
                      r.sourceArticleId === currentOpenedArticleId;
                    const contentAreaId =
                      !r.targetCategoryId && isSource && r.targetContentAreaId
                        ? r.targetContentAreaId
                        : undefined;
                    const href =
                      r.targetCategoryId && !currentOpenedCategoryId
                        ? getCategoryReferenceUrl(r.targetCategoryId)
                        : getArticleUrl(
                            articleIdForHref || '',
                            undefined,
                            contentAreaId,
                          );
                    const linkNotFound = linksNeedingAction.includes(
                      r.sourceContentArea?.id || '',
                    );
                    const linkNotFoundAndCanBeEdited =
                      linkNotFound && userCanEditReference && editModeIsActive;

                    return {
                      id: `${r.id}${REFERENCE_LIST_ITEM_ID_EXTENSION}` || '',
                      iconClass: linkNotFoundAndCanBeEdited
                        ? 'icon-notice_marker'
                        : a.categoryType?.iconCssClass || undefined,
                      iconColorHexCode:
                        a.categoryType?.colorHexCode || undefined,
                      content: getReferenceDisplayName(r),
                      href: linkNotFoundAndCanBeEdited ? undefined : href,

                      contextActions: getContextActions({
                        reference: r,
                        categoryTypeId: a.categoryType?.id || '',
                      }),
                      onClick: linkNotFoundAndCanBeEdited
                        ? () => {
                            dispatch(setActiveArticleReference(r));
                            if (linkNotFoundAndCanBeEdited) {
                              setLinkNeedsActionDialogOpened(true);
                            }
                          }
                        : undefined,
                    };
                  }) || []
              }
              forwardItemIdsToHtml
            />
          </AccordionItem>
        ))}
    </div>
  );
}

export default ArticleReferences;
