import { Button } from 'react-bootstrap';

interface IIconButtonWithTextProps {
  iconClassName: string;
  onClick: Function;
  label: string;
  variant?: string;
}

function IconButtonWithText({
  iconClassName,
  onClick,
  label,
  variant,
}: IIconButtonWithTextProps): JSX.Element {
  return (
    <Button variant={variant} onClick={() => onClick()}>
      <i aria-hidden className={`${iconClassName} me-2`} />
      {label}
    </Button>
  );
}

IconButtonWithText.defaultProps = { variant: 'primary' };

export default IconButtonWithText;
