import { useTranslation } from 'react-i18next';
import CustomDialog from '../../dialogs/CustomDialog';

interface INoValidSelectionDialogProps {
  show: boolean;
  setShow: (show: boolean) => void;
}

function NoValidSelectionDialog({
  show,
  setShow,
}: INoValidSelectionDialogProps): JSX.Element {
  const { t: translation } = useTranslation();

  return (
    <CustomDialog
      titleId='NoValidSelectionDialog'
      dialogTitle={translation('selectContent')}
      show={show}
      closeFunction={() => setShow(false)}
      closeTitle={translation('okay')}
      actionButtonVariant='outline-danger'>
      {translation('noContentSelected')}
    </CustomDialog>
  );
}

export default NoValidSelectionDialog;
