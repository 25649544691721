import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SyntheticEvent, useMemo } from 'react';
import { IBookmarkFolderTreeItem } from '../../../bookmarks/types';
import { paddingOffsetForTreeElements } from '../../../../shared/constants';
import { BookmarkFolder } from '../../../../redux/store/api/api';
import { ContextAction } from '../../../dropdown-menus/types';
import { useAppDispatch } from '../../../../redux/hooks';
import { setContextBookmarkFolder } from '../../../../redux/store/content/slice';
import ContextMenu from '../../../dropdown-menus/ContextMenu';
import { checkClickOnContextMenu } from '../../../../shared/utils';

interface IBookmarkFolderTreeItemProps {
  level: number;
  isBranch: boolean;
  isExpanded: boolean | undefined;
  element: IBookmarkFolderTreeItem;
  handleExpand: Function;
  getNodeProps: Function;
  isSelected: boolean;
  handleSelect: Function;
  setAddBookmarkFolderDialogOpened: (show: boolean) => void;
  setRenameBookmarkFolderDialogOpened: (show: boolean) => void;
  setDeleteBookmarkFolderDialogOpened: (show: boolean) => void;
}

function BookmarkFolderTreeItem({
  level,
  isBranch,
  isExpanded,
  element,
  handleExpand,
  getNodeProps,
  handleSelect,
  isSelected,
  setAddBookmarkFolderDialogOpened,
  setRenameBookmarkFolderDialogOpened,
  setDeleteBookmarkFolderDialogOpened,
}: IBookmarkFolderTreeItemProps) {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const bookmarkFolder = useMemo<BookmarkFolder>(
    () => ({
      id: element.id,
      parentId: element.parent,
      order: element.order,
      name: element.name,
    }),
    [element],
  );

  const contextActions = useMemo<ContextAction[]>(() => {
    const actions: ContextAction[] = [];

    actions.push({
      name: translation('addBookmarkFolder'),
      iconClass: 'icon-folder_new',
      iconColorClass: 'text-success',
      onClick: () => {
        dispatch(setContextBookmarkFolder(bookmarkFolder));
        setAddBookmarkFolderDialogOpened(true);
      },
    });

    actions.push({
      name: translation('renameBookmarkFolder'),
      iconClass: 'icon-umbenennen',
      onClick: () => {
        dispatch(setContextBookmarkFolder(bookmarkFolder));
        setRenameBookmarkFolderDialogOpened(true);
      },
    });

    if (
      bookmarkFolder.parentId !== '0' &&
      (bookmarkFolder.bookmarkFolderIds?.length || 0) +
        (bookmarkFolder.bookmarkIds?.length || 0) ===
        0
    ) {
      actions.push({
        name: translation('deleteBookmarkFolder'),
        iconClass: 'icon-trash',
        iconColorClass: 'text-danger',
        onClick: () => {
          dispatch(setContextBookmarkFolder(bookmarkFolder));
          setDeleteBookmarkFolderDialogOpened(true);
        },
      });
    }

    return actions;
  }, [bookmarkFolder]);

  return (
    <div>
      <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getNodeProps({
          onClick: (e: SyntheticEvent) => {
            if (checkClickOnContextMenu(e)) {
              return;
            }

            if (e.target instanceof HTMLInputElement) {
              return;
            }

            handleExpand(e);
          },
        })}
        style={{ paddingLeft: paddingOffsetForTreeElements * (level - 1) }}>
        <div className='d-flex'>
          {isBranch && isExpanded && (
            <i className='me-2 icon-carretup fs-5 mt-1' aria-hidden />
          )}
          {isBranch && !isExpanded && (
            <i className='me-2 icon-carretdown fs-5 mt-1' aria-hidden />
          )}
          <Form.Check id={bookmarkFolder.id || ''}>
            <Form.Check.Input
              onChange={(e) => {
                handleSelect(e);
                e.stopPropagation();
              }}
              className='mt-2'
              checked={isSelected}
              type='checkbox'
            />
            <Form.Check.Label>
              <i
                aria-hidden
                className='me-1 icon-folder_outline fs-5 align-text-bottom'
              />
              {bookmarkFolder.name}
            </Form.Check.Label>
          </Form.Check>
          <ContextMenu contextActions={contextActions} />
        </div>
      </div>
    </div>
  );
}

export default BookmarkFolderTreeItem;
