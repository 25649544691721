import { Editor } from '@tinymce/tinymce-react';
import React from 'react';
import { useGetApiSettingsUserQuery } from '../../redux/store/api/api';
import { settingTypeTinyConfigFile } from '../../shared/constants';
import Loader from '../loader/Loader';

interface IFullTinyEditorProps {
  onChange: (value: string) => void;
  value: string;
}

function FullTinyEditor({
  onChange,
  value,
}: IFullTinyEditorProps): JSX.Element {
  const { data: settings, isFetching } = useGetApiSettingsUserQuery();
  const tinyConfigSetting = settings?.resultObject?.find(
    (s) => s.settingType?.key === settingTypeTinyConfigFile,
  );

  const configToLoad =
    tinyConfigSetting?.value && tinyConfigSetting.value !== ''
      ? tinyConfigSetting.value
      : 'tinyConfig';

  return (
    <>
      {isFetching && <Loader />}
      {tinyConfigSetting && !isFetching && (
        <Editor
          tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
          onEditorChange={(content) => {
            onChange(content);
          }}
          value={value}
          init={window.tinyConfigs[configToLoad]}
        />
      )}
    </>
  );
}

export default React.memo(FullTinyEditor);
