import { ReactNode } from 'react';
import { Button, useAccordionButton } from 'react-bootstrap';

interface IAccordionTogglerProps {
  activeKey: string;
  eventKey: string;
  setActiveKey: (activeKey: string) => void;
  togglerId: string;
  accordionId: string;
  title?: ReactNode;
  iconInToggler?: boolean;
}

function AccordionToggler({
  activeKey,
  eventKey,
  setActiveKey,
  togglerId,
  accordionId,
  title,
  iconInToggler,
}: IAccordionTogglerProps): JSX.Element {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    const newActiveKey = eventKey === activeKey ? '-1' : eventKey;
    setActiveKey(newActiveKey);
  });
  const isActiveKey = activeKey === eventKey;

  return (
    <Button
      id={title ? togglerId : undefined}
      aria-expanded={isActiveKey}
      aria-controls={accordionId}
      className={`bg-transparent border-0 text-dark ${
        !iconInToggler && title
          ? 'accordion-text-button flex-grow-1 text-start'
          : ''
      } ${iconInToggler && title ? 'd-flex justify-content-between' : ''}`}
      onClick={decoratedOnClick}>
      {title && <span>{title}</span>}
      {iconInToggler && (
        <i
          aria-hidden
          className={isActiveKey ? 'icon-carretup' : 'icon-carretdown'}
        />
      )}
    </Button>
  );
}

AccordionToggler.defaultProps = { iconInToggler: false, title: null };

export default AccordionToggler;
