import React, { PropsWithChildren, ReactNode, SyntheticEvent } from 'react';
import { Accordion, Card, Form } from 'react-bootstrap';
import AccordionToggler from './AccordionToggler';
import IconButton from '../buttons/IconButton';
import AccordionCollapseContainer from './AccordionCollapseContainer';

export interface IAccordionAction {
  name: string;
  iconClassName?: string;
  iconColorClass?: string;
  underlineColorClass?: string;
  onClick: (e: SyntheticEvent) => void;
  isSwitchButton?: boolean;
  switchIsActive?: boolean;
}

interface IAccordionItemProps {
  title: ReactNode | string;
  setActiveKey: (activeKey: string) => void;
  activeKey: string;
  eventKey: string;
  actions?: IAccordionAction[];
}

function AccordionItem({
  title,
  setActiveKey,
  activeKey,
  eventKey,
  children,
  actions,
}: PropsWithChildren<IAccordionItemProps>): JSX.Element {
  return (
    <Card>
      <Card.Header>
        {actions && actions.length > 0 && (
          <div className='d-flex'>
            <AccordionToggler
              setActiveKey={setActiveKey}
              activeKey={activeKey}
              eventKey={eventKey}
              accordionId={`accordion-${eventKey}`}
              togglerId={eventKey}
              title={title}
            />
            <div className='accordion-icons'>
              {actions?.map((action) => (
                <React.Fragment key={action.name}>
                  {action.isSwitchButton && (
                    <Form.Check
                      type='switch'
                      checked={action.switchIsActive}
                      onChange={action.onClick}
                      aria-label={action.name}
                      title={action.name}
                    />
                  )}
                  {!action.isSwitchButton && action.iconClassName && (
                    <IconButton
                      textColorClass={action.iconColorClass || 'text-body'}
                      title={action.name}
                      iconClassName={`${action.iconClassName} ${
                        action.underlineColorClass || ''
                      }`}
                      ariaLabel={action.name}
                      onClick={action.onClick}
                    />
                  )}
                </React.Fragment>
              ))}
              <AccordionToggler
                setActiveKey={setActiveKey}
                activeKey={activeKey}
                eventKey={eventKey}
                accordionId={`accordion-${eventKey}`}
                togglerId={eventKey}
                iconInToggler
              />
            </div>
          </div>
        )}
        {(!actions || actions.length === 0) && (
          <AccordionToggler
            setActiveKey={setActiveKey}
            activeKey={activeKey}
            eventKey={eventKey}
            accordionId={`accordion-${eventKey}`}
            togglerId={eventKey}
            title={title}
            iconInToggler
          />
        )}
      </Card.Header>
      <Accordion.Collapse
        as={AccordionCollapseContainer}
        id={`accordion-${eventKey}`}
        aria-labelledby={eventKey}
        eventKey={eventKey}>
        <Card.Body>{children}</Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}

AccordionItem.defaultProps = { actions: undefined };

export default AccordionItem;
