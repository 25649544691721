import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CategoryType,
  useGetApiCategoryTypesQuery,
} from '../../redux/store/api/api';
import { addMessage } from '../../redux/store/layout/slice';
import { useAppDispatch } from '../../redux/hooks';
import CustomCard from '../cards/CustomCard';
import CustomListGroup from '../lists/CustomListGroup';
import Loader from '../loader/Loader';
import AddCategoryTypeDialog from './dialogs/AddCategoryTypeDialog';
import DeleteCategoryTypeDialog from './dialogs/DeleteCategoryTypeDialog';
import RenameCategoryTypeDialog from './dialogs/RenameCategoryTypeDialog';
import { RightKey } from '../../shared/enums';
import { ContextAction } from '../dropdown-menus/types';

interface ICategoryListProps {
  activeCategoryType: CategoryType;
  setActiveCategoryType: (categoryType: CategoryType) => void;
  rights: RightKey[];
}

function CategoryTypeList({
  activeCategoryType,
  setActiveCategoryType,
  rights,
}: ICategoryListProps): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const [contextCategoryType, setContextCategoryType] = useState<CategoryType>(
    {},
  );
  const [addCategoryTypeOpened, setAddCategoryTypeOpened] =
    useState<boolean>(false);
  const [renameCategoryTypeOpened, setRenameCategoryTypeOpened] =
    useState<boolean>(false);
  const [deleteCategoryTypeOpened, setDeleteCategoryTypeOpened] =
    useState<boolean>(false);
  const {
    data: categoryTypesData,
    isFetching,
    isError,
    error,
  } = useGetApiCategoryTypesQuery();

  // permissions
  const userCanAddCategoryType = rights.includes(
    RightKey.RightCategoryTypeManagementCreate,
  );
  const userCanEditCategoryType = rights.includes(
    RightKey.RightCategoryTypeManagementEdit,
  );
  const userCanDeleteCategoryType = rights.includes(
    RightKey.RightCategoryTypeManagementDelete,
  );
  const userCanReadCategoryType = rights.includes(
    RightKey.RightCategoryTypeManagementRead,
  );

  useEffect(() => {
    // If activeCategory is not set or activeCategory does not exist set first category as active if category are available
    if (
      categoryTypesData?.resultObject &&
      (!activeCategoryType.id ||
        !categoryTypesData.resultObject.find(
          (category) => category.id === activeCategoryType.id,
        ))
    ) {
      setActiveCategoryType(
        categoryTypesData.resultObject.length > 0
          ? categoryTypesData.resultObject[0]
          : {},
      );
    }
  }, [categoryTypesData]);

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetCategoryTypesError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  const getContextActions = (categoryType: CategoryType): ContextAction[] => {
    const contextActions: ContextAction[] = [];

    if (userCanEditCategoryType) {
      contextActions.push({
        iconClass: 'icon-umbenennen',
        iconColorClass: 'text-body',
        name: translation('renameCategoryType'),
        onClick: () => {
          setContextCategoryType(categoryType);
          setRenameCategoryTypeOpened(true);
        },
      });
    }

    if (userCanDeleteCategoryType) {
      contextActions.push({
        iconClass: 'icon-trash',
        iconColorClass: 'text-danger',
        name: translation('deleteCategoryType'),
        onClick: () => {
          setContextCategoryType(categoryType);
          setDeleteCategoryTypeOpened(true);
        },
      });
    }

    return contextActions;
  };

  return (
    <>
      <CustomCard
        headlineAsH1
        focusableHeadline
        title={translation('categoryTypes')}
        actions={
          userCanAddCategoryType
            ? [
                {
                  name: translation('add'),
                  iconClassName: 'icon-plus',
                  onClick: () => {
                    setAddCategoryTypeOpened(true);
                  },
                },
              ]
            : undefined
        }>
        {isFetching && <Loader />}
        {!isFetching && categoryTypesData?.resultObject && (
          <CustomListGroup
            activeListItem={activeCategoryType.id || ''}
            listItems={categoryTypesData.resultObject.map((categoryType) => ({
              id: categoryType.id || '',
              content: (
                <>
                  <i
                    className={`${categoryType.iconCssClass} me-2 mt-1`}
                    style={
                      categoryType.colorHexCode
                        ? { color: categoryType.colorHexCode }
                        : undefined
                    }
                    aria-hidden
                  />
                  {categoryType.name}
                </>
              ),
              onClick: userCanReadCategoryType
                ? () => {
                    setActiveCategoryType(categoryType);
                  }
                : undefined,
              contextActions: getContextActions(categoryType),
            }))}
          />
        )}
      </CustomCard>
      <AddCategoryTypeDialog
        show={addCategoryTypeOpened}
        setActiveCategoryType={setActiveCategoryType}
        onClose={() => {
          setAddCategoryTypeOpened(false);
        }}
      />
      <RenameCategoryTypeDialog
        show={renameCategoryTypeOpened}
        setActiveCategoryType={setActiveCategoryType}
        categoryType={contextCategoryType}
        onClose={() => {
          setRenameCategoryTypeOpened(false);
        }}
      />
      <DeleteCategoryTypeDialog
        show={deleteCategoryTypeOpened}
        categoryType={contextCategoryType}
        onClose={() => {
          setDeleteCategoryTypeOpened(false);
        }}
      />
    </>
  );
}

export default CategoryTypeList;
