import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiCategoryTreeQuery,
  usePutApiCategoryTreeSetDisabledStateMutation,
} from '../../../redux/store/api/api';
import {
  selectContextArticle,
  selectContextCategory,
  selectDisableElementsDialogOpened,
  setContextArticle,
  setContextCategory,
  setDisableElementsDialogOpened,
} from '../../../redux/store/content/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';
import {
  useGetArticleIdsForElementIds,
  useGetCategoryIdsForElementIds,
} from '../../../hooks/useGetArticlesOrCategoriesForElementIds';

interface IDisableElementsDialogProps {
  selectedIds: string[];
  setLastUpdatedItem: (updatedId: string) => void;
}

function DisableElementsDialog({
  selectedIds,
  setLastUpdatedItem,
}: IDisableElementsDialogProps): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const dialogShow = useAppSelector(selectDisableElementsDialogOpened);
  const articles = useGetArticleIdsForElementIds(dialogShow ? selectedIds : []);
  const categories = useGetCategoryIdsForElementIds(
    dialogShow ? selectedIds : [],
  );
  const contextCategory = useAppSelector(selectContextCategory);
  const contextArticle = useAppSelector(selectContextArticle);
  const [setDisableState, { isError, isLoading, error }] =
    usePutApiCategoryTreeSetDisabledStateMutation();
  const { refetch: refetchGetCategoriesTree } = useGetApiCategoryTreeQuery();

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'DisableElementsError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  const handleDisableElements = () => {
    setDisableState({
      categoryTreeBatchOperationItems: {
        articleIds: contextArticle.id ? [contextArticle.id] : articles,
        categoryIds: contextCategory.id ? [contextCategory.id] : categories,
      },
      disabled: true,
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'DisableElementsSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        dispatch(setContextCategory({}));
        dispatch(setContextArticle({}));
        dispatch(setDisableElementsDialogOpened(false));
        refetchGetCategoriesTree();
        setLastUpdatedItem(
          contextCategory?.id || contextArticle?.id || selectedIds[0],
        );
      });
  };

  return (
    <CustomDialog
      titleId='DisableElementsDialog'
      dialogTitle={translation('disableElements')}
      show={dialogShow}
      closeFunction={() => {
        dispatch(setDisableElementsDialogOpened(false));
        dispatch(setContextCategory({}));
        dispatch(setContextArticle({}));
      }}
      actionFunction={handleDisableElements}
      closeTitle={translation('cancel')}
      actionTitle={translation('disable')}
      actionButtonDisabled={isLoading}>
      {isLoading && <Loader />}
      {!isLoading && (
        <p>
          {translation(
            contextArticle.id || contextCategory.id
              ? 'shouldDisableSelectedElement'
              : 'shouldDisableSelectedElements',
          )}
        </p>
      )}
    </CustomDialog>
  );
}

export default DisableElementsDialog;
