import { Toast } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './Notifications.scss';
import { useState } from 'react';
import { Message } from '../../redux/store/layout/types';
import { useAppDispatch } from '../../redux/hooks';
import { removeMessage } from '../../redux/store/layout/slice';

interface IAutomaticallyClosingToastProps {
  message: Message;
  onClose?: () => void;
}

function AutomaticallyClosingToast({
  onClose,
  message,
}: IAutomaticallyClosingToastProps): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(true);

  return (
    <Toast
      bg={message.variant}
      onClose={() => {
        setShow(false);
        dispatch(removeMessage(message.id));
        if (onClose) {
          onClose();
        }
      }}
      show={show}
      delay={message.timeInMs || 5000}
      autohide>
      {message.messageKeyHeader && (
        <Toast.Header closeLabel={translation('close')}>
          <strong className='me-auto'>
            {translation(message.messageKeyHeader)}
          </strong>
        </Toast.Header>
      )}
      {message.messageKeyBody && (
        <Toast.Body>{translation(message.messageKeyBody)}</Toast.Body>
      )}
    </Toast>
  );
}

AutomaticallyClosingToast.defaultProps = {
  onClose: undefined,
};

export default AutomaticallyClosingToast;
