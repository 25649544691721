import parse from 'html-react-parser';
import { ChangeEvent, useCallback } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'usehooks-ts';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import {
  SearchArticleResult as SearchArticleResultType,
  SearchArticleResultItem,
  useGetApiCategoryTreeQuery,
} from '../../redux/store/api/api';
import CustomListGroup from '../lists/CustomListGroup';
import { SearchResultSort } from '../../shared/enums';
import { getArticleUrl } from '../../shared/urlBuilder';
import { formatToLocalDateString } from '../../shared/utils';
import OnlineHelpButton from '../buttons/OnlineHelpButton';
import { mdBreakpoint } from '../../shared/constants';

interface ISearchResultProps {
  searchResult: SearchArticleResultType;
  sortOption: SearchResultSort;
  onSortChange: (sort: SearchResultSort) => void;
}

function SearchArticleResult({
  searchResult,
  sortOption,
  onSortChange,
}: ISearchResultProps): JSX.Element {
  const { t: translation } = useTranslation();
  const navigate = useNavigate();
  const { width: windowWidth } = useWindowSize();
  const handleOnSortChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) =>
      onSortChange(+e.target.value as SearchResultSort),
    [onSortChange],
  );
  const { data: contentTreeData } = useGetApiCategoryTreeQuery();

  function getCategory(articleId: string | undefined | null) {
    if (articleId) {
      return (
        contentTreeData?.resultObject?.categories?.find(
          (c) => c.articleIds && c.articleIds.includes(articleId),
        ) || undefined
      );
    }
    return undefined;
  }

  const getResultItem = (resultItem: SearchArticleResultItem): JSX.Element => (
    <>
      <div className='result-item-header'>
        <h3>
          <Link
            to={getArticleUrl(
              resultItem.articleId || '',
              resultItem.versionId || undefined,
              undefined,
              undefined,
              searchResult.foundTerms ? searchResult.foundTerms : undefined,
            )}
            className='text-break fw-semibold mb-0 d-flex text-body text-decoration-none'>
            <i
              className={`me-2 ${
                getCategory(resultItem.articleId)?.categoryTypeIconClass || ''
              }`}
              style={
                getCategory(resultItem.articleId)?.categoryTypeColorHexCode
                  ? {
                      color:
                        getCategory(resultItem.articleId)
                          ?.categoryTypeColorHexCode || '',
                    }
                  : undefined
              }
              aria-hidden
            />
            <span className='mt-1'>
              {parse(
                resultItem.versionTreeTitle || resultItem.versionTitle || '',
              )}
            </span>
          </Link>
        </h3>
        <span className='result-version text-muted'>
          {resultItem.versionName}
          <i aria-hidden className='ms-1 me-2 icon-artikel' />
          {resultItem.validFrom
            ? formatToLocalDateString(resultItem.validFrom)
            : ''}
        </span>
      </div>
      <span className='text-muted mb-2 fs-6 fst-italic'>
        ({getCategory(resultItem.articleId)?.permittedPath || ''})
      </span>
      {resultItem.foundInAttachment && (
        <p>
          <i className='icon-attachments me-1' aria-hidden />
          {`${translation('searchKeywordIsInAttachment')} ${
            resultItem.attachmentName
          }`}
        </p>
      )}
      <p className='text-break result-extract'>
        {parse(resultItem.extract || '')}
      </p>
    </>
  );

  return (
    <>
      <div className='search-header-extention'>
        <Form.Group
          as={Row}
          className='mb-3 search-result-sorting'
          controlId='SearchSorting'>
          <Col sm='5'>
            <Form.Label>{translation('sortBy')}</Form.Label>
          </Col>
          <Col>
            <Form.Select value={sortOption} onChange={handleOnSortChange}>
              <option value={SearchResultSort.ByRelevance}>
                {translation('relevance')}
              </option>
              <option value={SearchResultSort.ByDate}>
                {translation('date')}
              </option>
            </Form.Select>
          </Col>
        </Form.Group>
        {windowWidth > mdBreakpoint && (
          <span className='mb-1 ms-2'>
            <OnlineHelpButton helpId='help_4_7' />
          </span>
        )}
      </div>
      {searchResult.items && (
        <CustomListGroup
          borderForItems
          noTabIndexForListItems
          listItems={searchResult.items.map((i, index) => ({
            id: index.toString(),
            onClick: () => {
              navigate(
                getArticleUrl(
                  i.articleId || '',
                  i.versionId || undefined,
                  undefined,
                  undefined,
                  searchResult.foundTerms ? searchResult.foundTerms : undefined,
                ),
                { replace: true },
              );
            },
            content: getResultItem(i),
          }))}
        />
      )}
    </>
  );
}

export default SearchArticleResult;
