export function getTextPassageIds(htmlString: string): string[] {
  const parser = new DOMParser();
  const htmlContent = parser.parseFromString(htmlString, 'text/html');

  const tagsWithId = htmlContent.querySelectorAll('[id]');

  const ids: string[] = [];
  tagsWithId.forEach((t) => {
    ids.push(t.getAttribute('id') || '');
  });

  return ids;
}

export function getTagsWithIds(htmlString: string): string[] {
  const parser = new DOMParser();
  const htmlContent = parser.parseFromString(htmlString, 'text/html');

  const tagsWithId = htmlContent.querySelectorAll('[id]');

  const tagsWithIds: string[] = [];

  tagsWithId.forEach((t) => {
    const divElement = document.createElement('div');
    divElement.classList.add('ps-2');
    divElement.append(t);
    const dummy = document.createElement('div');
    dummy.append(divElement);
    tagsWithIds.push(dummy.innerHTML);
  });

  return tagsWithIds;
}
