/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../shared/types';
import { User, UserGroup } from '../api/api';
import { UserManagementState } from './types';

const initialState: UserManagementState = {
  addUserGroupDialogOpened: false,
  deleteUserGroupDialogOpened: false,
  editUserGroupDialogOpened: false,
  addUserDialogOpened: false,
  deleteUserDialogOpened: false,
  editUserDialogOpened: false,
  moveUserDialogOpened: false,
  toggleLockoutUserDialogOpened: false,
  userImportDialogOpened: false,
  moveUserGroupDialogOpened: false,
  contentPermissionsDialogOpened: false,
  userGroupPermissionsDialogOpened: false,
  userGroupSettingsDialogOpened: false,
  roleAssignmentDialogOpened: false,
  moveUserStarted: false,
  selectedUsers: [],
  moveUserGroupStarted: false,
  contextUser: {},
  contextUserGroup: {},
  movingUserGroup: {},
  expandedUserGroups: [],
};

export const userManagementSlice = createSlice({
  initialState,
  name: 'userManagement',
  reducers: {
    setAddUserGroupDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.addUserGroupDialogOpened = action.payload;
    },
    setDeleteUserGroupDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.deleteUserGroupDialogOpened = action.payload;
    },
    setEditUserGroupDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.editUserGroupDialogOpened = action.payload;
    },
    setAddUserDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.addUserDialogOpened = action.payload;
    },
    setDeleteUserDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.deleteUserDialogOpened = action.payload;
    },
    setEditUserDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.editUserDialogOpened = action.payload;
    },
    setContentPermissionsDialogOpened: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.contentPermissionsDialogOpened = action.payload;
    },
    setUserGroupPermissionsDialogOpened: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.userGroupPermissionsDialogOpened = action.payload;
    },
    setRoleAssignmentDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.roleAssignmentDialogOpened = action.payload;
    },
    setUserGroupSettingsDialogOpened: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.userGroupSettingsDialogOpened = action.payload;
    },
    setMoveUserDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.moveUserDialogOpened = action.payload;
    },
    setToggleLockoutUserDialogOpened: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.toggleLockoutUserDialogOpened = action.payload;
    },
    setUserImportDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.userImportDialogOpened = action.payload;
    },
    setMoveUserGroupDialogOpened: (state, action: PayloadAction<boolean>) => {
      state.moveUserGroupDialogOpened = action.payload;
    },
    setMoveUserStarted: (state, action: PayloadAction<boolean>) => {
      state.moveUserStarted = action.payload;
    },
    setSelectedUsers: (state, action: PayloadAction<User[]>) => {
      state.selectedUsers = action.payload;
    },
    addSelectedUser: (state, action: PayloadAction<User>) => {
      state.selectedUsers.push(action.payload);
    },
    removeSelectedUser: (state, action: PayloadAction<User>) => {
      const newSelectedUsers = state.selectedUsers.filter(
        (u) => u.id !== action.payload.id,
      );
      state.selectedUsers = [...newSelectedUsers];
    },
    clearSelectedUsers: (state) => {
      state.selectedUsers = [];
    },
    setMoveUserGroupStarted: (state, action: PayloadAction<boolean>) => {
      state.moveUserGroupStarted = action.payload;
    },
    setMovingUserGroup: (state, action: PayloadAction<UserGroup>) => {
      state.movingUserGroup = action.payload;
    },
    setContextUser: (state, action: PayloadAction<User>) => {
      state.contextUser = action.payload;
    },
    setContextUserGroup: (state, action: PayloadAction<UserGroup>) => {
      state.contextUserGroup = action.payload;
    },
    addExpandedUserGroup: (state, action: PayloadAction<string>) => {
      if (!state.expandedUserGroups.includes(action.payload)) {
        state.expandedUserGroups.push(action.payload);
      }
    },
    addExpandedUserGroups: (state, action: PayloadAction<string[]>) => {
      action.payload.forEach((id) => {
        if (!state.expandedUserGroups.includes(id)) {
          state.expandedUserGroups.push(id);
        }
      });
    },
    removeExpandedUserGroup: (state, action: PayloadAction<string>) => {
      state.expandedUserGroups = state.expandedUserGroups.filter(
        (ui) => ui !== action.payload,
      );
    },
    setExpandedUserGroups: (state, action: PayloadAction<string[]>) => {
      state.expandedUserGroups = action.payload;
    },
  },
});

export const {
  setAddUserGroupDialogOpened,
  setDeleteUserGroupDialogOpened,
  setEditUserGroupDialogOpened,
  setAddUserDialogOpened,
  setDeleteUserDialogOpened,
  setEditUserDialogOpened,
  setMoveUserDialogOpened,
  setToggleLockoutUserDialogOpened,
  setUserImportDialogOpened,
  setMoveUserGroupDialogOpened,
  setMoveUserStarted,
  setSelectedUsers,
  addSelectedUser,
  removeSelectedUser,
  clearSelectedUsers,
  setContextUser,
  setContextUserGroup,
  setMoveUserGroupStarted,
  setMovingUserGroup,
  addExpandedUserGroup,
  addExpandedUserGroups,
  setExpandedUserGroups,
  removeExpandedUserGroup,
  setContentPermissionsDialogOpened,
  setUserGroupPermissionsDialogOpened,
  setRoleAssignmentDialogOpened,
  setUserGroupSettingsDialogOpened,
} = userManagementSlice.actions;

export const selectAddUserGroupDialogOpened = (state: RootState) =>
  state.userManagement.addUserGroupDialogOpened;

export const selectDeleteUserGroupDialogOpened = (state: RootState) =>
  state.userManagement.deleteUserGroupDialogOpened;

export const selectEditUserGroupDialogOpened = (state: RootState) =>
  state.userManagement.editUserGroupDialogOpened;

export const selectAddUserDialogOpened = (state: RootState) =>
  state.userManagement.addUserDialogOpened;

export const selectDeleteUserDialogOpened = (state: RootState) =>
  state.userManagement.deleteUserDialogOpened;

export const selectEditUserDialogOpened = (state: RootState) =>
  state.userManagement.editUserDialogOpened;

export const selectContentPermissionsDialogOpened = (state: RootState) =>
  state.userManagement.contentPermissionsDialogOpened;

export const selectUserGroupPermissionsDialogOpened = (state: RootState) =>
  state.userManagement.userGroupPermissionsDialogOpened;

export const selectRoleAssignmentDialogOpened = (state: RootState) =>
  state.userManagement.roleAssignmentDialogOpened;

export const selectUserGroupSettingsDialogOpened = (state: RootState) =>
  state.userManagement.userGroupSettingsDialogOpened;

export const selectMoveUserDialogOpened = (state: RootState) =>
  state.userManagement.moveUserDialogOpened;

export const selectToggleLockoutUserDialogOpened = (state: RootState) =>
  state.userManagement.toggleLockoutUserDialogOpened;

export const selectUserImportDialogOpened = (state: RootState) =>
  state.userManagement.userImportDialogOpened;

export const selectMoveUserGroupDialogOpened = (state: RootState) =>
  state.userManagement.moveUserGroupDialogOpened;

export const selectMoveUserStarted = (state: RootState) =>
  state.userManagement.moveUserStarted;

export const selectMoveUserGroupStarted = (state: RootState) =>
  state.userManagement.moveUserGroupStarted;

export const selectSelectedUsers = (state: RootState) =>
  state.userManagement.selectedUsers;

export const selectMovingUserGroup = (state: RootState) =>
  state.userManagement.movingUserGroup;

export const selectContextUser = (state: RootState) =>
  state.userManagement.contextUser;

export const selectContextUserGroup = (state: RootState) =>
  state.userManagement.contextUserGroup;

export const selectExpandedUserGroups = (state: RootState) =>
  state.userManagement.expandedUserGroups;

export default userManagementSlice.reducer;
