import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectMaxNumberOfVersionsDialogOpened,
  setMaxNumberOfVersionsDialogOpened,
} from '../../../redux/store/content/slice';
import CustomDialog from '../../dialogs/CustomDialog';

function MaxNumberOfVersionsDialog() {
  const dispatch = useAppDispatch();
  const { t: translation } = useTranslation();
  const dialogShow = useAppSelector(selectMaxNumberOfVersionsDialogOpened);

  return (
    <CustomDialog
      titleId='MaxNumberOfVersionsDialog'
      show={dialogShow}
      closeFunction={() => {
        dispatch(dispatch(setMaxNumberOfVersionsDialogOpened(false)));
      }}
      closeTitle={translation('close')}
      dialogTitle={translation('maxNumberReached')}>
      <p>{translation('maxNumberOfVersionsReached')}</p>
    </CustomDialog>
  );
}

export default MaxNumberOfVersionsDialog;
