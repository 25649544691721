import { Form } from 'react-bootstrap';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { DataType } from '../../shared/enums';
import SimpleTinyEditor from '../text-editor/SimpleTinyEditor';
import FileInput from './FileInput';
import AutoCompleteInput from './AutocompleteInput';
import IconButton from '../buttons/IconButton';

interface ISettingInputProps {
  controlId: string;
  label: string;
  value?: string;
  setValue?: (value: string) => void;
  listValues?: string[];
  setListValues?: (values: string[]) => void;
  file?: File | string;
  setFile?: (file: File) => void;
  dataType: DataType;
  possibleValues?: string[];
  userCanEditSetting?: boolean;
}

interface SettingsValue {
  id: string;
  value: string;
}

function SettingInput({
  controlId,
  label,
  value,
  setValue,
  listValues,
  setListValues,
  file,
  setFile,
  dataType,
  possibleValues,
  userCanEditSetting,
}: ISettingInputProps) {
  const { t: translation } = useTranslation('wisentro');

  return (
    <>
      {(() => {
        switch (dataType) {
          case DataType.String:
            return (
              <Form.Group controlId={controlId}>
                <Form.Label>{label}</Form.Label>
                <Form.Control
                  readOnly={!userCanEditSetting}
                  onChange={(e) => {
                    if (setValue) {
                      setValue(e.target.value);
                    }
                  }}
                  type='text'
                  value={value}
                />
              </Form.Group>
            );
          case DataType.Bool:
            return (
              <Form.Check
                readOnly={!userCanEditSetting}
                type='checkbox'
                id={controlId}
                label={label}
                checked={value === 'true'}
                onChange={(e) => {
                  if (setValue) {
                    setValue(e.target.checked.toString());
                  }
                }}
              />
            );
          case DataType.Image: {
            return (
              <>
                {userCanEditSetting && (
                  <FileInput
                    labelName={label}
                    files={file && typeof file !== 'string' ? [file] : null}
                    onChange={(e) => {
                      if (e.target.files && setFile) {
                        setFile(e.target.files[0]);
                      }
                    }}
                  />
                )}
                {file && (
                  <img
                    className='mt-1'
                    alt={label}
                    src={
                      file && typeof file !== 'string'
                        ? URL.createObjectURL(file)
                        : file
                    }
                  />
                )}
              </>
            );
          }
          case DataType.Integer:
            return (
              <Form.Group controlId={controlId}>
                <Form.Label>{label}</Form.Label>
                <Form.Control
                  readOnly={!userCanEditSetting}
                  onChange={(e) => {
                    if (setValue) {
                      setValue(e.target.value);
                    }
                  }}
                  type='number'
                  value={value}
                />
              </Form.Group>
            );
          case DataType.Text:
            return (
              <>
                <Form.Label htmlFor={controlId}>{label}</Form.Label>
                {userCanEditSetting && (
                  <SimpleTinyEditor
                    id={controlId}
                    onChange={(val) => {
                      if (setValue) {
                        setValue(val);
                      }
                    }}
                    value={value || ''}
                  />
                )}
                {!userCanEditSetting && <p>{parse(value || '')}</p>}
              </>
            );
          case DataType.List: {
            return (
              <>
                {userCanEditSetting ? (
                  <AutoCompleteInput
                    clearInputAfterSelection
                    valueMinLengthForSuggestion={0}
                    label={label}
                    controlId={controlId}
                    options={
                      (possibleValues?.map((val, index) => ({
                        id: index.toString(),
                        value: val,
                      })) || []) as SettingsValue[]
                    }
                    onConfirm={(option) => {
                      if (
                        listValues &&
                        setListValues &&
                        option &&
                        !listValues.includes(option.value)
                      ) {
                        setListValues([...listValues, option.value]);
                      }
                    }}
                    getDisplayName={(option) => option.value}
                    getSearchString={(option) => option.value}
                    getId={(option) => option.id}
                  />
                ) : (
                  <p>{label}</p>
                )}
                {listValues && setListValues && listValues.length > 0 && (
                  <>
                    <span>{`${translation('alreadySelectedOptions')} `}</span>
                    <ul className='list-unstyled'>
                      {listValues.map((val) => (
                        <li key={val}>
                          <span className='me-1'>{val}</span>
                          <IconButton
                            iconClassName='icon-trash'
                            onClick={() => {
                              setListValues(
                                listValues.filter((v) => v !== val),
                              );
                            }}
                            ariaLabel={translation('removeOption')}
                            title={translation('removeOption')}
                            textColorClass='text-danger'
                          />
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </>
            );
          }
          case DataType.TinySelector:
            return (
              <>
                {userCanEditSetting && (
                  <AutoCompleteInput
                    initialValue={value}
                    label={label}
                    controlId={controlId}
                    options={
                      window.tinyConfigList.map((val, index) => ({
                        id: index.toString(),
                        value: val,
                      })) as SettingsValue[]
                    }
                    onConfirm={(option) => {
                      if (setValue) {
                        setValue(option.value);
                      }
                    }}
                    getDisplayName={(option) => option.value}
                    getSearchString={(option) => option.value}
                    getId={(option) => option.id}
                    valueMinLengthForSuggestion={0}
                  />
                )}
                {!userCanEditSetting && (
                  <>
                    <p>{label}:</p>
                    <p>{value}</p>
                  </>
                )}
              </>
            );
          default:
            return <span />;
        }
      })()}
    </>
  );
}

SettingInput.defaultProps = {
  value: undefined,
  setValue: undefined,
  listValues: undefined,
  setListValues: undefined,
  file: undefined,
  setFile: undefined,
  possibleValues: undefined,
  userCanEditSetting: false,
};

export default SettingInput;
