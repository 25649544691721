import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Note, useGetApiBookmarkFoldersQuery } from '../../redux/store/api/api';
import {
  selectActiveNote,
  setActiveNote,
  setShowNotePopoverOpened,
  setAddMarkBookmarkDialogOpened,
  setContextBookmark,
  setDeleteBookmarkDialogOpened,
} from '../../redux/store/content/slice';
import { NoteVisibilityType } from '../../shared/enums';
import CustomListGroup, { ListItem } from '../lists/CustomListGroup';
import '../marks/Marks.scss';
import { ExtendedNote, VisibilityGroupString } from './types';
import { MARK_VISIBILITY_GROUP_KEY } from '../../shared/constants';
import { ContextAction } from '../dropdown-menus/types';
import { getNotesForAccordionList } from './functions';

interface INoteListProps {
  notes: ExtendedNote[];
  noNotesToShow: boolean;
  getUserCanDeleteNote: (note: Note) => boolean;
  getUserCanEditNote: (note: Note) => boolean;
  userHasAccessToContextActions: boolean;
  setShowNoteDialogOpened: (val: boolean) => void;
  setUpdateNoteDialogOpened: (val: boolean) => void;
  setDeleteNoteDialogOpened: (val: boolean) => void;
  setAddMarkDialogOpened: (val: boolean) => void;
  setVisibilitiesNotMatchingDialogOpened: (val: boolean) => void;
  setNoValidSelectionDialogOpened: (val: boolean) => void;
  setDeleteMarkDialogOpened: (val: boolean) => void;
  setMarkNeedsActionDialogOpened: (val: boolean) => void;
  setMoveNoteDialogOpened: (val: boolean) => void;
  moveNoteIsStarted: boolean;
  setMoveNoteIsStarted: (val: boolean) => void;
  noteToMove: ExtendedNote;
  setNoteToMove: (note: ExtendedNote) => void;
  setTargetNotePosition: (position: number) => void;
}

function NoteList({
  notes,
  noNotesToShow,
  getUserCanDeleteNote,
  getUserCanEditNote,
  userHasAccessToContextActions,
  setShowNoteDialogOpened,
  setUpdateNoteDialogOpened,
  setDeleteNoteDialogOpened,
  setAddMarkDialogOpened,
  setVisibilitiesNotMatchingDialogOpened,
  setNoValidSelectionDialogOpened,
  setDeleteMarkDialogOpened,
  setMarkNeedsActionDialogOpened,
  setMoveNoteDialogOpened,
  moveNoteIsStarted,
  setMoveNoteIsStarted,
  noteToMove,
  setNoteToMove,
  setTargetNotePosition,
}: INoteListProps): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const dangerClass = 'text-danger';
  const successClass = 'text-success';
  const blackClass = 'text-body';
  const activeNote = useAppSelector(selectActiveNote);
  const [activeMarkVisibilityGroup] =
    useSessionStorage<VisibilityGroupString | null>(
      MARK_VISIBILITY_GROUP_KEY,
      null,
    );
  const { data: bookmarkTreeData } = useGetApiBookmarkFoldersQuery();

  const getStartMoveActions = (
    note: ExtendedNote,
    maxOrder: number,
  ): ContextAction[] => {
    const actions = [];
    const noteOrder: number = note.order || 0;
    const targetOrderDown = noteOrder + 1;

    // one step move action - up
    if (noteOrder > 0) {
      actions.push({
        iconClass: 'icon-move',
        iconColorClass: blackClass,
        name: translation('moveNoteOneHigher'),
        onClick: () => {
          setMoveNoteIsStarted(true);
          setNoteToMove(note);
          setTargetNotePosition(noteOrder - 1);
          setMoveNoteDialogOpened(true);
        },
      });
    }

    // one step move action - down
    if (targetOrderDown <= maxOrder) {
      actions.push({
        iconClass: 'icon-move',
        iconColorClass: blackClass,
        name: translation('moveNoteOneLower'),
        onClick: () => {
          setMoveNoteIsStarted(true);
          setNoteToMove(note);
          setTargetNotePosition(targetOrderDown);
          setMoveNoteDialogOpened(true);
        },
      });
    }

    // start two step move action
    actions.push({
      iconClass: 'icon-move',
      iconColorClass: blackClass,
      name: translation('moveNote'),
      onClick: () => {
        setMoveNoteIsStarted(true);
        setNoteToMove(note);
        dispatch(setActiveNote(note));
      },
      addDividerAfterItem: true,
    });

    return actions;
  };

  // finish two step move action
  const getFinishMoveActions = (note: Note): ContextAction[] => {
    const actions = [];
    const noteOrder: number = note.order || 0;
    const targetOrderMoveAboveTargetNote =
      noteOrder < (noteToMove.order || 0) ? noteOrder : noteOrder - 1;
    const targetOrderMoveBelowTargetNote =
      noteOrder < (noteToMove.order || 0) ? noteOrder + 1 : noteOrder;
    const moveAboveTargetNoteAllowed =
      (noteToMove.order || 0) !== targetOrderMoveAboveTargetNote;
    const moveBelowTargetNoteAllowed =
      (noteToMove.order || 0) !== targetOrderMoveBelowTargetNote;

    if (moveAboveTargetNoteAllowed) {
      actions.push({
        iconClass: 'icon-move',
        iconColorClass: blackClass,
        name: translation('moveNoteOverThisElement'),
        onClick: () => {
          setTargetNotePosition(targetOrderMoveAboveTargetNote);
          setMoveNoteDialogOpened(true);
        },
      });
    }

    if (moveBelowTargetNoteAllowed) {
      actions.push({
        iconClass: 'icon-move',
        iconColorClass: blackClass,
        name: translation('moveNoteBelowThisElement'),
        onClick: () => {
          setTargetNotePosition(targetOrderMoveBelowTargetNote);
          setMoveNoteDialogOpened(true);
        },
      });
    }

    return actions;
  };

  const getMoveActions = (note: ExtendedNote): ContextAction[] => {
    const actions = [];
    const maxOrder = _.maxBy(notes, 'order')?.order || 0;

    if (!moveNoteIsStarted && maxOrder > 0) {
      actions.push(...getStartMoveActions(note, maxOrder));
    }

    if (moveNoteIsStarted) {
      actions.push({
        iconClass: 'icon-deaktivieren',
        iconColorClass: dangerClass,
        name: translation('stopMove'),
        onClick: () => {
          setMoveNoteIsStarted(false);
          setNoteToMove({});
          dispatch(setActiveNote({}));
        },
        addDividerAfterItem: true,
      });
    }

    if (
      moveNoteIsStarted &&
      note.visibility === noteToMove.visibility &&
      note.id !== noteToMove.id
    ) {
      actions.push(...getFinishMoveActions(note));
    }

    return actions;
  };

  const getContextActions = (note: ExtendedNote): ContextAction[] => {
    const handleAddMark = () => {
      const selection = window.getSelection();
      const selectionIsValid = selection !== null && !selection.isCollapsed;
      if (selectionIsValid) {
        if (
          activeMarkVisibilityGroup !== NoteVisibilityType[note.visibility || 0]
        ) {
          setVisibilitiesNotMatchingDialogOpened(true);
        } else {
          setAddMarkDialogOpened(true);
        }
      } else {
        setNoValidSelectionDialogOpened(true);
      }
    };

    const userCanDeleteNote = getUserCanDeleteNote(note);
    const userCanEditNote = getUserCanEditNote(note);
    const contextActions: ContextAction[] = [];

    if (userCanEditNote) {
      contextActions.push(...getMoveActions(note));
    }

    if (moveNoteIsStarted) {
      return contextActions;
    }

    const relatedBookmark = bookmarkTreeData?.resultObject?.bookmarks?.find(
      (b) => note.mark?.id === b.markId,
    );

    if (note.mark) {
      if (relatedBookmark) {
        contextActions.push({
          name: translation('deleteBookmark'),
          iconClass: 'icon-merkliste_remove',
          iconColorClass: 'text-danger',
          onClick: () => {
            dispatch(setContextBookmark(relatedBookmark));
            dispatch(setDeleteBookmarkDialogOpened(true));
          },
          addDividerAfterItem: userCanEditNote,
        });
      } else {
        contextActions.push({
          helpId: 'help_2_3_1',
          iconClass: 'icon-merkliste_new',
          iconColorClass: successClass,
          name: translation('addBookmark'),
          onClick: () => {
            dispatch(setActiveNote(note));
            dispatch(setAddMarkBookmarkDialogOpened(true));
          },
          addDividerAfterItem: userCanEditNote,
        });
      }
    }

    if (userCanEditNote) {
      contextActions.push({
        helpId: 'help_3_7_3',
        iconClass: 'icon-edit',
        iconColorClass: blackClass,
        name: translation('editNote'),
        onClick: () => {
          dispatch(setActiveNote(note));
          setUpdateNoteDialogOpened(true);
        },
      });
    }
    if (userCanDeleteNote) {
      contextActions.push({
        helpId: 'help_3_7_4',
        iconClass: 'icon-trash',
        iconColorClass: dangerClass,
        name: translation('deleteNote'),
        onClick: () => {
          dispatch(setActiveNote(note));
          setDeleteNoteDialogOpened(true);
        },
      });
    }

    if (
      note.mark &&
      note.mark.markInvalid &&
      (userCanEditNote || userCanDeleteNote)
    ) {
      contextActions.push({
        helpId: 'help_3_7_5',
        iconClass: 'icon-edit',
        iconColorClass: blackClass,
        name: translation('handleChanges'),
        onClick: () => {
          dispatch(setActiveNote(note));
          setMarkNeedsActionDialogOpened(true);
        },
      });
    }
    if (!note.mark && userCanEditNote) {
      contextActions.push({
        iconClass: 'icon-plus',
        iconColorClass: blackClass,
        name: translation('addMark'),
        onClick: () => {
          dispatch(setActiveNote(note));
          handleAddMark();
        },
      });
    }
    if (note.mark && userCanEditNote) {
      contextActions.push({
        helpId: 'help_3_7_6',
        iconClass: 'icon-trash',
        iconColorClass: dangerClass,
        name: translation('deleteMarkOfNote'),
        onClick: () => {
          dispatch(setActiveNote(note));
          setDeleteMarkDialogOpened(true);
        },
      });
    }

    return contextActions;
  };

  const getNoteIconClass = (
    note: ExtendedNote,
  ): 'icon-notiz' | 'icon-notice_marker' | 'icon-notice_with_marker' => {
    if (note.mark?.id) {
      return note.mark.markInvalid || note.mark.markIsError
        ? 'icon-notice_marker'
        : 'icon-notice_with_marker';
    }

    return 'icon-notiz';
  };

  const getIconDescription = (
    note: Note,
  ): 'noteWithoutMark' | 'noteWithChangedMark' | 'noteWithMark' => {
    if (note.mark?.id) {
      return note.mark.markInvalid ? 'noteWithChangedMark' : 'noteWithMark';
    }

    return 'noteWithoutMark';
  };

  const handleListItemClick = (e: SyntheticEvent, note: ExtendedNote) => {
    e.stopPropagation();
    if (activeNote !== note) {
      dispatch(setActiveNote(note));
    } else {
      dispatch(setActiveNote({}));
    }

    if (!note.mark?.id) {
      setShowNoteDialogOpened(true);
    } else if (note.mark.markInvalid || note.mark.markIsError) {
      setMarkNeedsActionDialogOpened(true);
    } else {
      dispatch(setShowNotePopoverOpened(true));
    }
  };

  return noNotesToShow ? (
    <p>{translation('noNotes')}</p>
  ) : (
    <CustomListGroup
      activeListItem={activeNote?.id || ''}
      listItems={getNotesForAccordionList(notes).map(
        (note: ExtendedNote) =>
          ({
            id: note.id,
            content: note.name,
            onClick: (e) => handleListItemClick(e, note),
            contextActions: userHasAccessToContextActions
              ? getContextActions(note)
              : undefined,
            iconClass: getNoteIconClass(note),
            iconDescription: translation(`${getIconDescription(note)}`),
          }) as ListItem,
      )}
      forwardItemIdsToHtml
    />
  );
}

export default NoteList;
