import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Alert } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiAttachmentsByVersionIdQuery,
  useGetApiSettingsUserQuery,
  usePostApiAttachmentsMutation,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import CustomListGroup from '../../lists/CustomListGroup';
import Loader from '../../loader/Loader';
import { IAttachmentFileUpload } from '../types';
import {
  selectActiveVersion,
  selectAddAttachmentDialogOpened,
  setAddAttachmentDialogOpened,
} from '../../../redux/store/content/slice';
import { getFileExtension, getFileIcon } from '../../../shared/utils';
import FileInput from '../../inputs/FileInput';
import {
  HttpStatusCode,
  settingTypeAllowedFileTypes,
  settingTypeMaxFileAmount,
  settingTypeMaxFileSize,
} from '../../../shared/constants';

function CreateAttachmentDialog(): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const version = useAppSelector(selectActiveVersion);
  const dialogShow = useAppSelector(selectAddAttachmentDialogOpened);
  const [files, setFiles] = useState<File[]>([]);
  const [fileInputIsInvalid, setFileInputIsInvalid] = useState(false);
  const [fileInputErrorMessage, setFileInputErrorMessage] = useState('');
  const [mimeTypeIsInvalid, setMimeTypeIsInvalid] = useState(false);

  const [addAttachments, { isError, isLoading, error }] =
    usePostApiAttachmentsMutation();

  const { data: settings } = useGetApiSettingsUserQuery();
  const allowedFileTypesSetting =
    settings?.resultObject?.find(
      (s) => s.settingType?.key === settingTypeAllowedFileTypes,
    )?.value || undefined;
  const maxFileSetting =
    Number(
      settings?.resultObject?.find(
        (s) => s.settingType?.key === settingTypeMaxFileAmount,
      )?.value,
    ) || undefined;
  const maxSizeSetting =
    Number(
      settings?.resultObject?.find(
        (s) => s.settingType?.key === settingTypeMaxFileSize,
      )?.value,
    ) || undefined;

  const { refetch } = useGetApiAttachmentsByVersionIdQuery(
    version.id
      ? {
          versionId: version.id,
        }
      : skipToken,
  );

  useEffect(() => {
    if (isError) {
      if (
        error &&
        'data' in error &&
        error.data?.statusCode === HttpStatusCode.UnsupportedMediaType
      ) {
        setMimeTypeIsInvalid(true);
      }

      dispatch(
        addMessage({
          id: 'CreateAttachmentError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );

      return;
    }

    if (mimeTypeIsInvalid) {
      setMimeTypeIsInvalid(false);
    }
  }, [isError]);

  const handleFileAdd = () => {
    if (files.length === 0) {
      setFileInputIsInvalid(true);
      return;
    }

    if (files) {
      const formData = new FormData();
      files.forEach((file) =>
        formData.append('AttachmentFiles', file as unknown as Blob),
      );

      formData.append('VersionId', version.id || '');

      addAttachments({
        body: formData as IAttachmentFileUpload,
      })
        .unwrap()
        .then((result) => {
          if (result.messageKey && result.messageKey !== '') {
            dispatch(
              addMessage({
                id: 'CreateAttachmentSuccess',
                variant: 'success',
                messageKeyBody: result.messageKey,
              }),
            );
          }
          refetch();
          dispatch(setAddAttachmentDialogOpened(false));
          setFiles([]);
        });
    }
  };

  const handleOnChange = (fs: File[]) => {
    setFiles(fs);
    setMimeTypeIsInvalid(false);

    if (maxFileSetting && fs.length > maxFileSetting) {
      setFileInputIsInvalid(true);
      setFileInputErrorMessage(
        translation('invalidMaxFileCount', {
          max: maxFileSetting,
        }),
      );
      return;
    }

    if (
      allowedFileTypesSetting &&
      fs.find(
        (f) =>
          !allowedFileTypesSetting
            .split(',')
            .includes(`.${getFileExtension(f.name)}`),
      )
    ) {
      setFileInputIsInvalid(true);
      setFileInputErrorMessage(
        translation('invalidFileType', {
          fileTypes: allowedFileTypesSetting,
        }),
      );
      return;
    }

    const bytesPerMB = 1048576;

    if (
      maxSizeSetting &&
      fs.find((f) => f.size / bytesPerMB > maxSizeSetting)
    ) {
      setFileInputIsInvalid(true);
      setFileInputErrorMessage(
        translation('invalidFileSize', { max: maxSizeSetting }),
      );
      return;
    }

    setFileInputIsInvalid(false);
  };

  return (
    <CustomDialog
      titleId='CreateAttachmentDialog'
      dialogTitle={translation('addAttachment')}
      show={dialogShow}
      closeFunction={() => {
        dispatch(setAddAttachmentDialogOpened(false));
        setFiles([]);
        setFileInputIsInvalid(false);
      }}
      closeTitle={translation('cancel')}
      actionFunction={handleFileAdd}
      actionTitle={translation('add')}
      actionButtonDisabled={isLoading}>
      <p>{translation('fieldsAreRequiredLegend')}</p>
      <FileInput
        required
        isValid={!fileInputIsInvalid}
        invalidMessage={
          files.length === 0
            ? translation('fieldNotEmpty')
            : fileInputErrorMessage
        }
        accept={allowedFileTypesSetting}
        labelName={`${translation('selectFiles')}*`}
        multiple
        onChange={(e) => handleOnChange(Array.from(e.target.files || []))}
        files={files}
        setFiles={setFiles}
        onClearFiles={() => {
          setFileInputIsInvalid(false);
        }}
      />
      {isLoading && <Loader />}
      {!isLoading && files && files.length > 0 && (
        <>
          <h3 className='mt-3 fs-5'>{translation('uploadFilesList')}</h3>
          {mimeTypeIsInvalid && (
            <Alert variant='danger'>
              {translation('mimeTypeNotAllowedError')}
            </Alert>
          )}
          <CustomListGroup
            listItems={files.map((f: File, i: number) => ({
              id: i.toString(),
              content: f.name,
              iconClass: getFileIcon(f.name),
              contextActions: [
                {
                  iconClass: 'icon-trash',
                  iconColorClass: 'text-black',
                  name: translation('delete'),
                  onClick: () => {
                    handleOnChange(
                      files.filter((fileToRemove) => fileToRemove !== f),
                    );
                  },
                },
              ],
              onlyOneActionAsButton: true,
            }))}
          />
        </>
      )}
    </CustomDialog>
  );
}

export default CreateAttachmentDialog;
