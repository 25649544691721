import { SyntheticEvent } from 'react';
import { paddingOffsetForTreeElements } from '../../../shared/constants';
import { IArticleTreeItem, ICategoryTreeItem } from '../../content-tree/types';
import ReferenceArticleTreeItem from './ReferenceArticleTreeItem';
import ReferenceCategoryTreeItem from './ReferenceCategoryTreeItem';

interface IReferenceTreeItemProps {
  isBranch: boolean;
  isExpanded: boolean;
  level: number;
  isSelected: boolean;
  getNodeProps: Function;
  element: IArticleTreeItem | ICategoryTreeItem;
  handleSelect: Function;
  handleExpand: Function;
  onlyHtmlArticleSelectable?: boolean;
  allowMultipleReferenceOnSameTarget?: boolean;
  allowDisabling?: boolean;
}

function ReferenceTreeItem({
  isBranch,
  isExpanded,
  isSelected,
  level,
  getNodeProps,
  element,
  handleExpand,
  handleSelect,
  onlyHtmlArticleSelectable,
  allowMultipleReferenceOnSameTarget,
  allowDisabling,
}: IReferenceTreeItemProps): JSX.Element {
  const isArticleElement: boolean = 'recentVersionId' in element;

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...getNodeProps({
        onClick: (e: SyntheticEvent) => {
          if (e.target instanceof HTMLInputElement) {
            return;
          }
          handleExpand(e);
        },
      })}
      style={{ paddingLeft: paddingOffsetForTreeElements * (level - 1) }}>
      <div className='d-flex'>
        {isBranch && isExpanded && (
          <i className='me-2 icon-carretup fs-5 mt-2' aria-hidden />
        )}
        {isBranch && !isExpanded && (
          <i className='me-2 icon-carretdown fs-5 mt-2' aria-hidden />
        )}
        {isArticleElement && (
          <ReferenceArticleTreeItem
            allowMultipleReferenceOnSameTarget={
              allowMultipleReferenceOnSameTarget
            }
            allowDisabling={allowDisabling}
            handleSelect={handleSelect}
            articleElement={element as IArticleTreeItem}
            isSelected={isSelected}
            onlyHtmlArticleSelectable={onlyHtmlArticleSelectable || false}
          />
        )}
        {!isArticleElement && (
          <ReferenceCategoryTreeItem
            allowMultipleReferenceOnSameTarget={
              allowMultipleReferenceOnSameTarget
            }
            allowDisabling={allowDisabling}
            categoryElement={element as ICategoryTreeItem}
            isSelected={isSelected}
            selectable={!onlyHtmlArticleSelectable}
            handleSelect={handleSelect}
          />
        )}
      </div>
    </div>
  );
}

ReferenceTreeItem.defaultProps = {
  onlyHtmlArticleSelectable: false,
  allowMultipleReferenceOnSameTarget: false,
  allowDisabling: true,
};

export default ReferenceTreeItem;
