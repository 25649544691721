import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ArticleType } from '../../../shared/enums';
import {
  getArticleIcon,
  getArticleTranslationKeyOfIconDescription,
} from '../../../shared/utils';
import { IArticleTreeItem } from '../../content-tree/types';
import DescriptiveIcon from '../../descriptive-icons/DescriptiveIcon';
import {
  articleIdUrlParam,
  categoryIdUrlParam,
} from '../../../shared/constants';
import {
  useGetApiArticlesReferencesByIdQuery,
  useGetApiCategoriesArticleReferencesByIdQuery,
} from '../../../redux/store/api/api';

interface IReferenceArticleTreeItemProps {
  articleElement: IArticleTreeItem;
  handleSelect: Function;
  isSelected: boolean;
  onlyHtmlArticleSelectable: boolean;
  allowMultipleReferenceOnSameTarget?: boolean;
  allowDisabling?: boolean;
}

function ReferenceArticleTreeItem({
  articleElement,
  handleSelect,
  isSelected,
  onlyHtmlArticleSelectable,
  allowMultipleReferenceOnSameTarget,
  allowDisabling,
}: IReferenceArticleTreeItemProps) {
  const { t: translation } = useTranslation();
  const [searchParams] = useSearchParams();
  const articleId: string | null = searchParams.get(articleIdUrlParam);
  const categoryId: string | null = searchParams.get(categoryIdUrlParam);
  const versionsOnlyDraftsOrDisabled =
    articleElement.versionsOnlyDrafts || articleElement.versionsDisabled;
  const isUnselectablePdfElement =
    articleElement.type === ArticleType.Pdf && onlyHtmlArticleSelectable;
  const disabledAricle: boolean =
    versionsOnlyDraftsOrDisabled ||
    articleElement.id === articleId ||
    articleElement.type === ArticleType.StructureElement ||
    isUnselectablePdfElement;
  const { data: referencesOfArticle } = useGetApiArticlesReferencesByIdQuery(
    articleId
      ? {
          id: articleId,
        }
      : skipToken,
  );
  const { data: referencesOfCategory } =
    useGetApiCategoriesArticleReferencesByIdQuery(
      categoryId ? { id: categoryId } : skipToken,
    );

  const getDescriptiveIconOfArticle = (type: ArticleType) => (
    <DescriptiveIcon
      iconClass={getArticleIcon(type)}
      description={translation(getArticleTranslationKeyOfIconDescription(type))}
    />
  );

  return (
    <>
      {disabledAricle && (
        <span className='text-muted'>
          {versionsOnlyDraftsOrDisabled ? (
            <DescriptiveIcon
              iconClass={
                articleElement.versionsOnlyDrafts
                  ? 'icon-entwurf'
                  : getArticleIcon(articleElement.type || ArticleType.Html)
              }
              description={
                articleElement.versionsOnlyDrafts
                  ? translation('onlyDraftsForThisArticle')
                  : translation('onlyDisabledForThisArticle')
              }
            />
          ) : (
            getDescriptiveIconOfArticle(articleElement.type)
          )}
          {articleElement.name}
        </span>
      )}
      {!disabledAricle && (
        <Form.Check
          tabIndex={-1}
          id={articleElement.id}
          onChange={(e) => {
            handleSelect(e);
            e.stopPropagation();
          }}
          checked={isSelected}
          type='checkbox'
          disabled={
            allowDisabling
              ? !allowMultipleReferenceOnSameTarget &&
                (!!referencesOfArticle?.resultObject?.articleReferenceLists?.find(
                  (r) =>
                    !!r.articleReferences?.find(
                      (a) => a.targetArticleId === articleElement.id,
                    ),
                ) ||
                  !!referencesOfCategory?.resultObject?.find(
                    (r) =>
                      !!r.articleReferences?.find(
                        (a) => a.targetArticleId === articleElement.id,
                      ),
                  ))
              : false
          }
          label={
            <>
              {getDescriptiveIconOfArticle(articleElement.type)}
              {articleElement.name}
            </>
          }
        />
      )}
    </>
  );
}

ReferenceArticleTreeItem.defaultProps = {
  allowMultipleReferenceOnSameTarget: false,
  allowDisabling: true,
};

export default ReferenceArticleTreeItem;
