import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useSessionStorage } from 'usehooks-ts';
import CustomDialog from '../../dialogs/CustomDialog';
import {
  Mark,
  Note,
  useGetApiNotesByVersionIdQuery,
  useGetApiSettingsUserQuery,
  usePostApiNotesMutation,
  usePutApiNotesByIdMutation,
} from '../../../redux/store/api/api';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { addMessage } from '../../../redux/store/layout/slice';
import {
  selectActiveVersion,
  setActiveNote,
  setExpandedNoteVisibilityGroup,
} from '../../../redux/store/content/slice';
import { defaultVisibilityGroup } from '../variables';
import { ExtendedNote, VisibilityGroupString } from '../types';
import { NoteVisibilityType } from '../../../shared/enums';
import {
  MARK_VISIBILITY_GROUP_KEY,
  settingTypeMaxNoteContentLength,
} from '../../../shared/constants';
import Loader from '../../loader/Loader';
import { getFirstWords } from '../functions';

type DialogType = 'create' | 'update';
interface ICreateUpdateNoteDialogProps {
  show: boolean;
  setShow: (value: boolean) => void;
  type: DialogType;
  note?: Note;
  setNote?: (note: Note | undefined) => void;
  newMark?: Mark;
  setNewMark?: (newMark: Mark | undefined) => void;
}

function CreateOrUpdateNoteDialog({
  show,
  setShow,
  type,
  note,
  setNote,
  newMark,
  setNewMark,
}: ICreateUpdateNoteDialogProps): JSX.Element {
  const { t: translation } = useTranslation();

  const defaultTitle = !newMark
    ? note?.name || ''
    : getFirstWords(newMark?.patternString) ?? '';
  const [titleValue, setTitleValue] = useState<string>(defaultTitle);
  const [titleIsValid, setTitleIsValid] = useState(true);
  const [contentEditorValue, setContentEditorValue] = useState<string>(
    note?.text || '',
  );
  const [activeMarkVisibilityGroup] =
    useSessionStorage<VisibilityGroupString | null>(
      MARK_VISIBILITY_GROUP_KEY,
      null,
    );

  const calculateVisibilityGroup = (): VisibilityGroupString => {
    if (newMark) {
      return activeMarkVisibilityGroup as VisibilityGroupString;
    }
    return NoteVisibilityType[
      note?.visibility != null ? note.visibility : defaultVisibilityGroup
    ] as VisibilityGroupString;
  };
  const [visibilityGroup, setVisibilityGroup] = useState<VisibilityGroupString>(
    calculateVisibilityGroup(),
  );
  const resetStates = (): void => {
    setTitleValue(defaultTitle);
    setContentEditorValue('');
    setVisibilityGroup(calculateVisibilityGroup());
    setTitleIsValid(true);

    if (setNewMark) {
      setNewMark(undefined);
    }
    if (setNote) {
      setNote(undefined);
    }
  };

  const version = useAppSelector(selectActiveVersion);
  const dispatch = useAppDispatch();
  const [
    addNote,
    {
      isError: addNoteIsError,
      isLoading: addNoteIsLoading,
      error: addNoteError,
    },
  ] = usePostApiNotesMutation();
  const [
    updateNote,
    {
      isError: updateNoteIsError,
      isLoading: updateNoteIsLoading,
      error: updateNoteError,
    },
  ] = usePutApiNotesByIdMutation();
  const isLoading = addNoteIsLoading || updateNoteIsLoading;
  const { refetch } = useGetApiNotesByVersionIdQuery(
    version.id
      ? {
          versionId: version.id,
        }
      : skipToken,
  );

  const [contentMaxIsError, setContentMaxIsError] = useState(false);
  const { data: settings } = useGetApiSettingsUserQuery();
  const contentMaxSetting =
    Number(
      settings?.resultObject?.find(
        (s) => s.settingType?.key === settingTypeMaxNoteContentLength,
      )?.value,
    ) || undefined;

  const handleCreateNote = () => {
    if (titleValue.trim().length === 0) {
      setTitleIsValid(false);
      return;
    }

    const newNote: Note = {
      name: titleValue,
      text: contentEditorValue,
      visibility: NoteVisibilityType[visibilityGroup],
      versionId: version.id,
      mark: newMark,
    };
    addNote({ note: newNote })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'CreateNoteSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        setShow(false);
        refetch();
        resetStates();
        dispatch(
          setExpandedNoteVisibilityGroup(
            newNote?.visibility?.toString() ||
              defaultVisibilityGroup.toString(),
          ),
        );
        window.getSelection()?.removeAllRanges();
        dispatch(setActiveNote((result.resultObject as ExtendedNote) || {}));
      });
  };

  const handleUpdateNote = () => {
    if (titleValue.trim().length === 0) {
      setTitleIsValid(false);
      return;
    }

    const changedNote: Note = {
      id: note?.id || '',
      name: titleValue,
      text: contentEditorValue,
      visibility: NoteVisibilityType[visibilityGroup],
      versionId: version.id,
    };
    updateNote({ id: note?.id || '', note: changedNote })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'UpdatNoteSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        resetStates();
        setShow(false);
        refetch();
        dispatch(setActiveNote(changedNote as ExtendedNote));
        dispatch(
          setExpandedNoteVisibilityGroup(
            changedNote?.visibility?.toString() ||
              defaultVisibilityGroup.toString(),
          ),
        );
      });
  };

  const getDialogTitle = (): string => {
    if (type === 'create') {
      if (newMark) {
        return translation('addNoteWithMark');
      }
      return translation('addNote');
    }
    return translation('editNote');
  };

  useEffect(() => {
    if (addNoteIsError) {
      dispatch(
        addMessage({
          id: 'AddNoteError',
          variant: 'danger',
          messageKeyBody:
            addNoteError && 'data' in addNoteError
              ? addNoteError.data?.messageKey
              : 'unknownError',
        }),
      );
    }

    if (updateNoteIsError) {
      dispatch(
        addMessage({
          id: 'UpdateNoteError',
          variant: 'danger',
          messageKeyBody:
            updateNoteError && 'data' in updateNoteError
              ? updateNoteError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [addNoteIsError, updateNoteIsError]);

  useEffect(() => {
    if (show) {
      setTitleValue(defaultTitle);
      setContentEditorValue(note?.text || '');
      setVisibilityGroup(calculateVisibilityGroup());
    }
  }, [show]);

  useEffect(() => {
    setVisibilityGroup(calculateVisibilityGroup());
  }, [newMark]);

  return (
    <CustomDialog
      titleId='CreateOrUpdateNoteDialog'
      show={show}
      closeFunction={() => {
        resetStates();
        setShow(false);
        if (type === 'update') {
          dispatch(setActiveNote({}));
        }
      }}
      actionFunction={() => {
        if (type === 'create') {
          handleCreateNote();
        } else {
          handleUpdateNote();
        }
      }}
      dialogTitle={getDialogTitle()}
      closeTitle={translation('cancel')}
      actionTitle={type === 'create' ? translation('add') : translation('save')}
      actionButtonDisabled={isLoading || contentMaxIsError}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          {note?.lastModifiedBy && (
            <div className='mb-3 text-muted'>
              <i className='icon-edit fs-5 align-text-top me-2' aria-hidden />
              {`${translation('lastModifiedBy')}: ${note.lastModifiedBy}`}
            </div>
          )}
          <Form>
            <p>{translation('fieldsAreRequiredLegend')}</p>
            <Form.Group className='mb-3' controlId='NoteTitleInput'>
              <Form.Label>{translation('titleOfTheNote')}*</Form.Label>
              <Form.Control
                required
                isInvalid={!titleIsValid}
                aria-describedby={titleIsValid ? undefined : 'TitleInputError'}
                type='text'
                placeholder={translation('inputTitle')}
                value={titleValue}
                onChange={(e) => {
                  setTitleValue(e.target.value);
                  if (e.target.value.trim().length > 0) {
                    setTitleIsValid(true);
                  }
                }}
              />
              <Form.Control.Feedback id='TitleInputError' type='invalid'>
                {translation('fieldNotEmpty')}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId='NoteContentInput'>
              <Form.Label>{translation('contentOfTheNote')}</Form.Label>
              <Form.Control
                aria-describedby={
                  contentMaxIsError ? 'ContentInputError' : undefined
                }
                isInvalid={contentMaxIsError}
                value={contentEditorValue}
                as='textarea'
                rows={7}
                onChange={(e) => {
                  setContentEditorValue(e.target.value);
                }}
                onBlur={(e) => {
                  if (
                    contentMaxSetting &&
                    e.target.value.length > contentMaxSetting
                  ) {
                    setContentMaxIsError(true);
                  } else {
                    setContentMaxIsError(false);
                  }
                }}
                placeholder={translation('inputNoteContent')}
              />
              <Form.Control.Feedback id='ContentInputError' type='invalid'>
                {translation('noteContentToLongError', {
                  max: contentMaxSetting,
                })}
              </Form.Control.Feedback>
            </Form.Group>
            {newMark && (
              <p className='mt-2'>
                {`${translation('noteWillCreateMarkInContent')} "${
                  newMark.patternString
                }"`}
              </p>
            )}
          </Form>
        </>
      )}
    </CustomDialog>
  );
}

CreateOrUpdateNoteDialog.defaultProps = {
  note: { visibilityGroup: defaultVisibilityGroup, title: '', content: '' },
  setNote: () => {},
  newMark: null,
  setNewMark: () => {},
};

export default CreateOrUpdateNoteDialog;
