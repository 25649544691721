import { Form } from 'react-bootstrap';
import TreeView from 'react-accessible-treeview';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectActiveNote,
  selectActiveVersion,
  selectAddMarkBookmarkDialogOpened,
  setAddMarkBookmarkDialogOpened,
} from '../../../redux/store/content/slice';
import {
  useGetApiBookmarkFoldersQuery,
  usePostApiBookmarksMutation,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import {
  BookmarkTreeItems,
  IBookmarkFolderTreeItem,
} from '../../bookmarks/types';
import { getBookmarkFolderTreeItems } from '../../bookmarks/functions';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';
import BookmarkFolderTreeItem from '../../version/dialogs/bookmarks/BookmarkFolderTreeItem';
import AddBookmarkFolderDialog from '../../bookmarks/dialog/AddBookmarkFolderDialog';
import RenameBookmarkFolderDialog from '../../bookmarks/dialog/RenameBookmarkFolderDialog';
import DeleteBookmarkFolderDialog from '../../bookmarks/dialog/DeleteBookmarkFolderDialog';

function CreateMarkBookmarkDialog() {
  const dispatch = useAppDispatch();
  const dialogShow = useAppSelector(selectAddMarkBookmarkDialogOpened);
  const { t: translation } = useTranslation();
  const [addBookmarkFolderDialogOpened, setAddBookmarkFolderDialogOpened] =
    useState(false);
  const [
    renameBookmarkFolderDialogOpened,
    setRenameBookmarkFolderDialogOpened,
  ] = useState(false);
  const [
    deleteBookmarkFolderDialogOpened,
    setDeleteBookmarkFolderDialogOpened,
  ] = useState(false);
  const [lastUpdatedItem, setLastUpdatedItem] = useState<string>();
  const [bookmarkName, setBookmarkName] = useState('');
  const [isValidName, setIsValidName] = useState(true);
  const note = useAppSelector(selectActiveNote);
  const version = useAppSelector(selectActiveVersion);
  const [selectedBookmarkFolderId, setSelectedBookmarkFolderId] = useState<
    string | null
  >(null);
  const [isValidBookmarkFolder, setIsValidBookmarkFolder] = useState(true);
  const { data: bookmarkTreeData, refetch: refetchBookmarkTree } =
    useGetApiBookmarkFoldersQuery();
  const [addBookmark, { isLoading, isError, error }] =
    usePostApiBookmarksMutation();

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'CreateMarkError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  const treeData = useMemo<BookmarkTreeItems>(() => {
    if (bookmarkTreeData?.resultObject) {
      return getBookmarkFolderTreeItems(bookmarkTreeData.resultObject);
    }
    return [];
  }, [bookmarkTreeData]);

  const handleAddBookmark = () => {
    if (bookmarkName.trim().length === 0 || !selectedBookmarkFolderId) {
      if (bookmarkName.trim().length === 0) {
        setIsValidName(false);
      }

      if (!selectedBookmarkFolderId) {
        setIsValidBookmarkFolder(false);
      }

      return;
    }

    addBookmark({
      bookmark: {
        name: bookmarkName.trim(),
        bookmarkFolderId: selectedBookmarkFolderId,
        versionId: note.versionId || '',
        articleId: version.id || '',
        markId: note.mark?.id || '',
      },
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'CreateMarkSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        setBookmarkName('');
        setSelectedBookmarkFolderId('');
        setIsValidName(true);
        setIsValidBookmarkFolder(true);
        dispatch(setAddMarkBookmarkDialogOpened(false));
        refetchBookmarkTree();
      });
  };

  return (
    <>
      <CustomDialog
        titleId='CreateMarkBookmarkDialog'
        show={dialogShow}
        closeFunction={() => {
          setBookmarkName('');
          setSelectedBookmarkFolderId('');
          setIsValidName(true);
          setIsValidBookmarkFolder(true);
          dispatch(setAddMarkBookmarkDialogOpened(false));
        }}
        actionFunction={handleAddBookmark}
        actionTitle={translation('add')}
        closeTitle={translation('close')}
        dialogTitle={translation('addBookmark')}
        actionButtonDisabled={isLoading}>
        {isLoading && <Loader />}
        {!isLoading && (
          <>
            <p>{translation('fieldsAreRequiredLegend')}</p>
            <Form.Group className='mb-3' controlId='BookmarkName'>
              <Form.Label>{translation('name')}*</Form.Label>
              <Form.Control
                required
                aria-describedby={
                  isValidName ? undefined : 'BookmarkNameInputErrors'
                }
                isInvalid={!isValidName}
                onChange={(e) => {
                  setBookmarkName(e.target.value);
                  if (e.target.value.trim().length > 0) {
                    setIsValidName(true);
                  }
                }}
                type='text'
                value={bookmarkName}
              />
              <Form.Control.Feedback id='BookmarkNameInputError' type='invalid'>
                {translation('fieldNotEmpty')}
              </Form.Control.Feedback>
            </Form.Group>
            <fieldset
              aria-describedby={
                isValidBookmarkFolder ? undefined : 'BookmarkFolderIsError'
              }>
              <legend className='mb-1 fs-6'>
                {translation('chooseAFolder')}*
              </legend>
              <TreeView
                onBlur={() => {
                  setLastUpdatedItem(undefined);
                }}
                focusedId={lastUpdatedItem}
                className='mb-2'
                data={treeData}
                onSelect={(e) => {
                  if (e.isSelected) {
                    setIsValidBookmarkFolder(true);
                    setSelectedBookmarkFolderId(
                      (e.element as IBookmarkFolderTreeItem).id,
                    );
                  }
                }}
                nodeRenderer={({
                  element,
                  getNodeProps,
                  handleExpand,
                  level,
                  isExpanded,
                  isBranch,
                  handleSelect,
                  isSelected,
                }) =>
                  BookmarkFolderTreeItem({
                    level,
                    isBranch,
                    isExpanded,
                    handleExpand,
                    getNodeProps,
                    handleSelect,
                    isSelected,
                    setAddBookmarkFolderDialogOpened,
                    setRenameBookmarkFolderDialogOpened,
                    setDeleteBookmarkFolderDialogOpened,
                    element: element as IBookmarkFolderTreeItem,
                  })
                }
              />
              {!isValidBookmarkFolder && (
                <Form.Control.Feedback
                  id='BookmarkFolderIsError'
                  type='invalid'
                  className='d-block'>
                  {translation('fieldNotEmpty')}
                </Form.Control.Feedback>
              )}
            </fieldset>
          </>
        )}
      </CustomDialog>
      <AddBookmarkFolderDialog
        dialogShow={addBookmarkFolderDialogOpened}
        setDialogShow={setAddBookmarkFolderDialogOpened}
        setLastUpdatedItem={setLastUpdatedItem}
      />
      <RenameBookmarkFolderDialog
        dialogShow={renameBookmarkFolderDialogOpened}
        setDialogShow={setRenameBookmarkFolderDialogOpened}
        setLastUpdatedItem={setLastUpdatedItem}
      />
      <DeleteBookmarkFolderDialog
        dialogShow={deleteBookmarkFolderDialogOpened}
        setDialogShow={setDeleteBookmarkFolderDialogOpened}
      />
    </>
  );
}

export default CreateMarkBookmarkDialog;
