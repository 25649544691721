import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router';
import Loader from '../loader/Loader';
import { addMessage } from '../../redux/store/layout/slice';
import { useAppDispatch } from '../../redux/hooks';

function ViewFallback() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    dispatch(
      addMessage({
        id: 'ViewError',
        messageKeyHeader: 'error',
        messageKeyBody: 'unknownError',
        variant: 'danger',
        timeInMs: 6000,
      }),
    );
    navigate('/');
  }, []);

  return <Loader />;
}

export default ViewFallback;
