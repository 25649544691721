import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';
import { useGetApiSettingsUserQuery } from '../../../redux/store/api/api';
import { settingTypeSupportText } from '../../../shared/constants';

interface ISupportTextDialogProps {
  show: boolean;
  setShow: (show: boolean) => void;
}

function SupportTextDialog({
  show,
  setShow,
}: ISupportTextDialogProps): JSX.Element {
  const { t: translation } = useTranslation();
  const { data: settings, isFetching } = useGetApiSettingsUserQuery();
  const supportSetting = settings?.resultObject?.find(
    (s) => s.settingType?.key === settingTypeSupportText,
  );

  return (
    <CustomDialog
      dialogId='SupportTextDialog'
      titleId='SupportTextDialogTitle'
      dialogTitle={translation('support')}
      show={show}
      closeFunction={() => {
        setShow(false);
      }}
      closeTitle={translation('close')}>
      {isFetching && <Loader />}
      {!isFetching && supportSetting?.value && (
        <>{parse(supportSetting.value || '')}</>
      )}
    </CustomDialog>
  );
}

export default SupportTextDialog;
