import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Setting } from '../../../redux/store/api/api';
import SettingInput from '../../inputs/SettingInput';
import { DataType } from '../../../shared/enums';
import {
  fileExtensions,
  settingTypeAllowedFileTypes,
} from '../../../shared/constants';

interface SettingItemProps {
  setting: Setting;
  saveFunction: (id: string, value?: string, listValues?: string[]) => void;
  userCanEditSettings: boolean;
}

function SettingItem({
  setting,
  saveFunction,
  userCanEditSettings,
}: SettingItemProps): JSX.Element {
  const { t: translation } = useTranslation();
  const defaultValue = setting.value || '';
  const [value, setValue] = useState<string>(defaultValue);
  const [listValues, setListValues] = useState<string[]>([]);

  const resetValue = () => {
    if (setting.settingType?.dataType === DataType.List) {
      setListValues(setting.value?.split(',') || []);
      return;
    }

    setValue(defaultValue);
  };

  useEffect(() => {
    resetValue();
  }, [setting]);

  const valueChanged =
    (setting.settingType?.dataType !== DataType.List &&
      value.trim() !== (setting.value ?? '')) ||
    (setting.settingType?.dataType === DataType.List &&
      listValues.toString() !== '' &&
      setting.value !== listValues.toString());

  return (
    <div className='mb-3'>
      <SettingInput
        controlId={setting.id || ''}
        label={translation(setting.settingType?.key || '')}
        setValue={(val) => {
          setValue(val);
        }}
        value={value}
        setListValues={
          setting.settingType?.dataType === DataType.List
            ? setListValues
            : undefined
        }
        listValues={
          setting.settingType?.dataType === DataType.List
            ? listValues
            : undefined
        }
        possibleValues={
          setting.settingType?.key === settingTypeAllowedFileTypes
            ? fileExtensions
            : undefined
        }
        dataType={setting.settingType?.dataType || DataType.String}
        userCanEditSetting={userCanEditSettings}
      />
      <div>
        {setting.settingType?.dataType !== DataType.Text && valueChanged && (
          <Button
            className='mt-2 me-2'
            variant='outline-dark'
            onClick={() => {
              resetValue();
            }}>
            {translation('reset')}
          </Button>
        )}
        {valueChanged && (
          <Button
            className='mt-2'
            variant='outline-success'
            onClick={() => {
              saveFunction(
                setting.id || '',
                setting.settingType?.dataType !== DataType.List
                  ? value
                  : undefined,
                setting.settingType?.dataType === DataType.List
                  ? listValues
                  : undefined,
              );
            }}>
            {translation('save')}
          </Button>
        )}
      </div>
    </div>
  );
}

export default SettingItem;
