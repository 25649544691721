import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface IUserGroupFormProps {
  userGroupName: string;
  maxUsersInGroup: number;
  maxUsersInTreeOfGroup: number;
  unlimitedUsersInGroup: boolean;
  unlimitedUsersInTreeOfGroup: boolean;
  setUserGroupName: (name: string) => void;
  setMaxUsersInGroup: (maxUserInGroup: number) => void;
  setMaxUsersInTreeOfGroup: (maxUsersInTree: number) => void;
  setUnlimitedUsersInGroup: (unlimitedUsersInGroup: boolean) => void;
  setUnlimitedUsersInTreeOfGroup: (
    unlimitedUsersInTreeOfGroup: boolean,
  ) => void;
  userGroupNameIsValid: boolean;
  setUserGroupNameIsValid: (valid: boolean) => void;
}

function UserGroupForm({
  userGroupName,
  maxUsersInGroup,
  maxUsersInTreeOfGroup,
  unlimitedUsersInGroup,
  unlimitedUsersInTreeOfGroup,
  setUserGroupName,
  setMaxUsersInGroup,
  setMaxUsersInTreeOfGroup,
  setUnlimitedUsersInGroup,
  setUnlimitedUsersInTreeOfGroup,
  userGroupNameIsValid,
  setUserGroupNameIsValid,
}: IUserGroupFormProps): JSX.Element {
  const { t: translation } = useTranslation();

  return (
    <Form>
      <p>{translation('fieldsAreRequiredLegend')}</p>
      <Form.Group className='mb-3' controlId='UserGroupName'>
        <Form.Label>{translation('nameOfUserGroup')}*</Form.Label>
        <Form.Control
          required
          aria-describedby={
            userGroupNameIsValid ? undefined : 'GroupNameInputError'
          }
          isInvalid={!userGroupNameIsValid}
          onChange={(e) => {
            setUserGroupName(e.target.value);
            if (e.target.value.length > 0) {
              setUserGroupNameIsValid(true);
            }
          }}
          value={userGroupName}
          type='text'
        />
        <Form.Control.Feedback id='GroupNameInputError' type='invalid'>
          {translation('fieldNotEmpty')}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className='mb-3'>
        <Form.Check
          type='checkbox'
          id='UnlimitedUserGroupMember'
          label={translation('unlimitedUsers')}
          checked={unlimitedUsersInGroup}
          onChange={() => {
            /* Add onChange to disable warning */
          }}
          onClick={() => {
            setUnlimitedUsersInGroup(!unlimitedUsersInGroup);
            setMaxUsersInGroup(-1);
          }}
        />
        <Form.Label htmlFor='MaxUsersInGroupInput'>
          {translation('unlimitedUsersInThisGroup')}
        </Form.Label>
        <Form.Control
          id='MaxUsersInGroupInput'
          disabled={unlimitedUsersInGroup}
          onChange={(e) => {
            const currentValue = Number(e.target.value);
            const integerMax = 2147483647;
            if (currentValue > integerMax) {
              return;
            }
            setMaxUsersInGroup(Number(e.target.value));
          }}
          value={
            maxUsersInGroup === -1 || maxUsersInGroup === 0
              ? ''
              : maxUsersInGroup
          }
          type='number'
        />
      </Form.Group>
      <Form.Group className='mb-3'>
        <Form.Check
          type='checkbox'
          id='UnlimitedUserGroupTreeMember'
          label={translation('unlimitedUsers')}
          checked={unlimitedUsersInTreeOfGroup}
          onChange={() => {
            /* Add onChange to disable warning */
          }}
          onClick={() => {
            setUnlimitedUsersInTreeOfGroup(!unlimitedUsersInTreeOfGroup);
            setMaxUsersInTreeOfGroup(-1);
          }}
        />
        <Form.Label htmlFor='MaxUsersInTreeOfGroupInput'>
          {translation('unlimitedUsersInTreeOfGroup')}
        </Form.Label>
        <Form.Control
          id='MaxUsersInTreeOfGroupInput'
          disabled={unlimitedUsersInTreeOfGroup}
          onChange={(e) => {
            setMaxUsersInTreeOfGroup(Number(e.target.value));
          }}
          value={
            maxUsersInTreeOfGroup === -1 || maxUsersInTreeOfGroup === 0
              ? ''
              : maxUsersInTreeOfGroup
          }
          type='number'
        />
      </Form.Group>
    </Form>
  );
}

export default UserGroupForm;
