import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';
import { ExternalReference } from '../../../redux/store/api/api';
import {
  EDIT_MODE_KEY,
  REFERENCE_LIST_ITEM_ID_EXTENSION,
  externalReferencesAccordionKey,
} from '../../../shared/constants';
import AccordionItem from '../../accordion/AccordionItem';
import { ContextAction } from '../../dropdown-menus/types';
import CustomListGroup from '../../lists/CustomListGroup';
import { ContextReferenceType } from '../types';
import { useAppSelector } from '../../../redux/hooks';
import { selectActiveVersion } from '../../../redux/store/content/slice';

interface IExternalReferencesProps {
  setActiveReferenceListKey: (key: string) => void;
  activeReferenceListKey: string;
  externalReferences: ExternalReference[] | undefined | null;
  setContextReference: (contextReference: ContextReferenceType) => void;
  setDeleteDialogShow: (show: boolean) => void;
  setRenameDialogShow: (show: boolean) => void;
  userCanEditReference: boolean;
}

function ExternalReferences({
  setActiveReferenceListKey,
  activeReferenceListKey,
  externalReferences,
  setContextReference,
  setDeleteDialogShow,
  setRenameDialogShow,
  userCanEditReference,
}: IExternalReferencesProps) {
  const { t: translation } = useTranslation();
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);
  const activeVersion = useAppSelector(selectActiveVersion);

  const getContextActions = (
    reference: ContextReferenceType,
  ): ContextAction[] => {
    const actions: ContextAction[] = [];

    actions.push({
      iconClass: 'icon-umbenennen',
      iconColorClass: 'text-body',
      name: translation('renameReference'),
      onClick: () => {
        setContextReference(reference);
        setRenameDialogShow(true);
      },
    });

    actions.push({
      iconClass: 'icon-trash',
      iconColorClass: 'text-danger',
      name: translation('deleteReference'),
      onClick: () => {
        setContextReference(reference);
        setDeleteDialogShow(true);
      },
    });

    return actions;
  };

  return (
    <div>
      {externalReferences && externalReferences.length > 0 && (
        <AccordionItem
          title={
            <>
              <i
                aria-hidden
                className='icon-externer_link text-primary me-1 fs-5 align-text-bottom'
              />
              {translation('externalReferences')}
            </>
          }
          setActiveKey={setActiveReferenceListKey}
          activeKey={activeReferenceListKey}
          eventKey={externalReferencesAccordionKey}>
          <CustomListGroup
            listItems={externalReferences
              .filter(
                (er) => !er.versionId || er.versionId === activeVersion.id,
              )
              .map((r) => ({
                id: `${r.id}${REFERENCE_LIST_ITEM_ID_EXTENSION}` || '',
                iconClass: 'icon-externer_link text-primary',
                content: r.name || '',
                href: r.hyperlink || '',
                openInNewTab: true,
                contextActions:
                  editModeIsActive && userCanEditReference
                    ? getContextActions({
                        reference: r,
                        categoryTypeId: externalReferencesAccordionKey,
                      })
                    : undefined,
              }))}
            forwardItemIdsToHtml
          />
        </AccordionItem>
      )}
    </div>
  );
}

export default ExternalReferences;
